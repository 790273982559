import { pathOr } from "ramda"

export function membershipInviteToBillingForm(membershipInvite) {
  return {
    email: membershipInvite.email,
    contactName: `${pathOr("", ["firstName"], membershipInvite)} ${pathOr(
      "",
      ["lastName"],
      membershipInvite,
    )}`.trim(),
    businessName: membershipInvite.companyName,
    street: membershipInvite.street,
    houseno: membershipInvite.houseno,
    city: membershipInvite.city,
    zip: membershipInvite.zip,
    country: membershipInvite.country,
    vatNumber: "",
  }
}
