import React from "react"
import { Formik, Form, Field } from "formik"
import { useHistory } from "react-router-dom"
import { useApi } from "../../providers/api-provider"
import { CLIENT_ERROR } from "../../constants"
import { Input } from "../../components/input"
import { Button } from "../../components/button"
import { BackButton } from "../../components/backbutton"
import { accountValidator } from "./account-validator"
import { toast } from "react-toastify"
import { toastError } from "../../components/error"
import { reverse } from "named-urls"
import { Trans, t } from "@lingui/macro"
import { urls } from "../../urls"
import {
  Col,
  Row,
  InputGroupText,
  Container,
  Card,
  CardGroup,
  CardBody,
  FormGroup,
} from "reactstrap"

export function AccountForm() {
  const history = useHistory()
  const api = useApi()

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <BackButton />
              <CardBody>
                <Formik
                  enableReinitialize
                  initialValues={{
                    name: "",
                  }}
                  validate={accountValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      await api.createAccount(values)

                      toast.success("Saved")

                      history.push(reverse(urls.booking.calendar))
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors({})
                      }
                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <h1>
                        <Trans>Account</Trans>
                      </h1>
                      <p className="text-muted">
                        <Trans>
                          Please create a name for your company or personal
                          account.
                        </Trans>
                      </p>

                      <Field
                        id="name"
                        type="text"
                        name="name"
                        component={Input}
                        label={t`Name`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-arrow-right" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <FormGroup className="form-actions">
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={!dirty || !isValid || isSubmitting}
                          color="primary"
                          block
                        >
                          <Trans>Submit</Trans>
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

AccountForm.propTypes = {}

AccountForm.defaultProps = {}
