import React from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { reverse } from "named-urls"
import { urls } from "../../../urls"
import { priceFromCents, renderPrice } from "../../../lib/helpers"
import humanizeDuration from "humanize-duration"
import { MONTH, getBookingDurationUnitName } from "../../resource"
import {
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardImg,
  CardText,
  Spinner as RSpinner,
} from "reactstrap"
import { Trans } from "@lingui/macro"
import { useMembership } from "../../membership"

function ResourceInfoCardEmptyState() {
  return (
    <Card className="mb-0">
      <CardHeader title="true">
        <CardTitle className="mb-0">
          <b>
            <Trans>Resource Name</Trans>
          </b>{" "}
          N/A
        </CardTitle>
      </CardHeader>
      <CardBody>
        <Trans>No data!</Trans>
      </CardBody>
    </Card>
  )
}
export default function ResourceInfoCard({ resource }) {
  const { selectedMembership } = useMembership()
  if (resource === null) return <ResourceInfoCardEmptyState />

  if (!resource) return <RSpinner />

  return (
    <Card className="mb-0">
      <CardHeader title="true">
        <CardTitle className="mb-0">
          <b>
            <Trans>Resource Name</Trans>
          </b>{" "}
          <Link
            to={reverse(urls.resource.details, { resourceId: resource.id })}
          >
            {resource.name}
          </Link>
        </CardTitle>
      </CardHeader>
      <CardBody>
        <CardImg
          top
          src={resource.img || "https://via.placeholder.com/300"}
          className="img-fluid mb-1"
        />
        {resource.description.split("\n").map((d, idx) => (
          <CardText key={idx}>{d}</CardText>
        ))}

        <CardText className="font-lg mb-0">
          <span className="text-muted">
            <Trans>Type</Trans>:
          </span>{" "}
          {resource.resourceType.name}
        </CardText>

        <span className="font-lg text-muted">
          <Trans>Bookable by</Trans>:{" "}
        </span>
        <ul>
          {resource.resourceType.bookingDurationUnits
            .filter((u) => u !== MONTH)
            .map((u) => (
              <li key={u} className="font-lg">
                {getBookingDurationUnitName(u)}
                {selectedMembership.location.billingEnabled && (
                  <>
                    {resource.prices && " - "}
                    {resource.prices &&
                      renderPrice(
                        priceFromCents(
                          resource.prices.find(
                            (p) => p.bookingDurationUnit === u,
                          ),
                        ),
                      )}
                  </>
                )}
              </li>
            ))}
        </ul>

        {Boolean(resource.timeBuffer) && (
          <div className="font-sm mb-0">
            <span className="text-muted">
              <Trans>Time buffer</Trans>:{" "}
            </span>
            <span>{humanizeDuration(resource.timeBuffer)}</span>
          </div>
        )}
      </CardBody>
    </Card>
  )
}

ResourceInfoCard.propTypes = {
  resource: PropTypes.object,
}
