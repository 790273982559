import { Trans } from "@lingui/macro"
import React from "react"
import { useHistory } from "react-router-dom"
import { Container, Row, Col, Button } from "reactstrap"

export function BookingIsPrivacyProtected() {
  const history = useHistory()

  return (
    <Container className="py-4 h-100">
      <Row className="h-100">
        <Col sm="12" md={{ size: 6, offset: 3 }} className="align-self-center">
          <p className="lead">
            <i className="far fa-lightbulb" />{" "}
            <Trans>
              Privacy settings do not allow you to see these details.
            </Trans>
          </p>
          <p className="lead">
            <Button
              color="primary"
              className="float-right"
              onClick={() => history.goBack()}
            >
              <Trans>Go Back</Trans>
            </Button>
          </p>
        </Col>
      </Row>
    </Container>
  )
}

BookingIsPrivacyProtected.propTypes = {}
BookingIsPrivacyProtected.defaultProps = {}
