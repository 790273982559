import React, { useContext, useCallback, useEffect, useReducer } from "react"
import PropTypes from "prop-types"
import { ApiContext } from "../../providers"
import { path, update } from "ramda"
import { getActionType } from "../../lib/helpers"
import MembershipDoesNotExist from "./membership-empty-state"
import {
  GET,
  LIST,
  MEMBERSHIP,
  SUCCESS,
  FAIL,
  START,
  CREATE,
  UPDATE,
  RESET,
  SELECT,
} from "../../constants"
import {
  isMembershipActive,
  isMembershipArchived,
  isMembershipCanceled,
} from "./helpers"
import SelectedMembershipisNotActiveState from "./selected-membership-is-not-active-state"

export const MembershipContext = React.createContext()

export function useMembership() {
  return useContext(MembershipContext)
}

const defaultState = {
  selectedMembershipId:
    parseInt(localStorage.getItem("selectedMembershipId"), 10) || null,
  memberships: [],
  count: null,
  error: null,
  isPending: true,
}

function membershipReducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, MEMBERSHIP, LIST, START): {
      return state
    }

    case getActionType(GET, MEMBERSHIP, LIST, SUCCESS): {
      return {
        ...state,
        memberships: payload.memberships,
        count: payload.count,
        error: null,
        isPending: false,
      }
    }

    case getActionType(GET, MEMBERSHIP, LIST, FAIL): {
      return { ...defaultState, error: payload.error, isPending: false }
    }

    case getActionType(GET, MEMBERSHIP, START): {
      return state
    }

    case getActionType(GET, MEMBERSHIP, SUCCESS): {
      const index = state.memberships.findIndex(
        (m) => m.id === payload.membership.id,
      )

      return {
        ...state,
        memberships: update(index, payload.membership, state.memberships),
        error: null,
      }
    }

    case getActionType(GET, MEMBERSHIP, FAIL): {
      return { ...defaultState, error: payload.error }
    }

    case getActionType(CREATE, MEMBERSHIP, SUCCESS): {
      return {
        ...state,
        memberships: [...state.memberships, payload.membership],
        count: state.count + 1,
      }
    }

    case getActionType(UPDATE, MEMBERSHIP, SUCCESS): {
      const index = state.memberships.findIndex(
        (m) => m.id === payload.membership.id,
      )

      return {
        ...state,
        memberships: update(index, payload.membership, state.memberships),
      }
    }

    case getActionType(SELECT, MEMBERSHIP): {
      return { ...state, selectedMembershipId: payload.membershipId }
    }

    case getActionType(RESET): {
      return { ...defaultState, selectedMembershipId: null, isPending: false }
    }

    default:
      return state
  }
}

export const MembershipProvider = ({ children }) => {
  const api = useContext(ApiContext)

  const [state, dispatch] = useReducer(membershipReducer, defaultState)

  const { memberships, count, error, isPending, selectedMembershipId } = state

  const getMembershipList = useCallback(async () => {
    try {
      dispatch({ type: getActionType(GET, MEMBERSHIP, LIST, START) })

      const { memberships, count } = await api.getMembershipList()

      getMembershipListSuccess({ memberships, count })
    } catch (error) {
      if (path(["response", "status"], error) !== 404) {
        dispatch({
          type: getActionType(GET, MEMBERSHIP, LIST, FAIL),
          payload: { error },
        })
      } else {
        getMembershipListSuccess({ memberships: [], count: 0 })
      }

      if (!error.isAxiosError) {
        throw error
      }
    }
  }, [api, dispatch])

  const getMembership = useCallback(
    async (membershipId) => {
      try {
        dispatch({ type: getActionType(GET, MEMBERSHIP, START) })

        const membership = await api.getMembership({ membershipId })

        dispatch({
          type: getActionType(GET, MEMBERSHIP, SUCCESS),
          payload: { membership },
        })
      } catch (error) {
        dispatch({
          type: getActionType(GET, MEMBERSHIP, FAIL),
          payload: { error },
        })

        if (!error.isAxiosError) {
          throw error
        }
      }
    },
    [api],
  )

  function getMembershipListSuccess({ memberships, count }) {
    return dispatch({
      type: getActionType(GET, MEMBERSHIP, LIST, SUCCESS),
      payload: { memberships, count },
    })
  }

  function createMembershipSuccess(membership) {
    return dispatch({
      type: getActionType(CREATE, MEMBERSHIP, SUCCESS),
      payload: { membership },
    })
  }

  function updateMembershipSuccess(membership) {
    return dispatch({
      type: getActionType(UPDATE, MEMBERSHIP, SUCCESS),
      payload: { membership },
    })
  }

  const selectMembership = useCallback(
    (membershipId) => {
      localStorage.setItem("selectedMembershipId", membershipId)

      return dispatch({
        type: getActionType(SELECT, MEMBERSHIP),
        payload: { membershipId },
      })
    },
    [dispatch],
  )

  function reset() {
    localStorage.removeItem("selectedMembershipId")
    return dispatch({ type: getActionType(RESET) })
  }

  useEffect(() => {
    getMembershipList()
  }, [getMembershipList])

  if (error && path(["response", "status"], error) !== 401) {
    throw error
  }

  const selectedMembership = memberships.find(
    (m) => m.id === selectedMembershipId,
  )

  const activeMembersips = memberships.filter(isMembershipActive)
  const canceledMembersips = memberships.filter(isMembershipCanceled)
  const archivedMembersips = memberships.filter(isMembershipArchived)

  useEffect(() => {
    if (count > 0 && !selectedMembershipId) {
      const activeMembership = memberships.filter(isMembershipActive)[0]

      if (activeMembership) {
        selectMembership(activeMembership.id)
      }
    }
  }, [count, selectMembership, selectedMembershipId, memberships])

  return (
    <MembershipContext.Provider
      value={{
        isPending,
        error,
        memberships,
        activeMembersips,
        canceledMembersips,
        archivedMembersips,
        count,
        getMembershipList,
        getMembership,
        getMembershipListSuccess,
        createMembershipSuccess,
        updateMembershipSuccess,
        selectedMembership,
        selectMembership,
        reset,
      }}
    >
      {children}
    </MembershipContext.Provider>
  )
}

MembershipProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

MembershipProvider.defaultProps = {}

export const WithMembershipRequired = ({ children }) => {
  const { selectedMembership } = useContext(MembershipContext)

  if (!selectedMembership) {
    return <MembershipDoesNotExist />
  }

  if (!isMembershipActive(selectedMembership)) {
    return <SelectedMembershipisNotActiveState />
  }

  return children
}

WithMembershipRequired.propTypes = {
  children: PropTypes.node.isRequired,
}

WithMembershipRequired.defaultProps = {}
