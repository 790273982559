import React, { useState, useCallback } from "react"
import PropTypes from "prop-types"
import { useApi } from "../../providers"
import { FormGroup, Label, Input, FormText } from "reactstrap"
import { Button } from "../button"
import { ErrorMessage } from "formik"
import { FormFeedback } from "./form-feedback"
import { toast } from "react-toastify"
import { toastError } from "../../components/error"
import { useDropzone } from "react-dropzone"
import { t } from "@lingui/macro"
import { MIME_TYPES_OF } from "../../constants"

export function FileUploaderInput({
  id,
  field,
  form,
  onUploadSuccess,
  acceptedTypesOf,
  className,
  disabled,
  label,
  color,
  outline,
  description,
  hint,
  alt,
  imgWidth,
  imgHeight,
  buttonText,
  buttonStyle,
  hideThumbnail,
  showDownloadLink,
}) {
  const api = useApi()
  const [isUploading, setIsUploading] = useState(false)

  const onDrop = useCallback(
    (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader()
        reader.onabort = () => toast.error(t`File reading was aborted`)
        reader.onerror = () => toast.error(t`File reading has failed`)
        reader.onload = async () => {
          if (
            acceptedTypesOf !== "any" &&
            !MIME_TYPES_OF[acceptedTypesOf].includes(file.type)
          ) {
            reader.abort()
            toast.error(t`File format "${file.type}" not supported`)
            return
          }

          try {
            setIsUploading(true)

            const { url: uploadUrl } = await api.createS3SignedUrlToPutObject({
              type: file.type,
            })

            await api.uploadObjectToS3({
              url: uploadUrl,
              type: file.type,
              object: reader.result,
            })

            const url = new URL(uploadUrl)
            const imageUrl = url.origin + url.pathname

            form.setFieldValue(field.name, imageUrl)

            onUploadSuccess(imageUrl)
          } catch (err) {
            toastError(err)
          } finally {
            setIsUploading(false)
          }
        }

        reader.readAsArrayBuffer(file)
      })
    },
    [api, field.name, form, onUploadSuccess, acceptedTypesOf],
  )
  const { getRootProps, getInputProps } = useDropzone({ onDrop })

  const labelComponent = <Label for={id}>{label}</Label>

  const formFeedback = (
    <ErrorMessage name={field.name} component={FormFeedback} />
  )

  return (
    <FormGroup className={className}>
      {labelComponent}
      {hint}

      {description && <FormText>{description}</FormText>}

      <div {...getRootProps()}>
        <input
          {...getInputProps()}
          id={id}
          name={field.name}
          disabled={disabled || isUploading}
        />

        <Button
          outline={outline}
          color={disabled ? "secondary" : color}
          className="mt-1"
          style={buttonStyle}
          loading={isUploading}
          disabled={disabled || isUploading}
        >
          {buttonText}
        </Button>

        {!hideThumbnail && (
          <>
            <br />

            {field.value && (
              <img
                className="mt-1"
                data-private
                src={field.value}
                height={imgHeight}
                width={imgWidth}
                alt={alt}
              />
            )}
          </>
        )}

        {showDownloadLink && (
          <>
            <br />
            <a href={field.value}>{field.value.split("/").pop()}</a>
          </>
        )}
      </div>

      <Input
        type="hidden"
        name={field.name}
        valid={form.touched[field.name] && !form.errors[field.name]}
        invalid={form.touched[field.name] && !!form.errors[field.name]}
      />
      {formFeedback}
    </FormGroup>
  )
}

FileUploaderInput.defaultProps = {
  disabled: false,
  onUploadSuccess: () => {},
  acceptedTypesOf: "any",
  color: "primary",
  outline: false,
  description: t`Logo image in SVG format`,
  alt: t`Upload a file`,
  imgWidth: 250,
  buttonText: t`Upload`,
  buttonStyle: {},
  hideThumbnail: false,
  showDownloadLink: false,
}

FileUploaderInput.propTypes = {
  id: PropTypes.string.isRequired,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  acceptedTypesOf: PropTypes.oneOf(Object.keys(MIME_TYPES_OF)),
  className: PropTypes.string,
  disabled: PropTypes.bool,
  outline: PropTypes.bool,
  label: PropTypes.node,
  color: PropTypes.string,
  description: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.bool,
  ]),
  hint: PropTypes.node,
  alt: PropTypes.string,
  imgHeight: PropTypes.number,
  imgWidth: PropTypes.number,
  buttonText: PropTypes.string,
  buttonStyle: PropTypes.object,
  hideThumbnail: PropTypes.bool,
  showDownloadLink: PropTypes.bool,
}
