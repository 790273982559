import React from "react"
import PropTypes from "prop-types"
import { Trans } from "@lingui/macro"
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap"
import { Button } from "../button"
import cn from "classnames"

export function ConfirmationDialog({
  isOpen,
  isConfirming,
  isClosing,
  onSubmit,
  onClose,
  confirmButtonColor,
  cancelButtonColor,
  variant,
  title,
  content,
  className,
  size,
  color,
}) {
  const css = cn(
    {
      [`modal-${color}`]: true,
    },
    className,
  )

  return (
    <Modal isOpen={isOpen} toggle={onClose} size={size} className={css}>
      <ModalHeader toggle={onClose}>{title}</ModalHeader>
      <ModalBody>{content}</ModalBody>
      <ModalFooter>
        {variant === "confirm" && (
          <>
            <Button
              color={confirmButtonColor || color}
              disabled={isConfirming || isClosing}
              loading={isConfirming}
              onClick={onSubmit}
            >
              <Trans>Confirm</Trans>
            </Button>
            <Button
              color={cancelButtonColor || "secondary"}
              disabled={isConfirming || isClosing}
              loading={isClosing}
              onClick={onClose}
            >
              <Trans>Cancel</Trans>
            </Button>
          </>
        )}
        {variant === "alert" && (
          <Button
            color={confirmButtonColor || color}
            disabled={isConfirming || isClosing}
            loading={isConfirming}
            onClick={onSubmit}
          >
            <Trans>Ok</Trans>
          </Button>
        )}
      </ModalFooter>
    </Modal>
  )
}

ConfirmationDialog.propTypes = {
  size: PropTypes.string.isRequired,
  color: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  isConfirming: PropTypes.bool.isRequired,
  isClosing: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  confirmButtonColor: PropTypes.string,
  cancelButtonColor: PropTypes.string,
  title: PropTypes.string.isRequired,
  variant: PropTypes.string.isRequired,
  content: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}

ConfirmationDialog.defaultProps = {
  size: "md",
  isOpen: false,
  isConfirming: false,
  isClosing: false,
  onSubmit: () => {},
  onClose: () => {},
  variant: "alert",
  className: "",
  title: "",
  content: "",
}
