export const config = {
  env: {
    enviroinment: process.env.REACT_APP_ENVIROINMENT || "production",
    version: process.env.REACT_APP_VERSION,
  },

  intertempi: {
    api: {
      url: process.env.REACT_APP_INTERTEMPI_API_URL,
    },
    homePage: {
      url: process.env.REACT_APP_INTERTEMPI_HOMEPAGE_URL,
    },
    memberPortalPage: {
      url: process.env.REACT_APP_INTERTEMPI_MEMBER_PORTAL_PAGE_URL,
    },
    operatorPortalPage: {
      url: process.env.REACT_APP_INTERTEMPI_OPERATOR_PORTAL_PAGE_URL,
    },
    staticUrl: process.env.REACT_APP_INTERTEMPI_STATIC_URL,
  },

  stripe: {
    publishableKey:
      process.env.NODE_ENV === "production"
        ? "pk_live_D6VRdCCGdAkdlG3apYA9r5ng"
        : "pk_test_6oJ0maIDHt79cml1owkX2I6q",
  },

  sentry: {
    dsn:
      process.env.NODE_ENV === "production"
        ? "https://3338b682727e46fcaeeaf961ff5454d8@sentry.io/1778892"
        : "",
  },

  logrocket: {
    url:
      process.env.NODE_ENV === "production"
        ? "f2v10u/member-portal-web-app"
        : "",
    options: {
      release: process.env.REACT_APP_VERSION,
      shouldCaptureIP: false,
      dom: {
        inputSanitizer: true,
      },
    },
  },
}
