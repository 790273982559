import bootstrapPlugin from "@fullcalendar/bootstrap"
import dayGridPlugin from "@fullcalendar/daygrid"
import interactionPlugin from "@fullcalendar/interaction"
import listPlugin from "@fullcalendar/list"
import FullCalendar from "@fullcalendar/react"
import timeGridPlugin from "@fullcalendar/timegrid"
import { Trans, t } from "@lingui/macro"
import {
  addMilliseconds,
  addMinutes,
  addYears,
  areIntervalsOverlapping,
  differenceInDays,
  endOfDay,
  endOfMonth,
  getUnixTime,
  isEqual,
  isFuture,
  isPast,
  isSameDay,
  isToday,
  startOfMonth,
  subMilliseconds,
} from "date-fns"
import { intersection } from "lodash"
import { reverse } from "named-urls"
import qs, { stringify } from "qs"
import React, { useContext, useRef } from "react"
import { useAsync } from "react-async"
import { useHistory, useLocation } from "react-router-dom"
import Select from "react-select"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap"
import {
  DateParam,
  DelimitedNumericArrayParam,
  NumberParam,
  StringParam,
  useQueryParam,
} from "use-query-params"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import { Spinner } from "../../components/spinner"
import {
  getCalendarEventName,
  selectOptionsToTags,
  tagsToSelectOptions,
} from "../../lib/helpers"
import { fullCalendarLocales } from "../../lib/i18n"
import { useStore } from "../../providers"
import { ApiContext } from "../../providers/api-provider"
import { urls } from "../../urls"
import { UserContext } from "../account"
import { useMembership } from "../membership"
import {
  DAY,
  HALF_HOUR,
  ResourceInfoCard,
  resourceTypeHasDay,
} from "../resource"
import {
  computePriceForBooking,
  isUserTimezoneDifferentFromLocationTimezone,
} from "./helpers"

const views = ["timeGridWeek", "timeGridDay", "dayGridMonth", "listWeek"]
const header = {
  left: "title",
  center: views.join(","),
  right: " prev,next today",
}

const validRange = {
  end: endOfMonth(addYears(startOfMonth(new Date()), 1)),
}
const plugins = [
  interactionPlugin,
  dayGridPlugin,
  timeGridPlugin,
  listPlugin,
  bootstrapPlugin,
]

export function BookingCalendar() {
  const history = useHistory()
  const location = useLocation()
  const { languageCode } = useStore()
  const calendarRef = useRef(null)
  const api = useContext(ApiContext)
  const { selectedMembership } = useMembership()
  const { user: currentUser } = useContext(UserContext)
  const [selectedResourceId, setSelectedResourceId] = useQueryParam(
    "resourceId",
    NumberParam,
  )
  let [calendarDefaultView, setCalendarDefaultView] = useQueryParam(
    "cview",
    StringParam,
  )
  let [calendarDefaultDate, setCalendarDefaultDate] = useQueryParam(
    "date",
    DateParam,
  )
  calendarDefaultDate = calendarDefaultDate ?? new Date()

  let [filterTagIds, setFilterTagIds] = useQueryParam(
    "tags",
    DelimitedNumericArrayParam,
  )

  filterTagIds = filterTagIds ?? []

  if (!views.includes(calendarDefaultView)) {
    calendarDefaultView = "timeGridWeek"
  }

  const {
    data: resourcesData,
    error: resourcesError,
    isPending,
  } = useAsync({
    promiseFn: api.getResourceList,
    membershipId: selectedMembership.id,
    bookingDurationUnits: [HALF_HOUR, DAY],
    eager: "[resourceType,prices,resourceTags,resourceUserRoles]",
    onResolve: (data) => {
      if (data.count === 0) return

      const resource =
        data.resources.find((r) => r.id === selectedResourceId) ??
        data.resources[0]

      runBookings({
        membershipId: selectedMembership.id,
        resourceId: resource.id,
        resource,
        rangeEnd: 999,
        eager: "[membership.[account.[company]],price,user]",
      })
    },
  })

  const {
    data: bookingsData,
    error: bookingsError,
    run: runBookings,
    isPending: isBookingsPending,
  } = useAsync({
    deferFn: async ([args]) => {
      const view = calendarRef?.current?.calendar.view

      if (view) {
        args.startAtGte = view.activeStart
        args.endAtLte = subMilliseconds(view.activeEnd, 1)
      }

      const data = await api.getBookingList(args)

      if (!selectedResourceId) {
        setSelectedResourceId(args.resource?.id)
      }

      return data
    },
  })

  const {
    data: tagsData,
    error: tagsError,
    isPending: tagsIsPending,
  } = useAsync({
    promiseFn: api.getResourceTagsList,
    membershipId: selectedMembership.id,
  })

  if (resourcesError) throw resourcesError
  if (bookingsError) throw bookingsError

  if (isPending) {
    return <Spinner />
  }

  const bookings = bookingsData?.bookings ?? []

  function getSelectedResource(resources = []) {
    return (
      selectedResource ??
      resources.find((r) => r.id === location.state?.booking?.resourceId) ??
      resources[0]
    )
  }

  function hasAccessToBooking(booking) {
    return (
      !selectedMembership.location.privacyMode ||
      booking.membershipId === selectedMembership.id
    )
  }

  function transformEvent(b) {
    const event = {
      id: b.id,
      title:
        // TODO: move the privacy check to backend
        selectedMembership.id === b.membershipId ||
        !selectedMembership.location.privacyMode
          ? getCalendarEventName(b)
          : t`Busy`,
      start: new Date(b.startAt),
      end: addMilliseconds(new Date(b.endAt), 1),
      editable: true,
      textColor: "white",
      classNames: [],
      display: b.display || "auto",
    }

    // booking.durationUnit HALF_HOUR - so we can calculate the size of buffer
    // add CSS classnames showing buffered time according to the size of the buffer
    const bufferSize = selectedResource.timeBuffer / (1000 * 60 * 30)
    event.classNames.push(
      `buffer-before-${bufferSize} buffer-after-${bufferSize}`,
    )

    if (!isFuture(addMinutes(new Date(b.startAt), 25))) {
      event.editable = false
    }

    if (!hasAccessToBooking(b)) {
      event.editable = false
      event.color = "grey"
      event.textColor = "black"
    }

    if (b.recurringBookingId) {
      event.color = "#293D96"
      event.textColor = "white"
    }

    if (isPast(new Date(b.endAt)) || !event.editable) {
      event.color = "grey"
      event.textColor = "black"
    }

    if (differenceInDays(event.end, event.start) > 0) {
      event.allDay = true
    }

    return event
  }

  function onSelectAllow({ start, end, allDay }) {
    if (!isSameDay(subMilliseconds(end, 1), start)) {
      return false
    }

    const events = calendarRef?.current.calendar.getEvents() || []

    const intervalsAreOverlapping = events.some((e) =>
      areIntervalsOverlapping(
        {
          start: e.start,
          end: e.end,
        },
        {
          start: start,
          end: end,
        },
      ),
    )

    if (intervalsAreOverlapping) return false

    const intervalsAreOverlappingWithTimeBuffer = events
      .filter((e) => differenceInDays(e.end, e.start) < 1) // do not conflict with bookings longer than day
      .some((e) =>
        areIntervalsOverlapping(
          {
            start: subMilliseconds(e.start, selectedResource.timeBuffer),
            end: addMilliseconds(e.end, selectedResource.timeBuffer),
          },
          {
            start: start,
            end: end,
          },
        ),
      )

    if (intervalsAreOverlappingWithTimeBuffer) return false

    return allDay
      ? isToday(start) || isFuture(start)
      : isFuture(addMinutes(start, 25))
  }

  function onEventSelect(data) {
    const qp = stringify(
      {
        start: getUnixTime(data.start),
        end: getUnixTime(subMilliseconds(data.end, 1)),
        allDay: data.allDay,
        rid: selectedResource.id,
      },
      { addQueryPrefix: true },
    )

    history.push(reverse(urls.booking.create) + qp)
  }

  function onEventAllow({ allDay, start, end }) {
    const { resourceType } = selectedResource

    if (allDay && !resourceTypeHasDay(resourceType)) {
      // prevent moving events to allDay
      return false
    }

    if (!isSameDay(subMilliseconds(end, 1), start)) {
      return false
    }

    return isFuture(addMinutes(start, 25))
  }

  function onEventClick({ event }) {
    const booking = bookings.find((b) => b.id === parseInt(event.id, 10))

    if (!booking || !hasAccessToBooking(booking)) {
      return
    }

    history.push(
      reverse(urls.booking.details, {
        bookingId: booking.id,
      }),
    )
  }

  async function onEventChange({ event, revert }) {
    const { id, start, end, allDay } = event

    try {
      let booking = await api.getBooking({
        membershipId: selectedMembership.id,
        bookingId: id,
      })

      if (
        booking.stripeInvoice?.status &&
        !["draft", "open"].includes(booking.stripeInvoice.status)
      ) {
        toast.info(t`Can't edit booking with finalized invoice.`)
        revert()
        return
      }

      const newBookingDto = {
        ...booking,
        durationUnit: HALF_HOUR,
        startAt: new Date(start),
      }

      if (
        isPast(new Date(booking.startAt)) &&
        !isEqual(new Date(booking.startAt), newBookingDto.startAt)
      ) {
        toast.info(t`Can't edit started booking.`)
        revert()
        return
      }

      if (allDay) {
        newBookingDto.endAt = endOfDay(new Date(start))
        newBookingDto.durationUnit = DAY
      } else if (end) {
        newBookingDto.endAt = subMilliseconds(new Date(end), 1)
      } else {
        newBookingDto.endAt = subMilliseconds(
          addMinutes(new Date(start), 30),
          1,
        )
      }

      const intervalsAreOverlappingWithTimeBuffer = bookings
        .filter((b) => b.id !== booking.id) // do not conflict with self
        .map(transformEvent)
        .filter((e) => differenceInDays(e.end, e.start) < 1) // do not conflict with bookings longer than day
        .some((e) => {
          const newEvent = transformEvent(newBookingDto)

          return areIntervalsOverlapping(
            {
              start: subMilliseconds(e.start, selectedResource.timeBuffer),
              end: addMilliseconds(e.end, selectedResource.timeBuffer),
            },
            {
              start: newEvent.start,
              end: newEvent.end,
            },
          )
        })

      if (intervalsAreOverlappingWithTimeBuffer) {
        toast.info(t`This time is reserved for maintenance.`)
        revert()
        return false
      }

      newBookingDto.price = computePriceForBooking({
        booking: newBookingDto,
        resource: selectedResource,
      })

      booking = await api.updateBooking({
        membershipId: selectedMembership.id,
        bookingId: booking.id,
        bookingDto: newBookingDto,
      })

      toast.success(t`Updated "${booking.title}"`)

      runBookings({
        membershipId: selectedMembership.id,
        resourceId: selectedResource.id,
        resource: selectedResource,
        rangeEnd: 999,
        eager: "[membership.[account.[company]],price,user]",
      })
    } catch (err) {
      toastError(err)
      revert()

      if (!err.isAxiosError) {
        throw err
      }
    }
  }

  function onViewChange(info) {
    setCalendarDefaultView(info.view.type)
  }

  async function onDatesChange(info) {
    const resource = getSelectedResource(resourcesData.resources)

    await runBookings({
      membershipId: selectedMembership.id,
      resourceId: resource.id,
      resource,
      rangeEnd: 999,
      startAtGte: info.view.activeStart,
      endAtLte: subMilliseconds(info.view.activeEnd, 1),
      eager: "[membership.[account.[company]],price,user]",
    })

    const view = calendarRef?.current?.calendar.view

    if (view) {
      setCalendarDefaultDate(view.activeStart)
    } else {
      setCalendarDefaultDate(info.view.activeStart)
    }
  }

  function tagsFilter(resource, tags) {
    const fTags = tags || filterTags
    const filterTagIds = fTags.map((t) => t.id)

    return filterTagIds.length > 0
      ? intersection(
          resource.resourceTags.map((rt) => rt.id),
          filterTagIds,
        ).length === filterTagIds.length
      : true
  }

  const selectedResource = (resourcesData?.resources ?? []).find(
    (r) => r.id === selectedResourceId,
  )
  const filterTags = (tagsData?.resourceTags ?? []).filter((t) =>
    filterTagIds.includes(t.id),
  )

  return (
    <Container fluid className="py-4">
      <Row>
        <Col lg="3" className="order-last order-lg-first">
          {resourcesData.count === 0 && (
            <Card>
              <CardHeader>
                <CardTitle>
                  <Trans>No resources to show</Trans>
                </CardTitle>
              </CardHeader>
              <CardBody>
                <Trans>
                  You should ask your Landlord to create a bookable resource
                </Trans>
              </CardBody>
            </Card>
          )}
          {resourcesData.count > 0 &&
            getSelectedResource(resourcesData.resources) && (
              <>
                <Select
                  id="search-filter-tags"
                  className="multi-select-input mb-3"
                  onChange={(options) => {
                    const newFilters = options?.map(selectOptionsToTags) || []

                    setFilterTagIds(newFilters.map((t) => t.id))

                    const filteredResourcesByTags =
                      resourcesData.resources.filter((r) =>
                        tagsFilter(r, newFilters),
                      )

                    if (filteredResourcesByTags.length > 0) {
                      setSelectedResourceId(filteredResourcesByTags[0]?.id)

                      runBookings({
                        membershipId: selectedMembership.id,
                        resourceId: filteredResourcesByTags[0].id,
                        resource: filteredResourcesByTags[0],
                        rangeEnd: 999,
                        eager: "[membership.[account.[company]],price,user]",
                      })
                    }
                  }}
                  isClearable
                  isMulti
                  isLoading={tagsIsPending}
                  disabled={tagsError}
                  options={
                    tagsData?.resourceTags?.map(tagsToSelectOptions) || []
                  }
                  value={filterTags.map(tagsToSelectOptions) || []}
                />

                <Input
                  id="resource-select"
                  name="resource-select"
                  type="select"
                  block
                  className={`mb-3 btn ${
                    resourcesData.resources.filter((r) => tagsFilter(r))
                      .length < 1
                      ? "btn-secondary"
                      : "btn-primary"
                  }`}
                  disabled={
                    isBookingsPending ||
                    resourcesData.resources.filter((r) => tagsFilter(r))
                      .length < 1
                  }
                  value={
                    selectedResource?.id ??
                    getSelectedResource(resourcesData.resources).id
                  }
                  onChange={(e) => {
                    const selectedItemId = e.target.value
                    const res = resourcesData.resources.find(
                      (r) => r.id === parseInt(selectedItemId, 10),
                    )
                    setSelectedResourceId(res?.id)

                    return runBookings({
                      membershipId: selectedMembership.id,
                      resourceId: selectedItemId,
                      resource: res,
                      rangeEnd: 999,
                      eager: "[membership.[account.[company]],price,user]",
                    })
                  }}
                >
                  {resourcesData.resources
                    .filter(
                      (r) =>
                        r.resourceUserRoles.length === 0 ||
                        r.resourceUserRoles
                          .map((ur) => ur.userId)
                          .includes(currentUser.id),
                    )
                    .filter((r) => tagsFilter(r))
                    .map((resource) => {
                      return (
                        <option key={resource.id} value={resource.id}>
                          {isBookingsPending ? (
                            <Trans>loading</Trans>
                          ) : (
                            resource.name
                          )}
                        </option>
                      )
                    })}
                </Input>

                <ResourceInfoCard
                  resource={
                    resourcesData.resources.filter((r) => tagsFilter(r))
                      .length < 1
                      ? null
                      : getSelectedResource(resourcesData.resources)
                  }
                />
              </>
            )}
        </Col>

        <Col>
          <Card>
            <CardHeader>
              <CardTitle className="mb-0">
                <div className="d-flex justify-content-between">
                  <div className="mb-0 mt-1">
                    <h4>
                      <Trans>Bookings</Trans>
                    </h4>
                  </div>
                  <Button
                    color="primary"
                    onClick={() =>
                      history.push(
                        reverse(urls.booking.create) +
                          qs.stringify(
                            { rid: selectedResource.id },
                            { addQueryPrefix: true },
                          ),
                      )
                    }
                    disabled={isPending || resourcesData.count === 0}
                  >
                    <Trans>Book now</Trans>
                  </Button>
                </div>

                <CardText className="text-muted">
                  <Trans>
                    Select a Resource from the list to see its availability /
                    occupancy on the calendar.
                  </Trans>
                </CardText>
                {isUserTimezoneDifferentFromLocationTimezone(
                  selectedMembership.location.timezone,
                ) && (
                  <CardText className="text-muted">
                    <Trans>
                      Please pay attention that your timezone differs from the
                      location timezone.
                    </Trans>
                  </CardText>
                )}
              </CardTitle>
            </CardHeader>
            <CardBody className="custom-calendar">
              {resourcesData.count === 0 ? (
                <div>
                  <Trans>
                    No resources to show. Please create your first resource to
                    see the calendar view.
                  </Trans>
                </div>
              ) : (
                <FullCalendar
                  ref={calendarRef}
                  themeSystem="bootstrap"
                  schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
                  plugins={plugins}
                  defaultView={calendarDefaultView}
                  defaultDate={calendarDefaultDate}
                  header={header}
                  events={bookings}
                  lazyFetching={false}
                  nowIndicator
                  weekNumbers={false}
                  locale={fullCalendarLocales[languageCode]}
                  firstDay={1}
                  slotDuration={selectedMembership.location.bookingSlotDuration}
                  minTime={selectedMembership.location.bookingMinTime}
                  maxTime={selectedMembership.location.bookingMaxTime}
                  scrollTime="07:00:00"
                  height="auto"
                  contentHeight="auto"
                  selectable
                  selectMirror
                  slotEventOverlap={false}
                  eventOverlap={false}
                  editable
                  eventStartEditable
                  eventResizableFromStart
                  datesDestroy={onDatesChange}
                  viewSkeletonRender={onViewChange}
                  eventDataTransform={transformEvent}
                  select={onEventSelect}
                  eventClick={onEventClick}
                  eventDrop={onEventChange}
                  eventResize={onEventChange}
                  validRange={validRange}
                  selectAllow={onSelectAllow}
                  eventAllow={onEventAllow}
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

BookingCalendar.propTypes = {}
BookingCalendar.defaultProps = {}
