import React from "react"
import { ErrorPage } from "../../components/error/error"
import { CLIENT_ERROR } from "../../constants"

export function NotFoundPage() {
  const err = new Error()
  err.problem = CLIENT_ERROR
  err.response = { status: 404 }

  return <ErrorPage err={err} />
}

NotFoundPage.propTypes = {}
NotFoundPage.defaultProps = {}
