// import React, { useContext, useState } from "react"
import React from "react"
import PropTypes from "prop-types"
// import { useHistory } from "react-router"
// import { Link } from "react-router-dom"
// import { Navbar, Nav, Row, Col } from "reactstrap"
// import { useAsync } from "react-async"
// import { ApiContext } from "../../providers/api-provider"
// import { useMembership } from "../../modules/membership/membership-provider"
import { useStopwatch } from "react-timer-hook"
import { addMilliseconds } from "date-fns"
// import { Button } from "../button"
// import { reverse } from "named-urls"
// import { urls } from "../../urls"
// import { toastError } from "../../components/error"
// import { toast } from "react-toastify"
import { Trans } from "@lingui/macro"

function CheckinStopwatch({ date }) {
  const { seconds, minutes, hours, days } = useStopwatch({
    autoStart: true,
    offsetTimestamp: addMilliseconds(
      new Date(),
      new Date() - new Date(date).getTime(),
    ),
  })

  return (
    <>
      {days ? (
        <strong>
          <u>
            {days} <Trans>days</Trans>
          </u>{" "}
          ,
        </strong>
      ) : null}
      <span>{("00" + hours).slice(-2)}</span>:
      <span>{("00" + minutes).slice(-2)}</span>:
      <span>{("00" + seconds).slice(-2)}</span>
    </>
  )
}
CheckinStopwatch.defaultProps = {}
CheckinStopwatch.propTypes = {
  date: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
}

export function CheckinHeaderTimer() {
  // const api = useContext(ApiContext)
  // const history = useHistory()
  // const { selectedMembership } = useMembership()
  // const [disabledCheckinBtn, setDisabledCheckinBtn] = useState(false)

  // const {
  //   data: myActiveCheckin,
  //   error: myActiveCheckinError,
  //   isPending: isMyActiveCheckinPending,
  // } = useAsync({
  //   promiseFn: api.getMyActiveCheckin,
  //   locationId: selectedMembership.locationId,
  // })

  return null

  // if (isMyActiveCheckinPending || myActiveCheckinError) return null

  // async function checkOut() {
  //   try {
  //     setDisabledCheckinBtn(true)

  //     await api.updateCheckin({
  //       locationId: myActiveCheckin.resource.locationId,
  //       resourceId: myActiveCheckin.resource.id,
  //     })

  //     toast.success("Checked-out")

  //     history.go(0)
  //   } catch (error) {
  //     toastError(error)
  //   } finally {
  //     setDisabledCheckinBtn(false)
  //   }
  // }

  // return (
  //   <>
  //     {!myActiveCheckinError && !isMyActiveCheckinPending && myActiveCheckin ? (
  //       <Navbar color="primary" light expand="xs">
  //         <Nav navbar className="w-100">
  //           <Row className="w-100">
  //             <Col xs="6">
  //               Check-in total time{" "}
  //               <CheckinStopwatch date={myActiveCheckin.startAt} />
  //               <br />
  //               <Link
  //                 to={reverse(urls.resource.details, {
  //                   resourceId: myActiveCheckin.resource.id,
  //                 })}
  //               >
  //                 {myActiveCheckin.resource.name}
  //               </Link>
  //             </Col>
  //             <Col xs="6">
  //               <Button
  //                 color="danger"
  //                 className="float-right"
  //                 disabled={disabledCheckinBtn}
  //                 onClick={checkOut}
  //               >
  //                 Check-Out
  //               </Button>
  //             </Col>
  //           </Row>
  //         </Nav>
  //       </Navbar>
  //     ) : null}
  //   </>
  // )
}

CheckinHeaderTimer.propTypes = {}
CheckinHeaderTimer.defaultProps = {}
