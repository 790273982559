const cssVarsMapping = {
  colorBodyBg: "--body-bg-color",
  colorNavbarBg: "--navbar-color",
  colorNavbarIcon: "--navbar-icon-color",
  colorNavbarBottomBorder: "--navbar-bottom-borderline-color",
  colorText: "--text-color",
  colorBookingBufferBg: "--booking-buffer-bg-color",
  colorCardBg: "--card-bg-color",
  colorCardHeaderBg: "--card-header-bg-color",
  colorListGroupItemBg: "--list-group-item-bg-color",

  colorBsPrimary: "--bs-primary-color",
  colorBsSecondary: "--bs-secondary-color",
  colorBsSuccess: "--bs-success-color",
  colorBsInfo: "--bs-info-color",
  colorBsWarning: "--bs-warning-color",
  colorBsDanger: "--bs-danger-color",
  colorBsLight: "--bs-light-color",
  colorBsDark: "--bs-dark-color",
}

export default cssVarsMapping
