import { t } from "@lingui/macro"
import { isLength, isEmail } from "validator"
import { countries } from "../../constants"

export const billingValidator = async (values) => {
  const errors = {}

  const maxValueOptions = { max: 100 }
  const allowedCountryCodes = countries.map((cc) => cc.code)

  if (!values.email) {
    errors.email = t`Value is required`
  } else if (!isEmail(values.email)) {
    errors.email = t`Email is required`
  } else if (!isLength(values.email, maxValueOptions)) {
    errors.email = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.contactName) {
    errors.contactName = t`Value is required`
  } else if (!isLength(values.contactName, maxValueOptions)) {
    errors.contactName = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!isLength(values.businessName, maxValueOptions)) {
    errors.businessName = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.street) {
    errors.street = t`Value is required`
  } else if (!isLength(values.street, maxValueOptions)) {
    errors.street = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.houseno) {
    errors.houseno = t`Value is required`
  } else if (!isLength(values.houseno, maxValueOptions)) {
    errors.houseno = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.city) {
    errors.city = t`Value is required`
  } else if (!isLength(values.city, maxValueOptions)) {
    errors.city = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.zip) {
    errors.zip = t`Value is required`
  } else if (!isLength(values.zip, maxValueOptions)) {
    errors.zip = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.country) {
    errors.country = t`Value is required`
  } else if (!allowedCountryCodes.includes(values.country)) {
    errors.country = t`Country should have a valid country code`
  }

  return errors
}
