import { capitalize } from "../../lib/helpers"
import { ACCEPTED, DECLINED, EXPIRED, PENDING } from "../../constants"

export const getInviteStatusText = (i) => {
  return capitalize(i.status.toLowerCase())
}

export const getInviteStatusColor = (i) => {
  return {
    [PENDING]: "info",
    [ACCEPTED]: "success",
    [DECLINED]: "danger",
    [EXPIRED]: "warning",
  }[i.status]
}
