import { Trans } from "@lingui/macro"
import React from "react"
import { Container, Row, Col } from "reactstrap"

export default function SelectedMembershipisNotActiveState() {
  return (
    <Container className="py-4 h-100">
      <Row className="h-100">
        <Col sm="12" md={{ size: 6, offset: 3 }} className="align-self-center">
          <p className="lead">
            <i className="far fa-lightbulb" />{" "}
            <Trans>
              Your selected membership is not active. To continue using the
              Members Portal you have to select an active membership.
            </Trans>
          </p>
        </Col>
      </Row>
    </Container>
  )
}
