import React from "react"
import PropTypes from "prop-types"
import { ErrorMessage } from "formik"
import { FormFeedback } from "./form-feedback"
import {
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  Input,
} from "reactstrap"
import "react-datepicker/dist/react-datepicker.css"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import enGb from "date-fns/locale/en-GB"

registerLocale("en-gb", enGb)

export const DatePicker = ({
  id,
  field,
  form,
  className,
  onChange,
  label,
  hint,
  addon,
  addonType,
  name,
  dateFormat,
  filterDate,
  minDate,
  showTimeSelect,
  showTimeSelectOnly,
  minTime,
  maxTime,
  excludeTimes,
  CustomInputComponent,
  locale = "en-gb",
  disabled,
}) => {
  const customInput = CustomInputComponent && (
    <CustomInputComponent
      valid={form.touched[field.name] && !form.errors[field.name]}
      invalid={form.touched[field.name] && !!form.errors[field.name]}
    />
  )

  const datepicker = (
    <ReactDatePicker
      id={id}
      name={name}
      selected={field.value}
      onChange={(value) => {
        onChange(value)
        return form.setFieldValue(field.name, value)
      }}
      onBlur={(e) => field.onBlur(e)}
      dateFormat={dateFormat}
      showTimeSelect={showTimeSelect}
      showTimeSelectOnly={showTimeSelectOnly}
      timeFormat="HH:mm"
      timeIntervals={30}
      timeCaption="Time"
      excludeTimes={excludeTimes}
      minDate={minDate}
      minTime={minTime}
      maxTime={maxTime}
      customInput={customInput}
      filterDate={filterDate}
      valid={form.touched[field.name] && !form.errors[field.name]}
      invalid={form.touched[field.name] && !!form.errors[field.name]}
      locale="en-gb"
      disabled={disabled}
    />
  )

  const labelComponent = <Label for={id}>{label}</Label>

  const formFeedback = (
    <ErrorMessage name={field.name} component={FormFeedback} />
  )

  return (
    <FormGroup className={className}>
      {label && labelComponent}

      {hint}

      {addon ? (
        <InputGroup>
          {addonType === "prepend" && (
            <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>
          )}
          {datepicker}
          {addonType === "append" && (
            <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>
          )}
          {formFeedback}
        </InputGroup>
      ) : (
        datepicker
      )}

      <Input
        type="hidden"
        name={field.name}
        valid={form.touched[field.name] && !form.errors[field.name]}
        invalid={form.touched[field.name] && !!form.errors[field.name]}
      />
      {formFeedback}
    </FormGroup>
  )
}

DatePicker.defaultProps = {
  type: "text",
  showTimeSelect: false,
  onChange: () => {},
  locale: "en-gb",
  disabled: false,
}

DatePicker.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  label: PropTypes.node,
  hint: PropTypes.node,
  addon: PropTypes.node,
  addonType: PropTypes.node,
  field: PropTypes.object.isRequired,
  name: PropTypes.string,
  value: PropTypes.object,
  onChange: PropTypes.func,
  dateFormat: PropTypes.string,
  filterDate: PropTypes.func,
  minDate: PropTypes.instanceOf(Date),
  showTimeSelect: PropTypes.bool,
  showTimeSelectOnly: PropTypes.bool,
  minTime: PropTypes.instanceOf(Date),
  maxTime: PropTypes.instanceOf(Date),
  excludeTimes: PropTypes.array,
  CustomInputComponent: PropTypes.func,
  form: PropTypes.object.isRequired,
  locale: "en-gb",
  disabled: PropTypes.bool,
}
