import React, { useState, useContext } from "react"
import { Route, Switch, NavLink, Link } from "react-router-dom"
import { urls } from "../../urls"
import { reverse } from "named-urls"
import { Button } from "../../components/button"
import {
  Container,
  Nav,
  NavItem,
  NavLink as BNavLink,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupAddon,
} from "reactstrap"
import { UserList } from "./user-list"
import { InviteList } from "./invite-list"
import { AccountContext, UserContext } from "../account"
import { isAccountOwner } from "../../lib/helpers"
import { Trans } from "@lingui/macro"

export function UserListContainer() {
  const [filterText, setFilterText] = useState("")
  const { selectedAccountId, accounts } = useContext(AccountContext)
  const { user: currentUser } = useContext(UserContext)
  const currentAccount = accounts.find((acc) => acc.id === selectedAccountId)
  const isCurrentUserOwner = isAccountOwner(currentUser, selectedAccountId)

  return (
    <Container className="py-4">
      <Row>
        <Col xs="12" md="12">
          <h1>{currentAccount.name}</h1>
        </Col>

        <Col xs="12" md="8">
          <InputGroup>
            <Input
              id="search-filter-text"
              type="text"
              placeholder="Search by text"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value.toLowerCase())}
            />
            <InputGroupAddon addonType="append">
              <Button
                color="light"
                className="border-0"
                onClick={() => setFilterText("")}
              >
                <i className="fas fa-times" style={{ color: "black" }} />
              </Button>
            </InputGroupAddon>
          </InputGroup>
        </Col>
        <Col xs="12" md="4">
          {isCurrentUserOwner ? (
            <div className="mb-2 d-flex flex-row-reverse">
              <Link to={reverse(urls.team.createInvite)}>
                <Button color="primary">
                  <Trans>Invite new teammate</Trans>
                </Button>
              </Link>
            </div>
          ) : null}
        </Col>
      </Row>
      <Row>
        <Col xs="12">
          <Nav tabs justified>
            <NavItem>
              <BNavLink tag={NavLink} exact to={reverse(urls.team.list)}>
                <i className="fas fa-user" />
                &nbsp;
                <span>
                  <Trans>Active Teammates</Trans>
                </span>
              </BNavLink>
            </NavItem>
            <NavItem>
              <BNavLink tag={NavLink} to={reverse(urls.team.invites)}>
                <i className="fas fa-user" />
                &nbsp;
                <span>
                  <Trans>Teammate Invites</Trans>
                </span>
              </BNavLink>
            </NavItem>
          </Nav>
          <Switch>
            <Route exact path={urls.team.list}>
              <UserList filterText={filterText} />
            </Route>
            <Route path={urls.team.invites}>
              <InviteList filterText={filterText} />
            </Route>
          </Switch>
        </Col>
      </Row>
    </Container>
  )
}

UserListContainer.propTypes = {}
UserListContainer.defaultProps = {}
