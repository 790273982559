import React, { useContext, useEffect } from "react"
import { useAsync } from "react-async"
import { Container, Row, Col, ListGroup } from "reactstrap"
import { ApiContext } from "../../providers/api-provider"
import { MembershipContext } from "../membership/membership-provider"
import { Spinner } from "../../components/spinner"
import { BackButton } from "../../components/backbutton"
import { UserCheckinListEmptyState, UserCheckinListItem } from "./components"
import { Trans } from "@lingui/macro"

export function UserCheckinsList() {
  const api = useContext(ApiContext)
  const { selectedMembership } = useContext(MembershipContext)

  const {
    data: checkinsData,
    error,
    isPending,
    reload: reloadCheckins,
  } = useAsync({
    promiseFn: api.getLocationCheckinsList,
    locationId: selectedMembership.locationId,
    eager: "[resource,user]",
  })

  useEffect(() => {
    reloadCheckins()
  }, [selectedMembership, reloadCheckins])

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  if (checkinsData.count === 0) {
    return <UserCheckinListEmptyState />
  }

  return (
    <Container className="py-4">
      <BackButton />
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <h2>
            <Trans>User Activity</Trans>
          </h2>

          <ListGroup>
            {checkinsData.checkins.map((checkin) => {
              return <UserCheckinListItem key={checkin.id} checkin={checkin} />
            })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  )
}

UserCheckinsList.propTypes = {}
UserCheckinsList.defaultProps = {}
