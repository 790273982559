import React, { useContext } from "react"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import { useHistory } from "react-router-dom"
import { ApiContext } from "../../providers/api-provider"
import { CLIENT_ERROR } from "../../constants"
import { Input } from "../../components/input"
import { Button } from "../../components/button"
import { inviteValidator } from "./invite-validator"
import { toastError } from "../../components/error"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import {
  Col,
  Row,
  Container,
  Card,
  CardGroup,
  CardBody,
  FormGroup,
  InputGroupText,
} from "reactstrap"
import { t, Trans } from "@lingui/macro"

export function InviteForm() {
  const api = useContext(ApiContext)
  const history = useHistory()

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Formik
                  initialValues={{
                    email: "",
                    firstName: "",
                    lastName: "",
                  }}
                  validate={inviteValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      await api.createInvite({
                        email: values.email,
                        firstName: values.firstName,
                        lastName: values.lastName,
                      })

                      toast.success(t`Invite sent!`)
                      history.push(reverse(urls.team.invites))
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors(err.response.data)
                      }
                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <h1>
                        <Trans>Invite teammate</Trans>
                      </h1>
                      <p className="text-muted">
                        <Trans>Enter teammate details</Trans>
                      </p>

                      <Field
                        id="email"
                        type="email"
                        name="email"
                        component={Input}
                        label={t`Email`}
                        addon={
                          <InputGroupText>
                            <i className="far fa-envelope" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Field
                        id="firstName"
                        type="text"
                        name="firstName"
                        component={Input}
                        label={t`First Name`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-arrow-right" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Field
                        id="lastName"
                        type="text"
                        name="lastName"
                        component={Input}
                        label={t`Last Name`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-arrow-right" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <FormGroup className="form-actions">
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={!dirty || !isValid || isSubmitting}
                          color="primary"
                          block
                        >
                          <Trans>Invite</Trans>
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

InviteForm.propTypes = {}
InviteForm.defaultProps = {}
