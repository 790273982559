import { config } from "../config"
import * as Sentry from "@sentry/browser"
import * as Integrations from "@sentry/integrations"

Sentry.init({
  dsn: config.sentry.dsn,
  environment: config.env.enviroinment,
  release: config.env.version,
  integrations: [new Integrations.Dedupe(), new Integrations.ExtraErrorData()],
  ignoreErrors: ["Request failed with status code", "Network Error"],
})

export { Sentry }
