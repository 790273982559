import { t } from "@lingui/macro"
import { isLength, isEmail } from "validator"

export const userValidator = async (values) => {
  const errors = {}

  const maxEmailOptions = { max: 50 }
  const maxLastNameOptions = { max: 50 }
  const maxFirstNameOptions = { max: 50 }

  if (!values.email) {
    errors.email = t`Value is required`
  } else if (!isEmail(values.email)) {
    errors.email = t`Email is required`
  } else if (!isLength(values.email, maxEmailOptions)) {
    errors.email = t`Value should be at most ${maxEmailOptions.max} characters long`
  }

  if (!values.lastName) {
    errors.lastName = t`Value is required`
  } else if (!isLength(values.lastName, maxLastNameOptions)) {
    errors.lastName = t`Value should be at most ${maxLastNameOptions.max} characters long`
  }

  if (!values.firstName) {
    errors.firstName = t`Value is required`
  } else if (!isLength(values.firstName, maxFirstNameOptions)) {
    errors.firstName = t`Value should be at most ${maxFirstNameOptions.max} characters long`
  }

  return errors
}
