import React from "react"
import { useHistory } from "react-router-dom"
import { BookingForm } from "./booking-form"
import { urls } from "../../urls"
import { reverse } from "named-urls"

export function BookingCreate() {
  const history = useHistory()

  return (
    <BookingForm
      onSubmitSuccess={(booking) =>
        history.length > 2
          ? history.goBack()
          : history.push(reverse(urls.booking.calendar), { booking })
      }
    />
  )
}

BookingCreate.propTypes = {}
BookingCreate.defaultProps = {}
