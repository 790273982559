import { ACTIVE, ARCHIVED, CANCELED } from "../../constants"

export function isMembershipActive(m) {
  return m.status === ACTIVE
}

export function isMembershipArchived(m) {
  return m.status === ARCHIVED
}

export function isMembershipCanceled(m) {
  return m.status === CANCELED
}
