import { Trans, t } from "@lingui/macro"
import {
  addMilliseconds,
  addMinutes,
  addMonths,
  addWeeks,
  differenceInHours,
  differenceInMinutes,
  endOfDay,
  isBefore,
  isFuture,
  isToday,
  roundToNearestMinutes,
  setHours,
  setMinutes,
  startOfDay,
  startOfToday,
  subMinutes,
} from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { Field, Form, Formik } from "formik"
import { intersection } from "lodash"
import { reverse } from "named-urls"
import PropTypes from "prop-types"
import { compose, groupBy, prop, sortBy, toLower } from "ramda"
import React, { useContext, useState } from "react"
import { useAsync } from "react-async"
import { useMediaQuery } from "react-responsive"
import { useHistory } from "react-router-dom"
import RSelect from "react-select"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardGroup,
  CardTitle,
  Col,
  Container,
  FormGroup,
  FormText,
  InputGroupText,
  Jumbotron,
  Label,
  Row,
} from "reactstrap"
import { NumberParam, StringParam, useQueryParam } from "use-query-params"
import { BackButton } from "../../components/backbutton"
import { Button } from "../../components/button"
import { useConfirmation } from "../../components/confirmation-dialog"
import { DatePicker } from "../../components/datepicker"
import { toastError } from "../../components/error"
import { FoldableBlock } from "../../components/foldable-block"
import {
  FormatDate,
  FormatRelativeDate,
} from "../../components/formatters/index"
import { Input, MultiSelect, Select } from "../../components/input"
import { Spinner } from "../../components/spinner"
import TooltipComponent from "../../components/tooltip/tooltip-component"
import { CLIENT_ERROR, USER } from "../../constants"
import {
  getUserFullName,
  priceFromCents,
  priceZeroEur,
  renderPrice,
  selectOptionsToTags,
  tagsToSelectOptions,
} from "../../lib/helpers"
import { ApiContext } from "../../providers/api-provider"
import { urls } from "../../urls"
import { UserContext } from "../account/user-provider"
import { MembershipContext } from "../membership/membership-provider"
import { DAY, HALF_HOUR, MONTH, getBookingDurationUnitName } from "../resource"
import BookingNotPossible from "./booking-empty-state"
import { bookingValidator } from "./booking-validator"
import { BookingIsPrivacyProtected } from "./components"
import { recurringBookingRepeatPatternOptions } from "./constants"
import {
  UnixTimeParam,
  applyLocationTimezoneToBooking,
  computePriceForBooking,
  formatBookingForForm,
  getPreparedBookingDto,
  getPreparedEndAt,
  getPreparedRecurringBookingDto,
  isUserTimezoneDifferentFromLocationTimezone,
  mergeDayAndTime,
  usersToAttandeesSelector,
} from "./helpers"

async function getBooking({
  bookingId,
  recurringBookingId,
  membershipId,
  api,
}) {
  if (!bookingId && !recurringBookingId) return null
  if (recurringBookingId) {
    return api.getRecurringBooking({ recurringBookingId, membershipId })
  }
  return api.getBooking({ bookingId, membershipId })
}

const CustomInputComponent = ({ value, onClick, color }) => (
  <Button color={color} onClick={onClick}>
    {value}
  </Button>
)

CustomInputComponent.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  color: PropTypes.string,
}
CustomInputComponent.defaultProps = {
  color: "primary",
}

function getInitialBookingRangeForResource({
  resourceId,
  resources,
  startAtDay,
  startAt,
  endAt,
}) {
  const hasHours = resourceId
    ? resources
        .find((r) => r.id === parseInt(resourceId, 10))
        .resourceType.bookingDurationUnits.includes(HALF_HOUR)
    : false

  if (!hasHours) {
    return {
      startAtDay: startOfDay(startAt),
      startAt: startOfDay(startAt),
      endAt: endOfDay(endAt),
      bookingDurationUnit: DAY,
    }
  }

  if (!isBefore(startAt, endAt)) {
    endAt = getPreparedEndAt(addMinutes(startAt, 30))
  }

  startAtDay = startAtDay ? startOfDay(startAtDay) : startOfDay(startAt)

  return {
    startAtDay,
    startAt: mergeDayAndTime(startAtDay, startAt),
    endAt: mergeDayAndTime(startAtDay, endAt),
    bookingDurationUnit: HALF_HOUR,
  }
}

function getDefaultStartAt() {
  return roundToNearestMinutes(addMinutes(new Date(), 15), {
    nearestTo: 30,
  })
}

export function BookingForm({
  bookingId,
  recurringBookingId,
  onSubmitSuccess,
}) {
  const api = useContext(ApiContext)
  const { selectedMembership } = useContext(MembershipContext)
  const { user: currentUser } = useContext(UserContext)
  const [qpStart] = useQueryParam("start", UnixTimeParam)
  const [qpEnd] = useQueryParam("end", UnixTimeParam)
  const [qpResourceId] = useQueryParam("rid", NumberParam)
  const [source] = useQueryParam("source", StringParam)
  const [filterTags, setFilterTags] = useState([])
  const minStartAtDay = startOfToday()
  const confirm = useConfirmation()
  const history = useHistory()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  const {
    data: booking,
    error,
    isPending,
  } = useAsync({
    promiseFn: getBooking,
    membershipId: selectedMembership.id,
    bookingId,
    recurringBookingId,
    api,
  })

  const {
    data: resourcesData,
    error: resourcesError,
    isPending: resourcesIsPending,
  } = useAsync({
    promiseFn: api.getResourceList,
    membershipId: selectedMembership.id,
    eager: "[resourceType,prices,resourceTags,resourceUserRoles]",
    bookingDurationUnits: [HALF_HOUR, DAY],
  })

  const {
    data: tagsData,
    error: tagsError,
    isPending: tagsIsPending,
  } = useAsync({
    promiseFn: api.getResourceTagsList,
    membershipId: selectedMembership.id,
  })

  const {
    data: allowedAttendeesData,
    isPending: allowedAttendeesIsPending,
    error: allowedAttendeesError,
  } = useAsync({
    promiseFn: api.getUserList,
    rangeStart: 0,
    rangeEnd: 999,
  })

  const {
    data: covdocsData,
    isPending: covdocsIsPending,
    error: covdocsError,
  } = useAsync({
    promiseFn: api.getMyCovidCertificatesList,
    srcType: USER,
    srcId: currentUser.id,
    validUntilGte: startOfToday(),
  })

  if (error) throw error
  if (resourcesError) throw resourcesError
  if (allowedAttendeesError) throw allowedAttendeesError
  if (covdocsError) throw covdocsError

  if (isPending || resourcesIsPending || covdocsIsPending) {
    return <Spinner />
  }

  if (
    booking &&
    selectedMembership.id !== booking.membershipId &&
    (selectedMembership.location.privacyMode || (booking && booking.isPrivate))
  ) {
    return <BookingIsPrivacyProtected />
  }

  const initialSelectedResourceId =
    resourcesData.resources.find((r) => r.id === qpResourceId)?.id ??
    resourcesData?.resources[0]?.id

  const defaultStartAt =
    qpStart && isFuture(qpStart) ? qpStart : getDefaultStartAt()

  const defaultEndAt =
    qpStart && qpEnd && isFuture(qpEnd) && qpStart < qpEnd
      ? getPreparedEndAt(qpEnd)
      : getPreparedEndAt(addMinutes(defaultStartAt, 30))

  const {
    startAtDay: initialStartAtDay,
    startAt: initialStartAt,
    endAt: initialEndAt,
    bookingDurationUnit: initialBookingDurationUnit,
  } = getInitialBookingRangeForResource({
    resourceId: initialSelectedResourceId,
    resources: resourcesData.resources,
    startAt: defaultStartAt,
    endAt: defaultEndAt,
  })

  function getSelectedResourceById(id) {
    return resourcesData.resources.find((r) => r.id === parseInt(id, 10))
  }

  let initialValues = {
    title: "",
    description: "",
    startAt: initialStartAt,
    endAt: initialEndAt,
    resourceId: initialSelectedResourceId,
    resource: getSelectedResourceById(initialSelectedResourceId),
    membershipId: selectedMembership.id,
    user: currentUser
      ? {
          value: currentUser.id,
          label: getUserFullName(currentUser),
        }
      : null,
    repeatPattern: recurringBookingRepeatPatternOptions[0].id,
    price: priceZeroEur,
    durationUnit: initialBookingDurationUnit,
    startAtDay: initialStartAtDay,
    isRecurringCheckbox: Boolean(recurringBookingId),
    endRecurringAtDay: startOfDay(addWeeks(initialStartAtDay, 1)),
    isPrivate: false,
    attendees: [],
  }

  initialValues.price =
    resourcesData.resources.length !== 0
      ? computePriceForBooking({
          booking: initialValues,
          resource: getSelectedResourceById(initialValues.resourceId),
        })
      : priceZeroEur

  if (booking) {
    const existingBookingUser = allowedAttendeesData?.users?.find(
      (u) =>
        u.id === booking.userId || u.id === booking.recurringBooking?.userId,
    )

    initialValues = {
      ...initialValues,
      ...booking,
      user: existingBookingUser
        ? {
            value: existingBookingUser.id,
            label: getUserFullName(existingBookingUser),
          }
        : null,
      startAtDay: startOfDay(new Date(booking.startAt)),
    }
  }

  initialValues = formatBookingForForm(initialValues)

  function getDurationString(startAt, endAt, durationUnit) {
    const diffHours = differenceInHours(endAt, startAt)
    const diffMinutes = differenceInMinutes(endAt, startAt) % 60

    return durationUnit === HALF_HOUR
      ? (diffHours > 0 ? `${diffHours} ` + t`hours` : "") +
          (diffMinutes > 0 ? ` ${diffMinutes} ` + t`minutes` : "")
      : t`full day`
  }

  function tagsFilter(resource) {
    const filterTagIds = filterTags.map((t) => t.id)
    return filterTagIds.length > 0
      ? intersection(
          resource.resourceTags.map((rt) => rt.id),
          filterTagIds,
        ).length === filterTagIds.length
      : true
  }

  function recalcStartEndAt(date, durationUnit, newStartAt, newEndAt) {
    if (durationUnit === HALF_HOUR) {
      const startAt = newStartAt || new Date(defaultStartAt)

      const assumeEndAt =
        newEndAt || roundToNearestMinutes(new Date(defaultEndAt))

      const endAt = !isBefore(startAt, assumeEndAt)
        ? addMinutes(startAt, 30)
        : assumeEndAt

      return { startAt, endAt }
    }

    if (durationUnit === DAY) {
      return { startAt: startOfDay(date), endAt: endOfDay(date) }
    }
  }

  if (resourcesData.resources.length === 0) {
    return <BookingNotPossible text={t`There are no Resources to book`} />
  }

  async function submitFormData(values, actions) {
    try {
      let newBooking = null

      const price = computePriceForBooking({
        booking: getPreparedBookingDto(values),
        resource: getSelectedResourceById(values.resourceId),
      })

      if (values.isRecurringCheckbox) {
        if (booking) {
          newBooking = await api.updateRecurringBooking({
            membershipId: selectedMembership.id,
            recurringBookingId: recurringBookingId,
            recurringBookingDto: applyLocationTimezoneToBooking({
              booking: getPreparedRecurringBookingDto({
                ...values,
                price: priceFromCents(price),
              }),
              location: selectedMembership.location,
            }),
          })
          actions.resetForm({
            values: formatBookingForForm(newBooking),
          })
        } else {
          newBooking = await api.createRecurringBooking({
            recurringBookingDto: applyLocationTimezoneToBooking({
              booking: getPreparedRecurringBookingDto({
                ...values,
                price: priceFromCents(price),
              }),
              location: selectedMembership.location,
            }),
            membershipId: selectedMembership.id,
          })
        }
      } else {
        if (booking) {
          newBooking = await api.updateBooking({
            membershipId: selectedMembership.id,
            bookingId,
            bookingDto: applyLocationTimezoneToBooking({
              booking: getPreparedBookingDto({
                ...values,
                price: priceFromCents(price),
              }),
              location: selectedMembership.location,
            }),
          })
          actions.resetForm({
            values: formatBookingForForm(newBooking),
          })
        } else {
          newBooking = await api.createBooking({
            bookingDto: applyLocationTimezoneToBooking({
              booking: getPreparedBookingDto({
                ...values,
                price: priceFromCents(price),
              }),
              location: selectedMembership.location,
            }),
            membershipId: selectedMembership.id,
          })
        }
      }

      onSubmitSuccess && onSubmitSuccess(newBooking)
      toast.success(t`Saved`)
    } catch (err) {
      if (err.problem === CLIENT_ERROR) {
        actions.setErrors(err.response.data)
      }

      if (err.response.data && err.response.data.error) {
        toast.error(err.response.data.error)
      } else {
        toastError(err)
      }

      if (!err.isAxiosError) {
        throw err
      }
    } finally {
      actions.setSubmitting(false)
    }
  }

  const confirmUploadCertificate = () =>
    confirm({
      title: t`COVID-19 certificate required`,
      content: t`You have to upload a COVID-19 Test or Certificate to be able to book. Open 'Covid-19' page in the Profile Menu.`,
      color: "info",
      variant: "alert",
      onConfirm: () => history.push(reverse(urls.covid.list)),
    })

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <CardTitle className="font-3xl mb-0">
                <BackButton />
              </CardTitle>
              <CardBody>
                <Formik
                  initialValues={initialValues}
                  validate={bookingValidator}
                  onSubmit={async (values, actions) => {
                    if (
                      selectedMembership.location?.requireCovidCertificates &&
                      covdocsData.documents.length === 0
                    ) {
                      confirmUploadCertificate()
                    } else {
                      await submitFormData(values, actions)
                    }
                  }}
                >
                  {({
                    isSubmitting,
                    isValid,
                    values,
                    errors,
                    setFieldValue,
                  }) => {
                    const resourceSelector = (
                      <FormGroup>
                        <div className="d-flex flex-row align-items-center">
                          <Field
                            id="resourceId"
                            type="select"
                            name="resourceId"
                            component={Input}
                            label={t`Bookable Resource`}
                            disabled={Boolean(booking)}
                            onChange={(event) => {
                              const resourceId = parseInt(
                                event.target.value,
                                10,
                              )
                              const booking = getPreparedBookingDto({
                                ...values,
                              })

                              const { startAtDay, bookingDurationUnit } =
                                getInitialBookingRangeForResource({
                                  resourceId,
                                  resources: resourcesData.resources,
                                  startAtDay: booking.startAtDay,
                                  startAt: booking.startAt,
                                  endAt: booking.endAt,
                                })

                              const newStartAt =
                                bookingDurationUnit === HALF_HOUR
                                  ? defaultStartAt
                                  : startOfDay(values.startAtDay)

                              const newEndAt =
                                bookingDurationUnit === HALF_HOUR
                                  ? // diff in ms calculation takes into counting
                                    // the -1ms for endAt timestamp
                                    defaultEndAt
                                  : endOfDay(values.startAtDay)

                              booking.startAtDay = startAtDay
                              booking.startAt = newStartAt
                              booking.endAt = newEndAt

                              booking.price = priceFromCents(
                                computePriceForBooking({
                                  booking,
                                  resource: getSelectedResourceById(
                                    parseInt(event.target.value, 10),
                                  ),
                                }),
                              )

                              const formBooking = formatBookingForForm({
                                ...booking,
                              })

                              setFieldValue("durationUnit", bookingDurationUnit)
                              setFieldValue(
                                "startAtDay",
                                formBooking.startAtDay,
                              )
                              setFieldValue("price", booking.price)
                              setFieldValue("startAt", newStartAt)
                              setFieldValue(
                                "endAt",
                                // ...but input selector requires rounded value
                                // but only for HALF_HOUR duration unit which is
                                // visible to the User
                                bookingDurationUnit === HALF_HOUR
                                  ? roundToNearestMinutes(new Date(newEndAt))
                                  : newEndAt,
                              )
                            }}
                          >
                            {Object.entries(
                              groupBy(
                                (r) => r.resourceType.name,
                                resourcesData.resources
                                  .filter(
                                    (r) =>
                                      r.resourceUserRoles.length === 0 ||
                                      r.resourceUserRoles
                                        .map((ur) => ur.userId)
                                        .includes(currentUser.id),
                                  )
                                  .filter(tagsFilter),
                              ),
                            ).map(([resourceTypeName, resources]) => (
                              <optgroup
                                key={resourceTypeName}
                                label={resourceTypeName}
                              >
                                {resources.map((r) => (
                                  <option key={r.id} value={r.id}>
                                    {r.name} (
                                    {r.resourceType.bookingDurationUnits
                                      .filter((u) => u !== MONTH)
                                      .map((u) => {
                                        const price = priceFromCents(
                                          r.prices.find(
                                            (p) => p.bookingDurationUnit === u,
                                          ),
                                        )

                                        return `${getBookingDurationUnitName(
                                          u,
                                        )}${
                                          selectedMembership.location
                                            .billingEnabled
                                            ? `: ${renderPrice(price)}`
                                            : ""
                                        }`
                                      })
                                      .join(" / ")}
                                    )
                                  </option>
                                ))}
                              </optgroup>
                            ))}
                          </Field>

                          <span
                            className="custom_tooltip"
                            id="ResourceInfoTooltip"
                            style={{
                              marginLeft: "7px",
                              marginTop: "14px",
                              height: "20px",
                            }}
                          >
                            ?
                          </span>
                          <TooltipComponent
                            targetId="ResourceInfoTooltip"
                            placement="top"
                          >
                            <h1>
                              {getSelectedResourceById(values.resourceId).name}
                            </h1>
                            <p>
                              {
                                getSelectedResourceById(values.resourceId)
                                  .description
                              }
                            </p>
                          </TooltipComponent>
                        </div>

                        <FormText color="muted">
                          <Trans>
                            If you do not see the resource in the list, then
                            maybe you do not have permissions to book it. Ask
                            your manager to add you permissions.
                          </Trans>
                        </FormText>
                      </FormGroup>
                    )

                    return (
                      <Form>
                        <h1>
                          {recurringBookingId && "Recurring "}
                          <Trans>Booking</Trans>
                        </h1>

                        {isMobile && (
                          <Row>
                            <Col xs="12" md="4">
                              {resourceSelector}
                            </Col>
                          </Row>
                        )}

                        <Field
                          id="membershipId"
                          name="membershipId"
                          type="hidden"
                          value={selectedMembership.id}
                        />

                        <Row>
                          <Col xs="12" md="4">
                            <Field
                              id="durationUnit"
                              type="select"
                              name="durationUnit"
                              component={Input}
                              label={t`Duration Unit`}
                              className="mr-3"
                              inputClassName="bg-primary i-input-no-validation-icon"
                              onChange={(event) => {
                                const durationUnit = event.target.value

                                const { startAt, endAt } = recalcStartEndAt(
                                  values.startAtDay,
                                  durationUnit,
                                )

                                setFieldValue("endAt", endAt)
                                setFieldValue("startAt", startAt)
                                setFieldValue(
                                  "price",
                                  priceFromCents(
                                    computePriceForBooking({
                                      booking: getPreparedBookingDto({
                                        ...values,
                                        durationUnit,
                                        startAt,
                                        endAt,
                                      }),
                                      resource: getSelectedResourceById(
                                        parseInt(values.resourceId, 10),
                                      ),
                                    }),
                                  ),
                                )
                              }}
                            >
                              {getSelectedResourceById(
                                values.resourceId,
                              ).resourceType.bookingDurationUnits.map((u) => (
                                <option key={u} value={u}>
                                  {getBookingDurationUnitName(u)}
                                </option>
                              ))}
                            </Field>
                          </Col>

                          <Col xs="12" md="6">
                            <Field
                              id="startAtDay"
                              type="text"
                              name="startAtDay"
                              label={t`Select a Day`}
                              component={DatePicker}
                              addon={
                                <InputGroupText>
                                  <i className="far fa-calendar-check" />
                                </InputGroupText>
                              }
                              addonType="prepend"
                              minDate={minStartAtDay}
                              CustomInputComponent={CustomInputComponent}
                              color={errors.startAt ? "danger" : "primary"}
                              dateFormat="MMM d, yyyy"
                              className="mr-3"
                              onChange={(newDate) => {
                                const { startAt, endAt } = recalcStartEndAt(
                                  newDate,
                                  values.durationUnit,
                                )
                                setFieldValue("endAt", endAt)
                                setFieldValue("startAt", startAt)
                              }}
                            />
                          </Col>

                          {values.durationUnit === HALF_HOUR && (
                            <>
                              <Col xs="12" md="4">
                                <Field
                                  id="startAt"
                                  type="text"
                                  name="startAt"
                                  label={t`Starting at`}
                                  disabled={source === "checkin"}
                                  component={DatePicker}
                                  onChange={(startAt) => {
                                    const { endAt } = recalcStartEndAt(
                                      values.startAtDay,
                                      values.durationUnit,
                                      startAt,
                                      values.endAt,
                                    )

                                    setFieldValue("endAt", endAt)

                                    const booking = getPreparedBookingDto({
                                      ...values,
                                      startAt,
                                      endAt,
                                    })

                                    const newPrice = priceFromCents(
                                      computePriceForBooking({
                                        booking,
                                        resource: getSelectedResourceById(
                                          parseInt(values.resourceId, 10),
                                        ),
                                      }),
                                    )

                                    setFieldValue("price", newPrice)
                                  }}
                                  addon={
                                    <InputGroupText>
                                      <i className="far fa-clock" />
                                    </InputGroupText>
                                  }
                                  addonType="prepend"
                                  CustomInputComponent={CustomInputComponent}
                                  color={errors.startAt && "danger"}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  minTime={
                                    isToday(values.startAtDay)
                                      ? // allow hot-booking up to 25 minutes after timeslot started
                                        subMinutes(new Date(), 25)
                                      : startOfDay(values.startAtDay)
                                  }
                                  maxTime={
                                    // allow booking up to 23:00->23:30
                                    setHours(
                                      setMinutes(
                                        startOfDay(values.startAtDay),
                                        0,
                                      ),
                                      23,
                                    )
                                  }
                                  timeCaption="Time"
                                  dateFormat="HH:mm"
                                  className="mr-3"
                                />
                              </Col>
                              <Col xs="12" md="4">
                                <Field
                                  id="endAt"
                                  type="text"
                                  name="endAt"
                                  label={t`Ending at`}
                                  component={DatePicker}
                                  onChange={(endAt) => {
                                    const newPrice = priceFromCents(
                                      computePriceForBooking({
                                        booking: getPreparedBookingDto({
                                          ...values,
                                          endAt,
                                        }),
                                        resource: getSelectedResourceById(
                                          parseInt(values.resourceId, 10),
                                        ),
                                      }),
                                    )

                                    setFieldValue("price", newPrice)
                                  }}
                                  addon={
                                    <InputGroupText>
                                      <i className="far fa-clock" />
                                    </InputGroupText>
                                  }
                                  addonType="prepend"
                                  CustomInputComponent={CustomInputComponent}
                                  color={errors.startAt && "danger"}
                                  showTimeSelect
                                  showTimeSelectOnly
                                  minTime={addMinutes(values.startAt, 30)}
                                  maxTime={
                                    // allow booking up to 23:00->23:30
                                    setHours(
                                      setMinutes(
                                        startOfDay(values.startAtDay),
                                        30,
                                      ),
                                      23,
                                    )
                                  }
                                  timeCaption={t`Time`}
                                  dateFormat="HH:mm"
                                />
                              </Col>
                            </>
                          )}
                        </Row>
                        {values.resource.repeatBookingEnabled && (
                          <Field
                            id="isRecurringCheckbox"
                            type="checkbox"
                            name="isRecurringCheckbox"
                            component={Input}
                            label={
                              <p className="m-0">
                                <Trans>Repeat</Trans>
                              </p>
                            }
                            disabled={Boolean(booking)}
                          />
                        )}

                        {values.isRecurringCheckbox && (
                          <Row>
                            <Col>
                              <Field
                                id="repeatPattern"
                                type="select"
                                name="repeatPattern"
                                label={t`Pattern`}
                                component={Input}
                              >
                                {recurringBookingRepeatPatternOptions.map(
                                  (rp) => (
                                    <option key={rp.id} value={rp.id}>
                                      {rp.name}
                                    </option>
                                  ),
                                )}
                              </Field>
                            </Col>
                            <Col>
                              <Field
                                id="endRecurringAtDay"
                                type="text"
                                name="endRecurringAtDay"
                                label={t`Repeat until`}
                                component={DatePicker}
                                addon={
                                  <InputGroupText>
                                    <i className="far fa-calendar-check" />
                                  </InputGroupText>
                                }
                                addonType="prepend"
                                minDate={startOfDay(values.startAtDay)}
                                maxDate={addMonths(
                                  startOfDay(values.startAtDay),
                                  6, // max. recurring length is half a year
                                )}
                                CustomInputComponent={CustomInputComponent}
                                dateFormat="MMM d, yyyy"
                                className="mr-3"
                              />
                            </Col>
                          </Row>
                        )}

                        <Field
                          id="isPrivate"
                          type="checkbox"
                          name="isPrivate"
                          component={Input}
                          label={
                            <p className="m-0" id="make-private">
                              <Trans>Make private</Trans>
                            </p>
                          }
                        />
                        <TooltipComponent targetId="make-private">
                          <span className="text-white">
                            Other users will not see your booking details.
                          </span>
                        </TooltipComponent>

                        <FoldableBlock
                          name={t`optional settings`}
                          outline={false}
                        >
                          <FormGroup>
                            <Field
                              id="user"
                              name="user"
                              type="select"
                              component={Select}
                              label="Booking made for"
                              disabled={Boolean(booking)}
                              value={values.user}
                              isClearable={false}
                              options={sortBy(compose(toLower, prop("label")))(
                                allowedAttendeesData?.users?.map((u) => ({
                                  value: u.id,
                                  label: getUserFullName(u),
                                })) ?? [],
                              )}
                            />
                          </FormGroup>

                          <FormGroup>
                            <Field
                              id="title"
                              type="text"
                              name="title"
                              component={Input}
                              label={
                                <p className="m-0">
                                  <Trans>Title</Trans>
                                  <span className="text-muted">
                                    {" "}
                                    (<Trans>optional</Trans>)
                                  </span>
                                </p>
                              }
                              addon={
                                <InputGroupText>
                                  <i className="fas fa-align-left" />
                                </InputGroupText>
                              }
                              addonType="prepend"
                            />
                            <FormText color="muted">
                              <Trans>
                                This title is private to your account/company.
                              </Trans>
                              <br />
                              <Trans>
                                It will be shown on your booking invoice.
                              </Trans>
                              : &quot;
                              <Trans>Workshop, Digital Team Meeting</Trans>
                              &quot;
                            </FormText>
                          </FormGroup>

                          <Field
                            id="description"
                            type="textarea"
                            name="description"
                            component={Input}
                            label={
                              <p className="m-0">
                                <Trans>Description</Trans>
                                <span className="text-muted">
                                  {" "}
                                  (<Trans>optional</Trans>)
                                </span>
                              </p>
                            }
                            rows="5"
                          />

                          <FormGroup>
                            <Field
                              id="attendees"
                              type="select"
                              name="attendees"
                              component={MultiSelect}
                              label={
                                <p className="m-0">
                                  <Trans>Attendees</Trans>
                                  <span className="text-muted">
                                    {" "}
                                    (<Trans>optional</Trans>)
                                  </span>
                                </p>
                              }
                              isLoading={allowedAttendeesIsPending}
                              disabled={
                                !(
                                  booking?.userId === currentUser.id ||
                                  booking?.recurringBooking?.userId ===
                                    currentUser.id ||
                                  !booking
                                )
                              }
                              options={
                                allowedAttendeesData?.users?.map(
                                  usersToAttandeesSelector,
                                ) || []
                              }
                              value={
                                values.attendees?.map(
                                  usersToAttandeesSelector,
                                ) || []
                              }
                            />
                          </FormGroup>

                          <FormGroup className="d-none">
                            <Label for="search-filter-tags">
                              <Trans>Filter</Trans>
                              <span className="text-muted">
                                {" "}
                                ( <Trans>optional</Trans>)
                              </span>
                            </Label>
                            <RSelect
                              id="search-filter-tags"
                              className="multi-select-input"
                              onChange={(options) =>
                                setFilterTags(
                                  options?.map(selectOptionsToTags) || [],
                                )
                              }
                              isClearable
                              isMulti
                              isLoading={tagsIsPending}
                              disabled={tagsError}
                              options={
                                tagsData?.resourceTags?.map(
                                  tagsToSelectOptions,
                                ) || []
                              }
                              value={filterTags.map(tagsToSelectOptions) || []}
                            />
                          </FormGroup>

                          {!isMobile && resourceSelector}
                        </FoldableBlock>

                        <hr className="bg-primary" style={{ height: "2px" }} />

                        <p className="font-lg font-weight-bold text-center">
                          <Trans>Booking summary</Trans>
                        </p>
                        <Jumbotron fluid className="py-2 bg-transparent">
                          <p className="mb-1 booking-summary-item">
                            <Trans>You will book</Trans>
                            <span className="float-right text-body">{` "${
                              resourcesData.resources.find(
                                (r) => r.id === parseInt(values.resourceId, 10),
                              )?.name
                            }"`}</span>
                          </p>
                          <p className="mb-1 booking-summary-item">
                            <Trans>Booking made for</Trans>:{" "}
                            <span className="float-right text-body">
                              {values.user?.label}
                            </span>
                          </p>
                          <p className="mb-1 booking-summary-item">
                            <Trans>Duration</Trans>
                            {": "}
                            <span className="float-right text-body">
                              {values.durationUnit === HALF_HOUR
                                ? getDurationString(
                                    values.startAt,
                                    values.endAt,
                                    values.durationUnit,
                                  )
                                : t`full day`}
                            </span>
                          </p>
                          {values.durationUnit === HALF_HOUR && (
                            <>
                              <p className="mb-1 booking-summary-item">
                                <Trans>Date:</Trans>
                                <span className="float-right text-body">
                                  <FormatDate
                                    date={new Date(values.startAt)}
                                    formatStr="PPPP"
                                  />
                                </span>
                              </p>
                              <p className="mb-1 booking-summary-item">
                                <Trans>Start at:</Trans>{" "}
                                <span className="float-right text-body">
                                  <FormatDate
                                    date={new Date(values.startAt)}
                                    formatStr="HH:mm"
                                  />
                                </span>
                              </p>
                              <p className="mb-1 booking-summary-item">
                                <Trans>End at:</Trans>{" "}
                                <span className="float-right text-body">
                                  <FormatDate
                                    date={new Date(values.endAt)}
                                    formatStr="HH:mm"
                                  />
                                </span>
                              </p>
                            </>
                          )}
                          {values.durationUnit === DAY && (
                            <>
                              <p className="mb-1 booking-summary-item">
                                <Trans>Start at:</Trans>{" "}
                                <span className="float-right text-body">
                                  <FormatDate
                                    date={new Date(values.startAt)}
                                    formatStr="PPPP"
                                  />
                                </span>
                              </p>
                              <p className="mb-1 booking-summary-item">
                                <Trans>End at:</Trans>{" "}
                                <span className="float-right text-body">
                                  <FormatDate
                                    date={new Date(values.endAt)}
                                    formatStr="PPPP"
                                  />
                                </span>
                              </p>
                            </>
                          )}

                          {isUserTimezoneDifferentFromLocationTimezone(
                            selectedMembership.location.timezone,
                          ) && (
                            <p className="mb-1 booking-summary-item">
                              <Trans>Location time</Trans>:{" "}
                              <span className="float-right text-body">
                                <FormatRelativeDate
                                  date={utcToZonedTime(
                                    applyLocationTimezoneToBooking({
                                      booking: values,
                                      location: selectedMembership.location,
                                    }).startAt,
                                    selectedMembership.location.timezone,
                                  )}
                                />
                                {" - "}
                                <FormatRelativeDate
                                  date={utcToZonedTime(
                                    addMilliseconds(
                                      applyLocationTimezoneToBooking({
                                        booking: values,
                                        location: selectedMembership.location,
                                      }).endAt,
                                      1,
                                    ),
                                    selectedMembership.location.timezone,
                                  )}
                                />
                              </span>
                            </p>
                          )}
                          {selectedMembership.location.billingEnabled && (
                            <p className="mb-1 booking-summary-item">
                              <Trans>Cost</Trans>{" "}
                              <span className="float-right text-body">
                                {values.price.amount} €
                              </span>
                            </p>
                          )}
                          {values.isRecurringCheckbox && (
                            <>
                              <p className="mb-1 booking-summary-item">
                                <Trans>Will repeat</Trans>
                                <span className="float-right text-body">
                                  {
                                    recurringBookingRepeatPatternOptions.find(
                                      (rp) => rp.id === values.repeatPattern,
                                    )?.name
                                  }
                                  {" until "}
                                  {values.isRecurringCheckbox && (
                                    <FormatDate
                                      date={new Date(values.endRecurringAtDay)}
                                      formatStr="PPPP"
                                    />
                                  )}
                                </span>
                              </p>
                            </>
                          )}
                        </Jumbotron>

                        <FormGroup className="form-actions">
                          <Button
                            type="submit"
                            loading={isSubmitting}
                            disabled={!isValid || isSubmitting}
                            color={
                              !isValid || isSubmitting ? "secondary" : "primary"
                            }
                            block
                          >
                            <Trans>Submit</Trans>
                          </Button>
                        </FormGroup>
                      </Form>
                    )
                  }}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

BookingForm.propTypes = {
  bookingId: PropTypes.number,
  recurringBookingId: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
}

BookingForm.defaultProps = {}
