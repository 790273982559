import axios from "axios"
import axiosRetry from "axios-retry"
import { config } from "../config"
import { MEMBER, PENDING } from "../constants"
import { errorInterceptor } from "./axios-interceptors"

export const http = axios.create({
  baseURL: config.intertempi.api.url,
  withCredentials: true,
  timeout: 10000,
  headers: {
    "Client-Type": MEMBER,
  },
})

axiosRetry(http, { retries: 3, retryDelay: axiosRetry.exponentialDelay })

http.interceptors.response.use((response) => response, errorInterceptor)

class Api {
  constructor(http) {
    this.http = http
  }

  setDefaultHeader = (header, value) => {
    http.defaults.headers[header] = value
  }

  getMyUser = async () => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/users/me",
    })

    return res.data
  }

  getUserList = async ({ rangeStart = 0, rangeEnd = 99 } = {}) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/users",
      params: { rangeStart, rangeEnd },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { users: res.data, count }
  }

  getUser = async ({ userId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/users/${userId}`,
    })

    return res.data
  }

  createUser = async ({ email, lastName, firstName }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/users",
      data: { email, lastName, firstName },
    })

    return res.data
  }

  updateUser = async (userDto) => {
    const res = await this.http({
      method: "PUT",
      url: "/api/v1/users/me",
      data: userDto,
    })

    return res.data
  }

  updateRole = async ({ userId, roleId, roleDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/users/${userId}/roles/${roleId}`,
      data: roleDto,
    })

    return res.data
  }

  deleteMyRole = async ({ roleId }) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/users/me/roles/${roleId}`,
    })

    return res.data
  }

  deleteRole = async ({ accountId, roleId }) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/accounts/${accountId}/roles/${roleId}`,
    })

    return res.data
  }

  createOTPW = async ({ email }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/otpws",
      data: { email, userType: MEMBER },
    })

    return res.data
  }

  createSession = async ({ email, password }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/sessions",
      data: { email, password },
    })

    return res.data
  }

  updateSession = async ({ accountId }) => {
    const res = await this.http({
      method: "PUT",
      url: "/api/v1/sessions/me",
      data: { accountId },
    })

    return res.data
  }

  deleteSession = async (sessionId = "me") => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/sessions/${sessionId}`,
    })

    return res.data
  }

  createToken = async ({ email, password }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/tokens",
      data: { email, password },
    })

    const token = res.data.token
    localStorage.setItem("savedToken", token)
    this.setDefaultHeader("authorization", token)

    return res.data
  }

  updateToken = async ({ accountId }) => {
    const res = await this.http({
      method: "PUT",
      url: "/api/v1/tokens/me",
      data: { accountId },
    })

    const token = res.data.token
    localStorage.setItem("savedToken", token)
    this.setDefaultHeader("authorization", token)

    return res.data
  }

  deleteToken = async (sessionId = "me") => {
    delete http.defaults.headers.authorization
    localStorage.removeItem("savedToken")
  }

  getAccountList = async ({ rangeStart = 0, rangeEnd = 99 } = {}) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/accounts",
      params: {
        rangeStart,
        rangeEnd,
        "types:any": MEMBER,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { accounts: res.data, count }
  }

  createAccount = async (accountDto) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/accounts",
      data: {
        ...accountDto,
        types: [MEMBER],
      },
    })

    return res.data
  }

  deleteMyAccount = async ({ accountId }) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/accounts/${accountId}`,
    })

    return res.data
  }

  getCompany = async () => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/company",
    })

    return res.data
  }

  createCompany = async (companyDto) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/company",
      data: companyDto,
    })

    return res.data
  }

  updateCompany = async (companyDto) => {
    const res = await this.http({
      method: "PUT",
      url: "/api/v1/company",
      data: companyDto,
    })

    return res.data
  }

  getWhiteLabelSettings = async () => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/members/white-label-settings",
    })

    return res.data
  }

  getInviteList = async ({ rangeStart = 0, rangeEnd = 99 } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/invites`,
      params: { rangeStart, rangeEnd },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { invites: res.data, count }
  }

  createInvite = async (inviteDto) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/invites`,
      data: {
        ...inviteDto,
        userType: MEMBER,
      },
    })

    return res.data
  }

  createMembershipInvite = async ({ locationId, membershipInviteDto }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/locations/${locationId}/membership-invites`,
      data: membershipInviteDto,
    })

    return res.data
  }

  getMyInviteList = async ({
    rangeStart = 0,
    rangeEnd = 99,
    status = PENDING,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/users/me/invites",
      params: {
        rangeStart,
        rangeEnd,
        status,
        eager: "[user,account]",
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { invites: res.data, count }
  }

  updateInvite = async ({ inviteId, inviteDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/users/me/invites/${inviteId}`,
      data: inviteDto,
    })

    return res.data
  }

  getMembershipInviteList = async ({
    rangeStart = 0,
    rangeEnd = 99,
    status = PENDING,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/users/me/membership-invites",
      params: { rangeStart, rangeEnd, status, eager: "location" },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { membershipInvites: res.data, count }
  }

  updateMembershipInvite = async ({
    membershipInviteId,
    membershipInviteDto,
  }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/users/me/membership-invites/${membershipInviteId}`,
      data: membershipInviteDto,
    })

    return res.data
  }

  getMembershipList = async ({
    rangeStart = 0,
    rangeEnd = 99,
    status,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships`,
      params: {
        rangeStart,
        rangeEnd,
        status,
        eager: eager || "location",
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { memberships: res.data, count }
  }

  getMembershipListByLocation = async ({
    locationId,
    rangeStart = 0,
    rangeEnd = 99,
    status,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/memberships`,
      params: {
        rangeStart,
        rangeEnd,
        status,
        eager: eager || "account",
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { memberships: res.data, count }
  }

  getMembership = async ({ membershipId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}`,
    })

    return res.data
  }

  getMembershipPlan = async ({ locationId, membershipPlanId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/membership-plans/${membershipPlanId}`,
    })

    return res.data
  }

  getResource = async ({ membershipId, resourceId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/resources/${resourceId}`,
    })

    return res.data
  }

  getResourceList = async ({
    membershipId,
    resourceTypeId,
    rangeStart = 0,
    rangeEnd = 599,
    bookingDurationUnits,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/resources`,
      params: {
        resourceTypeId,
        rangeStart,
        rangeEnd,
        "resourceType.bookingDurationUnits:any":
          bookingDurationUnits && bookingDurationUnits.join(","),
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { resources: res.data, count }
  }

  getBookingList = async ({
    membershipId,
    rangeStart = 0,
    rangeEnd = 99,
    resourceId,
    startAtGte,
    endAtLte,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/bookings`,
      params: {
        rangeStart,
        rangeEnd,
        resourceId,
        "startAt:gte": startAtGte,
        "endAt:lte": endAtLte,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { bookings: res.data, count }
  }

  getBookingListByLocation = async ({
    locationId,
    membershipId,
    rangeStart = 0,
    rangeEnd = 99,
    resourceId,
    startAtGte,
    endAtLte,
    startAtLte,
    endAtGte,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/bookings`,
      params: {
        membershipId,
        rangeStart,
        rangeEnd,
        resourceId,
        "startAt:gte": startAtGte,
        "endAt:lte": endAtLte,
        "startAt:lte": startAtLte,
        "endAt:gte": endAtGte,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { bookings: res.data, count }
  }

  getMyTeammatesBookingList = async ({
    membershipId,
    resourceId,
    rangeStart = 0,
    rangeEnd = 99,
    startAtGte,
    endAtLte,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/teammates/bookings`,
      params: {
        rangeStart,
        rangeEnd,
        resourceId,
        "startAt:gte": startAtGte,
        "endAt:lte": endAtLte,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { bookings: res.data, count }
  }

  getTeamBookingList = async ({
    locationId,
    membershipId,
    resourceId,
    rangeStart = 0,
    rangeEnd = 99,
    startAtGte,
    endAtLte,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/memberships/${membershipId}/teammates/bookings`,
      params: {
        rangeStart,
        rangeEnd,
        resourceId,
        "startAt:gte": startAtGte,
        "endAt:lte": endAtLte,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { bookings: res.data, count }
  }

  getBooking = async ({ membershipId, bookingId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/bookings/${bookingId}`,
    })

    return res.data
  }

  createBooking = async ({ membershipId, bookingDto }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/memberships/${membershipId}/bookings`,
      data: bookingDto,
    })

    return res.data
  }

  updateBooking = async ({ bookingId, membershipId, bookingDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/memberships/${membershipId}/bookings/${bookingId}`,
      data: bookingDto,
    })

    return res.data
  }

  deleteBooking = async ({ membershipId, bookingId }) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/memberships/${membershipId}/bookings/${bookingId}`,
    })

    return res.data
  }

  getRecurringBooking = async ({ membershipId, recurringBookingId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/recurring-bookings/${recurringBookingId}`,
    })

    return res.data
  }

  createRecurringBooking = async ({ membershipId, recurringBookingDto }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/memberships/${membershipId}/recurring-bookings`,
      data: recurringBookingDto,
    })

    return res.data
  }

  updateRecurringBooking = async ({
    recurringBookingId,
    membershipId,
    recurringBookingDto,
  }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/memberships/${membershipId}/recurring-bookings/${recurringBookingId}`,
      data: recurringBookingDto,
    })

    return res.data
  }

  deleteRecurringBooking = async ({ membershipId, recurringBookingId }) => {
    const res = await this.http({
      method: "DELETE",
      url: `/api/v1/memberships/${membershipId}/recurring-bookings/${recurringBookingId}`,
    })

    return res.data
  }

  getBilling = async () => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/billing`,
    })
    return res.data
  }

  createBilling = async ({ billingDto }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/billing`,
      data: billingDto,
    })

    return res.data
  }

  updateBilling = async ({ billingDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/billing`,
      data: billingDto,
    })

    return res.data
  }

  getStripeIntegration = async ({ membershipId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/integrations/stripe`,
    })

    return res.data
  }

  createCheckoutSession = async ({ membershipId }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/memberships/${membershipId}/integrations/stripe/checkout-sessions/`,
    })

    return res.data
  }

  getFloorList = async ({
    locationId,
    membershipId,
    rangeStart = 0,
    rangeEnd = 99,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/floors`,
      params: { membershipId, rangeStart, rangeEnd, eager },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { floors: res.data, count }
  }

  getPointList = async ({
    locationId,
    floorId,
    membershipId,
    rangeStart = 0,
    rangeEnd = 99,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/floors/${floorId}/points`,
      params: {
        locationId,
        floorId,
        membershipId,
        rangeStart,
        rangeEnd,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { points: res.data, count }
  }

  getMembershipPlanList = async ({
    locationId,
    membershipId,
    rangeStart = 0,
    rangeEnd = 999,
    startAtGte,
    endAtLte,
  }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/membership-plans`,
      params: {
        membershipId,
        rangeStart,
        rangeEnd,
        "startAt:gte": startAtGte,
        "endAt:lte": endAtLte,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { membershipPlans: res.data, count }
  }

  getResourceOccupancy = async ({
    locationId,
    membershipId,
    resourceId,
    startAt,
    endAt,
    timezone,
  }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/resources/${resourceId}/occupancy`,
      params: { startAt, endAt, timezone, membershipId },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { occupancy: res.data, count }
  }

  getResourceTagsList = async ({
    membershipId,
    rangeStart = 0,
    rangeEnd = 999,
  }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/memberships/${membershipId}/resource-tags`,
      params: { rangeStart, rangeEnd },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { resourceTags: res.data, count }
  }

  getDocumentsList = async ({
    rangeStart = 0,
    rangeEnd = 99,
    srcType,
    srcId,
  }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/documents`,
      params: {
        rangeStart,
        rangeEnd,
        srcType,
        srcId,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { documents: res.data, count }
  }

  createDocument = async ({ membershipId, documentDto }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/memberships/${membershipId}/documents`,
      data: documentDto,
    })

    return res.data
  }

  updateDocument = async ({ documentId, membershipId, documentDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/memberships/${membershipId}/documents/${documentId}`,
      data: documentDto,
    })

    return res.data
  }

  getMyCovidCertificatesList = async ({
    rangeStart = 0,
    rangeEnd = 9,
    validUntilGte,
    validUntilLte,
    eager,
  }) => {
    const res = await this.http({
      method: "GET",
      url: "/api/v1/covid/",
      params: {
        rangeStart,
        rangeEnd,
        "validUntil:gte": validUntilGte,
        "validUntil:lte": validUntilLte,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { documents: res.data, count }
  }

  getCovidCertificate = async ({ documentId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/covid/${documentId}`,
    })

    return res.data
  }

  createCovidCertificate = async ({ documentDto }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/covid",
      data: documentDto,
    })

    return res.data
  }

  updateCovidCertificate = async ({ documentId, documentDto }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/covid/${documentId}`,
      data: documentDto,
    })

    return res.data
  }

  uploadObjectToS3 = async ({ url, type, object }) => {
    const res = await this.http({
      method: "PUT",
      url,
      data: object,
      withCredentials: false,
      timeout: 30000,
      headers: {
        "Content-Type": type,
      },
      transformRequest: [
        (data, headers) => {
          delete headers.authorization
          return data
        },
      ],
    })

    return res.data
  }

  createS3SignedUrlToPutObject = async ({ type }) => {
    const res = await this.http({
      method: "POST",
      url: "/api/v1/signed-url-put-object",
      data: { type },
    })

    return res.data
  }

  getLocation = async (locationId) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}`,
    })

    return res.data
  }

  getSupply = async ({ locationId, supplyId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/supplies/${supplyId}`,
    })

    return res.data
  }

  createServiceTicket = async ({ locationId, serviceTicketDto }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/locations/${locationId}/maintenance/tickets`,
      data: serviceTicketDto,
    })

    return res.data
  }

  getLocationCheckinsList = async ({
    rangeStart = 0,
    rangeEnd = 99,
    locationId,
    eager,
  } = {}) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/checkins`,
      params: {
        rangeStart,
        rangeEnd,
        eager,
      },
    })

    const count = parseInt(res.headers["x-total-count"], 10)

    return { checkins: res.data, count }
  }

  getActiveResourceCheckin = async ({ locationId, resourceId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/resources/${resourceId}/checkins`,
    })

    return res.data
  }

  getMyActiveCheckin = async ({ locationId }) => {
    const res = await this.http({
      method: "GET",
      url: `/api/v1/locations/${locationId}/checkins/my`,
    })

    return res.data
  }

  createCheckin = async ({ locationId, resourceId }) => {
    const res = await this.http({
      method: "POST",
      url: `/api/v1/locations/${locationId}/resources/${resourceId}/checkins`,
      data: {},
    })

    return res.data
  }

  updateCheckin = async ({ locationId, resourceId }) => {
    const res = await this.http({
      method: "PUT",
      url: `/api/v1/locations/${locationId}/resources/${resourceId}/checkins`,
      data: {},
    })

    return res.data
  }
}

export const api = new Api(http)
api.setDefaultHeader("authorization", localStorage.getItem("savedToken"))
