import React, { useContext, useState } from "react"
import { useParsedParams } from "../../hooks"
import { Link, useHistory } from "react-router-dom"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import {
  startOfDay,
  intervalToDuration,
  addMilliseconds,
  differenceInMilliseconds,
  parseISO,
  startOfToday,
  endOfDay,
} from "date-fns"
import ReactDatePicker from "react-datepicker"
import { useAsync } from "react-async"
import { ApiContext } from "../../providers/api-provider"
import { Spinner } from "../../components/spinner"
import { UserContext } from "./user-provider"
import { getUserFullName } from "../../lib/helpers"
import {
  FormatDuration,
  FormatRelativeDate,
} from "../../components/formatters/index"
import { DatePickerCustomInput } from "../floorplan/floorplan"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  Spinner as RSpinner,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  ButtonGroup,
  Badge,
  Input,
} from "reactstrap"
import { Button } from "../../components/button"
import { BackButton } from "../../components/backbutton"
import { useConfirmation } from "../../components/confirmation-dialog"
import { useMembership } from "../membership/membership-provider"
import { useAccount } from "./account-provider"
import { getBookingBadgeTextAndStatusColor } from "../booking/helpers"
import { toast } from "react-toastify"
import { OWNER } from "../../constants"
import { toastError } from "../../components/error"
import { ClipboardIconButton } from "../../components/clipboard-button"
import { t, Trans } from "@lingui/macro"

export function UserTeamDetails() {
  const api = useContext(ApiContext)
  const history = useHistory()
  const [selectedDateTime, setSelectedDateTime] = useState(null)
  const [filterText, setFilterText] = useState("")
  const { userId } = useParsedParams({ userId: Number })
  const { user: currentUser } = useContext(UserContext)
  const { selectedMembership, memberships, selectMembership } = useMembership()
  const { selectedAccount, setSelectedAccount } = useAccount()
  const accountId = selectedAccount.id
  const confirm = useConfirmation()

  const {
    data: user,
    error: userError,
    isPending: isUserPending,
  } = useAsync({
    promiseFn: api.getUser,
    userId,
    onResolve: async (data) => {
      if (data.count === 0) return

      await runBookings({
        startAtGte: startOfDay(selectedDateTime ?? new Date()),
        endAtLte: selectedDateTime ? endOfDay(selectedDateTime) : undefined,
        rangeStart: 0,
        rangeEnd: 999,
        eager: "[membership,user,resource]",
      })
    },
  })

  const {
    data: bookingsData,
    error: bookingsError,
    isPending: isBookingsPending,
    run: runBookings,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await Promise.all(
        memberships.map((m) =>
          api.getMyTeammatesBookingList({
            locationId: m.locationId,
            membershipId: m.id,
            ...args,
          }),
        ),
      )

      return memberships.map((m, idx) => [m, data[idx]])
    },
  })

  function updateCurrentDate(date) {
    setSelectedDateTime(date)

    return runBookings({
      startAtGte: startOfDay(date ?? new Date()),
      endAtLte: date ? endOfDay(date) : undefined,
      rangeStart: 0,
      rangeEnd: 999,
      eager: "[membership,user,resource]",
    })
  }

  function filterThroughUsers(booking) {
    // TODO: update this function after extended to multiple attendees
    return (
      getUserFullName(booking.user ?? booking.recurringBooking?.user)
        .toLowerCase()
        .includes(filterText) ||
      (booking.user?.email ?? booking.recurringBooking?.user?.email).includes(
        filterText,
      ) ||
      booking.attendees.some(
        (a) =>
          getUserFullName(a).toLowerCase().includes(filterText) ||
          (a?.email ?? "").includes(filterText),
      )
    )
  }

  function textFilter(booking) {
    return (
      booking.title.toLowerCase().includes(filterText) ||
      booking.description.toLowerCase().includes(filterText) ||
      booking.resource.name.toLowerCase().includes(filterText) ||
      filterThroughUsers(booking)
    )
  }

  function getUserRolename(user) {
    if (!user || user.roles.length < 1) return ""
    return user.roles.find((role) => role.accountId === accountId).name
  }

  if (userError) {
    throw userError
  }

  if (isUserPending) {
    return <Spinner />
  }

  const confirmDeleteMyRole = () =>
    confirm({
      title: t`Confirm Leaving This Account`,
      content: t`Are you sure you want to leave this account?`,
      color: "danger",
      variant: "confirm",
      onConfirm: async () => {
        try {
          const role = user.roles.find((role) => role.accountId === accountId)

          await api.deleteMyRole({ roleId: role.id })

          setSelectedAccount(null)
          history.push(reverse(urls.booking.calendar))

          toast.success(t`You've successfully left the account.`)
        } catch (err) {
          toastError(err)
          throw err
        }
      },
    })

  const { privacyMode } = selectedMembership.location

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl" data-private>
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>
                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText className="text-truncate">
                      {getUserFullName(user)}

                      {user.id === currentUser.id && (
                        <>
                          {" "}
                          (<Trans>you</Trans>)
                        </>
                      )}
                    </CardText>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <p>
                <Trans>Team Profile</Trans>:
              </p>
              {user?.img && (
                <div className="d-flex justify-content-center">
                  <img className="w-25 m-2" src={user.img} alt="user img" />
                </div>
              )}
              <Row>
                <Col md="3" className="mb-4 text-right">
                  <Trans>Full name</Trans>:
                </Col>
                <Col md="9" className="mb-4" data-private>
                  {" " + getUserFullName(user)}
                </Col>
              </Row>
              {(!privacyMode || currentUser.id === user.id) && (
                <>
                  <Row>
                    <Col md="3" className="mb-4 text-right">
                      <Trans>Email</Trans>:
                    </Col>
                    <Col md="9" className="mb-4" data-private>
                      {user.email}{" "}
                      <ClipboardIconButton clipboardText={user.email} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="mb-4 text-right">
                      <Trans>Created At</Trans>:
                    </Col>
                    <Col md="9" className="mb-4">
                      <FormatRelativeDate date={new Date(user.createdAt)} />
                    </Col>
                  </Row>
                  <Row>
                    <Col md="3" className="mb-4 text-right">
                      <Trans>Last activity at</Trans>:
                    </Col>
                    <Col md="9" className="mb-4">
                      {user.lastActivityAt ? (
                        <FormatRelativeDate
                          date={new Date(user.lastActivityAt)}
                        />
                      ) : (
                        <Trans>never</Trans>
                      )}
                    </Col>
                  </Row>
                </>
              )}

              {user.id === currentUser.id && getUserRolename(user) !== OWNER && (
                <Row>
                  <Col md="3" className="mb-4 text-right">
                    <Trans>Leave account</Trans>:
                  </Col>
                  <Col md="9" className="mb-4">
                    <Button color="danger" onClick={confirmDeleteMyRole}>
                      <Trans>Leave Account</Trans>
                    </Button>
                  </Col>
                </Row>
              )}
            </CardBody>

            <CardBody>
              {isBookingsPending && <RSpinner style={{ margin: "0 auto" }} />}

              {!isBookingsPending && bookingsError && (
                <Card>
                  <CardBody>
                    <i className="fas fa-exclamation-triangle mr-1" />
                    <Trans>
                      Error loading bookings. Try to use{" "}
                      <u>
                        <Link to={reverse(urls.booking.teammates)}>
                          booking search
                        </Link>
                      </u>{" "}
                      instead.
                    </Trans>
                  </CardBody>
                </Card>
              )}

              {!isBookingsPending &&
                bookingsData &&
                bookingsData
                  .flatMap(([_, d]) => d?.bookings)
                  .filter(
                    (b) =>
                      b.userId === user.id ||
                      b.recurringBooking?.userId === user.id,
                  ).length === 0 && (
                  <Card>
                    <CardBody>
                      <p className="lead">
                        <i className="far fa-lightbulb" />{" "}
                        <Trans>There are no active bookings to show</Trans>
                      </p>
                    </CardBody>
                  </Card>
                )}

              {!isBookingsPending &&
                bookingsData &&
                // TODO: replace with backend filtering !
                bookingsData
                  .flatMap(([_, d]) => d?.bookings)
                  .filter(
                    (b) =>
                      b.userId === user.id ||
                      b.recurringBooking?.userId === user.id,
                  ).length > 0 && (
                  <ListGroup>
                    <Row>
                      <Col xs="12" md="6">
                        <Trans>Upcoming Bookings</Trans>
                      </Col>
                    </Row>

                    <Row className="mt-3">
                      <Col xs="12" md="4" className="mb-3">
                        <Input
                          id="search-filter-text"
                          type="text"
                          placeholder={t`Search by text`}
                          value={filterText}
                          onChange={(e) =>
                            setFilterText(e.target.value.toLowerCase())
                          }
                        />
                      </Col>
                      <Col
                        xs="12"
                        md="8"
                        className="mb-3 d-flex justify-content-end"
                      >
                        <ButtonGroup>
                          <Link to={reverse(urls.booking.create)}>
                            <Button color="primary">
                              <Trans>Book now</Trans>
                            </Button>
                          </Link>

                          <div className="custom-datetimepicker-wrapper ml-2">
                            <ReactDatePicker
                              id="filter-datetime-picker"
                              selected={selectedDateTime}
                              dateFormat="d MM yyyy"
                              popperPlacement="bottom-end"
                              customInput={<DatePickerCustomInput />}
                              onChange={(date) => updateCurrentDate(date)}
                            />
                          </div>

                          <Button
                            color="primary"
                            className="ml-2"
                            onClick={() => updateCurrentDate(startOfToday())}
                            disabled={
                              isBookingsPending ||
                              +selectedDateTime === +startOfToday()
                            }
                          >
                            <i className="far fa-calendar" />{" "}
                            <Trans>Today</Trans>
                          </Button>

                          <Button
                            color="primary"
                            className="ml-2"
                            onClick={() => updateCurrentDate(null)}
                            disabled={
                              isBookingsPending || selectedDateTime === null
                            }
                          >
                            <i className="far fa-calendar" />{" "}
                            <Trans>All upcoming</Trans>
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </Row>

                    {bookingsData.map(([membership, bookingsData]) => {
                      if (
                        !bookingsData ||
                        bookingsData.bookings.filter(
                          (b) =>
                            b.userId === user.id ||
                            b.recurringBooking?.userId === user.id,
                        ).length < 1
                      ) {
                        return null
                      }

                      return (
                        <div className="mt-3" key={membership.id}>
                          <h2>{membership.location.name}</h2>
                          {bookingsData.bookings
                            // TODO: replace with backend filtering !
                            .filter(
                              (b) =>
                                b.userId === user.id ||
                                b.recurringBooking?.userId === user.id,
                            )
                            .filter(textFilter)
                            .sort((fel, sel) => {
                              // sort in chronologic order top-to-bottom
                              return differenceInMilliseconds(
                                parseISO(fel.startAt),
                                parseISO(sel.startAt),
                              )
                            })
                            .map((booking) => {
                              const { color, text } =
                                getBookingBadgeTextAndStatusColor(booking)

                              return (
                                <ListGroupItem
                                  className="mt-1 cursor-pointer"
                                  key={booking.id}
                                  onClick={(e) => {
                                    if (e.target.tagName === "A") {
                                      return
                                    }

                                    if (
                                      booking.membershipId !==
                                      selectedMembership.id
                                    ) {
                                      selectMembership(booking.membershipId)
                                    }

                                    history.push(
                                      reverse(urls.booking.details, {
                                        bookingId: booking.id,
                                      }),
                                    )
                                  }}
                                >
                                  <Row>
                                    <Col xs="10">
                                      <p>{booking.title || "-"}</p>
                                    </Col>

                                    <Col xs="2">
                                      <Badge
                                        className="mr-1 float-right"
                                        color={color}
                                      >
                                        {text}
                                      </Badge>
                                    </Col>
                                  </Row>

                                  <ListGroupItemText className="mb-0">
                                    <Link
                                      to={reverse(urls.resource.details, {
                                        resourceId: booking.resource.id,
                                      })}
                                    >
                                      {booking.resource.name}
                                    </Link>
                                  </ListGroupItemText>

                                  <ListGroupItemText className="mb-0">
                                    {!booking.isPrivate
                                      ? (getUserFullName(booking.user) ||
                                          getUserFullName(
                                            booking.recurringBooking?.user,
                                          )) + ", "
                                      : null}
                                    <FormatRelativeDate
                                      date={new Date(booking.startAt)}
                                    />
                                    {", "}
                                    <FormatDuration
                                      duration={intervalToDuration({
                                        start: new Date(booking.startAt),
                                        end: addMilliseconds(
                                          new Date(booking.endAt),
                                          1,
                                        ),
                                      })}
                                    />
                                  </ListGroupItemText>
                                </ListGroupItem>
                              )
                            })}
                        </div>
                      )
                    })}
                  </ListGroup>
                )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserTeamDetails.propTypes = {}
UserTeamDetails.defaultProps = {}
