import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { MembershipPlanDetails } from "./membership-plan-details"
import {
  WithUserRequired,
  WithAccountRequired,
  WithCompanyRequired,
} from "../account"

export function MembershipPlan() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithCompanyRequired>
            <Switch>
              <Route
                exact
                path={urls.membershipPlan.details}
                component={MembershipPlanDetails}
              />
            </Switch>
          </WithCompanyRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

MembershipPlan.propTypes = {}
MembershipPlan.defaultProps = {}
