import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { BillingUpdate } from "./billing-update"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { WithUserRequired, WithAccountRequired } from "../account"

export function Billing() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <Switch>
            <Route path={urls.billing} component={BillingUpdate} />
          </Switch>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Billing.propTypes = {}
Billing.defaultProps = {}
