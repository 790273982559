import { partition } from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { Card, CardBody, CardGroup, Col, Container, Row } from "reactstrap"
import { StringParam, useQueryParam } from "use-query-params"
import { toastError } from "../../components/error"
import { Base64JsonParam, assert } from "../../lib/helpers"
import { useApi } from "../../providers"
import { useBilling } from "../billing"
import { useMembership, useMembershipInvite } from "../membership"
import { OTPWForm } from "./OTPW-Form"
import { AccountContext } from "./account-provider"
import { useCompany } from "./company-provider"
import { LoginForm } from "./login-form"
import { RegistrationForm } from "./registration-form"
import { UserContext } from "./user-provider"

export function AuthForm() {
  const [invite, setInvite] = useQueryParam("invite", Base64JsonParam)
  const [email, setEmail] = useState("")
  const [token, setToken] = useQueryParam("token", StringParam)
  const api = useApi()
  const { getUser, setLoggingIn } = useContext(UserContext)
  const { getBilling } = useBilling()
  const { getAccountList, setSelectedAccount } = useContext(AccountContext)
  const { getCompany } = useCompany()
  const { reload: getMembershipInviteList } = useMembershipInvite()
  const { getMembershipList } = useMembership()

  useEffect(() => {
    if (token) {
      setToken("")
      api.setDefaultHeader("authorization", token)
      localStorage.setItem("savedToken", token)
      ;(async function () {
        try {
          setLoggingIn(true)

          const [, accountsData, invitesData] = await Promise.all([
            getUser(),
            getAccountList(),
            api.getMyInviteList(),
          ])

          if (accountsData?.count === 1 && invitesData?.count === 0) {
            const account = accountsData.accounts[0]
            await api.updateToken({ accountId: account.id })

            try {
              await Promise.all([
                getCompany(),
                getBilling(),
                getMembershipList(),
              ])
            } catch (err) {
              assert(partition(["response", "status"], err) === 404, err)
            }

            setSelectedAccount(account.id)
            getMembershipInviteList()
          }
        } catch (err) {
          toastError(err)

          if (!err.isAxiosError) {
            throw err
          }
        } finally {
          setLoggingIn(false)
        }
      })()
    }
  }, [
    token,
    api,
    setToken,
    setLoggingIn,
    getAccountList,
    setSelectedAccount,
    getCompany,
    getMembershipList,
    getUser,
    getBilling,
    getMembershipInviteList,
  ])

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          {email ? (
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <OTPWForm email={email} />
                </CardBody>
              </Card>
            </CardGroup>
          ) : (
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  {invite ? (
                    <RegistrationForm
                      invite={invite}
                      onSubmitSuccess={({ email }) => setEmail(email)}
                      onLoginClick={() => setInvite("")}
                    />
                  ) : (
                    <LoginForm
                      onSubmitSuccess={({ email }) => setEmail(email)}
                    />
                  )}
                </CardBody>
              </Card>
            </CardGroup>
          )}
        </Col>
      </Row>
    </Container>
  )
}

AuthForm.propTypes = {}
AuthForm.defaultProps = {}
