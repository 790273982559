import { PENDING, ACTIVE, CANCELING, CANCELED } from "../../constants"

export const getMembershipPlanStatusColor = (i) => {
  return {
    [PENDING]: "info",
    [ACTIVE]: "success",
    [CANCELING]: "warning",
    [CANCELED]: "danger",
  }[i.status]
}
