import React, { useContext } from "react"
import PropTypes from "prop-types"
import { useAsync } from "react-async"
import { Formik, Form, Field } from "formik"
import { toast } from "react-toastify"
import { ApiContext } from "../../providers/api-provider"
import { MembershipContext } from "../membership/membership-provider"
import { serviceTicketValidator } from "./service-ticket-validator"
import { CLIENT_ERROR } from "../../constants"
import { Input } from "../../components/input"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import {
  Col,
  Row,
  Container,
  Card,
  CardGroup,
  CardBody,
  FormGroup,
} from "reactstrap"
import { t, Trans } from "@lingui/macro"

async function getSupply({ supplyId, locationId, api }) {
  if (!supplyId) return null
  return api.getSupply({ supplyId, locationId })
}

async function getResource({ resourceId, membershipId, api }) {
  if (!resourceId) return null
  return api.getResource({ resourceId, membershipId })
}

export function ServiceTicketForm({ supplyId, resourceId, onSubmitSuccess }) {
  const api = useContext(ApiContext)
  const { selectedMembership } = useContext(MembershipContext)

  let supply, resource

  const {
    data: supplyData,
    isPending: isSupplyPending,
    error: isSupplyError,
  } = useAsync({
    promiseFn: getSupply,
    locationId: selectedMembership.locationId,
    supplyId,
    api,
  })

  if (!isSupplyPending && !isSupplyError) {
    supply = supplyData
  }

  const {
    data: resourceData,
    isPending: isResourcePending,
    error: isResourceError,
  } = useAsync({
    promiseFn: getResource,
    membershipId: selectedMembership.id,
    resourceId,
    api,
  })

  if (!isResourcePending && !isResourceError) {
    resource = resourceData
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Formik
                  initialValues={{
                    resourceId,
                    supplyId,
                    description: "",
                    imgUrl: "https://via.placeholder.com/150",
                  }}
                  validate={serviceTicketValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      const ticket = await api.createServiceTicket({
                        locationId: selectedMembership.locationId,
                        serviceTicketDto: values,
                      })

                      onSubmitSuccess && onSubmitSuccess(ticket)
                      toast.success(t`Saved`)
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors({})
                      }

                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({ isSubmitting, isValid, dirty, values }) => (
                    <Form>
                      <p>
                        <Trans>Service Ticket</Trans>
                      </p>

                      {supply && (
                        <p>
                          <i className={`fa ${supply.icon} ml-2 mr-2`} />
                          <strong>{supply.name}</strong>
                        </p>
                      )}

                      {resource && (
                        <p>
                          <strong>{resource.name}</strong>
                        </p>
                      )}

                      <Field
                        id="supplyId"
                        type="hidden"
                        name="supplyId"
                        value={values.supplyId}
                      />

                      <Field
                        id="resourceId"
                        type="hidden"
                        name="resourceId"
                        value={values.resourceId}
                      />

                      <Field
                        id="imgUrl"
                        type="hidden"
                        name="imgUrl"
                        value={values.imgUrl}
                      />

                      <Field
                        id="description"
                        type="textarea"
                        name="description"
                        component={Input}
                        placeholder={t`Please describe the issue here`}
                        rows="5"
                      />

                      <FormGroup className="form-actions">
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={!dirty || !isValid || isSubmitting}
                          color={
                            !dirty || !isValid || isSubmitting
                              ? "secondary"
                              : "primary"
                          }
                          block
                        >
                          <Trans>Submit</Trans>
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

ServiceTicketForm.propTypes = {
  supplyId: PropTypes.number,
  resourceId: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
}

ServiceTicketForm.defaultProps = {
  supplyId: "",
  resourceId: "",
}
