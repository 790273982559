import React from "react"
import ReactDOM from "react-dom"
import "./styles/index.scss"
import "react-toastify/dist/ReactToastify.css"
import "./config"
import "./lib/sentry"
import { Root } from "./root"
import * as serviceWorker from "./serviceWorker"

async function main() {
  ReactDOM.render(<Root />, document.getElementById("root"))
}

main()

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
