import React from "react"
import PropTypes from "prop-types"
import { t } from "@lingui/macro"
import Clipboard from "react-clipboard.js"
import { toast } from "react-toastify"

export function ClipboardIconButton({
  id,
  className,
  disabled,
  style,
  clipboardText,
  componentType,
  href,
}) {
  return (
    <Clipboard
      id={id}
      className={className}
      disabled={disabled}
      style={style}
      component={componentType}
      button-href={href}
      data-clipboard-text={clipboardText}
      onSuccess={() => toast.success(t`Copied to clipboard`)}
    >
      {" "}
      <i className="far fa-copy" />
    </Clipboard>
  )
}

ClipboardIconButton.defaultProps = {
  className: "",
  disabled: false,
  color: "link",
  componentType: "a",
  href: "#",
}

ClipboardIconButton.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  style: PropTypes.string,
  componentType: PropTypes.string,
  href: PropTypes.string,
  clipboardText: PropTypes.string,
}
