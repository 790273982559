/*eslint-disable*/ module.exports = {
  messages: {
    "<0>Welcome back to <1>Member Portal</1></0>":
      "<0>Anmeldung als <1>Mitarbeiter</1></0>",
    Accept: "Akzeptieren",
    Account: "Account",
    "Accounts / Invites": "Accounts / Einladungen",
    "Active Teammates": "Aktive Teammitglieder",
    "Add SEPA Direct Debit to pay automatically":
      "SEPA-Lastschrift hinzufügen, um automatisiert zu bezahlen",
    "Add to Google calendar": "Zum Google-Kalender hinzufügen",
    "Add to Outlook calendar": "Zum Outlook-Kalender hinzufügen",
    "All recurring booking instances": "Alle wiederkehrenden Buchungen",
    "All upcoming": "Zukünftig",
    Archived: "Archiviert",
    "Are you sure you want to delete the booking":
      "Sind Sie sicher, dass Sie die Buchung stornieren möchten",
    "Are you sure you want to make another user a new owner if this account? If confirmed, changes will apply instantly.":
      "Sind Sie sicher, dass Sie einen anderen Benutzer zum neuen Besitzer dieses Accounts machen wollen? Wenn Sie dies bestätigen, werden die Änderungen sofort übernommen.",
    "Ask your Operator to check the Location settings to be able to checkin and checkout.":
      "Bitten Sie Ihren Operator, die Standorteinstellungen zu überprüfen, damit Sie ein- und auschecken können.",
    Attendees: "Teilnehmer",
    Billing: "Rechnungsstellung",
    "Billing Information": "Informationen zur Rechnungsstellung",
    "Book Now": "Jetzt buchen",
    "Book now": "Jetzt buchen",
    "Book now and Check-in": "Jetzt buchen und einchecken",
    "Bookable by": "Buchbar von",
    "Bookable resources with occupancy state":
      "Buchbare Ressourcen mit Belegungsstatus",
    "Booked Resource": "Gebuchte Ressource",
    Booking: "Buchung",
    "Booking made for": "Buchung für",
    "Booking summary": "Zusammenfassung der Buchung",
    Bookings: "Buchungen",
    Busy: "Gebucht",
    "COVID Certificate": "COVID-Zertifikat",
    "COVID-19 Certificates & Tests": "COVID-19 Bescheinigungen und Tests",
    Calendar: "Kalender",
    Cancel: "Abbrechen",
    Cancelation: "Stornierung",
    "Cannot calculate the distance to the location!":
      "Die Entfernung zum Standort kann nicht berechnet werden!",
    "Change Owner": "Owner wechseln",
    "Check your email Inbox": "Prüfen Sie Ihren E-Mail-Posteingang",
    "Check-in": "Einchecken",
    "Checked-in": "Eingecheckt",
    Checkins: "Checkins",
    Company: "Firma",
    "Company Business Name": "Unternehmensname",
    Confirm: "Bestätigen",
    "Confirm personal liability":
      "Bestätigung Sie bitte Ihre persönliche Haftung",
    "Copy title": "Titel kopieren",
    Cost: "Cost",
    "Create New": "Neu erstellen",
    Created: "Erstellt",
    "Created At": "Erstellt am",
    "Current location": "Aktueller Standort",
    "Danger Zone": "Achtung: Änderungen können nicht rückgängig gemacht werden",
    "Date:": "Date:",
    Decline: "Ablehnen",
    "Delete Account": "Account löschen",
    "Delete Booking": "Buchung löschen",
    Description: "Beschreibung",
    Documents: "Dokumente",
    "Download ICalendar (ics) file": "ICalendar (ics)-Datei herunterladen",
    "Download Invoice": "Rechnung herunterladen",
    Duration: "Dauer",
    "Edit Booking": "Buchung bearbeiten",
    "Edit Recurring Booking": "Wiederkehrende Buchung bearbeiten",
    "Edit title": "Titel bearbeiten",
    Email: "E-Mail",
    End: "Ende",
    "End at:": "End at:",
    "End date": "Enddatum",
    "Enter teammate details": "Details zu Teamkollegen eingeben",
    "Enter your One Time Password": "Warten Sie auf ihren Verifizierungscode",
    "Enter your details to register.":
      "Geben Sie Ihre Daten ein, um sich zu registrieren.",
    "Enter your email to get your login code.":
      "Geben Sie Ihre E-Mail-Adresse ein, um Ihr Passwort zu erhalten.",
    "Error loading bookings. Try to use <0><1>booking search</1></0> instead.":
      "Fehler beim Laden von Buchungen. Versuchen Sie stattdessen <0><1>Buchungssuche</1></0> zu verwenden.",
    Filter: "Filter",
    Floorplans: "Grundriss",
    From: "Von",
    "Full name": "Vollständiger Name",
    "Full-day": "Ganztägig",
    "Getting your current location": "Ermittlung des aktuellen Standorts",
    "Go Back": "Zurück",
    "Here to booking": "Hier zur Buchung",
    "I agree with the <0>Terms & conditions</0> and <1>Privacy policy</1>.":
      "Ich bin mit den <0>AGB</0> und <1>Datenschutz</1> einverstanden.",
    "I agree with the <0>terms and conditions.</0>":
      "Ich bin mit den AGB von <0> einverstanden.</0>",
    "I confirm personal responsibility for the information provided above and I will bear any legal consequences in case of providing false information in this form.":
      "Ich bestätige, dass die gemachten Angaben richtig sind und ich die rechtlichen Konsequenzen von falschen Angaben tragen werde.",
    "If you do not see the resource in the list, then maybe you do not have permissions to book it. Ask your manager to add you permissions.":
      "Wenn Sie eine Ressource nicht in der Liste sehen, dann haben Sie vielleicht keine Berechtigung, diese zu buchen. Bitten fragen Sie die Berechtigung bei Ihrem Operator an.",
    "If you need more accounts, please contact support.":
      "Wenn Sie mehr Accounts benötigen, wenden Sie sich bitte an den Support.",
    "If you want to delete the account, make sure there are no other Users using it, no Resources, no active Memberships atc.":
      "Wenn Sie den Account löschen möchten, stellen Sie bitte sicher, dass es keine anderen Benutzer, keine Ressourcen, keine aktiven Pläne etc. gibt.",
    "If you wanted to use Operators Portal instead, please log-out and login into the Operators Portal application.":
      "Wenn Sie stattdessen das Operator Portal nutzen möchten, melden Sie sich hier bitte ab und unter my.intertempi.com an.",
    Invite: "Einladung",
    "Invite new teammate": "Neuen Teamkollegen einladen",
    "Invite teammate": "Teamkollegen einladen",
    "Invited by": "Eingeladen von",
    Invites: "Einladungen",
    Invoice: "Rechnung",
    "It will be shown on your booking invoice.":
      "Sie wird auf Ihrer Buchungsrechnung ausgewiesen.",
    Language: "Sprache",
    "Last activity": "Letzte Aktivität",
    "Last activity at": "Letzte Aktivität",
    "Last edit": "Letzte Bearbeitung",
    "Last occurence": "Letztes Ereignis",
    "Latest Invoices": "Letzte Rechnungen",
    "Leave Account": "Account verlassen",
    "Leave account": "Account verlassen",
    Loading: "Lädt",
    "Location privacy mode is turned ON":
      "Der Privatsphäre-Modus ist für diesen Standort aktiviert",
    "Location time": "Uhrzeit am Standort",
    Login: "Anmeldung",
    "Login to <0>Operator Portal</0>": "Anmeldung als <0>Administrator</0>",
    Logout: "Abmelden",
    "Make private": "Privatmodus (anonymisiert)",
    Membership: "Mitgliedschaft",
    "Membership Plan": "Plan im Rahmen der Mitgliedschaft",
    "My Bookings": "Meine Buchungen",
    "My Company": "Mein Unternehmen",
    "My Documents": "Meine Dokumente",
    "My Profile": "Mein Profil",
    "My Team": "Mein Team",
    "No data!": "Keine Daten!",
    "No description": "Keine Beschreibung",
    "No documents": "Keine Dokumente",
    "No files attached": "Keine Dateien angehängt",
    "No organizations. Create a new one to get started.":
      "Keine Organisation vorhanden. Erstellen Sie eine neue, um loszulegen.",
    "No resources to show": "Keine Ressourcen vorhanden",
    "No resources to show. Please create your first resource to see the calendar view.":
      "Keine Ressourcen vorhanden. Bitte erstellen Sie Ihre erste Ressource, um die Kalenderansicht zu sehen.",
    Notes: "Anmerkungen",
    "Nothing yet": "Noch nichts vorhanden",
    Ok: "Ok",
    "Only this one": "Nur dieses Ereignis",
    "Open in tab": "Im Tab öffnen",
    Organizations: "Organisationen",
    "Our crew will be notified about this.":
      "Unsere Mitarbeiter werden darüber informiert.",
    "Overview of your resources occupation.": "Belegung der Ressourcen.",
    "Personal details": "Angaben zur Person",
    Plans: "Pläne",
    "Please create a name for your company or personal account.":
      "Bitte legen Sie einen Namen für den Account fest (z.B. Name des Teams, der Organisationseinheit, des Unternehmens oder der Person).",
    "Please fill out the following fields for your profile.":
      "Bitte füllen Sie die folgenden Informationen für Ihr Profil aus.",
    "Please pay attention that your timezone differs from the location timezone.":
      "Bitte beachten Sie, dass sich Ihre Zeitzone von der Zeitzone des Standorts unterscheidet.",
    "Preview Invoice": "Vorschau der Rechnung",
    Price: "Preis",
    "Privacy settings do not allow you to see these details.":
      "Die Privatsphäre-Einstellungen erlauben es Ihnen nicht, diese Details zu sehen.",
    Private: "Privat (anonymisiert)",
    "Private booking": "Private Buchung (anonymisiert)",
    Recurring: "Wiederkehrend",
    "Recurring Booking Details": "Details zu wiederkehrenden Buchung",
    Register: "Registrieren",
    Remove: "Entfernen",
    Repeat: "Buchungsserie (wiederholend)",
    Repeats: "Buchungsserie",
    Resource: "Ressource",
    "Resource Name": "Ressourcenname",
    "Resource is free": "Die Ressource ist nicht belegt und buchbar",
    "Resource occupancy overview": "Übersicht über die Ressourcenbelegung",
    Resources: "Ressourcen",
    Role: "Rolle",
    Save: "Speichern Sie",
    "Save changes": "Änderungen speichern",
    "Select a Resource from the list to see its availability / occupancy on the calendar.":
      "Ressource auswählen, um die Verfügbarkeit/Belegung im Kalender anzuzeigen.",
    "Select an organization to start.":
      "Wählen Sie eine Organisation aus, um zu starten.",
    "Select language": "Sprache auswählen",
    "Select location": "Standort auswählen",
    "Select teammate": "Teamkollege auswählen",
    Service: "Service",
    "Service Ticket": "Service Ticket",
    Settings: "Einstellungen",
    "Show all bookings": "Alle Buchungen anzeigen",
    "Something went wrong": "Etwas ist schief gelaufen",
    Start: "Start",
    "Start at:": "Start at:",
    "Start date": "Startdatum",
    "Start inviting teammates.": "Laden Sie Teamkollegen ein.",
    Status: "Status",
    Submit: "Bestätigen",
    "Summarize recurring bookings": "Buchungsserie zusammenfassen",
    "Switch account": "Switch account",
    "Team Bookings": "Team Buchungen",
    "Team Profile": "Profil des Teams",
    "Teammate Invites": "Teamkollegen-Einladungen",
    Teammates: "Teamkollegen",
    "Terms & Conditions": "AGB",
    "Thank you for submitting a request!":
      "Vielen Dank, dass Sie eine Anfrage eingereicht haben!",
    "There are no Floors (and Floorplans) assigned to this location. Ask a staff member about adding a floorplan for booking.":
      "Diesem Standort wurde noch kein Grundriss zugeordnet. Wenden Sie sich ggf. bitte an den Operator.",
    "There are no active bookings to show":
      "Es gibt aktuell keine Buchungen für diese Ansicht",
    "There is no floorplan uploaded!": "Es wurde kein Grundriss hochgeladen!",
    "There's no available activity information yet.":
      "Es sind noch keine Informationen über Aktivitäten verfügbar.",
    "This title is private to your account/company.":
      "Dieser Titel ist nur für Sie bzw. Ihren Account / Ihre Firma sichtbar.",
    "Time buffer": "Zeitpuffer",
    Timeline: "Zeitleiste",
    Title: "Titel",
    "To provide the checkin we need to know your current location":
      "Für das Einchecken müssen wir Ihren aktuellen Standort kennen",
    Today: "Heute",
    Type: "Typ",
    Until: "Bis",
    "Upcoming Bookings": "Zukünftige Buchungen",
    Upload: "Hochladen",
    "Upload a Test result or Vaccine Certificate":
      "Hochladen eines Testergebnisses oder einer Impfbescheinigung",
    "Upload a new file": "Eine neue Datei hochladen",
    "Use the fields below to update Billing.":
      "Verwenden Sie die Felder, um die Rechnungsdaten zu aktualisieren.",
    "User Activity": "Benutzeraktivität",
    "VAT Number": "Umsatzsteuer-ID",
    "Will repeat": "Will repeat",
    "Workshop, Digital Team Meeting": "Workshop, digitales Teamtreffen",
    "You are too far from the location!":
      "Sie sind zu weit vom Standort entfernt!",
    "You do not have permissions to book on a given resources. Please contact your Administrator.":
      "Sie haben keine Berechtigung, diese Ressource zu buchen. Bitte kontaktieren Sie Ihren Operator.",
    "You don't have teammates yet.": "Sie haben noch keine Teamkollegen.",
    "You have logged-in as a User but do not have registered account for this Portal. To continue using this Portal you have to ask an Operator to invite you.":
      "Sie haben sich als Benutzer angemeldet, aber haben noch keinen Account für das Member Portal. Bitte kontaktieren Sie Ihren Operator, um eine Einladung zu erhalten.",
    "You have opened a Booking Form page without required information (see details below). Please contact your Administrator to report an issue.":
      "Sie haben eine Buchungsseite ohne die erforderlichen Informationen geöffnet (siehe Details unten). Bitte kontaktieren Sie Ihren Operator, um das Problem zu melden.",
    "You should ask your Landlord to create a bookable resource":
      "Sie sollten Ihren Operator bitten, eine buchbare Ressource anzulegen",
    "You were invited": "Sie wurden eingeladen",
    'You were invited to "{0}". Invite will be accepted with your currently selected account: {1}. Do you want to accept or decline?':
      [
        'Sie wurden zu "',
        ["0"],
        '" eingeladen. Die Einladung wird mit Ihrem aktuell ausgewählten Account angenommen: ',
        ["1"],
        ". Möchten Sie annehmen oder ablehnen?",
      ],
    "You will book": "Buchung von",
    "Your selected membership is not active. To continue using the Members Portal you have to select an active membership.":
      "Die von Ihnen gewählte Mitgliedschaft ist nicht aktiv. Um das Member Portal weiterhin nutzen zu können, müssen Sie eine aktive Mitgliedschaft auswählen.",
    "Your timezone differs from the location timezone":
      "Ihre Zeitzone weicht von der Zeitzone des Standorts ab",
    "another user": "anderer Benutzer",
    "checked-in": "eingecheckt",
    days: "Tage",
    distance: "Entfernung",
    edit: "bearbeiten",
    loading: "lädt",
    never: "niemals",
    optional: "optional",
    outdated: "veraltet",
    "per booking": "pro Buchung",
    test: "Test",
    valid: "gültig",
    "valid 1-day": "gültig 1 Tag",
    you: "Sie",
    "{0}": [["0"]],
    "{0} is checked-in here": [["0"], " ist hier eingecheckt"],
    About: "Über",
    "Action is temporarily unavailable.":
      "Die Aktion ist vorübergehend nicht verfügbar.",
    "Are you sure you want to delete this Account? The account will be deleted if only there are no other users, no resources and no bookings registered on it.":
      "Sind Sie sicher, dass Sie diesen Account löschen möchten? Der Account wird nur dann gelöscht, wenn keine anderen Benutzer, keine Ressourcen und keine Buchungen vorhanden sind.",
    "Are you sure you want to leave this account?":
      "Sind Sie sicher, dass Sie dieses Konto verlassen wollen?",
    "Are you sure you want to remove the user {0} {1} {2} from account {3} {4}?":
      [
        "Sind Sie sicher, dass Sie den Benutzer ",
        ["0"],
        " ",
        ["1"],
        " ",
        ["2"],
        " aus dem Account ",
        ["3"],
        " ",
        ["4"],
        " löschen wollen?",
      ],
    Book: "Buchen",
    "Bookable Resource": "Buchbare Ressource",
    "COVID-19 certificate required": "COVID-19-Bescheinigung erforderlich",
    "Can't edit booking with finalized invoice.":
      "Buchung mit abgeschlossener Rechnung kann nicht bearbeitet werden.",
    "Can't edit started booking.":
      "Buchungen, die bereits gestartet sind, können nicht bearbeitet werden.",
    "Cannot set this starting time":
      "Diese Startzeit kann nicht gewählt werden",
    City: "Stadt",
    "Confirm Changing Account Owner":
      "Bestätigen Sie den Wechsel des Account-Owner",
    "Confirm Delete Booking": "Buchungsstornierung bestätigen",
    "Confirm Leaving This Account":
      "Bestätigen Sie bitte das Verlassen dieses Accounts",
    "Confirm Re-send Invite": "Bestätigen,, um die Einladung erneut zu senden",
    "Confirm Remove User From Account":
      "Bestätigen, um den Benutzer aus dem Account zu entfernen",
    "Consider using another web browser for reliability of the app features":
      "Bitte benutzen Sie einen anderen Browser, um die Zuverlässigkeit der Funktionen zu gewährleisten",
    "Contact person name, info": "Name der Kontaktperson, Info",
    "Copied to clipboard": "In die Zwischenablage kopiert",
    Country: "Land",
    "Country should have a valid country code":
      "Das Land sollte einen gültigen Ländercode haben",
    "Create New Organization-Account": "Neuen Account anlegen",
    Day: "Tag",
    "Description should be at most {0} characters long": [
      "Die Beschreibung darf höchstens ",
      ["0"],
      " Zeichen lang sein",
    ],
    "Do you want to re-send invitation Email to {0} {1} on {2}? Please wait for at least 10 minutes before re-sending a new Email.":
      [
        "Möchten Sie die Einladungs-E-Mail an ",
        ["0"],
        " ",
        ["1"],
        " für ",
        ["2"],
        " erneut senden? Bitte warten Sie mindestens 10 Minuten, bevor Sie eine neue E-Mail senden.",
      ],
    "Document title": "Titel des Dokuments",
    Done: "Erledigt",
    "Done!": "Erledigt!",
    "Duration Unit": "Zeiteinheit",
    "Email is required": "E-Mail ist erforderlich",
    "Ending at": "Ende",
    English: "Englisch",
    "File (scan) of the certificate or test as PDF or an image":
      "Datei (Scan) des Zertifikats oder des Tests als PDF oder als Bild",
    'File format "{0}" not supported': [
      'Dateiformat "',
      ["0"],
      '" nicht unterstützt',
    ],
    "File reading has failed": "Das Lesen der Datei ist fehlgeschlagen",
    "File reading was aborted": "Das Lesen der Datei wurde abgebrochen",
    "Filter by resource": "Nach Ressource filtern",
    "Filter by status": "Nach Status filtern",
    "Filter by tags": "Nach Gruppierung filtern",
    Finished: "Fertig",
    "First Name": "Vorname",
    "German (Deutsch)": "Deutsch",
    "Half Hour": "Halbe Stunde",
    House: "Haus",
    "Houston, we have a problem!": "Houston, wir haben ein Problem!",
    "I agree with the <0>Terms & conditions</0> and <1>Privacy policy.</1>":
      "Ich bin mit <0>AGB</0> und <1>Datenschutz</1> einverstanden.",
    "If you have no invites, double-check your email inbox and SPAM folder for the invitation. If you did not receive an invite email, then contact your Location Operator.":
      "Wenn Sie keine Einladungen erhalten haben, überprüfen Sie bitte Ihren E-Mail-Posteingang und Ihren SPAM-Ordner. Ansonsten wenden Sie sich bitte an Ihren Operator.",
    "Invalid data. Please check your input.":
      "Ungültige Daten. Bitte überprüfen Sie Ihre Eingabe.",
    "Invite accepted": "Einladung angenommen",
    "Invite declined": "Einladung abgelehnt",
    "Invite sent!": "Einladung gesendet!",
    "It will be shown on your booking invoice. ex":
      "Dies wird auf Ihrer Rechnung ausgewiesen.",
    "Last Name": "Nachname",
    "Logo image in SVG format": "Logo-Bilddatei im SVG-Format",
    Month: "Monat",
    "Must be valid URL format": "Muss ein gültiges URL-Format sein",
    Name: "Name",
    "Network error!": "Netzwerkfehler!",
    "One Time Password": "Einmaliges Passwort",
    "One time password sent! Please check your inbox.":
      "Einmaliges Passwort gesendet! Bitte prüfen Sie Ihren Posteingang.",
    "Oops! Something went wrong.": "Oops! Etwas ist schiefgelaufen.",
    "Oops! You're lost.": "Oops! Hier bist Du falsch.",
    Pattern: "Muster",
    Phone: "Telefon",
    "Please check your internet connection.":
      "Bitte überprüfen Sie Ihre Internetverbindung.",
    "Please describe the issue here": "Bitte beschreiben Sie das Problem",
    "Request is sent": "Anfrage wird gesendet",
    Running: "Laufend",
    Saved: "Gespeichert",
    "Search by text": "Nach Text suchen",
    "Select a Day": "Tag auswählen",
    "Sorry, you must agree to our privacy policy before you can login.":
      "Sie müssen unserer Datenschutzerklärung zustimmen, bevor Sie sich anmelden können.",
    "Sorry, you must agree to our privacy policy before you can register.":
      "Sie müssen unserer Datenschutzerklärung zustimmen, bevor Sie sich registrieren können.",
    "Starting at": "Anfang",
    Street: "Straße",
    Success: "Erfolg",
    System: "System",
    "The page you are looking for is temporarily unavailable.":
      "Die von Ihnen gesuchte Seite ist vorübergehend nicht verfügbar.",
    "The page you are looking for was not found.":
      "Die von Ihnen gesuchte Seite wurde nicht gefunden.",
    "There are no Resources to book":
      "Es gibt keine Ressourcen für eine Buchung",
    "This time is reserved for maintenance.":
      "Diese Zeit ist für Wartungsarbeiten reserviert.",
    Time: "Zeit",
    "Title should be at most {0} characters long": [
      "Der Titel darf höchstens ",
      ["0"],
      " Zeichen lang sein",
    ],
    "Type of document": "Art des Dokuments",
    Upcoming: "Kommende",
    'Updated "{0}"': ['Aktualisiert "', ["0"], '"'],
    "Upload a file": "Eine Datei hochladen",
    "Valid until date": "Gültig bis Datum",
    "Value is required": "Ein Wert ist erforderlich",
    "Value should be a number": "Der Wert sollte eine Zahl sein",
    "Value should be at most {0} characters long": [
      "Der Wert sollte höchstens ",
      ["0"],
      " Zeichen lang sein",
    ],
    "We use cookies and similar technologies to recognize your repeat visits and preferences, as well as to measure the effectiveness of our user experience and analyze user behaviour. To learn more about cookies, including how to disable them, view our cookie policy. By clicking accept you consent to the use of cookies. Without accepting these cookies you may not be able to utilize our platform properly.":
      'Wir verwenden Cookies und weitere Technologien, um Ihnen eine bestmögliche Nutzung unserer Plattform zu ermöglichen. Dafür analysieren wir Nutzerverhalten und messen Ihre Aktivitäten und Präferenzen. Um mehr über Cookies zu erfahren, einschließlich der Möglichkeit, sie zu deaktivieren, lesen Sie unsere Cookie-Richtlinie. Wenn Sie auf "Akzeptieren" klicken, erklären Sie sich mit der Verwendung von Cookies einverstanden. Wenn Sie diese Cookies nicht akzeptieren, können Sie unsere Plattform möglicherweise nicht richtig nutzen.',
    "Wrong code": "Falsches Passwort",
    "You are not authenticated to perform this action.":
      "Sie sind nicht authentifiziert, um diese Aktion durchzuführen.",
    "You are not authorized to perform this action or resource was not found.":
      "Sie sind nicht berechtigt, diese Aktion durchzuführen oder die Ressource wurde nicht gefunden.",
    "You have to upload a COVID-19 Test or Certificate to be able to book. Open 'Covid-19' page in the Profile Menu.":
      'Sie müssen einen negativen COVID-19 Test oder ein Impfzertifikat hochladen, um eine Buchung machen zu können. Öffnen Sie dafür bitte die Seite "COVID-19" in Ihrem persönlichen Menü (oben rechts).',
    "You have to upload a COVID-19 Test or Certificate to be able to check-in. Open 'Covid-19' page in the Profile Menu.":
      'Sie müssen einen negativen COVID-19 Test oder ein Impfzertifikat hochladen, um einchecken zu können. Öffnen Sie dafür bitte die Seite "COVID-19" in Ihrem persönlichen Menü (oben rechts).',
    "You must agree to our terms and conditions.":
      "Bitte stimmen Sie unseren AGB zu.",
    'You were invited to "{0}". Do you want to accept or decline?': [
      'Sie wurden eingeladen zu "',
      ["0"],
      '". Möchten Sie diese Einladung annehmen?',
    ],
    "You've successfully left the account.":
      "Sie haben denn Account erfolgreich verlassen.",
    Zip: "Zip",
    all: "alle",
    "and will repeat {0}": ["und wird ", ["0"], " wiederholen"],
    "did not start": "hat nicht begonnen",
    ended: "beendet",
    for: "für",
    "full day": "ganzer Tag",
    hours: "Stunden",
    "is running now": "läuft aktuell",
    minutes: "Minuten",
    "optional settings": "optionale Einstellungen",
  },
}
