import { defineMessage } from "@lingui/macro"

export const HALF_HOUR = "HALF_HOUR"
export const DAY = "DAY"
export const MONTH = "MONTH"

export const bookingDurationUnits = [HALF_HOUR, DAY, MONTH]

export const BOOKING_DURATION_UNITS_OPTIONS = [
  {
    value: HALF_HOUR,
    label: defineMessage({ message: "Half Hour" }),
  },
  {
    value: DAY,
    label: defineMessage({ message: "Day" }),
  },
  {
    value: MONTH,
    label: defineMessage({ message: "Month" }),
  },
]
