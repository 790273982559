import React from "react"
import PropTypes from "prop-types"
import { Container, Row, Col } from "reactstrap"
import { Trans } from "@lingui/macro"

export default function BookingNotPossible({ text }) {
  return (
    <Container className="py-4 h-100">
      <Row className="h-100">
        <Col sm="12" md={{ size: 6, offset: 3 }} className="align-self-center">
          <p className="lead">
            <i className="far fa-lightbulb" />{" "}
            <Trans>
              You have opened a Booking Form page without required information
              (see details below). Please contact your Administrator to report
              an issue.
            </Trans>
          </p>
          {text && (
            <>
              <hr className="my-2" />
              {text}
            </>
          )}
          <hr className="my-2" />
          <p>
            <Trans>
              If you wanted to use Operators Portal instead, please log-out and
              login into the Operators Portal application.
            </Trans>
          </p>
        </Col>
      </Row>
    </Container>
  )
}

BookingNotPossible.propTypes = {
  text: PropTypes.string,
}
BookingNotPossible.defaultProps = {}
