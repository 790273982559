import { Trans } from "@lingui/macro"
import React from "react"
import { Container, Row, Col } from "reactstrap"

export default function ServiceTicketSuccess() {
  return (
    <Container className="py-4 h-100">
      <Row className="h-100 ">
        <Col xs="12" md={{ size: 6, offset: 3 }} className="align-self-center">
          <p className="lead text-center">
            <i
              className="far fa-check-circle text-muted"
              style={{ fontSize: "5em" }}
            />
            <br />
            <Trans>Thank you for submitting a request!</Trans>
            <br />
            <Trans>Our crew will be notified about this.</Trans>
          </p>
        </Col>
      </Row>
    </Container>
  )
}
