import { i18n } from "@lingui/core"
import { BOOKING_DURATION_UNITS_OPTIONS, HALF_HOUR, DAY } from "./constants"

export function formatResource(resource) {
  resource = {
    ...resource,
    img: resource.img || "",
  }

  return resource
}

export function resourceTypeHasHalfHour(resourceType) {
  return resourceType.bookingDurationUnits.includes(HALF_HOUR)
}

export function resourceTypeHasDay(resourceType) {
  return resourceType.bookingDurationUnits.includes(DAY)
}

export function getBookingDurationUnitName(bookingDurationUnit) {
  return i18n._(
    BOOKING_DURATION_UNITS_OPTIONS.find(
      (unit) => unit.value === bookingDurationUnit,
    ).label,
  )
}

export function selectOptionsToTags(tag) {
  return {
    id: tag.id || parseInt(tag.value),
    name: tag.name || tag.label,
    backgroundColor: tag.backgroundColor,
    textColor: tag.textColor,
  }
}

export function tagsToSelectOptions(tag) {
  return { value: tag.id, label: tag.name, ...tag }
}
