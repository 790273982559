import { Trans } from "@lingui/macro"
import { getUnixTime } from "date-fns"
import { reverse } from "named-urls"
import qs from "qs"
import { intersection } from "ramda"
import React, { useContext, useState } from "react"
import { useAsync } from "react-async"
import { Link } from "react-router-dom"
import Select from "react-select"
import {
  Badge,
  CardImg,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Row,
} from "reactstrap"
import { Spinner } from "../../components/spinner"
import { priceFromCents, renderPrice } from "../../lib/helpers"
import { ApiContext } from "../../providers/api-provider"
import { urls } from "../../urls"
import { useMembership } from "../membership/membership-provider"
import {
  getBookingDurationUnitName,
  selectOptionsToTags,
  tagsToSelectOptions,
} from "./helpers"

export function ResourceList() {
  const api = useContext(ApiContext)
  let [filterText, setFilterText] = useState("")
  const [filterTags, setFilterTags] = useState([])
  const { selectedMembership } = useMembership()
  filterText = filterText.toLowerCase()

  const filterTagIds = filterTags.map((tag) => tag.id)

  const {
    data: tagsData,
    isPending: tagsIsPending,
    error: tagsError,
  } = useAsync({
    promiseFn: api.getResourceTagsList,
    membershipId: selectedMembership?.id,
  })

  const {
    data: resourceListData,
    error: resourceListError,
    isPending: isResourceListPending,
  } = useAsync({
    promiseFn: api.getResourceList,
    membershipId: selectedMembership?.id,
    eager: "[resourceType,prices,resourceTags]",
  })

  function filterThroughDurationTypes(resource) {
    return resource.resourceType.bookingDurationUnits.some((du) =>
      du.toLowerCase().includes(filterText),
    )
  }

  function filterThroughTags(resource) {
    return resource.resourceTags
      .map((rt) => rt.name)
      .join(" ")
      .toLowerCase()
      .includes(filterText)
  }

  function textFilter(resource) {
    return (
      resource.name.toLowerCase().includes(filterText) ||
      resource.description.toLowerCase().includes(filterText) ||
      resource.resourceType.name.toLowerCase().includes(filterText) ||
      filterThroughTags(resource) ||
      filterThroughDurationTypes(resource)
    )
  }

  function tagsFilter(resource) {
    return filterTagIds.length > 0
      ? intersection(
          resource.resourceTags.map((rt) => rt.id),
          filterTagIds,
        ).length === filterTagIds.length
      : true
  }

  const error = resourceListError || tagsError
  if (error) {
    throw error
  }

  if (isResourceListPending || tagsIsPending) {
    return <Spinner />
  }

  if (resourceListData.count < 1) {
    return (
      <Container className="py-4 h-100">
        <Row className="h-100">
          <Col
            sm="12"
            md={{ size: 6, offset: 3 }}
            className="align-self-center"
          >
            <p className="lead">
              <i className="far fa-lightbulb" />
              &nbsp;
              <Trans>
                Resources are bookable space or objects specific to your
                location.
              </Trans>
            </p>
            <hr className="my-2" />
            <p>
              <Trans>Some examples of Resources are</Trans>:{" "}
              <b>
                <Trans>Office</Trans> 01
              </b>
              ,
              <b>
                <Trans>Meeting Room</Trans> 01
              </b>
              , <b>Flex Desk 01</b> etc.
            </p>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container className="py-4">
      <Row className="mb-3">
        <Col xs="12" md="4" className="mb-3">
          <Input
            id="search-filter-text"
            type="text"
            placeholder="Search by text"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
        </Col>
        <Col xs="12" md="4" className="mb-3">
          <Select
            id="search-filter-tags"
            className="multi-select-input"
            onChange={(options) =>
              setFilterTags(options?.map(selectOptionsToTags) || [])
            }
            isClearable
            isMulti
            isLoading={tagsIsPending}
            disabled={tagsError}
            options={tagsData?.resourceTags?.map(tagsToSelectOptions) || []}
            value={filterTags.map(tagsToSelectOptions) || []}
            placeholder="Filter"
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <ListGroup>
            {resourceListData.resources
              .filter(textFilter)
              .filter(tagsFilter)
              .map((resource) => {
                return (
                  <ListGroupItem
                    className="mt-2"
                    key={resource.id}
                    resource={resource}
                  >
                    <Row>
                      <Col xs="4">
                        <CardImg
                          src={
                            resource.img || "https://via.placeholder.com/300"
                          }
                          className="img-fluid"
                        />

                        <Link
                          to={
                            reverse(urls.booking.create) +
                            qs.stringify(
                              {
                                rid: resource.id,
                                start: getUnixTime(new Date()),
                              },
                              { addQueryPrefix: true },
                            )
                          }
                        >
                          <Trans>Book now</Trans>
                        </Link>
                      </Col>

                      <Col xs="8">
                        <ListGroupItemHeading
                          className="font-xl mb-2"
                          title="true"
                        >
                          <ListGroupItemText>
                            <Link
                              to={reverse(urls.resource.details, {
                                resourceId: resource.id,
                              })}
                            >
                              {resource.name}
                            </Link>
                          </ListGroupItemText>
                        </ListGroupItemHeading>

                        {resource.description.split("\n").map((d, idx) => (
                          <ListGroupItemText key={idx}>{d}</ListGroupItemText>
                        ))}

                        {resource.resourceTags.length > 0 && (
                          <ListGroupItemText>
                            {resource.resourceTags.map((tag) => (
                              <Badge
                                key={tag.id}
                                style={{
                                  backgroundColor: tag.backgroundColor,
                                  color: tag.textColor,
                                }}
                                className="m-1"
                              >
                                {tag.name}
                              </Badge>
                            ))}
                          </ListGroupItemText>
                        )}

                        <div className="font-sm mb-0">
                          <span className="text-muted">
                            <Trans>Bookable by:</Trans>
                          </span>
                          <ul>
                            {resource.resourceType.bookingDurationUnits.map(
                              (u) => (
                                <li key={u}>
                                  {getBookingDurationUnitName(u)}
                                  {selectedMembership.location
                                    .billingEnabled ? (
                                    <>
                                      -
                                      {renderPrice(
                                        priceFromCents(
                                          resource.prices.find(
                                            (p) => p.bookingDurationUnit === u,
                                          ),
                                        ),
                                      )}
                                    </>
                                  ) : null}
                                </li>
                              ),
                            )}
                          </ul>
                        </div>
                      </Col>
                    </Row>
                  </ListGroupItem>
                )
              })}
          </ListGroup>
        </Col>
      </Row>
    </Container>
  )
}
