import React, { useContext, useCallback, useEffect, useReducer } from "react"
import PropTypes from "prop-types"
import { ApiContext } from "../../providers"
import { path } from "ramda"
import { getActionType } from "../../lib/helpers"
import { AccountList } from "./account-list"
import {
  GET,
  LIST,
  ACCOUNT,
  SUCCESS,
  FAIL,
  START,
  RESET,
  CREATE,
  SELECT,
} from "../../constants"

const defaultState = {
  selectedAccountId:
    parseInt(localStorage.getItem("selectedAccountId"), 10) || null,
  accounts: [],
  count: null,
  error: null,
  isPending: true,
}

function accountReducer(state = defaultState, action) {
  const { type, payload } = action

  switch (type) {
    case getActionType(GET, ACCOUNT, LIST, START): {
      return state
    }

    case getActionType(GET, ACCOUNT, LIST, SUCCESS): {
      return {
        ...state,
        accounts: payload.accounts,
        count: payload.count,
        error: null,
        isPending: false,
      }
    }

    case getActionType(GET, ACCOUNT, LIST, FAIL): {
      return { ...defaultState, error: payload.error, isPending: false }
    }

    case getActionType(CREATE, ACCOUNT, SUCCESS): {
      return {
        ...state,
        accounts: [...state.accounts, payload.account],
        count: state.count + 1,
      }
    }

    case getActionType(SELECT, ACCOUNT): {
      return { ...state, selectedAccountId: payload.accountId }
    }

    case getActionType(RESET): {
      return { ...defaultState, selectedAccountId: null, isPending: false }
    }

    default:
      return state
  }
}

export const AccountContext = React.createContext()

export function useAccount() {
  return useContext(AccountContext)
}

export const AccountProvider = ({ children }) => {
  const api = useContext(ApiContext)

  const [state, dispatch] = useReducer(accountReducer, defaultState)

  const { accounts, count, error, isPending, selectedAccountId } = state

  const getAccountList = useCallback(async () => {
    try {
      dispatch({ type: getActionType(GET, ACCOUNT, LIST, START) })

      const { accounts, count } = await api.getAccountList()

      getAccountListSuccess({ accounts, count })
      return { accounts, count }
    } catch (error) {
      dispatch({
        type: getActionType(GET, ACCOUNT, LIST, FAIL),
        payload: { error },
      })

      if (!error.isAxiosError) {
        throw error
      }
    }
  }, [api])

  function getAccountListSuccess({ accounts, count }) {
    return dispatch({
      type: getActionType(GET, ACCOUNT, LIST, SUCCESS),
      payload: { accounts, count },
    })
  }

  const createAccount = useCallback(
    async (accountDto) => {
      try {
        dispatch({ type: getActionType(CREATE, ACCOUNT, START) })

        const account = await api.createAccount(accountDto)

        dispatch({
          type: getActionType(CREATE, ACCOUNT, SUCCESS),
          payload: { account },
        })
      } catch (error) {
        dispatch({
          type: getActionType(CREATE, ACCOUNT, FAIL),
          payload: { error },
        })

        if (!error.isAxiosError) {
          throw error
        }
      }
    },
    [api],
  )

  const setSelectedAccount = useCallback(
    async (accountId) => {
      localStorage.setItem("selectedAccountId", accountId)

      return dispatch({
        type: getActionType(SELECT, ACCOUNT),
        payload: { accountId },
      })
    },
    [dispatch],
  )

  const selectAccount = useCallback(
    async (accountId) => {
      await api.updateToken({ accountId })
      setSelectedAccount(accountId)
    },
    [api, setSelectedAccount],
  )

  function reset() {
    localStorage.removeItem("selectedAccountId")
    return dispatch({ type: getActionType(RESET) })
  }

  useEffect(() => {
    getAccountList()
  }, [getAccountList])

  if (error && path(["response", "status"], error) !== 401) {
    throw error
  }

  const selectedAccount = accounts.find((a) => a.id === selectedAccountId)

  return (
    <AccountContext.Provider
      value={{
        isPending,
        error,
        accounts,
        count,
        getAccountList,
        createAccount,
        selectAccount,
        selectedAccount,
        selectedAccountId,
        setSelectedAccount,
        getAccountListSuccess,
        reset,
      }}
    >
      {children}
    </AccountContext.Provider>
  )
}

AccountProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

AccountProvider.defaultProps = {}

export const WithAccountRequired = ({ children }) => {
  const { selectedAccount } = useAccount()

  if (!selectedAccount) {
    return <AccountList />
  }

  return children
}

WithAccountRequired.propTypes = {
  children: PropTypes.node.isRequired,
}

WithAccountRequired.defaultProps = {}
