import React from "react"
import PropTypes from "prop-types"
import { Button } from "../button"
import { ClipboardIconButton } from "../clipboard-button"

export function CopyAndOpenLinkButtonGroup({ src }) {
  return (
    <>
      <Button color="link" size="md" className="p-1 ml-1">
        <ClipboardIconButton clipboardText={src} />
      </Button>
      <Button color="link" size="md" className="p-1">
        <a href={src} target="_blank" rel="noopener noreferrer">
          <i className="fas fa-external-link-alt" />
        </a>
      </Button>
    </>
  )
}

CopyAndOpenLinkButtonGroup.defaultProps = {}
CopyAndOpenLinkButtonGroup.propTypes = {
  src: PropTypes.string.isRequired,
}
