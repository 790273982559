import { Trans } from "@lingui/macro"
import { getUnixTime } from "date-fns"
import { reverse } from "named-urls"
import PropTypes from "prop-types"
import qs from "qs"
import React, { useContext } from "react"
import { useAsync } from "react-async"
import { useHistory } from "react-router-dom"
import {
  Card,
  CardBody,
  CardFooter,
  CardText,
  ListGroup,
  Spinner as RSpinner,
} from "reactstrap"
import { Button } from "../../../components/button"
import { FormatDate } from "../../../components/formatters/index"
import { ApiContext } from "../../../providers/api-provider"
import { urls } from "../../../urls"
import { BookingListItem } from "../../booking/components"
import { isUserTimezoneDifferentFromLocationTimezone } from "../../booking/helpers"
import { DAY, HALF_HOUR, ResourceInfoCard } from "../../resource"
import MiniMembershipPlanInfoCard from "./mini-membership-plan-info-card"

async function getLocation({ locationId, api }) {
  if (!locationId) return null
  return api.getLocation(locationId)
}

export default function ResourceDayOccupancyChart({
  occupancy,
  loading,
  error,
  selectedMembership,
  selectedResource,
  selectedDate,
}) {
  const api = useContext(ApiContext)
  const history = useHistory()

  const {
    data: location,
    error: isLocationError,
    isPending: isLocationPending,
  } = useAsync({
    promiseFn: getLocation,
    locationId: selectedMembership.locationId,
    api,
  })

  const locationTimezone =
    !isLocationError && !isLocationPending && location
      ? location.timezone
      : null

  const isOccupiedByMembershipPlan =
    occupancy.length === 1 && typeof occupancy[0].membershipPlan !== "undefined"

  const isOccupiedByBooking =
    occupancy.length > 0 && typeof occupancy[0].booking !== "undefined"

  const canBook = (selectedResource) =>
    selectedResource.resourceType.bookingDurationUnits.some((duration) =>
      [HALF_HOUR, DAY].includes(duration),
    )

  if (isLocationPending) return <RSpinner style={{ margin: "0 auto" }} />

  return (
    <>
      {locationTimezone &&
        isUserTimezoneDifferentFromLocationTimezone(locationTimezone) && (
          <Card body inverse color="danger">
            <CardText className="h5">
              <Trans>
                Please pay attention that your timezone differs from the
                location timezone.
              </Trans>
            </CardText>
          </Card>
        )}

      <ResourceInfoCard resource={selectedResource} />

      <Card>
        <CardBody>
          {error && <Trans>Something went wrong</Trans>}

          {loading && !error && <RSpinner />}

          {!loading && !error && (
            <>
              <CardText>
                <FormatDate date={selectedDate} formatStr="PPPP" />
              </CardText>

              {isOccupiedByMembershipPlan && (
                <MiniMembershipPlanInfoCard
                  membershipPlan={occupancy[0].membershipPlan}
                />
              )}

              {isOccupiedByBooking ? (
                <>
                  <p>
                    <Trans>Bookings</Trans>
                  </p>
                  <ListGroup>
                    {occupancy.map((oc) => (
                      <BookingListItem
                        key={oc.booking.id}
                        booking={oc.booking}
                      />
                    ))}
                  </ListGroup>
                </>
              ) : null}

              {!isOccupiedByMembershipPlan && !isOccupiedByBooking && (
                <Card body color="light">
                  <CardText className="font-lg">
                    <Trans>Resource is free</Trans>
                  </CardText>
                </Card>
              )}
            </>
          )}
        </CardBody>

        <CardFooter>
          {!isOccupiedByMembershipPlan && canBook(selectedResource) && (
            <Button
              block
              color="primary"
              onClick={() =>
                history.push(
                  reverse(urls.booking.create) +
                    qs.stringify(
                      {
                        rid: selectedResource.id,
                        start: getUnixTime(selectedDate),
                      },
                      { addQueryPrefix: true },
                    ),
                )
              }
            >
              <Trans>Here to booking</Trans>
            </Button>
          )}
        </CardFooter>
      </Card>
    </>
  )
}

ResourceDayOccupancyChart.defaultProps = {
  occupancy: [],
  loading: true,
}

ResourceDayOccupancyChart.propTypes = {
  occupancy: PropTypes.array,
  loading: PropTypes.bool,
  error: PropTypes.object,
  selectedMembership: PropTypes.object.isRequired,
  selectedResource: PropTypes.object.isRequired,
  selectedDate: PropTypes.object.isRequired,
}
