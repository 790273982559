import { isLength } from "validator"
import { t } from "@lingui/macro"

export const accountValidator = async (values) => {
  const errors = {}

  const maxValueOptions = { max: 50 }

  if (!values.name) {
    errors.name = t`Value is required`
  } else if (!isLength(values.name, maxValueOptions)) {
    errors.name = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  return errors
}
