import { t, Trans } from "@lingui/macro"
import { Field, Form, Formik } from "formik"
import PropTypes from "prop-types"
import { path } from "ramda"
import React, { useContext } from "react"
import { useAsync } from "react-async"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardGroup,
  CardTitle,
  Col,
  Container,
  FormGroup,
  InputGroupText,
  Row,
} from "reactstrap"
import { BackButton } from "../../components/backbutton"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import { Input } from "../../components/input"
import { Spinner } from "../../components/spinner"
import { CLIENT_ERROR, countries, defaultCountry } from "../../constants"
import { fetchStripe } from "../../lib/helpers"
import { ApiContext } from "../../providers/api-provider"
import { useMembership, useMembershipInvite } from "../membership"
import { useBilling } from "./billing-provider"
import { billingValidator } from "./billing-validator"
import { membershipInviteToBillingForm } from "./helpers"

export function BillingForm({ onSubmitSuccess }) {
  const api = useContext(ApiContext)

  const { setState: setBillingState } = useBilling()
  const { membershipInvites } = useMembershipInvite()
  const { selectedMembership } = useMembership()

  const {
    data: billing,
    error,
    isPending,
  } = useAsync({
    promiseFn: api.getBilling,
    onResolve: () => {
      if (selectedMembership) {
        return runLoadIntegration({ membershipId: selectedMembership.id })
      }
    },
  })

  const { run: runLoadIntegration } = useAsync({
    deferFn: ([{ membershipId }]) => api.getStripeIntegration({ membershipId }),
    onResolve: (stripeIntegration) => {
      return runLoadStripe({ stripeUserId: stripeIntegration.stripeUserId })
    },
  })

  const { data: stripe, run: runLoadStripe } = useAsync({
    deferFn: async ([{ stripeUserId }]) => {
      return fetchStripe({ stripeUserId })
    },
  })

  if (error && path(["response", "status"], error) !== 404) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  const handleClick = async () => {
    const { sessionId } = await api.createCheckoutSession({
      membershipId: selectedMembership.id,
    })

    await stripe.redirectToCheckout({
      sessionId,
    })
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              {billing && (
                <CardTitle className="font-3xl mb-0">
                  <BackButton />
                </CardTitle>
              )}
              <CardBody>
                <Formik
                  initialValues={{
                    email: "",
                    contactName: "",
                    businessName: "",
                    street: "",
                    houseno: "",
                    city: "",
                    zip: "",
                    vatNumber: "",
                    ...(membershipInvites && membershipInvites[0]
                      ? membershipInviteToBillingForm(membershipInvites[0])
                      : null),
                    ...billing,
                    country:
                      (billing && billing.country) || defaultCountry.code,
                  }}
                  validate={billingValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      let newBilling = null

                      if (billing) {
                        newBilling = await api.updateBilling({
                          billingDto: values,
                        })

                        actions.resetForm({ values: newBilling })
                      } else {
                        newBilling = await api.createBilling({
                          billingDto: values,
                        })
                      }

                      setBillingState((state) => ({
                        ...state,
                        error: null,
                        billing: newBilling,
                      }))

                      onSubmitSuccess && onSubmitSuccess(newBilling)
                      toast.success(t`Saved`)
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors(err.response.data)
                      }

                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({ isSubmitting, isValid, dirty }) => {
                    return (
                      <Form>
                        <h1>
                          <Trans>Billing Information</Trans>
                        </h1>
                        <p className="text-muted">
                          <Trans>Use the fields below to update Billing.</Trans>
                        </p>

                        <Field
                          id="email"
                          type="email"
                          name="email"
                          component={Input}
                          label={t`Email`}
                          addon={
                            <InputGroupText>
                              <i className="far fa-envelope" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                        />

                        <Field
                          id="contactName"
                          type="text"
                          name="contactName"
                          component={Input}
                          label={t`Contact person name, info`}
                          addon={
                            <InputGroupText>
                              <i className="fas fa-arrow-right" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                        />

                        <Field
                          id="businessName"
                          type="text"
                          name="businessName"
                          component={Input}
                          label={
                            <p className="m-0">
                              <Trans>Company Business Name</Trans>
                              <span className="text-muted">
                                {" "}
                                ( <Trans>optional</Trans> )
                              </span>
                            </p>
                          }
                          addon={
                            <InputGroupText>
                              <i className="fas fa-arrow-right" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                        />
                        <Row>
                          <Col md="8">
                            <Field
                              id="street"
                              type="text"
                              name="street"
                              component={Input}
                              label={t`Street`}
                              addon={
                                <InputGroupText>
                                  <i className="fas fa-map-marker-alt" />
                                </InputGroupText>
                              }
                              addonType="prepend"
                            />
                          </Col>
                          <Col md="4">
                            <Field
                              id="houseno"
                              type="text"
                              name="houseno"
                              component={Input}
                              label={t`House`}
                              addon={
                                <InputGroupText>
                                  <i className="fas fa-map-marker-alt" />
                                </InputGroupText>
                              }
                              addonType="prepend"
                            />
                          </Col>
                        </Row>

                        <Row>
                          <Col md="8">
                            <Field
                              id="city"
                              type="text"
                              name="city"
                              component={Input}
                              label={t`City`}
                              addon={
                                <InputGroupText>
                                  <i className="fas fa-map-marker-alt" />
                                </InputGroupText>
                              }
                              addonType="prepend"
                            />
                          </Col>
                          <Col md="4">
                            <Field
                              id="zip"
                              type="text"
                              name="zip"
                              component={Input}
                              label={t`Zip`}
                              addon={
                                <InputGroupText>
                                  <i className="fas fa-map-marker-alt" />
                                </InputGroupText>
                              }
                              addonType="prepend"
                            />
                          </Col>
                        </Row>

                        <Field
                          id="country"
                          type="select"
                          name="country"
                          component={Input}
                          label={t`Country`}
                          addon={
                            <InputGroupText>
                              <i className="fas fa-map-marker-alt" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                        >
                          {countries.map((cc) => (
                            <option key={cc.code} value={cc.code}>
                              {cc.name}
                            </option>
                          ))}
                        </Field>

                        <Field
                          id="vatNumber"
                          type="text"
                          name="vatNumber"
                          component={Input}
                          label={
                            <p className="m-0">
                              <Trans>VAT Number</Trans>
                              <span className="text-muted">
                                {" "}
                                (<Trans>optional</Trans>)
                              </span>
                            </p>
                          }
                          addon={
                            <InputGroupText>
                              <i className="fas fa-map-marker-alt" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                        />

                        <FormGroup className="form-actions">
                          <Button
                            type="submit"
                            loading={isSubmitting}
                            disabled={!dirty || !isValid || isSubmitting}
                            block
                            color={
                              !dirty || !isValid || isSubmitting
                                ? "secondary"
                                : "primary"
                            }
                          >
                            <Trans>Save</Trans>
                          </Button>
                        </FormGroup>
                      </Form>
                    )
                  }}
                </Formik>
                {Boolean(billing) && Boolean(stripe) && (
                  <Button color="link" onClick={handleClick}>
                    <Trans>Add SEPA Direct Debit to pay automatically</Trans>
                  </Button>
                )}
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

BillingForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
}

BillingForm.defaultProps = {}
