import { Trans, t } from "@lingui/macro"
import { Field, Form, Formik } from "formik"
import cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { toast } from "react-toastify"
import { FormGroup, FormText, InputGroupText } from "reactstrap"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import { Input } from "../../components/input"
import { config } from "../../config"
import { CLIENT_ERROR } from "../../constants"
import { ApiContext } from "../../providers/api-provider"
import { registrationValidator } from "./registration-validator"

export function RegistrationForm({ onSubmitSuccess, invite, onLoginClick }) {
  const api = useContext(ApiContext)

  return (
    <Formik
      initialValues={{
        email: "",
        firstName: "",
        lastName: "",
        tocCheckbox: false,
        ...invite,
      }}
      validate={registrationValidator}
      onSubmit={async (values, actions) => {
        const isNecessaryCookies = cookies.get("rcl_consent_given")

        if (!isNecessaryCookies) {
          toast.error(
            t`Sorry, you must agree to our privacy policy before you can register.`,
            { autoClose: 60000 },
          )
          return
        }

        try {
          await api.createUser({
            email: values.email,
            firstName: values.firstName,
            lastName: values.lastName,
          })

          await api.createOTPW({ email: values.email })

          onSubmitSuccess && onSubmitSuccess(values)
          toast.success("One time password sent! Check your inbox.")
        } catch (err) {
          if (err.problem === CLIENT_ERROR) {
            actions.setErrors({
              email: "User with this email already exists.",
            })
          }

          toastError(err)

          if (!err.isAxiosError) {
            throw err
          }
        } finally {
          actions.setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, isValid, dirty }) => (
        <Form>
          <h1>
            <Trans>Register</Trans>
          </h1>
          <p className="text-muted">
            <Trans>Enter your details to register.</Trans>
          </p>

          <Field
            id="email"
            type="email"
            name="email"
            component={Input}
            label={t`Email`}
            disabled={Boolean(invite)}
            addon={
              <InputGroupText>
                <i className="far fa-envelope" />
              </InputGroupText>
            }
            addonType="prepend"
          />

          <Field
            id="firstName"
            type="text"
            name="firstName"
            component={Input}
            label={t`First Name`}
            addon={
              <InputGroupText>
                <i className="fas fa-arrow-right" />
              </InputGroupText>
            }
            addonType="prepend"
          />

          <Field
            id="lastName"
            type="text"
            name="lastName"
            component={Input}
            label={t`Last Name`}
            addon={
              <InputGroupText>
                <i className="fas fa-arrow-right" />
              </InputGroupText>
            }
            addonType="prepend"
          />

          <FormText style={{ textAlign: "center" }}>
            <Field
              id="tocCheckbox"
              type="checkbox"
              name="tocCheckbox"
              component={Input}
              label={
                <span
                  style={{
                    position: "relative",
                    verticalAlign: "middle",
                    bottom: "-3px",
                    left: "4px",
                  }}
                >
                  <Trans>
                    I agree with the{" "}
                    <a
                      href={`${config.intertempi.homePage.url}/terms-conditions`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Terms & conditions
                    </a>{" "}
                    and{" "}
                    <a
                      href={`${config.intertempi.homePage.url}/privacy-policy`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Privacy policy
                    </a>
                    .
                  </Trans>
                </span>
              }
            />
          </FormText>

          <FormGroup className="form-actions">
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting}
              color="primary"
              block
            >
              <Trans>Submit</Trans>
            </Button>
          </FormGroup>

          <FormGroup className="form-actions align-center justify-center">
            <Trans>Already registered?</Trans>
            <Button type="button" color="link" onClick={onLoginClick}>
              <Trans>Login</Trans>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}

RegistrationForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
  onLoginClick: PropTypes.func,
  invite: PropTypes.object,
}

RegistrationForm.defaultProps = {
  invite: null,
}
