import { path } from "ramda"
import React, { useContext } from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { BookingCalendar } from "./booking-calendar"
import { BookingTimeline } from "./booking-timeline"
import { TeammatesBookingList } from "./booking-teammates"
import { BookingCreate } from "./booking-create"
import { BookingDetails } from "./booking-details"
import { BookingUpdate } from "./booking-update"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { RecurringBookingUpdate } from "./recurring-booking-update"
import {
  MembershipContext,
  WithMembershipRequired,
} from "../membership/membership-provider"
import { WithUserRequired, WithAccountRequired } from "../account"
import { WithBillingRequired } from "../billing"

export function Booking() {
  const { selectedMembership } = useContext(MembershipContext)

  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithBillingRequired>
            <WithMembershipRequired>
              <Switch>
                <Route
                  exact
                  key={path(["id"], selectedMembership)}
                  path={urls.booking.calendar}
                  component={BookingCalendar}
                />
                <Route
                  exact
                  key={path(["id"], selectedMembership)}
                  path={urls.booking.timeline}
                  component={BookingTimeline}
                />

                <Route
                  exact
                  key={path(["id"], selectedMembership)}
                  path={urls.booking.teammates}
                  component={TeammatesBookingList}
                />

                <Route
                  exact
                  path={urls.booking.create}
                  component={BookingCreate}
                />
                <Route
                  exact
                  path={urls.booking.details}
                  component={BookingDetails}
                />
                <Route
                  exact
                  path={urls.booking.update}
                  component={BookingUpdate}
                />
                <Route
                  exact
                  path={urls.recurringBooking.update}
                  component={RecurringBookingUpdate}
                />
              </Switch>
            </WithMembershipRequired>
          </WithBillingRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Booking.propTypes = {}
Booking.defaultProps = {}
