import {
  fromUnixTime,
  getUnixTime,
  setSeconds,
  setMilliseconds,
  roundToNearestMinutes,
  endOfMinute,
  subMinutes,
  differenceInMilliseconds,
  startOfDay,
  endOfDay,
  isBefore,
  isAfter,
  addWeeks,
} from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import {
  getTimezone,
  priceFromCents,
  priceToCents,
  getUserFullName,
} from "../../lib/helpers"
import { HALF_HOUR, DAY, MONTH } from "../resource"
import ms from "ms"
import { UPCOMING, RUNNING, FINISHED } from "../../constants"
import { OutlookCalendar, GoogleCalendar, ICalendar } from "datebook"
import { t } from "@lingui/macro"

export function formatBookingToCalendar(booking, membershipId) {
  return {
    id: booking.id,
    title: membershipId === booking.membershipId ? booking.title : "Busy",
    startAt: new Date(booking.startAt),
    endAt: roundToNearestMinutes(new Date(booking.endAt)),
    allDay: false,
  }
}

export function formatBookingForForm(booking) {
  if (!booking) return null

  const startAtDay = booking.startAtDay
    ? new Date(booking.startAtDay)
    : startOfDay(new Date(booking.startAt))

  const endRecurringAtDay = booking.endRecurringAtDay
    ? new Date(booking.endRecurringAtDay)
    : booking.startAtDay
    ? startOfDay(addWeeks(new Date(booking.startAtDay), 1))
    : startOfDay(addWeeks(new Date(booking.startAt), 1))

  const isDayLong = booking.durationUnit === DAY

  return {
    ...booking,
    id: booking.id,
    title: booking.title,
    startAtDay,
    endRecurringAtDay,
    startAt: isDayLong
      ? startAtDay
      : mergeDayAndTime(startAtDay, new Date(booking.startAt)),
    endAt: isDayLong
      ? endOfDay(startAtDay)
      : mergeDayAndTime(
          startAtDay,
          roundToNearestMinutes(new Date(booking.endAt)),
        ),
    durationUnit: booking.durationUnit,
    membershipId: booking.membershipId,
    user: booking.user,
    resourceId: booking.resourceId,
    isRecurringCheckbox:
      booking.isRecurringCheckbox ??
      Boolean(booking.recurringBookingId ?? booking.repeatPattern),
    price: priceFromCents(booking.price),
    isPrivate: booking.isPrivate,
  }
}

export function mergeDayAndTime(day = new Date(), time) {
  return new Date(
    day.getFullYear(),
    day.getMonth(),
    day.getDate(),
    time.getHours(),
    time.getMinutes(),
    time.getSeconds(),
    time.getMilliseconds(),
  )
}

export function getPreparedEndAt(endAt) {
  return endOfMinute(subMinutes(roundToNearestMinutes(new Date(endAt)), 1))
}

export function getPreparedBookingDto({
  user,
  membershipId,
  resourceId,
  title,
  description,
  durationUnit,
  startAtDay,
  startAt,
  endAt,
  price,
  isPrivate,
  attendees,
}) {
  startAtDay = new Date(startAtDay)
  startAt = durationUnit === DAY ? startOfDay(startAtDay) : new Date(startAt)
  endAt = durationUnit === DAY ? endOfDay(startAtDay) : new Date(endAt)

  return {
    userId: user.value,
    membershipId,
    resourceId,
    title,
    description,
    durationUnit,
    price: priceToCents(price),
    startAt: mergeDayAndTime(startAtDay, startAt),
    endAt: getPreparedEndAt(mergeDayAndTime(startAtDay, endAt)),
    timezone: getTimezone(),
    isPrivate,
    attendees: attendees?.map((u) => u.id) || [],
  }
}

export function getPreparedRecurringBookingDto({
  user,
  membershipId,
  resourceId,
  title,
  description,
  durationUnit,
  startAt,
  endAt,
  repeatPattern,
  endRecurringAtDay,
  price,
  isPrivate,
  attendees,
}) {
  return {
    userId: user.value,
    membershipId,
    resourceId,
    title,
    description,
    durationUnit,
    startAt: new Date(startAt),
    endAt: getPreparedEndAt(endAt),
    timezone: getTimezone(),
    repeatPattern,
    endRecurringAtDay,
    price: priceToCents(price),
    isPrivate,
    attendees: attendees?.map((u) => u.id) || [],
  }
}

export function applyLocationTimezoneToBooking({ booking, location }) {
  if (booking.durationUnit === DAY) {
    booking = {
      ...booking,
      startAt: zonedTimeToUtc(booking.startAt, location.timezone),
      endAt: zonedTimeToUtc(booking.endAt, location.timezone),
      timezone: location.timezone,
    }
  }

  return booking
}

export function isUserTimezoneDifferentFromLocationTimezone(locationTimezone) {
  return getTimezone() !== locationTimezone
}

export function decodeUnixTimeParam(date) {
  if (!date) return null

  try {
    const value = fromUnixTime(date)
    return value
  } catch {
    return null
  }
}

export function encodeUnixTimeParam(date) {
  if (!date) return null

  try {
    const value = getUnixTime(date)
    return value
  } catch {
    return null
  }
}

export const UnixTimeParam = {
  encode(date) {
    return encodeUnixTimeParam(date)
  },

  decode(date) {
    return decodeUnixTimeParam(date)
  },
}

export const normalizeTime = (time) =>
  setSeconds(setMilliseconds(new Date(time), 0), 0)

export const normalizeBooking = (booking) => ({
  ...booking,
  startAt: normalizeTime(new Date(booking.startAt)), // .toISOString(),
  endAt: normalizeTime(new Date(booking.endAt)), // .toISOString(),
})

export function computePriceForBooking({ booking, resource }) {
  const diff = differenceInMilliseconds(booking.endAt, booking.startAt)

  const price = resource.prices.find(
    (p) => p.bookingDurationUnit === booking.durationUnit,
  )

  if (booking.durationUnit === MONTH) {
    return {
      currency: price.currency,
      amount: price.amount,
    }
  }

  if (booking.durationUnit === DAY) {
    return {
      currency: price.currency,
      amount: price.amount,
    }
  }

  if (booking.durationUnit === HALF_HOUR) {
    return {
      currency: price.currency,
      amount: ((diff + 1) / ms("30min")) * price.amount,
    }
  }

  return null
}

export const getBookingBadgeTextAndStatusColor = ({ startAt, endAt }) => {
  if (isAfter(Date.parse(startAt), new Date())) {
    return { text: t`Upcoming`, color: "success", status: UPCOMING }
  }

  if (
    isBefore(Date.parse(startAt), new Date()) &&
    isAfter(Date.parse(endAt), new Date())
  ) {
    return { text: t`Running`, color: "warning", status: RUNNING }
  }

  if (isBefore(Date.parse(endAt), new Date())) {
    return { text: t`Finished`, color: "danger", status: FINISHED }
  }

  return { text: t`n/a`, color: "info", status: "n/a" }
}

export function bookingStatusesToSelectOptions(status) {
  return { value: status.toLowerCase(), label: status }
}

export function selectOptionsToBookingStatus(opt) {
  return opt.value.toUpperCase()
}

export function getCalendarEventLinksFromBooking({ booking, location = "" }) {
  const options = {
    title: booking.title,
    location,
    description: booking.description,
    start: new Date(booking.startAt),
    end: new Date(booking.endAt),
  }

  /** Only Google calendar supports recurring events and only infinitely
  if (booking.recurringBooking && !booking.recurringBooking.endAt) {
    options.recurrence = {
      frequency: getICalFrequencyFromRepeatPattern(
        booking.recurringBooking.repeatPattern,
      ),
      interval: 1,
    }
  }
  function getICalFrequencyFromRepeatPattern(repeatPattern) {
    return {
      [REPEAT_DAILY]: "DAILY",
      [REPEAT_WEEKLY]: "WEEKLY",
      [REPEAT_MONTHLY]: "MONTHLY",
    }[repeatPattern]
  }
  **/

  const outlookCalendar = new OutlookCalendar(options)
  const googleCalendar = new GoogleCalendar(options)
  const iCalendar = new ICalendar(options)

  return {
    outlookUrl: outlookCalendar.render(),
    googleUrl: googleCalendar.render(),
    downloadIcal: () => iCalendar.download(`${booking.title}.ics`),
  }
}

export function usersToAttandeesSelector(user) {
  return {
    ...user,
    value: user.id,
    label: `${getUserFullName(user)} <${user.email}>`,
  }
}
