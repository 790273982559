import { t } from "@lingui/macro"
import { isLength } from "validator"

export const serviceTicketValidator = async (values) => {
  const errors = {}
  const maxValueOptions = { max: 500 }

  if (!values.description) {
    errors.description = t`Value is required`
  } else if (!isLength(values.description, maxValueOptions)) {
    errors.description = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  return errors
}
