import { isLength, isEmail } from "validator"
import { t } from "@lingui/macro"

export const emailValidator = async (values) => {
  const errors = {}

  const maxEmailOptions = { max: 50 }

  if (!values.email) {
    errors.email = t`Value is required`
  } else if (!isEmail(values.email)) {
    errors.email = t`Email is required`
  } else if (!isLength(values.email, maxEmailOptions)) {
    errors.email = t`Value should be at most ${maxEmailOptions.max} characters long`
  }

  return errors
}
