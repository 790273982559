import React, { useContext, useState, useCallback, useEffect } from "react"
import { useHistory } from "react-router-dom"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import PropTypes from "prop-types"
import { ApiContext } from "../../providers"
import { path } from "ramda"
import { BillingForm } from "./billing-form"
import { useMembership } from "../membership"

export const BillingContext = React.createContext()

export function useBilling() {
  return useContext(BillingContext)
}

const defaultState = {
  billing: null,
  error: null,
  isPending: true,
}

export const BillingProvider = ({ children }) => {
  const api = useContext(ApiContext)

  const [state, setState] = useState(defaultState)

  const { billing, error, isPending } = state

  const getBilling = useCallback(async () => {
    try {
      const billing = await api.getBilling()

      setState((state) => ({
        ...state,
        billing,
        isPending: false,
        error: null,
      }))
    } catch (error) {
      if (path(["response", "status"], error) !== 404) {
        setState((state) => ({
          ...state,
          billing: null,
          isPending: false,
          error,
        }))
      } else {
        setState((state) => ({
          ...state,
          billing: null,
          isPending: false,
          error: null,
        }))
      }

      if (!error.isAxiosError) {
        throw error
      }
    }
  }, [api])

  function reset() {
    return setState({ ...defaultState, isPending: false })
  }

  useEffect(() => {
    getBilling()
  }, [getBilling])

  if (error && path(["response", "status"], error) !== 401) {
    throw error
  }

  return (
    <BillingContext.Provider
      value={{ isPending, error, billing, setState, getBilling, reset }}
    >
      {children}
    </BillingContext.Provider>
  )
}

BillingProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

BillingProvider.defaultProps = {}

export const WithBillingRequired = ({ children }) => {
  const { billing } = useContext(BillingContext)
  const { selectedMembership } = useMembership()
  const history = useHistory()

  const keys = [
    "email",
    "contactName",
    "street",
    "houseno",
    "city",
    "zip",
    "country",
  ]

  if (!selectedMembership?.location?.billingEnabled) return children

  if (billing && keys.every((key) => Boolean(billing[key]))) return children

  return (
    <BillingForm
      onSubmitSuccess={() => history.push(reverse(urls.booking.calendar))}
    />
  )
}

WithBillingRequired.propTypes = {
  children: PropTypes.node.isRequired,
}

WithBillingRequired.defaultProps = {}
