import { isBefore, isAfter, startOfDay, endOfDay, getUnixTime } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"
import { sortBy } from "lodash"
import {
  OCCUPIED,
  PARTLY_OCCUPIED,
  AVAILABLE,
  availabilityTypes,
} from "../constants"
import { DAY } from "../../resource/constants"

export function getPolygonCentroid(points) {
  const centroid = { x: 0, y: 0 }
  for (let i = 0; i < points.length; i++) {
    centroid.x += points[i][0]
    centroid.y += points[i][1]
  }
  centroid.x /= points.length
  centroid.y /= points.length
  return centroid
}

export function isResourceOccupiedAtDatetime({ startAt, endAt }, datetime) {
  return (
    isAfter(Date.parse(datetime), Date.parse(startAt)) &&
    isBefore(Date.parse(datetime), Date.parse(endAt))
  )
}

export function isResourceOccupiedInsideADay(
  { startAt, endAt, timezone },
  datetime,
) {
  return (
    // startOfDay <= startAt < endAt <= endOfDay
    getUnixTime(utcToZonedTime(new Date(startAt), timezone)) >=
      getUnixTime(startOfDay(datetime)) &&
    getUnixTime(utcToZonedTime(new Date(endAt), timezone)) <=
      getUnixTime(endOfDay(datetime))
  )
}

export function getOccupiedResourcesByMembershipPlans(
  membershipPlans,
  datetime,
) {
  if (!membershipPlans || membershipPlans.length < 1) return []

  return Array.from(
    new Set(
      membershipPlans
        .filter((mp) => isResourceOccupiedAtDatetime(mp, datetime))
        .map((mp) =>
          mp.resources.map((r) => ({
            ...r,
            resourceId: r.id,
            name: `${r.name}, ${mp.name}`,
            membershipPlanId: mp.id,
          })),
        )
        .flat(),
    ),
  )
}

export function getOccupiedResourcesByBookings(bookings, datetime) {
  if (!bookings || bookings.length < 1) return []

  return Array.from(
    new Set(
      bookings
        .filter((b) => isResourceOccupiedInsideADay(b, datetime))
        .map((b) => ({
          ...b.resource,
          resourceId: b.resource.id,
          name: `${b.resource.name}, ${b.title}`,
          bookingId: b.id,
          durationUnit: b.durationUnit,
        })),
    ),
  )
}

function getResourcePoints(resource, points) {
  const resourcePoints = points.filter((p) => p.resourceId === resource.id)

  if (resourcePoints.length < 1) return []

  return sortBy(resourcePoints, "id")
}

export function getPointsMappedOnOccupancy(
  points,
  resources,
  occupiedResources,
) {
  if (!points || !resources || !occupiedResources) return []

  return resources.map((rs) => {
    const matchingResource = occupiedResources.find((or) => or.id === rs.id)

    return {
      ...rs,
      availability: !matchingResource
        ? AVAILABLE
        : matchingResource.bookingId &&
          matchingResource.durationUnit &&
          matchingResource.durationUnit !== DAY
        ? PARTLY_OCCUPIED
        : OCCUPIED,
      name: rs.name,
      coordinates: getResourcePoints(rs, points),
    }
  })
}

export function getColorByAvailability(availability) {
  // default color is lightblue
  if (!availabilityTypes.includes(availability)) return "lightblue"

  return {
    [OCCUPIED]: "red",
    [PARTLY_OCCUPIED]: "orange",
    [AVAILABLE]: "#2ECC71",
  }[availability]
}
