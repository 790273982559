import React from "react"
import PropTypes from "prop-types"
import { FormFeedback as BFormFeedback } from "reactstrap"

export const FormFeedback = ({
  children,
  tag,
  className,
  cssModule,
  valid,
}) => {
  return (
    <BFormFeedback
      tag={tag}
      className={className}
      cssModule={cssModule}
      valid={valid}
    >
      {children}
    </BFormFeedback>
  )
}

FormFeedback.defaultProps = {}

FormFeedback.propTypes = {
  children: PropTypes.node.isRequired,
  tag: PropTypes.node,
  className: PropTypes.string,
  cssModule: PropTypes.string,
  valid: PropTypes.bool,
}
