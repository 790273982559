export function requestSanitizer(request) {
  if (request.body) {
    request.body = undefined
  }

  return request
}

export function responseSanitizer(response) {
  if (response.body) {
    response.body = undefined
  }

  return response
}
