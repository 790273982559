import { Trans, t } from "@lingui/macro"
import { Field, Form, Formik } from "formik"
import React, { useContext } from "react"
import { useAsync } from "react-async"
import { useHistory } from "react-router-dom"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardGroup,
  CardText,
  CardTitle,
  Col,
  Container,
  FormGroup,
  InputGroupText,
  Row,
} from "reactstrap"
import { BackButton } from "../../components/backbutton"
import { Button } from "../../components/button"
import { useConfirmation } from "../../components/confirmation-dialog"
import { toastError } from "../../components/error"
import { FoldableBlock } from "../../components/foldable-block"
import { ImageInput, Input } from "../../components/input"
import { Spinner } from "../../components/spinner"
import { CLIENT_ERROR, USER_IMAGE_PLACEHOLDER } from "../../constants"
import { ApiContext } from "../../providers/api-provider"
import { useAccount } from "./account-provider"
import { UserContext } from "./user-provider"
import { userValidator } from "./user-validator"
export function UserForm() {
  const history = useHistory()
  const { selectedAccount, setSelectedAccount } = useAccount()
  const confirm = useConfirmation()
  const api = useContext(ApiContext)
  const { setState: setUserState } = useContext(UserContext)

  const {
    data: user,
    error,
    isPending,
  } = useAsync({
    promiseFn: api.getMyUser,
  })

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  const confirmDeleteAccount = () =>
    confirm({
      title: t`Delete Account`,
      content: t`Are you sure you want to delete this Account? The account will be deleted if only there are no other users, no resources and no bookings registered on it.`,
      color: "danger",
      variant: "confirm",
      onConfirm: async () => {
        try {
          await api.deleteMyAccount({
            accountId: selectedAccount.id,
          })
          setSelectedAccount(null)
          history.push("/")
        } catch (err) {
          toastError(err)
          throw err
        }
      },
    })

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <CardTitle className="font-3xl mb-0">
                <BackButton />
              </CardTitle>

              <CardBody>
                <Formik
                  initialValues={{
                    email: "",
                    firstName: "",
                    lastName: "",
                    img: USER_IMAGE_PLACEHOLDER,
                    ...user,
                  }}
                  validate={userValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      const newUser = await api.updateUser({
                        firstName: values.firstName,
                        lastName: values.lastName,
                        img: values.img,
                      })

                      setUserState((state) => ({
                        ...state,
                        user: newUser,
                      }))

                      actions.resetForm({ values: newUser })
                      toast.success(t`Saved`)
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors({})
                      }
                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({
                    isSubmitting,
                    isValid,
                    dirty,
                    resetForm,
                    setFieldValue,
                  }) => (
                    <Form>
                      <h1>
                        <Trans>Personal details</Trans>
                      </h1>
                      <p className="text-muted">
                        <Trans>
                          Please fill out the following fields for your profile.
                        </Trans>
                      </p>

                      <Field
                        id="email"
                        type="email"
                        name="email"
                        component={Input}
                        label={t`Email`}
                        disabled
                        addon={
                          <InputGroupText>
                            <i className="far fa-envelope" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Field
                        id="firstName"
                        type="text"
                        name="firstName"
                        component={Input}
                        label={t`First Name`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-arrow-right" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Field
                        id="lastName"
                        type="text"
                        name="lastName"
                        component={Input}
                        label={t`Last Name`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-arrow-right" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Field
                        id="img"
                        name="img"
                        label={
                          <p className="m-0">
                            Image URL
                            <span className="text-muted"> (optional)</span>
                          </p>
                        }
                        component={ImageInput}
                        onUploadSuccess={async (url) => {
                          if (user) {
                            const newImgUrl = await api.updateUser({
                              userDto: {
                                ...user,
                                img: url,
                              },
                            })

                            resetForm({ values: newImgUrl })
                            toast.success("Image uploaded")
                          }
                        }}
                      />

                      {user?.img && (
                        <FormGroup className="form-actions">
                          <Button
                            color="danger"
                            onClick={() => {
                              setFieldValue("img", null)
                            }}
                          >
                            Delete Image
                          </Button>
                        </FormGroup>
                      )}
                      <FormGroup className="form-actions">
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={!dirty || !isValid || isSubmitting}
                          color="primary"
                          block
                        >
                          <Trans>Submit</Trans>
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>

      <Row className="justify-content-center">
        <Col md="8">
          <FoldableBlock name="advanced settings">
            <CardGroup>
              <Card className="p-4">
                <CardBody>
                  <CardText className="h4" style={{ color: "red" }}>
                    <Trans>Danger Zone</Trans>
                  </CardText>
                  <Row>
                    <Col xs="12" md="8" className="mb-2">
                      <CardText>
                        <Trans>
                          If you want to delete the account, make sure there are
                          no other Users using it, no Resources, no active
                          Memberships atc.
                        </Trans>
                      </CardText>
                    </Col>
                    <Col xs="12" md="4" className="d-flex justify-content-end">
                      <Button
                        outline
                        color="danger"
                        onClick={confirmDeleteAccount}
                      >
                        <Trans>Delete Account</Trans>
                      </Button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </CardGroup>
          </FoldableBlock>
        </Col>
      </Row>
    </Container>
  )
}

UserForm.propTypes = {}

UserForm.defaultProps = {}
