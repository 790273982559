import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { UserListContainer } from "./user-list-container"
import { InviteForm } from "./invite-form"
import { UserTeamDetails } from "./user-team-details"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { WithUserRequired, WithAccountRequired, WithCompanyRequired } from "."

export function Users() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithCompanyRequired>
            <Switch>
              <Route
                exact
                path={[urls.team.list, urls.team.invites]}
                component={UserListContainer}
              />
              <Route path={urls.team.createInvite} component={InviteForm} />
              <Route path={urls.team.details} component={UserTeamDetails} />
            </Switch>
          </WithCompanyRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Users.propTypes = {}
Users.defaultProps = {}
