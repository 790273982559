import React from "react"
import { Route, Switch } from "react-router-dom"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { urls } from "../../urls"
import { WithAccountRequired, WithUserRequired } from "../account"
import { WithMembershipRequired } from "../membership/membership-provider"
import { ResourceDetails } from "./resource-details"
import { ResourceList } from "./resource-list"

export function Resources() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithMembershipRequired>
            <Switch>
              <Route path={urls.resource.details} component={ResourceDetails} />
              <Route path={urls.resource.list} component={ResourceList} />
            </Switch>
          </WithMembershipRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Resources.propTypes = {}
Resources.defaultProps = {}
