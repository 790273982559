import React, {
  useContext,
  useState,
  useMemo,
  useEffect,
  useCallback,
} from "react"
import PropTypes from "prop-types"
import { Sentry } from "../lib/sentry"
import { i18n } from "../lib/i18n"
import { getLanguageCode } from "../lib/helpers"
import { SYSTEM_LANGUAGE } from "../constants"

export const StoreContext = React.createContext(null)

export function useStore() {
  return useContext(StoreContext)
}

const defaultStore = {
  language: SYSTEM_LANGUAGE,
}

function resolveLanguageCode(language) {
  return language
    ? language === SYSTEM_LANGUAGE
      ? getLanguageCode()
      : language
    : getLanguageCode()
}

export function StoreProvider({ children }) {
  const [store, setStore] = useState(null)
  const [isLoading, setIsLoading] = useState(true)
  const [isError, setIsError] = useState(false)
  const [error, setError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        setIsLoading(true)

        let store = localStorage.getItem("store")

        if (!store) {
          store = {
            ...defaultStore,
          }

          setStore(store)
          setIsLoading(false)
          return
        }

        try {
          store = { ...defaultStore, ...JSON.parse(store) }
        } catch (err) {
          Sentry.captureException(err)
          store = defaultStore
        }

        setStore(store)
        setIsLoading(false)
      } catch (err) {
        setError(err)
        setIsError(true)
        setIsLoading(false)
      }
    })()
  }, [])

  const updateStore = useCallback(
    (val) => {
      const newStore = { ...store, ...val }

      localStorage.setItem("store", JSON.stringify(newStore))

      setStore(newStore)

      return newStore
    },
    [store],
  )

  const languageCode = useMemo(
    () => resolveLanguageCode(store?.language),
    [store?.language],
  )

  useEffect(() => {
    if (store?.language) {
      i18n.activate(languageCode)
    }
  }, [languageCode, store?.language])

  if (isLoading) {
    return null
  }

  if (isError) {
    throw error
  }

  const { language } = store

  return (
    <StoreContext.Provider
      value={{
        updateStore,
        store,
        language,
        languageCode,
      }}
    >
      {children}
    </StoreContext.Provider>
  )
}

StoreProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
