import React from "react"
import PropTypes from "prop-types"
import ReactDatePicker, { registerLocale } from "react-datepicker"
import enGb from "date-fns/locale/en-GB"

registerLocale("en-gb", enGb)

export const DatePickerFormless = ({
  id,
  selected,
  dateFormat,
  popperPlacement,
  onChange,
  customInput,
}) => {
  return (
    <div className="custom-datetimepicker-wrapper ml-2">
      <ReactDatePicker
        id={id}
        selected={selected}
        dateFormat={dateFormat}
        popperPlacement={popperPlacement}
        customInput={customInput}
        onChange={onChange}
        locale="en-gb"
      />
    </div>
  )
}

DatePickerFormless.defaultProps = {
  selected: new Date(),
  dateFormat: "d MM yyyy",
  popperPlacement: "bottom-end",
  onChange: () => {},
}

DatePickerFormless.propTypes = {
  id: PropTypes.string,
  selected: PropTypes.object,
  dateFormat: PropTypes.string,
  popperPlacement: PropTypes.string,
  onChange: PropTypes.func,
  customInput: PropTypes.object,
}
