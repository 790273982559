import React from "react"
import { useHistory } from "react-router-dom"
import { urls } from "../../urls"
import { reverse } from "named-urls"
import { CovidCertificateForm } from "./covid-certificate-form"

export function CovidCertificateCreate() {
  const history = useHistory()

  return (
    <CovidCertificateForm
      onSubmitSuccess={() =>
        history.length > 2
          ? history.goBack()
          : history.push(reverse(urls.covid.list))
      }
    />
  )
}

CovidCertificateCreate.propTypes = {}
CovidCertificateCreate.defaultProps = {}
