import { isLength } from "validator"
import { t } from "@lingui/macro"

export const otpwValidator = async (values) => {
  const errors = {}

  const maxOTPWOptions = { max: 10 }

  if (!values.otpw) {
    errors.otpw = t`Value is required`
  } else if (!isLength(values.otpw, maxOTPWOptions)) {
    errors.otpw = t`Value should be at most ${maxOTPWOptions.max} characters long`
  }

  return errors
}
