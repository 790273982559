import { Trans, t } from "@lingui/macro"
import {
  endOfToday,
  getUnixTime,
  isWithinInterval,
  startOfToday,
} from "date-fns"
import { zonedTimeToUtc } from "date-fns-tz"
import getDistance from "fast-haversine"
import humanizeDuration from "humanize-duration"
import { reverse } from "named-urls"
import qs from "qs"
import React, { useContext, useEffect, useState } from "react"
import { useAsync } from "react-async"
import { useHistory } from "react-router"
import { Link } from "react-router-dom"
import { toast } from "react-toastify"
import {
  Badge,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  CardImg,
  CardText,
  CardTitle,
  Col,
  Container,
  Spinner as RSpinner,
  Row,
} from "reactstrap"
import { usePosition } from "use-position"
import { BackButton } from "../../components/backbutton"
import { Button } from "../../components/button"
import { useConfirmation } from "../../components/confirmation-dialog"
import { toastError } from "../../components/error"
import { FormatRelativeDate } from "../../components/formatters/index"
import { Spinner } from "../../components/spinner"
import { USER } from "../../constants"
import { useParsedParams } from "../../hooks"
import { getUserFullName, priceFromCents, renderPrice } from "../../lib/helpers"
import { ApiContext } from "../../providers/api-provider"
import { urls } from "../../urls"
import { UserContext } from "../account/user-provider"
import { computePriceForBooking } from "../booking/helpers"
import { useMembership } from "../membership/membership-provider"
import { DAY } from "./constants"
import { getBookingDurationUnitName } from "./helpers"

function getIsNearby(distance, threshold) {
  if (!distance || !threshold) return null
  return distance < threshold
}

export function ResourceDetails() {
  const api = useContext(ApiContext)
  const history = useHistory()
  const { user: currentUser } = useContext(UserContext)
  const { resourceId } = useParsedParams({ resourceId: Number })
  const { selectedMembership } = useMembership()
  const [disabledCheckinBtn, setDisabledCheckinBtn] = useState(false)
  const [distance, setDistance] = useState(null)
  const confirm = useConfirmation()

  const {
    latitude: currentLatitude,
    longitude: currentLongitude,
    error: positionError,
  } = usePosition(false, { enableHighAccuracy: true })

  const {
    data: resource,
    error: resourceError,
    isPending: isResourcePending,
  } = useAsync({
    promiseFn: api.getResource,
    membershipId: selectedMembership?.id,
    resourceId,
    onResolve: async (data) => {
      if (!data) return

      await loadActiveCheckin({
        locationId: data.locationId,
        resourceId: data.id,
        eager: "[user,resource]",
      })

      await runGetBookingList({
        membershipId: selectedMembership?.id,
        locationId: selectedMembership?.locationId,
        resourceId,
        startAtLte: new Date(),
        endAtGte: new Date(),
      })

      await loadMyActiveCheckin({
        locationId: data.locationId,
      })
    },
  })

  const { data: checkinsData } = useAsync({
    promiseFn: api.getLocationCheckinsList,
    locationId: selectedMembership.locationId,
    eager: "[resource,user]",
  })

  const { data: bookingsData, run: runGetBookingList } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getBookingListByLocation(args)
      return data
    },
  })

  const {
    data: myActiveCheckin,
    // error: myActiveCheckinError,
    // isPending: isMyActiveCheckinPending,
    run: loadMyActiveCheckin,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getMyActiveCheckin(args)
      return data
    },
  })

  const {
    data: checkin,
    error: checkinError,
    isPending: isCheckinPeding,
    run: loadActiveCheckin,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getActiveResourceCheckin(args)
      return data
    },
  })

  const {
    data: covdocsData,
    isPending: covdocsIsPending,
    error: covdocsError,
  } = useAsync({
    promiseFn: api.getMyCovidCertificatesList,
    srcType: USER,
    srcId: currentUser.id,
    validUntilGte: startOfToday(),
  })

  useEffect(() => {
    if (currentLatitude && currentLongitude) {
      setDistance(
        getDistance(
          {
            lat: parseFloat(selectedMembership.location.latitude),
            lon: parseFloat(selectedMembership.location.longitude),
          },
          { lat: currentLatitude, lon: currentLongitude },
        ),
      )
    }
  }, [currentLatitude, currentLongitude, selectedMembership, setDistance])

  const error = resourceError || checkinError || covdocsError
  if (error) {
    throw error
  }

  if (isResourcePending || isCheckinPeding || covdocsIsPending) {
    return <Spinner />
  }

  if (!resource) {
    return <p>Resource is not found !</p>
  }

  const confirmUploadCertificate = () =>
    confirm({
      title: t`COVID-19 certificate required`,
      content: t`You have to upload a COVID-19 Test or Certificate to be able to check-in. Open 'Covid-19' page in the Profile Menu.`,
      color: "info",
      variant: "alert",
      onConfirm: () => history.push(reverse(urls.covid.list)),
    })

  async function verifyRequiredCovidCertificate(rs, cb) {
    if (
      selectedMembership.location?.requireCovidCertificates &&
      covdocsData.documents.length === 0
    ) {
      confirmUploadCertificate()
    } else {
      await cb(rs)
    }
  }

  async function bookDailyAndChecking(rs) {
    const bookingDto = {
      title: "",
      description: "",
      resourceId: rs.id,
      membershipId: selectedMembership.id,
      durationUnit: DAY,
      startAt: zonedTimeToUtc(
        startOfToday(),
        selectedMembership.location.timezone,
      ),
      endAt: zonedTimeToUtc(endOfToday(), selectedMembership.location.timezone),
      timezone: selectedMembership.location.timezone,
    }

    bookingDto.price = computePriceForBooking({
      booking: bookingDto,
      resource: rs,
    })

    try {
      await api.createBooking({
        bookingDto,
        membershipId: selectedMembership.id,
      })

      await api.createCheckin({
        locationId: rs.locationId,
        resourceId: rs.id,
      })

      toast.success(t`Checked-in`)
    } catch (err) {
      toastError(err)
    } finally {
      setDisabledCheckinBtn(false)
    }
  }

  async function checkIn(rs) {
    try {
      setDisabledCheckinBtn(true)

      await api.createCheckin({
        locationId: rs.locationId,
        resourceId: rs.id,
      })

      toast.success(t`Checked-in`)

      setTimeout(() => history.go(0), 1000)
    } catch (error) {
      toastError(error)
    } finally {
      setDisabledCheckinBtn(false)
    }
  }

  const checkins = checkinsData?.checkins ?? []

  const currentBookings = (bookingsData?.bookings ?? []).filter((b) => {
    if (b.membershipId !== selectedMembership?.id) {
      return false
    }

    if (
      b.user?.id !== currentUser.id &&
      b.recurringBooking?.user?.id !== currentUser.id &&
      b.attendees.every((a) => a?.id !== currentUser.id)
    ) {
      return false
    }

    return true
  })

  // async function checkOut(rs) {
  //   try {
  //     setDisabledCheckinBtn(true)

  //     await api.updateCheckin({
  //       locationId: rs.locationId,
  //       resourceId: rs.id,
  //     })

  //     toast.success("Checked-out")

  //     await loadActiveCheckin({
  //       locationId: rs.locationId,
  //       resourceId: rs.id,
  //     })
  //   } catch (error) {
  //     toastError(error)
  //   } finally {
  //     setDisabledCheckinBtn(false)
  //   }
  // }

  const currentCheckin = checkins.find((c) => {
    return (
      c.userId === currentUser.id &&
      currentBookings.length > 0 &&
      currentBookings.every((b) =>
        isWithinInterval(new Date(c.createdAt), {
          start: new Date(b.startAt),
          end: new Date(b.endAt),
        }),
      )
    )
  })

  const isOnlyDayBookable =
    resource.resourceType.bookingDurationUnits.length === 1 &&
    resource.resourceType.bookingDurationUnits[0] === DAY

  const { radius, latitude, longitude } = selectedMembership.location
  const isCurrentUserChecked = Boolean(currentCheckin)
  const isAnotherUserChecked = checkin && checkin.userId !== currentUser.id
  const isCurrentUserCheckedElsewhere =
    myActiveCheckin && myActiveCheckin.resourceId !== resource.id

  const isNearby = distance ? getIsNearby(distance, parseFloat(radius)) : false
  const askGeolocation =
    selectedMembership.location?.isCheckinGeolocationRequired ?? false
  const geolocationVerified = askGeolocation && isNearby
  // const canCheckout = !askGeolocation || (geolocationVerified && !positionError)
  const canCheckin =
    !askGeolocation ||
    (!positionError &&
      currentLatitude &&
      currentLongitude &&
      geolocationVerified)

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl">
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>
                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText>{resource.name}</CardText>
                  </Col>
                </Row>
                <Row className="d-md-none d-sm-inline ml-0 mr-0">
                  <Col>{resource.name}</Col>
                </Row>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <Row>
                <Col xs="12" md="4">
                  <CardImg
                    src={resource.img || "https://via.placeholder.com/300"}
                    className="img-fluid"
                  />
                </Col>

                <Col xs="12" md="8">
                  <Row>
                    <Col xs="12">
                      <Trans>Type</Trans>: {resource.resourceType.name}
                    </Col>

                    <Col xs="12">
                      <strong>
                        <Trans>Bookable by</Trans>
                      </strong>
                      <ul>
                        {resource.resourceType.bookingDurationUnits.map((u) => (
                          <li key={u}>
                            {getBookingDurationUnitName(u)}
                            {selectedMembership.location.billingEnabled && (
                              <>
                                -{" "}
                                {renderPrice(
                                  priceFromCents(
                                    resource.prices.find(
                                      (p) => p.bookingDurationUnit === u,
                                    ),
                                  ),
                                )}
                              </>
                            )}
                          </li>
                        ))}
                      </ul>
                    </Col>

                    <Col xs="12">
                      {resource.description.split("\n").map((d, idx) => (
                        <p key={idx}>{d}</p>
                      ))}
                    </Col>

                    <Col xs="12">
                      {resource.resourceTags.length > 0 &&
                        resource.resourceTags.map((tag) => (
                          <Badge
                            key={tag.id}
                            style={{
                              backgroundColor: tag.backgroundColor,
                              color: tag.textColor,
                            }}
                            className="m-1"
                          >
                            {tag.name}
                          </Badge>
                        ))}
                    </Col>

                    <Col xs="12">
                      {Boolean(resource.timeBuffer) && (
                        <div className="font-sm mb-0">
                          <span className="text-muted">
                            <Trans>Time buffer</Trans>:{" "}
                          </span>
                          <span>{humanizeDuration(resource.timeBuffer)}</span>
                        </div>
                      )}
                    </Col>
                  </Row>
                </Col>
              </Row>

              <Row>
                <Col className="text-center mt-3">
                  {!positionError &&
                  !currentLatitude &&
                  !currentLongitude &&
                  askGeolocation ? (
                    <Row>
                      <Col xs="12">
                        <RSpinner />
                      </Col>
                      <Col xs="12">
                        <Trans>Getting your current location</Trans>...
                      </Col>
                    </Row>
                  ) : null}

                  {positionError && askGeolocation ? (
                    <Badge color="info">
                      <Trans>
                        To provide the checkin we need to know your current
                        location
                      </Trans>
                    </Badge>
                  ) : null}

                  {canCheckin &&
                  currentBookings.length > 0 &&
                  !currentCheckin ? (
                    <Button
                      color="primary"
                      onClick={() =>
                        verifyRequiredCovidCertificate(resource, checkIn)
                      }
                      disabled={disabledCheckinBtn}
                    >
                      <Trans>Check-in</Trans>{" "}
                      <i className="fas fa-arrow-circle-down text-white" />
                    </Button>
                  ) : null}

                  {currentBookings.length < 1 &&
                    (isOnlyDayBookable ? (
                      <Button
                        onClick={() =>
                          verifyRequiredCovidCertificate(
                            resource,
                            bookDailyAndChecking,
                          )
                        }
                        color="primary"
                        disabled={disabledCheckinBtn}
                      >
                        <Trans>Book now and Check-in</Trans>{" "}
                        <i className="fas fa-arrow-circle-down text-white" />
                      </Button>
                    ) : (
                      <Link
                        to={
                          reverse(urls.booking.create) +
                          qs.stringify(
                            {
                              rid: resource.id,
                              start: getUnixTime(new Date()),
                              source: "checkin",
                            },
                            { addQueryPrefix: true },
                          )
                        }
                      >
                        <Trans>Book now</Trans>
                      </Link>
                    ))}

                  {/* {isCurrentUserChecked &&
                  canCheckout ? (
                    <Button
                      color="danger"
                      onClick={() => checkOut(resource)}
                      disabled={disabledCheckinBtn}
                    >
                      Check-out{" "}
                      <i className="fas fa-arrow-circle-up text-white" />
                    </Button>
                  ) : null} */}

                  {isCurrentUserChecked && (
                    <Badge color="info">
                      <Trans>Checked-in</Trans>
                    </Badge>
                  )}

                  {!positionError &&
                  currentLatitude &&
                  currentLongitude &&
                  distance &&
                  askGeolocation &&
                  !isNearby &&
                  !isAnotherUserChecked &&
                  !isCurrentUserCheckedElsewhere ? (
                    <p>
                      <Trans>You are too far from the location!</Trans>
                      <br />
                      <span className="text-muted">
                        lat: {currentLatitude}
                        <br />
                        lon: {currentLongitude}
                        <br />
                        <Trans>distance</Trans>: {Math.floor(distance)} m
                      </span>
                    </p>
                  ) : null}

                  {askGeolocation && !distance && (!latitude || !longitude) ? (
                    <>
                      <p>
                        <Trans>
                          Cannot calculate the distance to the location!
                        </Trans>
                        <br />
                        <Trans>
                          Ask your Operator to check the Location settings to be
                          able to checkin and checkout.
                        </Trans>
                      </p>
                    </>
                  ) : null}

                  {/* {isCurrentUserCheckedElsewhere &&
                  !myActiveCheckinError &&
                  !isMyActiveCheckinPending ? (
                    <p>
                      You are checked-in on{" "}
                      <Link
                        to={reverse(urls.resource.details, {
                          resourceId: myActiveCheckin.resource.id,
                        })}
                      >
                        <strong>{myActiveCheckin.resource.name}</strong>
                      </Link>
                      . First you need to check-out there to check-in here.
                    </p>
                  ) : null} */}

                  {checkin && isAnotherUserChecked ? (
                    /**
                      TODO: verify the user is in my team to reveal the name
                       */
                    <p>
                      <Trans>
                        {getUserFullName(checkin.user)} is checked-in here
                      </Trans>
                    </p>
                  ) : null}
                </Col>
              </Row>
            </CardBody>

            <CardFooter>
              <CardText className="text-muted float-right">
                <Trans>Created</Trans>:{" "}
                <FormatRelativeDate date={new Date(resource.createdAt)} />
              </CardText>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

ResourceDetails.propTypes = {}
ResourceDetails.defaultProps = {}
