import React, { Component } from "react"
import PropTypes from "prop-types"
import hoistStatics from "recompose/hoistStatics"
import wrapDisplayName from "recompose/wrapDisplayName"
import { ErrorPage } from "../components/error"
import { Sentry } from "../lib/sentry"

export function withErrorBoundary() {
  return hoistStatics((SourceComponent) => {
    class ErrorBoundary extends Component {
      static propTypes = {
        ErrorComponent: PropTypes.func,
        renderError: PropTypes.func,
      }

      static defaultProps = {
        ErrorComponent: ErrorPage,
      }

      static displayName = wrapDisplayName(SourceComponent, "withErrorBoundary")

      state = {
        err: null,
      }

      componentDidCatch(err, info) {
        this.setState({ err })
        Sentry.withScope((scope) => {
          scope.setExtra("info", info)
          Sentry.captureException(err)
        })
      }

      render() {
        if (this.state.err) {
          const { renderError, ErrorComponent } = this.props
          return typeof renderError === "function" ? (
            renderError(this.state.err)
          ) : (
            <ErrorComponent err={this.state.err} />
          )
        }

        return <SourceComponent {...this.props} />
      }
    }

    return ErrorBoundary
  })
}

export const ErrorBoundary = withErrorBoundary()((props) => {
  return typeof props.children === "function"
    ? props.children()
    : props.children
})

ErrorBoundary.propTypes = {
  ErrorComponent: PropTypes.func,
  renderError: PropTypes.func,
}

ErrorBoundary.defaultProps = {
  ErrorComponent: ErrorPage,
}
