import React, { useContext, useState } from "react"
import { useAsync } from "react-async"
import { ApiContext } from "../../providers/api-provider"
import { Spinner } from "../../components/spinner"
import { BackButton } from "../../components/backbutton"
import { FileUploaderButtonForm } from "../../components/file-uploader-button-form"
import {
  ListGroup,
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
} from "reactstrap"
import { DocumentListItem } from "./components"
import { toast } from "react-toastify"
import { toastError } from "../../components/error"
import { MEMBERSHIP } from "../../constants"
import { useMembership } from "../membership"
import { lorem } from "faker"
import { useAccount } from "../account/account-provider"
import { t, Trans } from "@lingui/macro"

export function UserMembershipDocuments() {
  const api = useContext(ApiContext)
  const { selectedMembership } = useMembership()
  const { selectedAccount } = useAccount()
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [editingDocument, setEditingDocument] = useState(null)

  const {
    data: memDocsData,
    error,
    isPending,
    reload: reloadMembershipDocumentsList,
  } = useAsync({
    promiseFn: api.getDocumentsList,
    srcType: MEMBERSHIP,
    srcId: selectedMembership.id,
  })

  function onEditDocument(doc) {
    setEditingDocument(doc)
    setIsEditingMode(true)
  }

  async function onSaveDocument(newDoc) {
    try {
      await api.updateDocument({
        membershipId: selectedMembership.id,
        documentId: newDoc.id,
        documentDto: {
          title: newDoc.title,
          description: newDoc.description,
          isArchived: false,
          srcType: MEMBERSHIP,
          srcId: selectedMembership.id,
        },
      })
      reloadMembershipDocumentsList()
      toast.success(t`Saved`)
    } catch (error) {
      toastError(error)
    } finally {
      setEditingDocument(null)
      setIsEditingMode(false)
    }
  }

  async function onUploadDocument(url) {
    try {
      await api.createDocument({
        membershipId: selectedMembership.id,
        documentDto: {
          srcType: MEMBERSHIP,
          srcId: selectedMembership.id,
          url,
          title: lorem.slug(),
          description: new Date().toISOString() + "_" + selectedAccount.name,
        },
      })
      reloadMembershipDocumentsList()
      toast.success(t`Success`)
    } catch (err) {
      toastError(err)
    }
  }

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <CardTitle className="font-3xl" data-private>
                <BackButton />
                <Trans>Documents</Trans>
              </CardTitle>
              <FileUploaderButtonForm
                onUploadSuccess={onUploadDocument}
                acceptedTypesOf="text"
              />
            </CardHeader>
            <CardBody>
              <ListGroup>
                {memDocsData.count === 0 && <Trans>No documents</Trans>}
                {memDocsData.count > 0 &&
                  memDocsData.documents.map((doc) => {
                    return (
                      <DocumentListItem
                        key={doc.id}
                        actionsDisabled={
                          doc.isArchived ||
                          (isEditingMode && editingDocument.id !== doc.id)
                        }
                        archiveActionDisabled
                        isEditing={
                          isEditingMode && editingDocument.id === doc.id
                        }
                        document={doc}
                        onEdit={onEditDocument}
                        onSave={(newDoc) =>
                          onSaveDocument({ ...doc, ...newDoc })
                        }
                        onCancel={() => {
                          setEditingDocument(null)
                          setIsEditingMode(false)
                        }}
                      />
                    )
                  })}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserMembershipDocuments.propTypes = {}
UserMembershipDocuments.defaultProps = {}
