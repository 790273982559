import React from "react"
import PropTypes from "prop-types"
import { toast } from "react-toastify"
import { Formik, Form, Field } from "formik"
import { CLIENT_ERROR, MIME_TYPES_OF } from "../../constants"
import { FileUploaderInput } from "../input"
import { toastError } from "../error"

export function FileUploaderButtonForm({
  onUploadSuccess,
  showSuccessToast,
  acceptedTypesOf,
  buttonStyle,
}) {
  return (
    <Formik
      initialValues={{ fileUrl: "" }}
      onSubmit={async (values, actions) => {
        try {
          onUploadSuccess && onUploadSuccess(values.fileUrl)
          showSuccessToast && toast.success("Success")
        } catch (err) {
          if (err.problem === CLIENT_ERROR) {
            actions.setErrors({})
          }

          toastError(err)

          if (!err.isAxiosError) {
            throw err
          }
        } finally {
          actions.setSubmitting(false)
        }
      }}
    >
      {({ isSubmitting, setFieldValue, submitForm }) => (
        <Form>
          <Field
            id="fileUrl"
            name="fileUrl"
            component={FileUploaderInput}
            acceptedTypesOf={acceptedTypesOf}
            hideThumbnail
            description={false}
            outline
            onUploadSuccess={(url) => {
              setFieldValue("fileUrl", url)

              submitForm()
            }}
            disabled={isSubmitting}
            buttonStyle={buttonStyle}
          />
        </Form>
      )}
    </Formik>
  )
}

FileUploaderButtonForm.propTypes = {
  onUploadSuccess: PropTypes.func,
  showSuccessToast: PropTypes.bool,
  acceptedTypesOf: PropTypes.oneOf(Object.keys(MIME_TYPES_OF)),
  buttonStyle: PropTypes.object,
}

FileUploaderButtonForm.defaultProps = {
  acceptedTypesOf: "any",
  showSuccessToast: false,
  buttonStyle: {},
}
