import React, { useContext, useState, useRef } from "react"
import Select from "react-select"
import { stringify } from "qs"
import { useAsync } from "react-async"
import { intersection } from "lodash"
import { useHistory } from "react-router-dom"
import { reverse } from "named-urls"
import FullCalendar from "@fullcalendar/react"
import { fullCalendarLocales } from "../../lib/i18n"
import interactionPlugin from "@fullcalendar/interaction"
import resourceTimelinePlugin from "@fullcalendar/resource-timeline"
import {
  useQueryParam,
  StringParam,
  DelimitedNumericArrayParam,
  DateParam,
} from "use-query-params"
import bootstrapPlugin from "@fullcalendar/bootstrap"
import { useMembership } from "../membership"
import { ApiContext } from "../../providers/api-provider"
import { Spinner } from "../../components/spinner"
import { urls } from "../../urls"
import { HALF_HOUR, DAY } from "../resource"
import { tagsToSelectOptions, selectOptionsToTags } from "../resource/helpers"
import { addMilliseconds, subMilliseconds, getUnixTime } from "date-fns"
import {
  Container,
  Card,
  CardText,
  CardTitle,
  CardBody,
  CardHeader,
} from "reactstrap"
import { getCalendarEventName } from "../../lib/helpers"
import { FormatDate } from "../../components/formatters/index"
import { t, Trans } from "@lingui/macro"
import { useStore } from "../../providers"

const views = [
  "resourceTimelineDay",
  "resourceTimelineWeek",
  "resourceTimelineMonth",
]
const header = {
  left: "title",
  center: views.join(","),
  right: "prev,next today",
}
const plugins = [interactionPlugin, resourceTimelinePlugin, bootstrapPlugin]

export function BookingTimeline() {
  const { languageCode } = useStore()
  const api = useContext(ApiContext)
  const history = useHistory()
  const calendarRef = useRef(null)
  const { selectedMembership } = useMembership()
  let [calendarDefaultView, setCalendarDefaultView] = useQueryParam(
    "cview",
    StringParam,
  )
  let [calendarDefaultDate, setCalendarDefaultDate] = useQueryParam(
    "date",
    DateParam,
  )
  calendarDefaultDate = calendarDefaultDate ?? new Date()

  let [filterTagIds, setFilterTagIds] = useQueryParam(
    "tags",
    DelimitedNumericArrayParam,
  )

  filterTagIds = filterTagIds ?? []

  if (!views.includes(calendarDefaultView)) {
    calendarDefaultView = "resourceTimelineWeek"
  }

  const [slotDuration, setSlotDuration] = useState({ days: 1 })
  const [slotLabelFormat, setSlotLabelFormat] = useState({
    weekday: "short",
    day: "numeric",
  })

  function onViewChange(info) {
    const viewType = calendarRef?.current?.calendar.view.type

    if (viewType === "resourceTimelineDay") {
      setSlotDuration("00:30:00")
      // https://github.com/fullcalendar/fullcalendar/blob/f6b0acf48f3f1283b4d5511b1b2f4ce42a555344/packages/__tests__/src/view-render/slotDuration.ts
      setSlotLabelFormat({ hour: "2-digit", minute: "2-digit", hour12: false })
    }
    if (viewType === "resourceTimelineWeek") {
      setSlotDuration({ days: 1 })
      setSlotLabelFormat({ weekday: "short", day: "numeric" })
    }
    if (viewType === "resourceTimelineMonth") {
      setSlotDuration(null)
      setSlotLabelFormat({ weekday: "short", day: "numeric" })
    }

    runBookings({
      membershipId: selectedMembership.id,
      rangeEnd: 999,
      eager: "[membership.[account.[company]],price,user]",
    })

    setCalendarDefaultView(info.view.type)
  }

  const {
    data: tagsData,
    error: tagsError,
    isPending: tagsIsPending,
  } = useAsync({
    promiseFn: api.getResourceTagsList,
    membershipId: selectedMembership.id,
  })

  const {
    data: resourcesData,
    error: resourcesError,
    isPending,
  } = useAsync({
    promiseFn: api.getResourceList,
    membershipId: selectedMembership.id,
    bookingDurationUnits: [HALF_HOUR, DAY],
    eager: "[resourceType,resourceTags,prices]",
    onResolve: (data) => {
      if (data.count === 0) return

      runBookings({
        membershipId: selectedMembership.id,
        rangeEnd: 999,
        eager: "[membership.[account.[company]],price,user]",
      })
    },
  })

  const {
    data: bookingsData,
    error: bookingsError,
    run: runBookings,
  } = useAsync({
    deferFn: async ([args]) => {
      const view = calendarRef?.current?.calendar.view

      if (view) {
        args.startAtGte = view.activeStart
        args.endAtLte = subMilliseconds(view.activeEnd, 1)
      }

      const data = await api.getBookingList(args)

      return data
    },
  })

  if (resourcesError) throw resourcesError
  if (bookingsError) throw bookingsError

  if (isPending) {
    return <Spinner />
  }

  const bookings = bookingsData?.bookings ?? []
  const { resources, count: resourcesCount } = resourcesData

  function hasAccessToBooking(booking) {
    return (
      !selectedMembership.location.privacyMode ||
      booking.membershipId === selectedMembership.id
    )
  }

  function transformResource(r) {
    const resource = {
      id: r.id,
      title: r.name,
      classNames: [],
      groupName: r.resourceType.name,
    }
    return resource
  }

  function onEventClick({ event }) {
    const booking = bookings.find((b) => b.id === parseInt(event.id, 10))

    if (!booking || !hasAccessToBooking(booking)) {
      return
    }

    history.push(
      reverse(urls.booking.details, {
        bookingId: booking.id,
      }),
    )
  }

  function onEventSelect(data) {
    // filtering the selected resource from a set of resources
    const res = resourcesData.resources.find(
      (r) => r.id === parseInt(data.resource._resource.id, 10),
    )
    // setSelectedResource(res)
    const qp = stringify(
      {
        start: getUnixTime(data.start),
        end: getUnixTime(subMilliseconds(data.end, 1)),
        allDay: data.allDay,
        rid: res.id, // updating the resource id directly using the selected resource
        // rid: bookings.find((b) => b.id === data.id).resourceId,
      },
      { addQueryPrefix: true },
    )

    history.push(reverse(urls.booking.create) + qp)
  }

  function transformEvent(b) {
    const event = {
      id: b.id,
      title:
        selectedMembership.id === b.membershipId ||
        !selectedMembership.location.privacyMode
          ? getCalendarEventName(b)
          : t`Busy`,
      start: new Date(b.startAt),
      end: addMilliseconds(new Date(b.endAt), 1),
      editable: true,
      textColor: "white",
      classNames: [],
      display: b.display || "auto",
      resourceId: b.resourceId,
    }

    return event
  }

  async function onDatesChange(info) {
    await runBookings({
      membershipId: selectedMembership.id,
      rangeEnd: 999,
      startAtGte: info.view.activeStart,
      endAtLte: subMilliseconds(info.view.activeEnd, 1),
      eager: "[membership.[account.[company]],price,user]",
    })

    const view = calendarRef?.current?.calendar.view

    if (view) {
      setCalendarDefaultDate(view.activeStart)
    } else {
      setCalendarDefaultDate(info.view.activeStart)
    }
  }

  function filterResourcesByTags(resource) {
    const filterTagIds = filterTags.map((t) => t.id)
    return filterTagIds.length > 0
      ? intersection(
          resource.resourceTags.map((rt) => rt.id),
          filterTagIds,
        ).length === filterTagIds.length
      : true
  }

  if (resourcesCount < 1) {
    return (
      <Card>
        <CardBody>
          <p className="lead">
            <i className="far fa-lightbulb" />{" "}
            <Trans>
              You do not have permissions to book on a given resources. Please
              contact your Administrator.
            </Trans>
          </p>
        </CardBody>
      </Card>
    )
  }

  const filterTags = (tagsData?.resourceTags ?? []).filter((t) =>
    filterTagIds.includes(t.id),
  )

  return (
    <Container fluid className="py-4">
      <Card>
        <CardHeader>
          <CardTitle className="mb-0">
            <CardText className="h3">
              {`${selectedMembership.location?.name + ":" ?? ""} `}
              <FormatDate date={new Date()} formatStr="MMM d, yyyy" />
            </CardText>
            <CardText className="text-muted">
              <Trans>Overview of your resources occupation.</Trans>
            </CardText>
          </CardTitle>
          <Select
            id="search-filter-tags"
            className="multi-select-input mt-2"
            onChange={(options) =>
              setFilterTagIds(
                (options?.map(selectOptionsToTags) || []).map((t) => t.id),
              )
            }
            isClearable
            isMulti
            styles={{
              menu: (provided, state) => ({
                ...provided,
                zIndex: 2,
              }),
            }}
            isLoading={tagsIsPending}
            disabled={tagsError}
            options={tagsData?.resourceTags?.map(tagsToSelectOptions) || []}
            value={filterTags.map(tagsToSelectOptions) || []}
            placeholder={t`Filter by tags`}
          />
        </CardHeader>
        <CardBody className="custom-timeline custom-calendar">
          <FullCalendar
            ref={calendarRef}
            themeSystem="bootstrap"
            schedulerLicenseKey="GPL-My-Project-Is-Open-Source"
            plugins={plugins}
            defaultView={calendarDefaultView}
            defaultDate={calendarDefaultDate}
            slotDuration={slotDuration}
            slotLabelFormat={slotLabelFormat}
            minTime="07:00:00"
            maxTime="24:00:00"
            firstDay={1}
            displayEventTime={false}
            header={header}
            events={bookings}
            resources={resources
              .filter(filterResourcesByTags)
              .map(transformResource)}
            resourceOrder="title"
            locale={fullCalendarLocales[languageCode]}
            resourceGroupField="groupName"
            resourcesInitiallyExpanded={false}
            height="auto"
            nowIndicator
            selectable
            slotEventOverlap={false}
            eventOverlap={false}
            eventDataTransform={transformEvent}
            resourceDataTransform={transformResource}
            select={onEventSelect}
            eventClick={onEventClick}
            viewSkeletonRender={onViewChange}
            datesDestroy={onDatesChange}
            eventAllow={() => false}
          />
        </CardBody>
      </Card>
    </Container>
  )
}
