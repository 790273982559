import { i18n } from "@lingui/core"
import { Trans, t } from "@lingui/macro"
import {
  addMilliseconds,
  differenceInMilliseconds,
  endOfDay,
  intervalToDuration,
  parseISO,
  startOfDay,
  startOfToday,
} from "date-fns"
import { reverse } from "named-urls"
import { uniqBy, when } from "ramda"
import React, { useContext, useState } from "react"
import { useAsync } from "react-async"
import ReactDatePicker from "react-datepicker"
import { Link } from "react-router-dom"
import Select from "react-select"
import {
  Badge,
  ButtonGroup,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Spinner as RSpinner,
  Row,
} from "reactstrap"
import {
  ArrayParam,
  DateParam,
  NumberParam,
  useQueryParam,
} from "use-query-params"
import { Button } from "../../components/button"
import {
  FormatDate,
  FormatDuration,
  FormatRelativeDate,
} from "../../components/formatters/index"
import { Spinner } from "../../components/spinner"
import { ACTIVE, BOOKING_STATUSES_OPTIONS } from "../../constants"
import { getUserFullName } from "../../lib/helpers"
import { ApiContext } from "../../providers/api-provider"
import { urls } from "../../urls"
import { UserContext } from "../account"
import { DatePickerCustomInput } from "../floorplan/floorplan"
import { useMembership } from "../membership/membership-provider"
import { DAY, HALF_HOUR } from "../resource"
import {
  getBookingBadgeTextAndStatusColor,
  selectOptionsToBookingStatus,
} from "./helpers"

export function TeammatesBookingList() {
  const api = useContext(ApiContext)
  const { selectedMembership } = useMembership()
  const { user } = useContext(UserContext)
  const [filterText, setFilterText] = useState("")
  const [selectedDateTime, setSelectedDateTime] = useQueryParam(
    "date",
    DateParam,
  )
  const [selectedResourceId, setSelectedResourceId] = useQueryParam(
    "resourceId",
    NumberParam,
  )
  let [filterStatuses, setFilterStatuses] = useQueryParam("status", ArrayParam)
  filterStatuses = filterStatuses ?? []

  const [summarizeRecurring, setSummarizeRecurring] = useState(false)
  const [selectedMembershipId, setSelectedMembershipId] = useQueryParam(
    "membershipId",
    NumberParam,
  )

  const {
    data: membershipsData,
    error: membershipsError,
    isPending: membershipsIsPending,
    run: runMemberships,
  } = useAsync({
    deferFn: ([args]) => api.getMembershipListByLocation(args),
    locationId: selectedMembership.locationId,
    status: ACTIVE,
    eager: "account.[users,company]",
    onResolve: async (data) => {
      if (data.count === 0) return

      await runTeamBookings({
        locationId: selectedMembership.locationId,
        membershipId: selectedMembershipId,
        startAtGte: startOfDay(selectedDateTime ?? new Date()),
        endAtLte: selectedDateTime ? endOfDay(selectedDateTime) : undefined,
        resourceId: selectedResource?.value,
        rangeStart: 0,
        rangeEnd: 999,
      })
    },
  })

  const { data: resourcesData } = useAsync({
    promiseFn: api.getResourceList,
    membershipId: selectedMembership.id,
    bookingDurationUnits: [HALF_HOUR, DAY],
    eager: "[resourceType,prices,resourceTags,resourceUserRoles]",
    onResolve: async (data) => {
      if (data.count === 0) return

      await runMemberships({
        locationId: selectedMembership.locationId,
        status: ACTIVE,
        eager: "account.[users,company]",
      })
    },
  })

  const {
    data: bookingsData,
    error: bookingsError,
    isPending: isBookingsPending,
    run: runTeamBookings,
  } = useAsync({
    deferFn: async ([args]) => {
      let data
      if (!args.membershipId) {
        data = await api.getBookingListByLocation(args)
      } else {
        data = await api.getTeamBookingList(args)
      }
      return data
    },
  })

  const err = bookingsError || membershipsError
  if (err) throw err

  function filterThroughUsers(booking) {
    // TODO: update this function after extended to multiple attendees
    const user = booking.user ?? booking.recurringBooking?.user
    const attendees = booking.attendees ?? booking.recurringBooking?.attendees

    return (
      getUserFullName(user).toLowerCase().includes(filterText) ||
      (user?.email ?? "").includes(filterText) ||
      attendees.some(
        (a) =>
          getUserFullName(a).toLowerCase().includes(filterText) ||
          (a.email ?? "").includes(filterText),
      )
    )
  }

  function statusFilter(booking) {
    if (filterStatuses.length === 0) return true

    return filterStatuses.includes(
      getBookingBadgeTextAndStatusColor(booking).status,
    )
  }

  function textFilter(booking) {
    return (
      booking.title.toLowerCase().includes(filterText) ||
      booking.description.toLowerCase().includes(filterText) ||
      filterThroughUsers(booking)
    )
  }

  function updateCurrentDate(date) {
    setSelectedDateTime(date)
    setSelectedMembershipId()

    return runTeamBookings({
      locationId: selectedMembership.locationId,
      membershipId: null,
      startAtGte: startOfDay(date ?? new Date()),
      endAtLte: date ? endOfDay(date) : undefined,
      resourceId: selectedResource?.value,
      rangeStart: 0,
      rangeEnd: 999,
    })
  }

  if (membershipsIsPending) {
    return <Spinner />
  }

  const { privacyMode } = selectedMembership.location

  let selectedResource = (resourcesData?.resources ?? []).find(
    (r) => r.id === selectedResourceId,
  )

  const membershipSelectorChoice = (membershipsData?.memberships ?? []).find(
    (m) => m.id === selectedMembershipId,
  )

  if (selectedResource) {
    selectedResource = {
      value: selectedResource.id,
      label: selectedResource.name,
    }
  }

  return (
    <Container className="py-4">
      <Row>
        <Col md={{ size: 10, offset: 1 }}>
          <Row className="mt-3">
            <Col xs="12" md="6" className="mb-3">
              <Input
                id="select-membership"
                type="select"
                value={
                  (membershipSelectorChoice && membershipSelectorChoice.id) ||
                  ""
                }
                disabled={
                  isBookingsPending || membershipsIsPending || privacyMode
                }
                onChange={(e) => {
                  const selectedItemId = parseInt(e.target.value, 10)
                  const newMembershipChoice = membershipsData.memberships.find(
                    (m) => m.id === selectedItemId,
                  )

                  setSelectedMembershipId(newMembershipChoice.id)
                  setFilterText("")

                  return runTeamBookings({
                    locationId: selectedMembership.locationId,
                    membershipId: newMembershipChoice
                      ? newMembershipChoice.id
                      : null,

                    startAtGte: startOfDay(selectedDateTime ?? new Date()),
                    endAtLte: selectedDateTime
                      ? endOfDay(selectedDateTime)
                      : undefined,
                    resourceId: selectedResource?.value,
                    rangeStart: 0,
                    rangeEnd: 999,
                  })
                }}
              >
                <option key={0} value="">
                  - {t`all`} -
                </option>
                {!privacyMode &&
                  (membershipsData?.memberships ?? []).map((m) => (
                    <option key={m.id} value={m.id}>
                      {m.name ||
                        (m.account.company && m.account.company.name) ||
                        getUserFullName(m.account.users[0])}
                    </option>
                  ))}
              </Input>
            </Col>

            <Col xs="12" md="6" className="mb-3 d-flex justify-content-end">
              <ButtonGroup>
                <Link to={reverse(urls.booking.create)}>
                  <Button color="primary">
                    <Trans>Book now</Trans>
                  </Button>
                </Link>

                <div className="custom-datetimepicker-wrapper ml-2">
                  <ReactDatePicker
                    id="filter-datetime-picker"
                    selected={selectedDateTime}
                    dateFormat="d MM yyyy"
                    popperPlacement="bottom-end"
                    customInput={<DatePickerCustomInput />}
                    onChange={(date) => updateCurrentDate(date)}
                  />
                </div>

                <Button
                  color="primary"
                  className="ml-2"
                  onClick={() => updateCurrentDate(startOfToday())}
                  disabled={
                    isBookingsPending ||
                    membershipsIsPending ||
                    +selectedDateTime === +startOfToday()
                  }
                >
                  <i className="far fa-calendar" /> <Trans>Today</Trans>
                </Button>

                <Button
                  color="primary"
                  className="ml-2"
                  onClick={() => updateCurrentDate(null)}
                  disabled={
                    isBookingsPending ||
                    membershipsIsPending ||
                    selectedDateTime === null
                  }
                >
                  <i className="far fa-calendar" /> <Trans>All upcoming</Trans>
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" className="mb-3 d-flex justify-content-end">
              <ButtonGroup>
                <Button
                  color="primary"
                  className="ml-2"
                  onClick={() => setSummarizeRecurring(!summarizeRecurring)}
                  disabled={isBookingsPending || membershipsIsPending}
                >
                  {summarizeRecurring ? (
                    <Trans>Show all bookings</Trans>
                  ) : (
                    <Trans>Summarize recurring bookings</Trans>
                  )}
                </Button>
              </ButtonGroup>
            </Col>
          </Row>

          <Row>
            <Col xs="12" md="4" className="mb-3">
              <Input
                id="search-filter-text"
                type="text"
                placeholder={t`Search by text`}
                value={filterText}
                onChange={(e) => setFilterText(e.target.value.toLowerCase())}
              />
            </Col>

            <Col xs="12" md="4" className="mb-3">
              <Select
                id="search-filter-status"
                className="multi-select-input"
                onChange={(options) =>
                  setFilterStatuses(
                    options?.map(selectOptionsToBookingStatus) || [],
                  )
                }
                isClearable
                isMulti
                options={BOOKING_STATUSES_OPTIONS.map((o) => ({
                  ...o,
                  label: i18n._(o.label),
                }))}
                value={filterStatuses
                  .map((s) =>
                    BOOKING_STATUSES_OPTIONS.find((bs) => s === bs.value),
                  )
                  .map((o) => ({
                    ...o,
                    label: i18n._(o.label),
                  }))}
                placeholder={t`Filter by status`}
                disabled={!selectedMembership || isBookingsPending}
              />
            </Col>

            <Col xs="12" md="4" className="mb-3">
              <Select
                id="search-resource-id"
                className="multi-select-input"
                onChange={(option) => {
                  setSelectedMembershipId()

                  runTeamBookings({
                    locationId: selectedMembership.locationId,
                    membershipId: null,
                    startAtGte: startOfDay(selectedDateTime ?? new Date()),
                    endAtLte: selectedDateTime
                      ? endOfDay(selectedDateTime)
                      : undefined,
                    resourceId: option?.value,
                    rangeStart: 0,
                    rangeEnd: 999,
                  })

                  return option
                    ? setSelectedResourceId(option.value)
                    : setSelectedResourceId()
                }}
                isClearable
                options={(resourcesData?.resources ?? []).map((r) => ({
                  value: r.id,
                  label: r.name,
                }))}
                value={selectedResource}
                placeholder={t`Filter by resource`}
                disabled={!selectedMembership || isBookingsPending}
              />
            </Col>
          </Row>

          {isBookingsPending && <RSpinner style={{ margin: "0 auto" }} />}

          {!isBookingsPending && bookingsData && bookingsData.count === 0 && (
            <Card>
              <CardBody>
                <p className="lead">
                  <i className="far fa-lightbulb" />{" "}
                  <Trans>There are no active bookings to show</Trans>
                </p>
              </CardBody>
            </Card>
          )}

          {!isBookingsPending && bookingsData && bookingsData.count > 0 && (
            <Row>
              <Col>
                <ListGroup>
                  {when(
                    () => summarizeRecurring,
                    (bookings) => [
                      ...uniqBy((b) => b.recurringBooking.id)(bookings),
                      ...bookingsData.bookings.filter(
                        (b) => !b.recurringBooking,
                      ),
                    ],
                  )(
                    bookingsData.bookings.filter((b) =>
                      summarizeRecurring ? Boolean(b.recurringBooking) : true,
                    ),
                  )
                    .filter(textFilter)
                    .filter(statusFilter)
                    .sort((fel, sel) => {
                      // sort in chronologic order top-to-bottom
                      return differenceInMilliseconds(
                        parseISO(fel.startAt),
                        parseISO(sel.startAt),
                      )
                    })
                    .map((booking) => {
                      const { color, text } =
                        getBookingBadgeTextAndStatusColor(booking)

                      const bookingUser = booking.user ??
                        booking.recurringBooking?.user ?? { id: 0 }

                      let isPrivate = false

                      if (
                        privacyMode &&
                        booking.membershipId !== selectedMembership.id
                      ) {
                        isPrivate = true
                      }

                      if (
                        booking.isPrivate &&
                        booking.userId !== user.id &&
                        booking.recurringBooking?.userId !== user.id
                      ) {
                        isPrivate = true
                      }

                      return (
                        <ListGroupItem className="mt-2" key={booking.id}>
                          <Row>
                            <Col xs="12" md="8">
                              <ListGroupItemHeading className="font-xl mb-2">
                                {booking.user?.img && (
                                  <img
                                    src={booking.user.img}
                                    alt="user img"
                                    width="50px"
                                    className="mr-2 rounded-circle"
                                  />
                                )}
                                {booking?.recurringBooking?.user?.img && (
                                  <img
                                    src={booking?.recurringBooking?.user?.img}
                                    alt="user img"
                                    width="50px"
                                    className="mr-2 rounded-circle"
                                  />
                                )}
                                <Link
                                  to={reverse(urls.booking.details, {
                                    bookingId: booking.id,
                                  })}
                                >
                                  {getUserFullName(bookingUser) || (
                                    <Trans>Private booking</Trans>
                                  )}
                                </Link>
                              </ListGroupItemHeading>
                            </Col>

                            {!summarizeRecurring && (
                              <Col xs="12" md="4">
                                <Badge
                                  className="mr-1 float-right membership-list-bdg-btn"
                                  color={color}
                                >
                                  {text}
                                </Badge>

                                {/* TODO: add button "join" after extended with multiple attendees */}
                              </Col>
                            )}
                          </Row>

                          <Row>
                            <Col>
                              <ListGroupItemText>
                                {booking.durationUnit === DAY ? (
                                  <FormatDate
                                    date={new Date(booking.startAt)}
                                    formatStr="PPPP"
                                  />
                                ) : (
                                  <FormatRelativeDate
                                    date={new Date(booking.startAt)}
                                  />
                                )}
                                <br />
                                <Trans>Duration</Trans>:{" "}
                                <FormatDuration
                                  duration={intervalToDuration({
                                    start: new Date(booking.startAt),
                                    end: addMilliseconds(
                                      new Date(booking.endAt),
                                      1,
                                    ),
                                  })}
                                />
                              </ListGroupItemText>
                            </Col>
                            <Col>
                              <ListGroupItemText>
                                <b>
                                  <Trans>Attendees</Trans>:
                                </b>{" "}
                                {!isPrivate ? (
                                  booking.attendees &&
                                  booking.attendees.length > 0 ? (
                                    booking.attendees.map((u) => (
                                      <Link
                                        key={u.id}
                                        to={reverse(urls.team.details, {
                                          userId: u.id,
                                        })}
                                      >
                                        {getUserFullName(u)}
                                      </Link>
                                    ))
                                  ) : (
                                    <Link
                                      to={reverse(urls.team.details, {
                                        userId: bookingUser.id,
                                      })}
                                    >
                                      {getUserFullName(bookingUser)}
                                    </Link>
                                  )
                                ) : (
                                  "-"
                                )}
                                <br />
                                <b>
                                  <Trans>Resource</Trans>:
                                </b>{" "}
                                <Link
                                  to={reverse(urls.resource.details, {
                                    resourceId: booking.resource.id,
                                  })}
                                >
                                  {booking.resource.name}
                                </Link>
                              </ListGroupItemText>
                            </Col>
                          </Row>

                          {booking.recurringBooking && (
                            <Row>
                              <Col>
                                <Badge className="mr-2" color="info">
                                  <Trans>Recurring</Trans>
                                </Badge>
                                <FormatDate
                                  date={
                                    new Date(booking.recurringBooking.startAt)
                                  }
                                  formatStr="PP"
                                />{" "}
                                -{" "}
                                <FormatDate
                                  date={
                                    new Date(
                                      booking.recurringBooking.endRecurringAtDay,
                                    )
                                  }
                                  formatStr="PP"
                                />
                              </Col>
                            </Row>
                          )}

                          {!isPrivate && (
                            <>
                              <ListGroupItemText>
                                {booking.description}
                              </ListGroupItemText>

                              <div className="text-muted float-right">
                                <Trans>Created</Trans>{" "}
                                <FormatRelativeDate
                                  date={new Date(booking.createdAt)}
                                />
                              </div>
                            </>
                          )}
                        </ListGroupItem>
                      )
                    })}
                </ListGroup>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  )
}

TeammatesBookingList.propTypes = {}
TeammatesBookingList.defaultProps = {}
