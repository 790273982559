import React, { useContext, useState } from "react"
import { useAsync } from "react-async"
import { ApiContext } from "../../providers/api-provider"
import { useMembership } from "../membership/membership-provider"
import { UserContext } from "./user-provider"
import { Spinner } from "../../components/spinner"
import { BackButton } from "../../components/backbutton"
import { DocumentListItem } from "./components"
import { getUserFullName } from "../../lib/helpers"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  CardFooter,
  ListGroup,
} from "reactstrap"
import { toastError } from "../../components/error"
import { toast } from "react-toastify"
import { FileUploaderButtonForm } from "../../components/file-uploader-button-form"
import { USER } from "../../constants"
import { t, Trans } from "@lingui/macro"

export function UserDocuments() {
  const api = useContext(ApiContext)
  const { user: currentUser } = useContext(UserContext)
  const { selectedMembership } = useMembership()
  const [isEditingMode, setIsEditingMode] = useState(false)
  const [editingDocument, setEditingDocument] = useState(null)

  const {
    data: documentsData,
    error,
    isPending,
    reload: reloadDocuments,
  } = useAsync({
    promiseFn: api.getDocumentsList,
    srcType: USER,
    srcId: currentUser.id,
  })

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  async function onSaveDocument(newDoc) {
    try {
      await api.updateDocument({
        membershipId: selectedMembership.id,
        documentId: newDoc.id,
        documentDto: {
          title: newDoc.title,
          description: newDoc.description,
          isArchived: false,
          srcType: USER,
          srcId: currentUser.id,
        },
      })
      reloadDocuments()
      toast.success(t`Saved`)
    } catch (error) {
      toastError(error)
    } finally {
      setEditingDocument(null)
      setIsEditingMode(false)
    }
  }

  async function onUploadDocument(url) {
    try {
      await api.createDocument({
        membershipId: selectedMembership.id,
        documentDto: {
          srcType: USER,
          srcId: currentUser.id,
          url,
          title: new Date().toISOString(),
          description:
            getUserFullName(currentUser) + "_" + new Date().toISOString(),
        },
      })
      reloadDocuments()
      toast.success(t`Success`)
    } catch (err) {
      toastError(err)
    }
  }

  function onEditDocument(doc) {
    setEditingDocument(doc)
    setIsEditingMode(true)
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl">
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>
                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText>
                      <Trans>My Documents</Trans>
                    </CardText>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <ListGroup>
                {documentsData.count === 0 && <Trans>No documents</Trans>}
                {documentsData.count > 0 &&
                  documentsData.documents.map((doc) => {
                    return (
                      <DocumentListItem
                        key={doc.id}
                        actionsDisabled={
                          doc.isArchived ||
                          (isEditingMode && editingDocument.id !== doc.id)
                        }
                        isEditing={
                          isEditingMode && editingDocument.id === doc.id
                        }
                        document={doc}
                        onEdit={onEditDocument}
                        onSave={(newDoc) =>
                          onSaveDocument({ ...doc, ...newDoc })
                        }
                        onCancel={() => {
                          setEditingDocument(null)
                          setIsEditingMode(false)
                        }}
                      />
                    )
                  })}
              </ListGroup>
            </CardBody>

            <CardFooter>
              <Row>
                <Col sm="2" className="text-right">
                  <FileUploaderButtonForm
                    onUploadSuccess={onUploadDocument}
                    acceptedTypesOf="any"
                  />
                </Col>
                <Col sm="10" className="d-flex align-items-center">
                  <CardText>
                    <Trans>Upload a new file</Trans>
                  </CardText>
                </Col>
              </Row>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserDocuments.propTypes = {}
UserDocuments.defaultProps = {}
