import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { CompanyForm } from "./company-form"
import { UserForm } from "./user-form"
import { WithUserRequired } from "./user-provider"
import { WithAccountRequired } from "./account-provider"
import { AccountForm } from "./account-form"
import { Users } from "./users"
import { UserMembershipDetails } from "./user-membership-details"
import { UserMembershipDocuments } from "./user-membership-documents"
import { UserDocuments } from "./user-documents"

export function AccountManagement() {
  return (
    <WithAccountRequired>
      <Switch>
        <Route path={urls.company} component={CompanyForm} />
        <Route path={urls.team.list} component={Users} />
      </Switch>
    </WithAccountRequired>
  )
}

export function Accounts() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <Switch>
          <Route
            exact
            path={urls.profile.membership.details}
            component={UserMembershipDetails}
          />
          <Route
            path={urls.profile.membership.documents}
            component={UserMembershipDocuments}
          />
          <Route path={urls.profile.user.documents} component={UserDocuments} />
          <Route exact path={urls.profile.update} component={UserForm} />
          <Route
            path={[urls.company, urls.team.list]}
            component={AccountManagement}
          />
          <Route path={urls.account.create} component={AccountForm} />
        </Switch>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Accounts.propTypes = {}
Accounts.defaultProps = {}
