import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { WithUserRequired, WithAccountRequired } from "../account"
import { WithMembershipRequired } from "../membership/membership-provider"
import { UserCheckinsList } from "./user-checkins-list"

export function Activity() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithMembershipRequired>
            <Switch>
              <Route
                path={urls.activity.checkins.list}
                component={UserCheckinsList}
              />
            </Switch>
          </WithMembershipRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Activity.propTypes = {}
Activity.defaultProps = {}
