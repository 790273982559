import React, { useContext, useState } from "react"
import PropTypes from "prop-types"
import { Field, Form, Formik } from "formik"
import { useAsync } from "react-async"
import { toast } from "react-toastify"
import {
  Card,
  CardBody,
  CardGroup,
  CardTitle,
  Row,
  Col,
  Container,
  FormGroup,
  InputGroupText,
  Label,
} from "reactstrap"
import { BackButton } from "../../components/backbutton"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import { Input, FileUploaderInput } from "../../components/input"
import { DatePicker } from "../../components/datepicker"
import { Spinner } from "../../components/spinner"
import { CLIENT_ERROR } from "../../constants"
import { ApiContext } from "../../providers/api-provider"
import { startOfToday, startOfTomorrow } from "date-fns"
import { covidCertificateFormValidator } from "./validators"
import { covidDocumentTypeOptions, TEST } from "./constants"
import { t, Trans } from "@lingui/macro"
import { formatDate } from "../../lib/helpers"

const CustomInputComponent = ({ value, onClick, color }) => (
  <Button color={color} onClick={onClick}>
    {value}
  </Button>
)

CustomInputComponent.propTypes = {
  value: PropTypes.any,
  onClick: PropTypes.func,
  color: PropTypes.string,
}
CustomInputComponent.defaultProps = {
  color: "primary",
}

async function getCovidCertificate({ documentId, api }) {
  if (!documentId) return null
  return api.getCovidCertificate({ documentId })
}

export function CovidCertificateForm({ documentId, onSubmitSuccess }) {
  const api = useContext(ApiContext)
  const minStartAtDay = startOfTomorrow()
  const [isDisclaimerConfirmed, setIsDisclaimerConfirmed] = useState(false)

  const {
    data: certificate,
    error,
    isPending,
  } = useAsync({
    promiseFn: getCovidCertificate,
    documentId,
    api,
  })

  const initialValues = {
    title:
      certificate?.document?.title ?? formatDate(new Date(), "PP, eeee, HH:mm"),
    description: certificate?.document?.description ?? "",
    type: certificate?.type ?? "TEST",
    documentUrl: certificate?.document?.url ?? "",
    validUntil: certificate ? new Date(certificate.validUntil) : new Date(),
  }

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <CardTitle className="font-3xl mb-0">
                <BackButton />
              </CardTitle>
              <CardBody>
                <Formik
                  initialValues={initialValues}
                  validate={covidCertificateFormValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      let newCertificate = null

                      if (certificate) {
                        newCertificate = await api.updateCovidCertificate({
                          documentId: certificate.id,
                          documentDto: values,
                        })
                      } else {
                        newCertificate = await api.createCovidCertificate({
                          documentDto: values,
                        })
                      }

                      onSubmitSuccess && onSubmitSuccess(newCertificate)
                      toast.success(t`Saved`)
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors(err.response.data)
                      }

                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({
                    isSubmitting,
                    isValid,
                    dirty,
                    values,
                    setFieldValue,
                  }) => {
                    return (
                      <Form>
                        <h1>
                          <Trans>COVID Certificate</Trans>
                        </h1>
                        <p className="text-muted">
                          <Trans>
                            Upload a Test result or Vaccine Certificate
                          </Trans>
                        </p>

                        <Field
                          id="title"
                          type="text"
                          name="title"
                          component={Input}
                          label={t`Document title`}
                        />

                        <Field
                          id="description"
                          type="text"
                          name="description"
                          component={Input}
                          label={
                            <p className="m-0">
                              <Trans>Notes</Trans>
                              <span className="text-muted">
                                {" "}
                                (<Trans>optional</Trans>)
                              </span>
                            </p>
                          }
                        />

                        <Field
                          id="type"
                          type="select"
                          name="type"
                          component={Input}
                          label={t`Type of document`}
                          addon={
                            <InputGroupText>
                              <i className="fas fa-file" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                          onChange={(e) => {
                            if (e.target.value === TEST) {
                              setFieldValue("validUntil", startOfToday())
                            }
                          }}
                        >
                          {covidDocumentTypeOptions.map((cc) => (
                            <option key={cc.code} value={cc.code}>
                              {cc.name}
                            </option>
                          ))}
                        </Field>

                        <Field
                          id="validUntil"
                          type="text"
                          name="validUntil"
                          label={t`Valid until date`}
                          component={DatePicker}
                          addon={
                            <InputGroupText>
                              <i className="far fa-calendar-check" />
                            </InputGroupText>
                          }
                          addonType="prepend"
                          minDate={minStartAtDay}
                          CustomInputComponent={CustomInputComponent}
                          dateFormat="MMM d, yyyy"
                          className="mr-3"
                        />

                        <Field
                          id="documentUrl"
                          name="documentUrl"
                          component={FileUploaderInput}
                          acceptedTypesOf="any"
                          label={t`File (scan) of the certificate or test as PDF or an image`}
                          description={false}
                          hideThumbnail
                          showDownloadLink
                          disabled={!values.title || certificate}
                          onUploadSuccess={async (url) => {
                            // let newCert
                            // if (certificate) {
                            //   newCert = await api.upsertCovidCertificate({
                            //     documentId: certificate.documentId,
                            //     documentDto: { ...values, documentUrl: url },
                            //   })
                            //   // updateLocationSuccess(newCert)
                            //   onSubmitSuccess && onSubmitSuccess(newCert)
                            //   toast.success("Saved")
                            // } else {
                            //   toast.info("Save a new certificate record")
                            // }
                          }}
                        />

                        <FormGroup>
                          <input
                            id="isDisclaimerConfirmed"
                            type="checkbox"
                            name="isDisclaimerConfirmed"
                            value={isDisclaimerConfirmed}
                            onChange={(e) => {
                              console.log(e.target.checked)
                              setIsDisclaimerConfirmed(e.target.checked)
                            }}
                          />
                          <Label for="isDisclaimerConfirmed" className="ml-2">
                            <Trans>Confirm personal liability</Trans>
                          </Label>

                          <p className="text-muted">
                            <Trans>
                              I confirm personal responsibility for the
                              information provided above and I will bear any
                              legal consequences in case of providing false
                              information in this form.
                            </Trans>
                          </p>
                        </FormGroup>

                        <FormGroup className="form-actions">
                          <Button
                            type="submit"
                            loading={isSubmitting}
                            block
                            disabled={
                              !isDisclaimerConfirmed ||
                              !dirty ||
                              !isValid ||
                              isSubmitting
                            }
                            color={
                              !isDisclaimerConfirmed ||
                              !dirty ||
                              !isValid ||
                              isSubmitting
                                ? "secondary"
                                : "primary"
                            }
                          >
                            <Trans>Save</Trans>
                          </Button>
                        </FormGroup>
                      </Form>
                    )
                  }}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

CovidCertificateForm.propTypes = {
  documentId: PropTypes.number,
  onSubmitSuccess: PropTypes.func,
}

CovidCertificateForm.defaultProps = {}
