import React from "react"
import PropTypes from "prop-types"
import { Row, Col } from "reactstrap"
import { Trans } from "@lingui/macro"
import { FormatDate } from "../../../components/formatters/index"

export default function MiniMembershipPlanInfoCard({ membershipPlan }) {
  return (
    <>
      <Row className="mb-2">
        <Col xs="12" className="mb-1">
          <Trans>Membership Plan</Trans>:
        </Col>
        <Col>{membershipPlan.name}</Col>
      </Row>
      <Row className="mb-2">
        <Col xs="12" className="mb-1">
          <Trans>Start date</Trans>:
        </Col>
        <Col>
          <FormatDate
            date={new Date(membershipPlan.startAt)}
            formatStr="dd/MM/yyyy"
          />
        </Col>
      </Row>
      <Row className="mb-2">
        <Col xs="12" className="mb-1">
          <Trans>End date</Trans>:
        </Col>
        <Col>
          {membershipPlan.endAt ? (
            <FormatDate
              date={new Date(membershipPlan.endAt)}
              formatStr="dd/MM/yyyy"
            />
          ) : (
            "-"
          )}
        </Col>
      </Row>
    </>
  )
}

MiniMembershipPlanInfoCard.propTypes = {
  membershipPlan: PropTypes.object,
}
