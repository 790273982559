import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { WithUserRequired, WithAccountRequired } from "../account"
import { WithMembershipRequired } from "../membership/membership-provider"
import { ServiceTicketCreate } from "./service-ticket-create"
import ServiceTicketSuccess from "./service-ticket-success"

export function Maintenance() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithMembershipRequired>
            <Switch>
              <Route
                path={urls.maintenance.serviceTickets.create}
                component={ServiceTicketCreate}
              />
              <Route
                path={urls.maintenance.serviceTickets.success}
                component={ServiceTicketSuccess}
              />
            </Switch>
          </WithMembershipRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Maintenance.propTypes = {}
Maintenance.defaultProps = {}
