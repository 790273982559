import { Trans, t } from "@lingui/macro"
import { Field, Form, Formik } from "formik"
import cookies from "js-cookie"
import PropTypes from "prop-types"
import React, { useContext } from "react"
import { toast } from "react-toastify"
import { FormGroup, InputGroupText } from "reactstrap"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import { Input } from "../../components/input"
import { config } from "../../config"
import { CLIENT_ERROR, MEMBER } from "../../constants"
import { ApiContext } from "../../providers/api-provider"
import { emailValidator } from "./email-validator"

export function LoginForm({ onSubmitSuccess }) {
  const api = useContext(ApiContext)

  return (
    <>
      <Formik
        initialValues={{ email: "" }}
        validate={emailValidator}
        onSubmit={async (values, actions) => {
          const isNecessaryCookies = cookies.get("rcl_consent_given")

          if (!isNecessaryCookies) {
            toast.error(
              t`Sorry, you must agree to our privacy policy before you can login.`,
              { autoClose: 60000 },
            )
            return
          }

          try {
            const { redirectToSSO } = await api.createOTPW({
              email: values.email,
            })

            if (redirectToSSO) {
              window.location.assign(
                `${
                  config.intertempi.api.url
                }/auth/oidc?email=${encodeURIComponent(
                  values.email,
                )}&clientType=${MEMBER}`,
              )
              return
            }

            onSubmitSuccess && onSubmitSuccess(values)
            toast.success(t`One time password sent! Please check your inbox.`)
          } catch (err) {
            if (err.problem === CLIENT_ERROR) {
              actions.setErrors(err.response.data)
            }

            toastError(err)

            if (!err.isAxiosError) {
              throw err
            }
          } finally {
            actions.setSubmitting(false)
          }
        }}
      >
        {({ isSubmitting, isValid, dirty }) => (
          <Form>
            <Trans>
              <h1>
                Welcome back to{" "}
                <span className="i-color-blue">Member Portal</span>
              </h1>
            </Trans>
            <p className="text-muted">
              <Trans>Enter your email to get your login code.</Trans>
            </p>

            <Field
              id="email-login"
              type="email"
              name="email"
              component={Input}
              label={t`Email`}
              addon={
                <InputGroupText>
                  <i className="far fa-envelope" />
                </InputGroupText>
              }
              addonType="prepend"
            />

            <FormGroup className="form-actions">
              <Button
                type="submit"
                loading={isSubmitting}
                disabled={!dirty || !isValid || isSubmitting}
                // color="primary"
                block
                style={{ backgroundColor: "#242F62", color: "#fff" }}
              >
                <Trans>Submit</Trans>
              </Button>
            </FormGroup>
          </Form>
        )}
      </Formik>
      <p className="text-center">
        <Trans>
          Login to{" "}
          <a
            className="i-color-blue"
            href={config.intertempi.operatorPortalPage.url}
          >
            Operator Portal
          </a>
        </Trans>
      </p>
    </>
  )
}

LoginForm.propTypes = {
  onSubmitSuccess: PropTypes.func,
}

LoginForm.defaultProps = {}
