import is from "@sindresorhus/is"
import { include } from "named-urls"
import { toPairs } from "ramda"

export const urls = buildUrls({
  covid: include("/covid", {
    list: "",
    create: "upload",
    edit: ":documentId",
  }),
  profile: include("/profile", {
    membership: include("/membership", {
      details: "",
      documents: "documents",
    }),
    update: "/update",
    user: include("/my", {
      documents: "documents",
    }),
  }),
  company: "/company",
  team: include("/team", {
    list: "",
    invites: "invites",
    createInvite: "create",
    details: ":userId",
    update: ":userId/update",
  }),
  booking: include("", {
    teammates: "bookings/teammates",
    calendar: "bookings/calendar",
    timeline: "bookings/timeline",
    create: "bookings/create",
    details: "bookings/:bookingId",
    update: "bookings/:bookingId/update",
  }),
  recurringBooking: include("/recurring-booking", {
    update: ":recurringBookingId/update",
  }),
  resource: include("/resources", {
    list: "",
    details: ":resourceId",
  }),
  account: include("/accounts", {
    list: "",
    create: "create",
  }),
  membershipPlan: include("/membership-plans", {
    details: ":membershipPlanId",
  }),
  billing: "/billing",
  floorplan: include("", {
    view: "",
  }),
  maintenance: include("", {
    serviceTickets: include("/tickets", {
      create: "create",
      success: "success",
    }),
  }),
  activity: include("", {
    checkins: include("checkins", {
      list: "",
    }),
  }),
})

function buildUrls(urls) {
  return toPairs(urls).reduce(
    (acc, [key, val]) => ({
      ...acc,
      [key]: is.string(val)
        ? val.length > 1 && val.endsWith("/")
          ? val.slice(0, -1)
          : val
        : buildUrls(val),
    }),
    urls,
  )
}
