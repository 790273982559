import React from "react"
import { BookingForm } from "./booking-form"
import { useHistory } from "react-router-dom"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import { useParsedParams } from "../../hooks"

export function RecurringBookingUpdate() {
  const history = useHistory()
  const { recurringBookingId } = useParsedParams({ recurringBookingId: Number })

  return (
    <BookingForm
      recurringBookingId={recurringBookingId}
      onSubmitSuccess={() => history.push(reverse(urls.booking.calendar))}
    />
  )
}

RecurringBookingUpdate.propTypes = {}
RecurringBookingUpdate.defaultProps = {}
