import React, { useState } from "react"
import { useAsync } from "react-async"
import { useConfirmation } from "../../components/confirmation-dialog"
// import { useHistory } from "react-router-dom"
// import { reverse } from "named-urls"
// import { urls } from "../../urls"
import { useAccount } from "./account-provider"
import { useApi } from "../../providers"
import { path } from "ramda"
import { Spinner } from "../../components/spinner"
import { Button } from "../../components/button"
import { useCompany } from "./company-provider"
import { useMembership, useMembershipInvite } from "../membership"
import { toast } from "react-toastify"
import { toastError } from "../../components/error"
import { getUserFullName, assert } from "../../lib/helpers"
import { ACCEPTED, DECLINED, MEMBER } from "../../constants"
import { Trans, t } from "@lingui/macro"
import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemText,
  ListGroupItemHeading,
  Container,
  Row,
  Col,
  CardHeader,
  ButtonGroup,
} from "reactstrap"
import { useBilling } from "../billing"

export function AccountList() {
  const { selectedAccount, setSelectedAccount, getAccountListSuccess } =
    useAccount()
  const { getCompany } = useCompany()
  const { getBilling } = useBilling()
  const { getMembershipList, selectMembership } = useMembership()
  const { reload: getMembershipInviteList } = useMembershipInvite()

  const [selectingAccountId, setSelectingAccountId] = useState(null)
  const [acceptingInviteId, setAcceptingInviteId] = useState(null)
  const [decliningInviteId, setDecliningInviteId] = useState(null)
  const api = useApi()
  // const history = useHistory()
  const confirm = useConfirmation()

  const {
    data: accountsData,
    error: accountsError,
    isPending: accountsIsPending,
    reload: reloadAccounts,
    counter: accountsCouner,
  } = useAsync({
    promiseFn: api.getAccountList,
    onResolve: ({ accounts, count }) => {
      return getAccountListSuccess({ accounts, count })
    },
  })

  const {
    data: invitesData,
    error: invitesError,
    isPending: invitesIsPending,
    reload: reloadInvites,
    counter: invitesCouner,
  } = useAsync({
    promiseFn: api.getMyInviteList,
  })

  if (accountsError) {
    throw accountsError
  }

  if (invitesError) {
    throw invitesError
  }

  if (
    (accountsIsPending && accountsCouner < 2) ||
    (invitesIsPending && invitesCouner < 2)
  ) {
    return <Spinner />
  }

  const { accounts, count: accountsCount } = accountsData
  const invites =
    invitesData?.invites?.filter((i) => i.account.types.includes(MEMBER)) ?? []

  const confirmCreateNewAccount = () =>
    confirm({
      title: t`Create New Organization-Account`,
      // content: `Are you sure you want to create a new empty Organization Account? If you have been invited on an account, double-check your email inbox and SPAM folder for the invitation. If you really want a completely new empty account, click confirm to proceed.`,
      content: t`If you have no invites, double-check your email inbox and SPAM folder for the invitation. If you did not receive an invite email, then contact your Location Operator.`,
      color: "danger",
      variant: "confirm",
      onConfirm: () => {
        // history.push(reverse(urls.account.create))
      },
      confirmButtonColor: "link",
      cancelButtonColor: "primary",
    })

  return (
    <Container className="py-4">
      <Row>
        <Col md={{ size: 5, offset: 1 }}>
          <Card>
            <CardHeader className="d-flex justify-content-between">
              <div>
                <i className="fas fa-align-justify" />{" "}
                <strong>
                  <Trans>Organizations</Trans>
                </strong>
              </div>
              <Button color="link" size="sm" onClick={confirmCreateNewAccount}>
                <Trans>Create New</Trans>
              </Button>
            </CardHeader>
            <CardBody>
              <ListGroup>
                {accountsCount > 0 && (
                  <strong>
                    <Trans>Select an organization to start.</Trans>
                  </strong>
                )}
                {accountsCount < 1 && (
                  <strong>
                    <Trans>
                      No organizations. Create a new one to get started.
                    </Trans>
                  </strong>
                )}
                {accounts.map((a) => (
                  <Button
                    key={a.id}
                    tag="button"
                    className="mt-2"
                    color="primary"
                    disabled={
                      a.id === path(["id"], selectedAccount) ||
                      Boolean(selectingAccountId)
                    }
                    onClick={async (e) => {
                      e.preventDefault()

                      try {
                        setSelectingAccountId(a.id)

                        await api.updateToken({ accountId: a.id })

                        try {
                          await Promise.all([
                            getCompany(),
                            getBilling(),
                            getMembershipList(),
                          ])
                        } catch (err) {
                          assert(path(["response", "status"], err) === 404, err)
                        }

                        selectMembership(null)
                        setSelectedAccount(a.id)
                        getMembershipInviteList()
                      } catch (err) {
                        setSelectingAccountId(null)
                        toastError(err)
                        assert(err.isAxiosError, err)
                      }
                    }}
                  >
                    {a.name || "Default"}
                  </Button>
                ))}

                <div className="mt-2">
                  <Trans>
                    If you need more accounts, please contact support.
                  </Trans>
                </div>
              </ListGroup>
            </CardBody>
          </Card>
        </Col>

        {invites.length !== 0 ? (
          <Col md={{ size: 5 }}>
            <Card>
              <CardHeader>
                <i className="fas fa-align-justify" />
                <strong>
                  <Trans>Invites</Trans>
                </strong>
              </CardHeader>
              <CardBody>
                <ListGroup>
                  {invites.map((i) => (
                    <ListGroupItem className="mt-2" key={i.id}>
                      <ListGroupItemHeading>
                        {i.account.name}
                      </ListGroupItemHeading>
                      <ListGroupItemText className="mb-0" data-private>
                        <Trans>Invited by</Trans>: {getUserFullName(i.user)} -{" "}
                        {i.user.email}
                      </ListGroupItemText>
                      <ButtonGroup className="mt-1 btn-block">
                        <Button
                          disabled={
                            Boolean(acceptingInviteId) ||
                            Boolean(decliningInviteId) ||
                            invitesIsPending
                          }
                          loading={acceptingInviteId === i.id}
                          onClick={async () => {
                            try {
                              setAcceptingInviteId(i.id)
                              await api.updateInvite({
                                inviteId: i.id,
                                inviteDto: { status: ACCEPTED },
                              })
                              toast.success(t`Invite accepted`)
                            } catch (err) {
                              toastError(err)
                              assert(err.isAxiosError, err)
                            } finally {
                              reloadInvites()
                              reloadAccounts()
                              setAcceptingInviteId(null)
                            }
                          }}
                          color="success"
                        >
                          <Trans>Accept</Trans>
                        </Button>
                        <Button
                          disabled={
                            Boolean(acceptingInviteId) ||
                            Boolean(decliningInviteId) ||
                            invitesIsPending
                          }
                          loading={decliningInviteId === i.id}
                          color="danger"
                          onClick={async () => {
                            try {
                              setDecliningInviteId(i.id)
                              await api.updateInvite({
                                inviteId: i.id,
                                inviteDto: { status: DECLINED },
                              })
                              toast.success(t`Invite declined`)
                            } catch (err) {
                              toastError(err)
                              assert(err.isAxiosError, err)
                            } finally {
                              reloadInvites()
                              reloadAccounts()
                              setDecliningInviteId(null)
                            }
                          }}
                        >
                          <Trans>Decline</Trans>
                        </Button>
                      </ButtonGroup>
                    </ListGroupItem>
                  ))}
                </ListGroup>
              </CardBody>
            </Card>
          </Col>
        ) : null}
      </Row>
    </Container>
  )
}

AccountList.propTypes = {}
AccountList.defaultProps = {}
