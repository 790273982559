import { Trans, t } from "@lingui/macro"
import { Field, Form, Formik } from "formik"
import PropTypes from "prop-types"
import { path } from "ramda"
import React, { useContext, useState } from "react"
import { FormGroup, InputGroupText } from "reactstrap"
import { useQueryParam } from "use-query-params"
import { Button } from "../../components/button"
import { toastError } from "../../components/error"
import { Input } from "../../components/input"
import { CLIENT_ERROR } from "../../constants"
import { Base64JsonParam, assert } from "../../lib/helpers"
import { useApi } from "../../providers/api-provider"
import { useBilling } from "../billing"
import { useMembership, useMembershipInvite } from "../membership"
import { AccountContext } from "./account-provider"
import { useCompany } from "./company-provider"
import { otpwValidator } from "./otpw-validator"
import { UserContext } from "./user-provider"

export function OTPWForm({ email }) {
  const [, setInvite] = useQueryParam("invite", Base64JsonParam)
  const api = useApi()
  const { getCompany } = useCompany()
  const { getBilling } = useBilling()
  const { getUser, setLoggingIn } = useContext(UserContext)
  const { getAccountList, setSelectedAccount } = useContext(AccountContext)
  const { getMembershipList } = useMembership()
  const { reload: getMembershipInviteList } = useMembershipInvite()
  const [pwdHidden, setPwdHidden] = useState(false)

  return (
    <Formik
      initialValues={{ otpw: "" }}
      validate={otpwValidator}
      onSubmit={async (values, actions) => {
        try {
          await api.createToken({
            email: email,
            password: values.otpw,
          })

          setLoggingIn(true)

          const [, accountsData, invitesData] = await Promise.all([
            getUser(),
            getAccountList(),
            api.getMyInviteList(),
          ])

          if (accountsData?.count === 1 && invitesData?.count === 0) {
            const account = accountsData.accounts[0]
            await api.updateToken({ accountId: account.id })

            try {
              await Promise.all([
                getCompany(),
                getBilling(),
                getMembershipList(),
              ])
            } catch (err) {
              assert(path(["response", "status"], err) === 404, err)
            }

            setSelectedAccount(account.id)
            getMembershipInviteList()
          }

          setInvite(null)
        } catch (err) {
          toastError(err)

          if (err.problem === CLIENT_ERROR) {
            actions.setErrors({
              otpw: t`Wrong code`,
            })
          }

          if (!err.isAxiosError) {
            throw err
          }
        } finally {
          actions.setSubmitting(false)
          setLoggingIn(false)
        }
      }}
    >
      {({ isSubmitting, isValid, dirty, submitForm, setFieldValue }) => (
        <Form>
          <h1>
            <Trans>Enter your One Time Password</Trans>
          </h1>
          <p className="text-muted">
            <Trans>Check your email Inbox</Trans>
          </p>

          <Field
            id="password"
            type={pwdHidden ? "password" : "text"}
            name="otpw"
            component={Input}
            label={t`One Time Password`}
            addon={
              <InputGroupText>
                <Button
                  color="link"
                  className="m-0 p-0"
                  onClick={() => setPwdHidden(!pwdHidden)}
                >
                  {pwdHidden ? (
                    <i className="far fa-eye-slash" />
                  ) : (
                    <i className="far fa-eye" />
                  )}
                </Button>
              </InputGroupText>
            }
            addonType="prepend"
            onChange={async (e) => {
              const code = e.target.value
              if (code.length === 6) {
                await setFieldValue("otpw", code)
                await submitForm()
              }
            }}
          />

          <FormGroup className="form-actions">
            <Button
              type="submit"
              loading={isSubmitting}
              disabled={!dirty || !isValid || isSubmitting}
              color="primary"
              block
            >
              <Trans>Submit</Trans>
            </Button>
          </FormGroup>
        </Form>
      )}
    </Formik>
  )
}

OTPWForm.propTypes = {
  email: PropTypes.string.isRequired,
}

OTPWForm.defaultProps = {}
