import { Trans } from "@lingui/macro"
import React from "react"
import { useHistory } from "react-router"
import { Container, Row, Col } from "reactstrap"
import { Button } from "../../components/button"
import { useAccount } from "../account"
import { useMembership } from "./membership-provider"

export default function MembershipDoesNotExist() {
  const { reset: resetMembership } = useMembership()
  const { setSelectedAccount } = useAccount()
  const history = useHistory()

  return (
    <Container className="py-4 h-100">
      <Row className="h-100">
        <Col sm="12" md={{ size: 6, offset: 3 }} className="align-self-center">
          <p className="lead">
            <i className="far fa-lightbulb" />{" "}
            <Trans>
              You have logged-in as a User but do not have registered account
              for this Portal. To continue using this Portal you have to ask an
              Operator to invite you.
            </Trans>
          </p>
          <Button
            color="primary"
            onClick={() => {
              setSelectedAccount(null)
              resetMembership()
              history.push("/")
            }}
          >
            <Trans>Accounts / Invites</Trans>
          </Button>
          <hr className="my-2" />
          <p>
            <Trans>
              If you wanted to use Operators Portal instead, please log-out and
              login into the Operators Portal application.
            </Trans>
          </p>
        </Col>
      </Row>
    </Container>
  )
}
