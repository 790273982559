import { i18n } from "@lingui/core"
import { en, de } from "make-plural/plurals"
import enLocale from "@fullcalendar/core/locales/en-gb"
import deLocale from "@fullcalendar/core/locales/de"
import { messages as enMessages } from "../locales/en/messages"
import { messages as deMessages } from "../locales/de/messages"

i18n.loadLocaleData({
  en: { plurals: en },
  de: { plurals: de },
})

i18n.load({
  en: enMessages,
  de: deMessages,
})

const fullCalendarLocales = {
  en: enLocale,
  de: deLocale,
}

export { i18n, fullCalendarLocales }
