import { path } from "ramda"
import React, { useContext } from "react"
import Floorplan from "./floorplan"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import {
  WithUserRequired,
  WithAccountRequired,
  WithCompanyRequired,
} from "../account"
import {
  MembershipContext,
  WithMembershipRequired,
} from "../membership/membership-provider"
import { WithBillingRequired } from "../billing"

export default function FloorplanContainer() {
  const { selectedMembership } = useContext(MembershipContext)

  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithCompanyRequired>
            <WithBillingRequired>
              <WithMembershipRequired>
                <Floorplan key={path(["id"], selectedMembership)} />
              </WithMembershipRequired>
            </WithBillingRequired>
          </WithCompanyRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}
