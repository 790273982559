import * as d3 from "d3"
import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { Spinner } from "../../components/spinner"
import {
  getColorByAvailability,
  getPointsMappedOnOccupancy,
} from "./helpers/index"

export default function FloorplanEditor({
  loading,
  selectedResource,
  onResourceMouseClick,
  points,
  resources,
  occupiedResources,
}) {
  const floorplanCanvasRef = useRef()
  const [zones, setZones] = useState([])
  const [selectedZones, setSelectedZones] = useState([])
  const [canvasBoundary, setCanvasBoundary] = useState()

  function addPolygonText(canvasBoundary, polygonNode, zone, cb) {
    const rect = polygonNode.getBoundingClientRect()

    const rectHalfWidth = Math.round((rect.right - rect.left) / 2)
    const rectHalfHeight = Math.round((rect.top - rect.bottom) / 2)

    const center = {
      x: rectHalfWidth + rect.left - canvasBoundary.left,
      y: rectHalfHeight + rect.top - canvasBoundary.top + window.scrollY,
    }

    const textNode = document.createTextNode(zone.name)
    const textEl = document.createElement("div")
    textEl.style.display = "none"
    textEl.appendChild(textNode)

    textEl.id = "zone-label-" + zone.id
    textEl.className = "zone-label"
    textEl.style.position = "absolute"
    textEl.style.left = center.x - rectHalfWidth + "px"
    textEl.style.top = center.y - rectHalfHeight + "px"
    textEl.style.whiteSpace = "pre"
    textEl.style.backgroundColor = "rgba(255, 255, 255, 0.5)"
    textEl.style.padding = "0.5em"
    textEl.style.border = "thick double #32a1ce"
    textEl.style.borderRadius = "1em"
    textEl.style["pointer-events"] = "none"

    if (cb) textEl.onclick = cb

    const labelsDiv = document.getElementById("floorplan-labels")
    labelsDiv.appendChild(textEl)

    const textElRect = textEl.getBoundingClientRect()

    textEl.style.left =
      parseInt(textEl.style.left) -
      (textElRect.right - textElRect.left) / 2 +
      "px"

    textEl.style.top =
      parseInt(textEl.style.top) -
      (textElRect.bottom - textElRect.top) / 2 +
      "px"

    textEl.addEventListener("mouseover", function () {
      this.style.display = "block"
    })
    textEl.addEventListener("mouseout", function () {
      this.style.display = "none"
    })
  }

  useEffect(() => {
    if (points.length >= 0 && resources.length >= 0) {
      setZones(getPointsMappedOnOccupancy(points, resources, occupiedResources))
    }
  }, [points, resources, occupiedResources])

  useEffect(() => {
    if (zones && zones.length >= 0) {
      setSelectedZones(zones)
    }
  }, [zones, selectedResource])

  useEffect(() => {
    d3.selectAll(".custom-timeline svg").remove()
    const labelsEl = document.getElementById("floorplan-labels")
    if (labelsEl) labelsEl.innerHTML = ""

    d3.select(floorplanCanvasRef.current)
      .append("svg")
      .attr("width", "100%")
      .attr("height", "200vh")
      .style("border", "1px solid black")

    const canvas = d3.select(floorplanCanvasRef.current)
    const svg = canvas.select("svg")

    if (!canvasBoundary || !canvasBoundary.left || !canvasBoundary.top) {
      const canvasWrapperEl = document.getElementById("floorplan-container")
      if (canvasWrapperEl) {
        const canvasWrapperRect =
          canvasWrapperEl && canvasWrapperEl.getBoundingClientRect()

        setCanvasBoundary({
          left: canvasWrapperRect.left,
          top: canvasWrapperRect.top,
        })
      }
    }

    for (let i = 0; i < selectedZones.length; i++) {
      const zone = selectedZones[i]

      if (zone.coordinates && zone.coordinates.length > 2) {
        const coords = [...zone.coordinates, zone.coordinates[0]]
          .map((p) => [p.x, p.y].join(","))
          .join(" ")

        const g = svg.append("g")
        const polygon = g.append("polygon")

        polygon
          .attr("id", zone.id)
          .attr("name", zone.name)
          .attr("points", coords)
          .style("fill", getColorByAvailability(zone.availability))
          .style("opacity", 0.5)
          .style("cursor", "pointer")

        const onClickCallback = () => onResourceMouseClick(zone.id)

        polygon.on("click", onClickCallback)

        // Show custom-made pure CSS tooltips following the mouse pointer
        polygon
          .on("mouseover", function () {
            try {
              document.getElementById("zone-label-" + zone.id).style.display =
                "block"
            } catch (err) {
              console.warn(err)
            }
          })
          .on("mouseout", function () {
            try {
              document.getElementById("zone-label-" + zone.id).style.display =
                "none"
            } catch (err) {
              console.warn(err)
            }
          })

        const polygonNode = polygon.node()

        if (polygonNode && canvasBoundary.left && canvasBoundary.top) {
          addPolygonText(
            canvasBoundary,
            polygonNode,
            selectedZones[i],
            onClickCallback,
          )
        }
      }
    }
  }, [selectedZones, onResourceMouseClick, canvasBoundary])

  if (loading || !points || !resources || !occupiedResources) return <Spinner />

  return (
    <>
      <div id="floorplan-labels" style={{ position: "absolute" }} />
      <div ref={floorplanCanvasRef} id="floorplan-canvas" />
    </>
  )
}

FloorplanEditor.defaultProps = {
  loading: false,
  zones: [],
  onResourceMouseClick: () => {},
}

FloorplanEditor.propTypes = {
  loading: PropTypes.bool,
  selectedResource: PropTypes.object,
  onResourceMouseClick: PropTypes.func,
  points: PropTypes.array.isRequired,
  resources: PropTypes.array.isRequired,
  occupiedResources: PropTypes.array.isRequired,
}
