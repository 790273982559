import React from "react"
import PropTypes from "prop-types"
import { Button as BButton } from "reactstrap"

export const Button = ({
  loading,
  type,
  className,
  disabled,
  outline,
  active,
  block,
  color,
  tag,
  innerRef,
  onClick,
  size,
  id,
  style,
  cssModule,
  children,
}) => {
  return (
    <BButton
      type={type}
      disabled={disabled}
      className={className}
      outline={outline}
      active={active}
      block={block}
      color={color}
      tag={tag}
      innerRef={innerRef}
      onClick={onClick}
      size={size}
      id={id}
      style={style}
      cssModule={cssModule}
    >
      {loading && (
        <>
          <span
            className="spinner-border spinner-border-sm"
            role="status"
            aria-hidden="true"
          />{" "}
        </>
      )}
      {children}
    </BButton>
  )
}

Button.defaultProps = {
  type: "button",
  className: "",
  disabled: false,
  loading: false,
}

Button.propTypes = {
  loading: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  className: PropTypes.string.isRequired,
  disabled: PropTypes.bool.isRequired,
  outline: PropTypes.bool,
  active: PropTypes.bool,
  block: PropTypes.bool,
  color: PropTypes.string,
  tag: PropTypes.string,
  innerRef: PropTypes.node,
  onClick: PropTypes.func,
  size: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.string,
  cssModule: PropTypes.object,
  children: PropTypes.node.isRequired,
}
