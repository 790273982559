import React, { useContext } from "react"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"
import { ListGroupItem, Row, Col, Badge, UncontrolledTooltip } from "reactstrap"
import { reverse } from "named-urls"
import { urls } from "../../../urls"
import { getUserFullName } from "../../../lib/helpers"
import { FormatDate } from "../../../components/formatters/index"
import { UserContext } from "../../account/user-provider"
import { useMembership } from "../../membership/membership-provider"
import { Trans } from "@lingui/macro"

export const UserCheckinListItem = ({ checkin }) => {
  const { user: currentUser } = useContext(UserContext)
  const { selectedMembership } = useMembership()

  const isCurrentUser = checkin && checkin.userId === currentUser.id
  const { privacyMode } = selectedMembership.location

  return (
    <ListGroupItem className="mb-2" key={checkin.id}>
      <Row>
        <Col className="i-flex-center-left" sm="3">
          {checkin.resource && (
            <Link
              to={reverse(urls.resource.details, {
                resourceId: checkin.resource.id,
              })}
            >
              <strong>{checkin.resource.name}</strong>
            </Link>
          )}
        </Col>

        <Col className="i-flex-center-left" sm="2">
          {isCurrentUser ? (
            <Trans>you</Trans>
          ) : privacyMode ? (
            <>
              <span>
                <Trans>another user</Trans>
              </span>
              <i
                id={"tooltip-" + checkin.id}
                className="far fa-question-circle ml-2"
              />
              <UncontrolledTooltip target={"tooltip-" + checkin.id}>
                <Trans>Location privacy mode is turned ON</Trans>
              </UncontrolledTooltip>
            </>
          ) : (
            <Link
              to={reverse(urls.team.details, {
                userId: checkin.user.id,
              })}
            >
              {getUserFullName(checkin.user)}
            </Link>
          )}
        </Col>

        <Col className="i-flex-center-left" sm="5">
          <FormatDate date={new Date(checkin.startAt)} formatStr="H:mm" />
          {/* {formatDate(new Date(checkin.startAt), "H:mm")} */}
          <FormatDate
            date={new Date(checkin.startAt)}
            formatStr="`,  PP (cccc)`"
          />
        </Col>

        <Col className="i-flex-center-left" sm="2">
          {/* {checkin.endAt ? (
            formatDuration(
              intervalToDuration({
                start: new Date(checkin.startAt),
                end: new Date(checkin.endAt),
              }),
            )
          ) : (
            <h4>
              <Badge color="info">checked-in</Badge>
            </h4>
          )} */}
          <h4>
            <Badge color="info">
              <Trans>checked-in</Trans>
            </Badge>
          </h4>
        </Col>
      </Row>
    </ListGroupItem>
  )
}

UserCheckinListItem.propTypes = {
  checkin: PropTypes.object.isRequired,
}
UserCheckinListItem.defaultProps = {}
