import React from "react"
import { useParsedParams } from "../../hooks"
import { useHistory } from "react-router-dom"
import { urls } from "../../urls"
import { reverse } from "named-urls"
import { CovidCertificateForm } from "./covid-certificate-form"

export function CovidCertificateUpdate() {
  const history = useHistory()
  const { documentId } = useParsedParams({ documentId: Number })

  return (
    <CovidCertificateForm
      documentId={documentId}
      onSubmitSuccess={() => history.push(reverse(urls.covid.list))}
    />
  )
}

CovidCertificateUpdate.propTypes = {}
CovidCertificateUpdate.defaultProps = {}
