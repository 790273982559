import React from "react"
import { BookingForm } from "./booking-form"
import { useParsedParams } from "../../hooks"
import { useHistory } from "react-router-dom"
import { urls } from "../../urls"
import { reverse } from "named-urls"

export function BookingUpdate() {
  const history = useHistory()
  const { bookingId } = useParsedParams({ bookingId: Number })

  return (
    <BookingForm
      bookingId={bookingId}
      onSubmitSuccess={(booking) =>
        history.push(reverse(urls.booking.details, { bookingId: booking.id }))
      }
    />
  )
}

BookingUpdate.propTypes = {}
BookingUpdate.defaultProps = {}
