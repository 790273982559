import { loadStripe } from "@stripe/stripe-js"
import {
  always,
  compose,
  curry,
  gte,
  head,
  ifElse,
  is,
  isNil,
  join,
  juxt,
  lensProp,
  map,
  path,
  pathSatisfies,
  prop,
  range,
  reduce,
  set,
  sortBy,
  tail,
  toLower,
  toUpper,
  pathOr,
} from "ramda"
import { isHexColor } from "validator"
import { config } from "../config"
import cssVarsMapping from "./css-variables-mapping"
import { EN_LANGUAGE, LANGUAGES, OWNER } from "../constants"
import {
  format,
  formatRelative,
  isPast,
  setDayOfYear,
  getDayOfYear,
  formatDuration as fnsFormatDuration,
  formatDistanceStrict as fnsFormatDistanceStrict,
} from "date-fns"
import * as locales from "date-fns/locale"

export function getTimezone() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone ?? "Europe/Berlin"
}

export function assert(val, err) {
  if (!val) throw err
}

export function option(key, value) {
  return value !== undefined ? { [key]: value } : {}
}

export function getActionType(...args) {
  return args.join("_")
}

export const isWithin = curry((min, max, value) => {
  const isNumber = is(Number)
  return (
    isNumber(min) &&
    isNumber(max) &&
    isNumber(value) &&
    gte(value, min) &&
    gte(max, value)
  )
})

export const in200s = isWithin(200, 299)
export const in400s = isWithin(400, 499)
export const in500s = isWithin(500, 599)
export const statusNil = ifElse(isNil, always(undefined), prop("status"))

export function isMethod(method, err) {
  return pathSatisfies(
    (m) => m === method.toLowerCase(),
    ["config", "method"],
    err,
  )
}

export function isStatus(status, err) {
  return pathSatisfies((s) => s === status, ["response", "status"], err)
}

export function isProblem(problem, err) {
  return pathSatisfies((s) => s === problem, ["problem"], err)
}

export function hasCode(err) {
  return Boolean(path(["code"], err))
}

export function hasProblem(err) {
  return Boolean(path(["problem"], err))
}

export const arrayToObject = curry((array, idProp) =>
  reduce((prev, curr) => set(lensProp(curr[idProp]), curr, prev), {}, array),
)

export function stopPropagation(fn) {
  return (e) => {
    e.stopPropagation()
    return fn(e)
  }
}

export function preventDefault(fn) {
  return (e) => {
    e.preventDefault()
    return fn(e)
  }
}

export function randomInteger(min, max) {
  return Math.round(min - 0.5 + Math.random() * (max - min + 1))
}

export function sample(array) {
  if (!array || array.length === 0) return null
  if (array.length === 1) return head(array)

  return array[randomInteger(0, array.length - 1)]
}

export function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new window.FileReader()

    reader.readAsDataURL(file)

    reader.onload = () => {
      resolve(reader.result)
    }

    reader.onabort = (error) => {
      reject(error)
    }

    reader.onerror = (error) => {
      reject(error)
    }
  })
}

export const capitalize = compose(
  join(""),
  juxt([compose(toUpper, head), tail]),
  toLower,
)

export function sortByCaseInsensitive(key, array) {
  return sortBy(compose(toLower, path(key)))(array)
}

export function decodeFromBase64(base64String) {
  if (!base64String) return null

  const jsonString = atob(base64String)

  try {
    const value = JSON.parse(jsonString)
    return value
  } catch {
    return null
  }
}

export function encodeToBase64(value) {
  if (!value) return null

  try {
    const jsonString = JSON.stringify(value)
    const base64String = btoa(jsonString)

    return base64String
  } catch {
    return null
  }
}

export const Base64JsonParam = {
  encode(value) {
    return encodeToBase64(value)
  },

  decode(strValue) {
    return decodeFromBase64(strValue)
  },
}

export function getUserFullName(user) {
  if (!user) return ""
  return getFullName(user.firstName, user.lastName)
}

export function getFullName(firstName, lastName) {
  if (!firstName && !lastName) return ""
  return `${firstName} ${lastName}`
}

export function getCalendarEventName(booking) {
  const username = getUserFullName(
    booking.user ?? booking.recurringBooking?.user,
  )

  const companyName = pathOr(
    null,
    ["membership", "account", "company", "name"],
    booking,
  )

  let title = username ? `${username} ` : ""
  if (companyName) title += `(${companyName})`

  return title.length > 0 ? title : "Busy"
}

export function isIntertempiEmail(email) {
  return email.includes("@intertempi.com")
}

export function isIntertempiUser(user) {
  return isIntertempiEmail(user.email)
}

export const rangeStep = (start, step, stop) =>
  map((n) => start + step * n, range(0, (1 + (stop - start) / step) >>> 0))

export function priceAmountToCents(amount) {
  return parseInt((amount * 100).toFixed(2), 10)
}

export function priceAmountFromCents(amount) {
  return amount / 100
}

export function priceToCents(price) {
  return {
    ...price,
    amount: priceAmountToCents(price.amount),
  }
}

export function priceFromCents(price) {
  return {
    ...price,
    amount: priceAmountFromCents(price.amount),
  }
}

export const priceZeroEur = {
  amount: 0,
  currency: "EUR",
}

export function isNonZeroPrice(price) {
  return price && price.amount && price.amount > 0
}

export function renderPrice({
  currency = "EUR",
  amount,
  locale = navigator.language,
}) {
  return new Intl.NumberFormat(locale, {
    style: "currency",
    currency: currency,
  }).format(amount)
}

export function getMembershipLocationAddress(membership) {
  if (membership && membership.location) {
    const { street, houseno, zip, city } = membership?.location

    if (street && houseno && zip && city) {
      return `${street} ${houseno}, ${zip} ${city}`
    }

    if (street && houseno && (!zip || !city)) {
      return `${street} ${houseno}`
    }

    if (street && city) {
      return `${street}, ${city}`
    }
  }

  return ""
}

export function getThemeVariable(varName) {
  return window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(cssVarsMapping[varName])
}

export function setThemeVariable(varName, value) {
  return document.documentElement.style.setProperty(
    cssVarsMapping[varName],
    value,
  )
}

export function getLanguageCode() {
  const code = navigator.language.slice(0, 2)

  if (LANGUAGES.includes(code)) {
    return code
  }

  return EN_LANGUAGE
}

const dateFormatLocales = Object.keys(locales)

function getDateFormattingLocale() {
  let language = navigator.language.slice(0, 2)

  language = language.replace("-", "")

  return dateFormatLocales.includes(language) ? language : "enGB"
}

export function formatDate(
  date,
  formatStr = "PP",
  locale = getDateFormattingLocale(),
) {
  if (typeof date === "string") {
    date = new Date(date)
  }

  return format(date, formatStr, {
    locale: locales[locale],
  })
}

export function formatRelativeDate(
  date,
  baseDate = new Date(),
  locale = getDateFormattingLocale(),
) {
  if (typeof date === "string") {
    date = new Date(date)
  }
  return formatRelative(date, baseDate, {
    locale: locales[locale],
  })
}

export function formatDuration(duration, locale = getDateFormattingLocale()) {
  return fnsFormatDuration(duration, { locale: locales[locale] })
}

export function formatDistanceStrict(date1, date2, options) {
  if (!options.locale) {
    options.locale = locales[getDateFormattingLocale()]
  }

  return fnsFormatDistanceStrict(date1, date2, options)
}

export function fetchStripe({ stripeUserId }) {
  return loadStripe(config.stripe.publishableKey, {
    stripeAccount: stripeUserId,
  })
}
export function hexToRgb(hex) {
  return hex
    .replace(
      /^#?([a-f\d])([a-f\d])([a-f\d])$/i,
      (m, r, g, b) => "#" + r + r + g + g + b + b,
    )
    .substring(1)
    .match(/.{2}/g)
    .map((x) => parseInt(x, 16))
}

/**
 * According to Luma Reg.709 docs
 * But represented as value in range [0, 1]
 * */
export function getColorBrightness(hex) {
  if (!isHexColor(hex)) return 0

  const [R, G, B] = hexToRgb(hex)
  const Y = 0.375 * R + 0.5 * G + 0.125 * B

  if (Y === 0) return 0
  return Y / 255
}

export function getReadableTextColor(hexColor) {
  return getColorBrightness(hexColor) > 0.5 ? "#000000" : "#ffffff"
}

/*!
 * Dynamically changing favicons with JavaScript
 * Works in all A-grade browsers except Safari and Internet Explorer
 * Demo: http://mathiasbynens.be/demo/dynamic-favicons
 */
export function changeFavicon({ faviconIco, favicon192, favicon512 }) {
  if (faviconIco) {
    let linkIco = document.querySelector("link[rel~='icon']")

    if (!linkIco) {
      linkIco = document.createElement("link")
      linkIco.rel = "shortcut icon"
      document.getElementsByTagName("head")[0].appendChild(linkIco)
    }

    linkIco.href = faviconIco
  }

  if (favicon192) {
    let link192 = document.querySelector("link[rel~='apple-touch-icon']")
    if (!link192) {
      link192 = document.createElement("link")
      link192.rel = "apple-touch-icon"
      document.getElementsByTagName("head")[0].appendChild(link192)
    }
    link192.href = favicon192
  }

  if (favicon512) {
    let link512 = document.querySelector("link[rel~='']")
    if (!link512) {
      link512 = document.createElement("link")
      link512.rel = ""
      document.getElementsByTagName("head")[0].appendChild(link512)
    }

    link512.href = favicon512
  }
}

export function selectOptionsToTags(tag) {
  return {
    id: tag.id || parseInt(tag.value),
    name: tag.name || tag.label,
    backgroundColor: tag.backgroundColor,
    textColor: tag.textColor,
  }
}

export function tagsToSelectOptions(tag) {
  return { value: tag.id, label: tag.name, ...tag }
}

export function getUserRolename(user, accountId) {
  if (!user || !user.roles || user.roles.length < 1) return ""
  return user.roles.find((role) => role.accountId === accountId).name
}

export function isAccountOwner(user, accountId) {
  return getUserRolename(user, accountId) === OWNER
}

export function modifyIfPastEventToToday(day) {
  const today = getDayOfYear(new Date())
  return isPast(day) ? setDayOfYear(day, today) : day
}

export function getLocationAddress({ street, houseno, zip, city }) {
  return `${street} ${houseno}, ${zip} ${city}`
}
