import React, { useContext } from "react"
import { useAsync } from "react-async"
import { useHistory, Link } from "react-router-dom"
import { ApiContext } from "../../providers/api-provider"
import { Spinner } from "../../components/spinner"
import { getUserFullName } from "../../lib/helpers"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
  Badge,
} from "reactstrap"
import { BackButton } from "../../components/backbutton"
import { Button } from "../../components/button"
import { ClipboardIconButton } from "../../components/clipboard-button"
import { CopyAndOpenLinkButtonGroup } from "../../components/copy-and-open-button-group"
import { urls } from "../../urls"
import { reverse } from "named-urls"
import { useMembership } from "../membership"
import { ACTIVE, MEMBERSHIP } from "../../constants"
import { getMembershipPlanStatusColor } from "../plan"
import { Trans } from "@lingui/macro"

export function UserMembershipDetails() {
  const api = useContext(ApiContext)
  const history = useHistory()
  const { selectedMembership } = useMembership()

  const {
    data: user,
    error: userError,
    isPending: userIsPending,
  } = useAsync({
    promiseFn: api.getMyUser,
  })

  const {
    data: membershipsData,
    error: membershipsError,
    isPending: membershipsIsPending,
  } = useAsync({
    promiseFn: api.getMembershipList,
    locationId: selectedMembership.locationId,
    status: ACTIVE,
    eager: "[location,membershipPlans,account.[users]]",
  })

  const {
    data: membershipDocumentsData,
    error: membershipDocumentsError,
    isPending: membershipDocumentsIsPending,
  } = useAsync({
    promiseFn: api.getDocumentsList,
    srcType: MEMBERSHIP,
    srcId: selectedMembership.id,
  })

  if (userError) throw userError
  if (membershipsError) throw membershipsError
  if (membershipDocumentsError) throw membershipDocumentsError

  if (userIsPending || membershipsIsPending || membershipDocumentsIsPending) {
    return <Spinner />
  }

  // only memberships which belong to my current account are received
  const { memberships } = membershipsData

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl" data-private>
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>
                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText className="text-truncate">
                      {getUserFullName(user)}
                    </CardText>
                  </Col>
                  <Col
                    xs="6"
                    md="3"
                    className="d-flex d-inline justify-content-end"
                  >
                    <Button
                      className="align-self-center mr-3"
                      color="primary"
                      onClick={() => history.push(reverse(urls.profile.update))}
                    >
                      <i className="fas fa-edit" />
                    </Button>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col md="6" className="mb-4">
                  <ListGroup className="mt-4">
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        <Trans>Teammates</Trans>
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    {/* [continued] ...thus only selected membership plans */}
                    {memberships
                      .filter(
                        (m) => m.locationId === selectedMembership.locationId,
                      )
                      .map((m) => m.account.users)
                      .flat()
                      .map((u) => (
                        <ListGroupItem key={u.id} className="p-2">
                          <ListGroupItemText className="m-0" data-private>
                            {getUserFullName(u)}
                            {` - ${u.email} `}
                            <ClipboardIconButton clipboardText={u.email} />
                          </ListGroupItemText>
                        </ListGroupItem>
                      ))}
                  </ListGroup>
                </Col>
                <Col md="6" className="mb-4">
                  <ListGroup className="mt-4">
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        <Row>
                          <Col>
                            <Trans>Documents</Trans>
                          </Col>
                          <Col xs="2" className="d-flex justify-content-end">
                            <Link
                              to={reverse(urls.profile.membership.documents)}
                            >
                              <Trans>edit</Trans>
                            </Link>
                          </Col>
                        </Row>
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    {membershipDocumentsData.documents.length > 0 ? (
                      membershipDocumentsData.documents.map((doc) => (
                        <ListGroupItem key={doc.id} className="p-2">
                          <ListGroupItemText
                            className="m-0 text-nowrap"
                            data-private
                          >
                            {doc.title}{" "}
                            {doc.isArchived && (
                              <Badge pill color="warning" className="ml-2">
                                <Trans>Archived</Trans>
                              </Badge>
                            )}{" "}
                            <CopyAndOpenLinkButtonGroup src={doc.url} />
                          </ListGroupItemText>
                        </ListGroupItem>
                      ))
                    ) : (
                      <ListGroupItem className="p-2">
                        <ListGroupItemText className="m-0" data-private>
                          <Trans>No files attached</Trans>
                        </ListGroupItemText>
                      </ListGroupItem>
                    )}
                  </ListGroup>{" "}
                </Col>
                <Col md="6" className="mb-4">
                  <ListGroup className="mt-4">
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        <Trans>Plans</Trans>
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    {memberships
                      .filter(
                        (m) => m.locationId === selectedMembership.locationId,
                      )
                      .map((m) => m.membershipPlans)
                      .flat()
                      .map((mplan) => (
                        <ListGroupItem key={mplan.id} className="p-2">
                          <Row>
                            <Col sm="9">
                              <Link
                                to={reverse(urls.membershipPlan.details, {
                                  membershipPlanId: mplan.id,
                                })}
                              >
                                {mplan.name}
                              </Link>
                            </Col>
                            <Col sm="3">
                              <Badge
                                className="p-1 float-right"
                                color={getMembershipPlanStatusColor(mplan)}
                              >
                                {mplan.status}
                              </Badge>
                            </Col>
                          </Row>
                        </ListGroupItem>
                      ))}
                  </ListGroup>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

UserMembershipDetails.propTypes = {}
UserMembershipDetails.defaultProps = {}
