import React, { useContext, useRef } from "react"
import { isNil } from "ramda"
import { toast } from "react-toastify"
import { useParsedParams } from "../../hooks"
import { isPast } from "date-fns"
import { useAsync } from "react-async"
import { ApiContext } from "../../providers/api-provider"
import { MembershipContext } from "../membership/membership-provider"
import { UserContext } from "../account"
import { Spinner } from "../../components/spinner"
import { useHistory } from "react-router-dom"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import { Button } from "../../components/button"
import { BackButton } from "../../components/backbutton"
import { useConfirmation } from "../../components/confirmation-dialog"
import { toastError } from "../../components/error"
import { DAY } from "../resource"
import { utcToZonedTime } from "date-fns-tz"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  CardFooter,
  UncontrolledTooltip,
  FormGroup,
  Label,
} from "reactstrap"
import {
  priceFromCents,
  renderPrice,
  getMembershipLocationAddress,
  getUserFullName,
} from "../../lib/helpers"
import {
  FormatRelativeDate,
  FormatDate,
} from "../../components/formatters/index"
import {
  isUserTimezoneDifferentFromLocationTimezone,
  getCalendarEventLinksFromBooking,
} from "./helpers"
import { recurringBookingRepeatPatternOptions } from "./constants"
import { t, Trans } from "@lingui/macro"

export function BookingDetails() {
  const history = useHistory()
  const api = useContext(ApiContext)
  const { user } = useContext(UserContext)
  const confirm = useConfirmation()
  const refOptionDeleteAll = useRef(null)
  const refOptionDeleteOne = useRef(null)

  const { bookingId } = useParsedParams({ bookingId: Number })
  const { selectedMembership } = useContext(MembershipContext)

  const {
    data: booking,
    error,
    isPending,
  } = useAsync({
    promiseFn: api.getBooking,
    membershipId: selectedMembership.id,
    bookingId,
  })

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  const { privacyMode } = selectedMembership.location

  const isMyBooking =
    booking.userId === user.id ||
    (booking.recurringBooking && booking.recurringBooking.userId === user.id)

  let isPrivate = false

  if (privacyMode && booking.membershipId !== selectedMembership.id) {
    isPrivate = true
  }

  if (
    booking.isPrivate &&
    booking.userId !== user.id &&
    booking.recurringBooking?.userId !== user.id
  ) {
    isPrivate = true
  }

  const alertContent = (
    <>
      <p>
        <Trans>Are you sure you want to delete the booking</Trans>
        {booking.title.length > 0 ? ` "${booking.title}"` : ""}?
      </p>
      {booking.recurringBookingId && (
        <>
          <FormGroup check disabled>
            <Label check>
              <input
                type="radio"
                name="deleteOption"
                ref={refOptionDeleteAll}
                value="all"
              />{" "}
              <Trans>All recurring booking instances</Trans>
            </Label>
          </FormGroup>
          <FormGroup check>
            <Label check>
              <input
                type="radio"
                name="deleteOption"
                ref={refOptionDeleteOne}
                value="one"
                defaultChecked
              />{" "}
              <Trans>Only this one</Trans>
            </Label>
          </FormGroup>
        </>
      )}
    </>
  )

  const confirmDeleteBooking = () =>
    confirm({
      title: t`Confirm Delete Booking`,
      content: alertContent,
      color: "danger",
      variant: "confirm",
      onConfirm: async () => {
        try {
          if (refOptionDeleteAll?.current?.checked) {
            // delete all occurances of recurring booking
            await api.deleteRecurringBooking({
              membershipId: selectedMembership.id,
              recurringBookingId: booking.recurringBookingId,
            })
          } else {
            // delete single instance of recurring booking
            await api.deleteBooking({
              membershipId: selectedMembership.id,
              bookingId: booking.id,
            })
          }

          history.length > 2
            ? history.goBack()
            : history.push(reverse(urls.booking.calendar))
        } catch (err) {
          if (err.response.data && err.response.data.error) {
            toast.error(err.response.data.error)
          } else {
            toastError(err)
          }
          if (!err.isAxiosError) {
            throw err
          }
        }
      },
    })

  const { googleUrl, outlookUrl, downloadIcal } =
    getCalendarEventLinksFromBooking({
      booking,
      location: getMembershipLocationAddress(selectedMembership),
    })

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl">
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>

                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText>
                      {booking.recurringBookingId && (
                        <small>
                          <i className="fas fa-history mr-3" />
                        </small>
                      )}
                      {booking.title.length > 0 ? (
                        booking.title
                      ) : (
                        <Trans>No description</Trans>
                      )}
                    </CardText>
                  </Col>
                  <Col
                    xs="6"
                    md="3"
                    className="d-flex d-inline justify-content-end"
                  >
                    {!isPrivate && isMyBooking ? (
                      <>
                        {booking.recurringBookingId ? (
                          <>
                            <Button
                              id="editRecurringBookingBtn"
                              className="align-self-center mr-3"
                              color="primary"
                              disabled={!booking.recurringBookingId}
                              onClick={() =>
                                history.push(
                                  reverse(urls.recurringBooking.update, {
                                    recurringBookingId:
                                      booking.recurringBookingId,
                                  }),
                                )
                              }
                            >
                              <i className="fas fa-history" />
                            </Button>
                            <UncontrolledTooltip
                              placement="top"
                              target="editRecurringBookingBtn"
                            >
                              <Trans>Edit Recurring Booking</Trans>
                            </UncontrolledTooltip>
                          </>
                        ) : null}
                        <>
                          <Button
                            id="editSingleBookingBtn"
                            className="align-self-center mr-3"
                            color="primary"
                            disabled={
                              isPast(new Date(booking.startAt)) ||
                              !isNil(booking.membershipPlanId) ||
                              (booking.stripeInvoice?.status &&
                                !["draft", "open"].includes(
                                  booking.stripeInvoice?.status,
                                ))
                            }
                            onClick={() =>
                              history.push(
                                reverse(urls.booking.update, {
                                  bookingId: booking.id,
                                }),
                              )
                            }
                          >
                            <i className="fas fa-edit" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="editSingleBookingBtn"
                          >
                            <Trans>Edit Booking</Trans>
                          </UncontrolledTooltip>
                        </>
                        <>
                          <Button
                            id="deleteBookingBtn"
                            className="align-self-center"
                            color="danger"
                            disabled={
                              !isNil(booking.membershipPlanId) ||
                              (booking.stripeInvoice?.status &&
                                !["draft", "open"].includes(
                                  booking.stripeInvoice?.status,
                                ))
                            }
                            onClick={confirmDeleteBooking}
                          >
                            <i className="fas fa-trash-alt" />
                          </Button>
                          <UncontrolledTooltip
                            placement="top"
                            target="deleteBookingBtn"
                          >
                            <Trans>Delete Booking</Trans>
                          </UncontrolledTooltip>
                        </>
                      </>
                    ) : null}
                  </Col>
                </Row>
                <Row className="d-md-none d-sm-inline ml-0 mr-0">
                  <Col>
                    {booking.title.length > 0 ? booking.title : "Booking"}
                  </Col>
                </Row>
              </CardTitle>
              {isUserTimezoneDifferentFromLocationTimezone(
                selectedMembership.location.timezone,
              ) && (
                <div className="text-muted">
                  <Trans>
                    Please pay attention that your timezone differs from the
                    location timezone.
                  </Trans>
                </div>
              )}
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="12" md="3" className="mb-4">
                  <strong>
                    <Trans>Booked Resource</Trans>
                  </strong>
                </Col>
                <Col xs="12" md="9" className="mb-4">
                  <p>{booking.resource.name}</p>
                  {booking.resource.description.length > 0 ? (
                    <p>{booking.resource.description}</p>
                  ) : null}
                </Col>
              </Row>

              {!isPrivate ? (
                <Row>
                  <Col xs="12" md="3" className="mb-4">
                    <strong>
                      <Trans>Description</Trans>
                    </strong>
                  </Col>
                  <Col xs="12" md="9" className="mb-4">
                    <p>{booking.description}</p>
                  </Col>
                </Row>
              ) : null}

              {!isPrivate && booking.attendees.length > 0 ? (
                <Row>
                  <Col xs="12" md="3" className="mb-4">
                    <strong>
                      <Trans>Attendees</Trans>
                    </strong>
                  </Col>
                  <Col xs="12" md="9" className="mb-4">
                    <ul style={{ listStyleType: "none" }} className="p-0">
                      {booking.attendees.map((u) => (
                        <li key={u.id}>{`${getUserFullName(u)} <${
                          u.email
                        }>`}</li>
                      ))}
                    </ul>
                  </Col>
                </Row>
              ) : null}

              <Row>
                <Col xs="3" className="mb-4">
                  <strong>
                    <Trans>From</Trans>
                  </strong>
                </Col>
                <Col xs="9" className="mb-4">
                  <FormatRelativeDate
                    date={
                      booking.durationUnit === DAY
                        ? utcToZonedTime(
                            new Date(booking.startAt),
                            selectedMembership.location.timezone,
                          )
                        : new Date(booking.startAt)
                    }
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-4">
                  <strong>
                    <Trans>Until</Trans>
                  </strong>
                </Col>
                <Col xs="9" className="mb-4">
                  <FormatRelativeDate
                    date={
                      booking.durationUnit === DAY
                        ? utcToZonedTime(
                            new Date(booking.endAt),
                            selectedMembership.location.timezone,
                          )
                        : new Date(booking.endAt)
                    }
                  />
                </Col>
              </Row>

              {!isPrivate && selectedMembership.location.billingEnabled ? (
                <Row>
                  <Col xs="3" className="mb-4">
                    <strong>
                      <Trans>Price</Trans>{" "}
                      {booking.recurringBookingId ? (
                        <>
                          {" "}
                          <Trans>per booking</Trans>
                        </>
                      ) : (
                        ""
                      )}
                    </strong>
                  </Col>
                  <Col xs="9" className="mb-4">
                    {renderPrice(priceFromCents(booking.price))}
                  </Col>
                </Row>
              ) : null}

              {!isPrivate && booking.recurringBookingId ? (
                <Row>
                  <Col xs="12" md="3" className="mb-4">
                    <strong>
                      <Trans>Recurring Booking Details</Trans>
                    </strong>
                  </Col>
                  <Col xs="12" md="9" className="mb-4">
                    {booking.recurringBooking.title.length > 0 ? (
                      <p>{booking.recurringBooking.title}</p>
                    ) : null}
                    {booking.recurringBooking.description.length > 0 ? (
                      <p>{booking.recurringBooking.description}</p>
                    ) : null}

                    {Boolean(booking.recurringBooking.endRecurringAtDay) && (
                      <Row>
                        <Col xs="3" className="mb-4">
                          <strong>
                            <Trans>Last occurence</Trans>
                          </strong>
                        </Col>
                        <Col xs="9" className="mb-4">
                          <FormatDate
                            date={
                              booking.durationUnit === DAY
                                ? utcToZonedTime(
                                    new Date(booking.endAt),
                                    selectedMembership.location.timezone,
                                  )
                                : new Date(
                                    booking.recurringBooking.endRecurringAtDay,
                                  )
                            }
                            formatStr="EEE, d.L.Y"
                          />
                        </Col>
                      </Row>
                    )}

                    <Row>
                      <Col xs="3" className="mb-4">
                        <strong>
                          <Trans>Repeats</Trans>
                        </strong>
                      </Col>
                      <Col xs="9" className="mb-4">
                        {
                          recurringBookingRepeatPatternOptions.find(
                            (rp) =>
                              rp.id === booking.recurringBooking.repeatPattern,
                          ).name
                        }
                      </Col>
                    </Row>
                  </Col>
                </Row>
              ) : null}

              {isMyBooking && Boolean(booking.stripeInvoice?.invoicePdf) ? (
                <Row>
                  <Col xs="12" md="3" className="mb-4">
                    <strong>
                      <Trans>Invoice</Trans>
                    </strong>
                  </Col>
                  <Col xs="12" md="9" className="mb-4">
                    <a
                      href={booking.stripeInvoice.hostedInvoiceUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Trans>Preview Invoice</Trans>
                    </a>
                    {" | "}
                    <a
                      href={booking.stripeInvoice.invoicePdf}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Trans>Download Invoice</Trans>
                    </a>
                  </Col>
                </Row>
              ) : null}
            </CardBody>

            {!isPrivate ? (
              <CardFooter>
                <Row>
                  <Col>
                    <CardText className="text-muted">
                      <Trans>Created</Trans>:{" "}
                      <FormatRelativeDate date={new Date(booking.createdAt)} />
                    </CardText>
                  </Col>
                  <Col className="d-flex d-inline justify-content-end">
                    <a
                      id="addToOutlookCalendarBtn"
                      href={outlookUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="align-self-center mr-3 text-muted"
                    >
                      <i className="fab fa-windows" />
                    </a>
                    <UncontrolledTooltip
                      placement="top"
                      target="addToOutlookCalendarBtn"
                    >
                      <Trans>Add to Outlook calendar</Trans>
                    </UncontrolledTooltip>

                    <a
                      id="addToGoogleCalendarBtn"
                      href={googleUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="align-self-center mr-3 text-muted"
                    >
                      <i className="fab fa-google" />
                    </a>
                    <UncontrolledTooltip
                      placement="top"
                      target="addToGoogleCalendarBtn"
                    >
                      <Trans>Add to Google calendar</Trans>
                    </UncontrolledTooltip>

                    <Button
                      id="downloadIcsFileBtn"
                      color="link"
                      className="p-0 text-muted"
                      onClick={downloadIcal}
                    >
                      <i className="fas fa-file-download" />
                    </Button>
                    <UncontrolledTooltip
                      placement="top"
                      target="downloadIcsFileBtn"
                    >
                      <Trans>Download ICalendar (ics) file</Trans>
                    </UncontrolledTooltip>
                  </Col>
                </Row>
              </CardFooter>
            ) : null}
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

BookingDetails.propTypes = {}
BookingDetails.defaultProps = {}
