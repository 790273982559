export const minAllowedBookingTime = 6
export const maxAllowedBookingTime = 21

export const REPEAT_DAILY = "DAY"
export const REPEAT_WEEKLY = "WEEK"
export const REPEAT_MONTHLY = "MONTH"

export const recurringBookingRepeatPatterns = [
  REPEAT_DAILY,
  REPEAT_WEEKLY,
  REPEAT_MONTHLY,
]

export const recurringBookingRepeatPatternOptions = [
  { id: REPEAT_DAILY, name: "Daily" },
  { id: REPEAT_WEEKLY, name: "Weekly" },
  { id: REPEAT_MONTHLY, name: "Monthly" },
]
