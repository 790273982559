import { I18nProvider } from "@lingui/react"
import "core-js"
import React from "react"
import { Route, Router, Switch } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import { QueryParamProvider } from "use-query-params"
import { AppLayout } from "./components/app-layout"
import { ConfirmationDialogProvider } from "./components/confirmation-dialog"
import { EmptyLayout } from "./components/empty-layout"
import { ErrorBoundary } from "./decorators/error-boundary-decorator"
import { history } from "./history"
import { api } from "./lib/api"
import { i18n } from "./lib/i18n"
import {
  AccountProvider,
  CompanyProvider,
  UserProvider,
  WhiteLabelProvider,
  WithLoading,
} from "./modules/account"
import { BillingProvider } from "./modules/billing"
import {
  MembershipInviteProvider,
  MembershipProvider,
} from "./modules/membership"
import { ApiContext, StoreProvider } from "./providers"

export function Root() {
  return (
    <ErrorBoundary>
      <StoreProvider>
        <ApiContext.Provider value={api}>
          <I18nProvider i18n={i18n} forceRenderOnLocaleChange={false}>
            <ToastContainer
              position="top-right"
              autoClose={4000}
              hideProgressBar
              newestOnTop
              closeOnClick={false}
              rtl={false}
              pauseOnVisibilityChange
              draggable
              pauseOnHover
            />
            {/* <Script
              async
              url="https://embed.tawk.to/5f2abc5e5c885a1b7fb68f43/default"
              charset="UTF-8"
              crossorigin="*"
            /> */}
            <ConfirmationDialogProvider>
              <WhiteLabelProvider>
                <UserProvider>
                  <AccountProvider>
                    <CompanyProvider>
                      <BillingProvider>
                        <MembershipProvider>
                          <WithLoading>
                            <MembershipInviteProvider>
                              <Router history={history}>
                                <QueryParamProvider ReactRouterRoute={Route}>
                                  <Switch>
                                    <Route
                                      path="/empty"
                                      component={EmptyLayout}
                                    />
                                    <Route component={AppLayout} />
                                  </Switch>
                                </QueryParamProvider>
                              </Router>
                            </MembershipInviteProvider>
                          </WithLoading>
                        </MembershipProvider>
                      </BillingProvider>
                    </CompanyProvider>
                  </AccountProvider>
                </UserProvider>
              </WhiteLabelProvider>
            </ConfirmationDialogProvider>
          </I18nProvider>
        </ApiContext.Provider>
      </StoreProvider>
    </ErrorBoundary>
  )
}
