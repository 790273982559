import React, { useContext, useState } from "react"
import { useAsync } from "react-async"
import { isURL } from "validator"
import PropTypes from "prop-types"
import { Formik, Form, Field } from "formik"
import { ApiContext } from "../../providers"
import { toast } from "react-toastify"
import { toastError } from "../../components/error"
import { ACCEPTED, DECLINED } from "../../constants"
import { useMembership } from "./membership-provider"
import { useAccount } from "../account"
import { useBilling } from "../billing"
import { useCompany } from "../account/company-provider"
import {
  Container,
  Row,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  FormText,
} from "reactstrap"
import { Button } from "../../components/button"
import { Input } from "../../components/input"
import { t, Trans } from "@lingui/macro"

export const MembershipInviteContext = React.createContext()

export function useMembershipInvite() {
  return useContext(MembershipInviteContext)
}

export const MembershipInviteProvider = ({ children }) => {
  const api = useContext(ApiContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isClosing, setIsClosing] = useState(false)
  const { selectedAccount, setSelectedAccount } = useAccount()
  const { getMembershipList } = useMembership()
  const { getBilling } = useBilling()
  const { getCompany } = useCompany()
  const { reset: resetMembership } = useMembership()

  const { error, data, isPending, reload } = useAsync({
    promiseFn: api.getMembershipInviteList,
  })

  let invite = false
  if (
    data &&
    !isPending &&
    selectedAccount &&
    data.membershipInvites.length > 0
  ) {
    invite = data.membershipInvites[0]
  }

  const onSwitch = () => {
    setSelectedAccount(null)
    resetMembership()
  }

  const onSubmit = async () => {
    try {
      setIsSubmitting(true)

      await api.updateMembershipInvite({
        membershipInviteId: invite.id,
        membershipInviteDto: { status: ACCEPTED },
      })

      toast.success(t`Invite accepted`)

      reload()
      getBilling()
      getCompany()
      getMembershipList()
      setIsSubmitting(false)
    } catch (err) {
      toastError(err)
      setIsSubmitting(false)
    }
  }
  const onClose = async () => {
    try {
      setIsClosing(true)

      await api.updateMembershipInvite({
        membershipInviteId: invite.id,
        membershipInviteDto: { status: DECLINED },
      })

      toast.success(t`Invite declined`)

      reload()
      setIsClosing(false)
    } catch (err) {
      toastError(err)
      setIsClosing(false)
    }
  }

  return (
    <MembershipInviteContext.Provider
      value={{
        error,
        isPending,
        reload,
        membershipInvites: data?.membershipInvites,
      }}
    >
      {invite && (
        <Formik
          initialValues={{
            tcCheckbox: isURL(invite.location.tcUrl),
          }}
        >
          {({ values }) => (
            <Modal isOpen={invite} className="modal-info">
              <ModalHeader>
                <Trans>You were invited</Trans>
              </ModalHeader>
              <ModalBody>
                <Container>
                  <Row>
                    <Trans>
                      You were invited to "{invite.location.name}". Invite will
                      be accepted with your currently selected account:{" "}
                      {selectedAccount.name}. Do you want to accept or decline?
                    </Trans>
                  </Row>
                  {isURL(invite.location.tcUrl) ? (
                    <Row>
                      <Form>
                        <FormText style={{ textAlign: "center" }}>
                          <Field
                            id="tcCheckbox"
                            type="checkbox"
                            name="tcCheckbox"
                            component={Input}
                            label={
                              <span
                                style={{
                                  position: "relative",
                                  verticalAlign: "middle",
                                  bottom: "-3px",
                                  left: "4px",
                                }}
                              >
                                <Trans>
                                  I agree with the{" "}
                                  <a
                                    href={invite.location.tcUrl}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    terms and conditions.
                                  </a>
                                </Trans>
                              </span>
                            }
                          />
                        </FormText>
                      </Form>
                    </Row>
                  ) : null}
                </Container>
              </ModalBody>
              <ModalFooter style={{ justifyContent: "space-between" }}>
                <Button onClick={onSwitch} color="primary">
                  <Trans>Switch account</Trans>
                </Button>
                <div>
                  <Button
                    color="info"
                    disabled={
                      isSubmitting ||
                      isClosing ||
                      (isURL(invite.location.tcUrl) && !values.tcCheckbox)
                    }
                    loading={isSubmitting}
                    onClick={onSubmit}
                  >
                    <Trans>Accept</Trans>
                  </Button>
                  <Button
                    color="secondary"
                    disabled={isSubmitting || isClosing}
                    loading={isClosing}
                    onClick={onClose}
                    style={{ marginLeft: 5 }}
                  >
                    <Trans>Decline</Trans>
                  </Button>
                </div>
              </ModalFooter>
            </Modal>
          )}
        </Formik>
      )}
      {children}
    </MembershipInviteContext.Provider>
  )
}

MembershipInviteProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

MembershipInviteProvider.defaultProps = {}
