import React from "react"
import { Route, Switch } from "react-router-dom"
import { urls } from "../../urls"
import { ErrorBoundary } from "../../decorators/error-boundary-decorator"
import { WithUserRequired, WithAccountRequired } from "../account"
import { WithMembershipRequired } from "../membership/membership-provider"
import { CovidCertificates } from "./covid-certificates"
import { CovidCertificateCreate } from "./covid-certificate-create"
import { CovidCertificateUpdate } from "./covid-certificate-update"

export function Covid() {
  return (
    <ErrorBoundary>
      <WithUserRequired>
        <WithAccountRequired>
          <WithMembershipRequired>
            <Switch>
              <Route
                exact
                path={urls.covid.list}
                component={CovidCertificates}
              />
              <Route
                exact
                path={urls.covid.create}
                component={CovidCertificateCreate}
              />
              <Route
                exact
                path={urls.covid.update}
                component={CovidCertificateUpdate}
              />
            </Switch>
          </WithMembershipRequired>
        </WithAccountRequired>
      </WithUserRequired>
    </ErrorBoundary>
  )
}

Covid.propTypes = {}
Covid.defaultProps = {}
