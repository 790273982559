import React, { useContext } from "react"
import PropTypes from "prop-types"
import { UserContext } from "./user-provider"
import { CompanyContext } from "./company-provider"
import { AccountContext } from "./account-provider"
import { Spinner } from "../../components/spinner"
import { useMembership } from "../membership"
import { useBilling } from "../billing"

export const InitContext = React.createContext()

export const WithLoading = ({ children }) => {
  const { isPending: isUserPending } = useContext(UserContext)
  const { isPending: isAccountPending } = useContext(AccountContext)
  const { isPending: isCompanyPending } = useContext(CompanyContext)
  const { isPending: isMembershipPending } = useMembership()
  const { isPending: isBillingPending } = useBilling()

  if (
    isUserPending ||
    isAccountPending ||
    isCompanyPending ||
    isMembershipPending ||
    isBillingPending
  )
    return <Spinner />

  return children
}

WithLoading.propTypes = {
  children: PropTypes.node.isRequired,
}

WithLoading.defaultProps = {}
