import React, { useState } from "react"
import PropTypes from "prop-types"
import { ErrorMessage } from "formik"
import { FormFeedback } from "./form-feedback"
import { useApi } from "../../providers"
import Dropzone from "react-dropzone"
import { FormGroup, Label, Input, FormText } from "reactstrap"
import { toastError } from "../../components/error"
import { Button } from "../button"
import browserImageSize from "browser-image-size"
import ReactCrop from "react-image-crop"

const acceptedTypes = ["image/jpeg", "image/png"]
const maxSizeInBytes = 5300000 // 5.3Mb

const defaultCrop = {
  unit: "px",
  x: 0,
  y: 0,
  // aspect: 1,
  width: 250,
  height: 250,
}

async function validateImageFile(file) {
  if (!acceptedTypes.includes(file.type)) {
    return "File type is not supported."
  }

  if (file.size > maxSizeInBytes) {
    return "File size is too big."
  }

  const size = await browserImageSize(file)

  if (size.width < 250 || size.height < 250) {
    return "Picture resolution is too small."
  }

  if (size.width > 4032 || size.height > 4032) {
    return "Picture resolution is too big."
  }
}

function getCroppedImg(image, crop, fileName) {
  const canvas = document.createElement("canvas")
  const scaleX = image.naturalWidth / image.width
  const scaleY = image.naturalHeight / image.height
  const scale = 1.5
  canvas.width = crop.width * scale
  canvas.height = crop.height * scale
  const ctx = canvas.getContext("2d")

  ctx.drawImage(
    image,
    crop.x * scaleX,
    crop.y * scaleY,
    crop.width * scaleX,
    crop.height * scaleY,
    0,
    0,
    crop.width * scale,
    crop.height * scale,
  )

  return new Promise((resolve, reject) => {
    canvas.toBlob(
      (blob) => {
        if (blob) {
          blob.name = fileName
          resolve(blob)
          return
        }

        reject(new Error("Can't get blob from image"))
      },
      "image/jpeg",
      1,
    )
  })
}

export const ImageInput = ({
  id,
  field,
  form,
  className,
  label,
  hint,
  onUploadSuccess,
  description,
  imageStyle,
}) => {
  const api = useApi()
  const [isPending, setIsPending] = useState(false)
  const [crop, setCrop] = useState(defaultCrop)

  const [croppingObject, setCroppingObject] = useState(null)

  const labelComponent = <Label for={id}>{label}</Label>

  const formFeedback = (
    <ErrorMessage name={field.name} component={FormFeedback} />
  )

  async function onImageDrop([acceptedFile], [rejectedFile]) {
    form.setFieldTouched(field.name, true, false)

    try {
      if (rejectedFile) {
        const error = await validateImageFile(rejectedFile)

        if (error) {
          form.setFieldError(field.name, error)
        }
        return
      }

      if (acceptedFile) {
        const error = await validateImageFile(acceptedFile)

        if (error) {
          form.setFieldError(field.name, error)
          return
        }

        setCroppingObject({
          url: URL.createObjectURL(acceptedFile),
          file: acceptedFile,
        })
      }
    } catch (err) {
      toastError(err)
    }
  }

  async function onImageCrop() {
    try {
      const img = await getCroppedImg(
        croppingObject.image,
        crop,
        croppingObject.file.name,
      )

      setIsPending(true)

      const { url: uploadUrl } = await api.createS3SignedUrlToPutObject({
        type: img.type,
      })

      await api.uploadObjectToS3({
        url: uploadUrl,
        type: img.type,
        object: img,
      })

      const url = new URL(uploadUrl)

      const imageUrl = url.origin + url.pathname

      form.setFieldValue(field.name, imageUrl)

      URL.revokeObjectURL(croppingObject.url)
      setCroppingObject(null)
      setCrop(defaultCrop)

      onUploadSuccess(imageUrl)
    } catch (err) {
      toastError(err)
    } finally {
      setIsPending(false)
    }
  }

  return (
    <FormGroup className={className}>
      {labelComponent}
      {hint}

      <FormText>{description}</FormText>

      {croppingObject ? (
        <>
          <ReactCrop
            src={croppingObject.url}
            crop={crop}
            onChange={(crop) => setCrop(crop)}
            onImageLoaded={(image) => {
              setCroppingObject({
                ...croppingObject,
                image,
              })
            }}
            minWidth={150}
            minHeight={150}
            maxWidth={imageStyle.width}
            maxHeight={imageStyle.height}
            imageStyle={imageStyle}
            keepSelection
          />
          <div className="mt-1">
            <Button
              color="primary"
              loading={isPending}
              disabled={isPending}
              onClick={onImageCrop}
            >
              Crop Image
            </Button>
            <Button
              color="secondary"
              disabled={isPending}
              onClick={() => {
                URL.revokeObjectURL(croppingObject.url)
                setCroppingObject(null)
                setCrop(defaultCrop)
              }}
            >
              Cancel
            </Button>
          </div>
        </>
      ) : (
        <Dropzone
          disabled={isPending}
          multiple={false}
          style={{}}
          maxSize={maxSizeInBytes}
          accept={acceptedTypes.join(",")}
          onFileDialogCancel={() => form.setFieldTouched(field.name, true)}
          onDrop={onImageDrop}
        >
          {({ getRootProps, getInputProps }) => (
            <div {...getRootProps()}>
              <input {...getInputProps()} id={id} name={field.name} />

              <Button className="mt-1" loading={isPending} disabled={isPending}>
                Select Image
              </Button>

              <br />

              {field.value && (
                <img
                  className="mt-1"
                  style={{ maxWidth: "100%", maxHeight: "100%" }}
                  data-private
                  src={field.value}
                  alt="Location"
                />
              )}
            </div>
          )}
        </Dropzone>
      )}
      <Input
        type="hidden"
        name={field.name}
        valid={form.touched[field.name] && !form.errors[field.name]}
        invalid={form.touched[field.name] && !!form.errors[field.name]}
      />
      {formFeedback}
    </FormGroup>
  )
}

ImageInput.defaultProps = {
  disabled: false,
  imageStyle: { maxHeight: 480, maxWidth: 480 },
  onUploadSuccess: () => {},
  description: (
    <>
      Picture format should be .jpeg
      <br />
      Picture size should be less than 5 megabyte
      <br />
      Picture resolution should be bigger than 250x250
      <br />
      Picture resolution should be less than 4032x4032
    </>
  ),
}

ImageInput.propTypes = {
  id: PropTypes.string.isRequired,
  onUploadSuccess: PropTypes.func.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  hint: PropTypes.node,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  imageStyle: PropTypes.object.isRequired,
  description: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
}
