import PropTypes from "prop-types"
import React, { useState } from "react"
import { Collapse } from "reactstrap"
import { Button } from "../button"

export default function FoldableBlock({
  children,
  name,
  outline,
  tag,
  isUnfoldedOnLoad,
}) {
  const [isUnfolded, setIsUnfolded] = useState(isUnfoldedOnLoad)

  return (
    <>
      <Button
        tag={tag}
        outline={outline}
        type="button"
        color="primary"
        onClick={() => setIsUnfolded(!isUnfolded)}
        style={{ marginBottom: "1rem" }}
      >
        {name}{" "}
        <i className={isUnfolded ? "fas fa-caret-up" : "fas fa-caret-down"} />
      </Button>
      <Collapse isOpen={isUnfolded}>{children}</Collapse>
    </>
  )
}

FoldableBlock.defaultProps = {
  name: "Open",
  outline: true,
  isUnfoldedOnLoad: false,
}
FoldableBlock.propTypes = {
  children: PropTypes.node.isRequired,
  name: PropTypes.string,
  outline: PropTypes.bool,
  tag: PropTypes.oneOfType([PropTypes.node, PropTypes.string]),
  isUnfoldedOnLoad: PropTypes.bool,
}
