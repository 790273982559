import React from "react"
import PropTypes from "prop-types"
import { ErrorMessage } from "formik"
import { FormFeedback } from "./form-feedback"
import cn from "classnames"
import {
  Input as Binput,
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
} from "reactstrap"

export const Input = ({
  id,
  field,
  form,
  rows,
  className,
  inputClassName,
  placeholder,
  type,
  label,
  disabled,
  hint,
  addon,
  addonType,
  min,
  max,
  multiple,
  children,
  valid,
  invalid,
  onChange,
}) => {
  const input = (
    <Binput
      {...field}
      onChange={(...args) => {
        onChange && onChange(...args)
        return field.onChange(...args)
      }}
      className={cn({
        "custom-control-input": type === "checkbox",
        className,
      })}
      id={id}
      type={type}
      min={min}
      max={max}
      placeholder={placeholder}
      valid={
        valid === null
          ? null
          : form.touched[field.name] && !form.errors[field.name]
      }
      invalid={
        invalid === null
          ? null
          : form.touched[field.name] && !!form.errors[field.name]
      }
      disabled={disabled}
      rows={rows}
      multiple={multiple}
    >
      {children}
    </Binput>
  )

  const labelComponent = (
    <Label
      className={cn({ "custom-control-label": type === "checkbox" })}
      for={id}
    >
      {label}
    </Label>
  )

  const formFeedback = (
    <ErrorMessage name={field.name} component={FormFeedback} />
  )

  return (
    <FormGroup
      className={cn(
        { "custom-control custom-checkbox": type === "checkbox" },
        className,
      )}
    >
      {label && type !== "checkbox" && labelComponent}

      {hint}

      {addon ? (
        <InputGroup>
          {addonType === "prepend" && (
            <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>
          )}
          {input}
          {addonType === "append" && (
            <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>
          )}
          {formFeedback}
        </InputGroup>
      ) : (
        input
      )}

      {label && type === "checkbox" && labelComponent}

      {formFeedback}
    </FormGroup>
  )
}

Input.defaultProps = {
  type: "text",
  disabled: false,
  multiple: false,
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  onChange: PropTypes.func,
  min: PropTypes.string,
  max: PropTypes.string,
  type: PropTypes.string.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  label: PropTypes.node,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  rows: PropTypes.string,
  hint: PropTypes.node,
  addon: PropTypes.node,
  addonType: PropTypes.node,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  multiple: PropTypes.bool,
  children: PropTypes.array,
}
