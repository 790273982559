import PropTypes from "prop-types"
import React, { useState } from "react"
import { Tooltip } from "reactstrap"

export default function TooltipComponent({
  targetId,
  children,
  placement = "right",
  className = "custom_tooltip_wrap",
}) {
  const [tooltipOpen, setToolTipOpen] = useState(false)

  const toggle = () => {
    setToolTipOpen(!tooltipOpen)
  }
  return (
    <Tooltip
      className={className}
      target={targetId}
      placement={placement}
      isOpen={tooltipOpen}
      toggle={toggle}
    >
      {children}
    </Tooltip>
  )
}

TooltipComponent.propTypes = {
  children: PropTypes.element,
  targetId: PropTypes.string,
  placement: PropTypes.string,
  className: PropTypes.string,
}
