import { useLingui } from "@lingui/react"
import * as locales from "date-fns/locale"
import {
  formatDuration,
  formatRelativeDate,
  formatDistanceStrict,
  formatDate,
} from "../../lib/helpers"

export function FormatDuration({ duration }) {
  const { i18n } = useLingui()
  return formatDuration(duration, i18n.locale)
}

export function FormatRelativeDate({ date, baseDate = new Date() }) {
  const { i18n } = useLingui()
  return formatRelativeDate(date, baseDate, i18n.locale)
}

export function FormatDistanceStrict({ date1, date2, options }) {
  const { i18n } = useLingui()
  options.locale = locales[i18n.locale]

  return formatDistanceStrict(date1, date2, options)
}

export function FormatDate({ date, formatStr = "PP" }) {
  const { i18n } = useLingui()
  return formatDate(date, formatStr, i18n.locale)
}
