import React, { useContext, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { ApiContext } from "../../providers"
import { path } from "ramda"
import { Spinner } from "../../components/spinner"

export const WhiteLabelContext = React.createContext()

export function useWhiteLabel() {
  return useContext(WhiteLabelContext)
}

const defaultState = {
  whiteLabelSettings: null,
  error: null,
  isPending: true,
}

export const WhiteLabelProvider = ({ children }) => {
  const api = useContext(ApiContext)

  const [state, setState] = useState(defaultState)

  const { whiteLabelSettings, error, isPending } = state

  const getWhiteLabelSettings = useCallback(async () => {
    try {
      const whiteLabelSettings = await api.getWhiteLabelSettings()

      setState((state) => ({
        ...state,
        whiteLabelSettings,
        isPending: false,
        error: null,
      }))
    } catch (error) {
      if (path(["response", "status"], error) !== 404) {
        setState((state) => ({
          ...state,
          whiteLabelSettings: null,
          isPending: false,
          error,
        }))
      } else {
        setState((state) => ({
          ...state,
          whiteLabelSettings: null,
          isPending: false,
          error: null,
        }))
      }

      if (!error.isAxiosError) {
        throw error
      }
    }
  }, [api])

  function reset() {
    return setState({ ...defaultState, isPending: false })
  }

  useEffect(() => {
    getWhiteLabelSettings()
  }, [getWhiteLabelSettings])

  if (error && path(["response", "status"], error) !== 401) {
    throw error
  }

  if (isPending) return <Spinner />

  return (
    <WhiteLabelContext.Provider
      value={{
        isPending,
        error,
        whiteLabelSettings,
        setState,
        getWhiteLabelSettings,
        reset,
      }}
    >
      {children}
    </WhiteLabelContext.Provider>
  )
}

WhiteLabelProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

WhiteLabelProvider.defaultProps = {}
