import React, { useContext, useRef } from "react"
import PropTypes from "prop-types"
import { useAsync } from "react-async"
import { Link } from "react-router-dom"
import { ApiContext } from "../../providers/api-provider"
import { useAccount } from "./account-provider"
import { UserContext } from "./user-provider"
import { Spinner } from "../../components/spinner"
import { Button } from "../../components/button"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import {
  getUserFullName,
  isAccountOwner,
  getUserRolename,
} from "../../lib/helpers"
import { FormatRelativeDate } from "../../components/formatters/index"
import {
  ListGroup,
  ListGroupItem,
  Row,
  Col,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap"
import { ClipboardIconButton } from "../../components/clipboard-button"
import { useConfirmation } from "../../components/confirmation-dialog"
import { toast } from "react-toastify"
import { toastError } from "../../components/error"
import { OWNER } from "../../constants"
import { t, Trans } from "@lingui/macro"

export function UserList({ filterText }) {
  const api = useContext(ApiContext)
  const confirm = useConfirmation()

  const { selectedAccount } = useAccount()
  const { user: currentUser } = useContext(UserContext)
  const newAccountOwnerUserRef = useRef()

  const {
    data: usersData,
    error,
    isPending,
    reload: reloadUserList,
  } = useAsync({
    promiseFn: api.getUserList,
    rangeStart: 0,
    rangeEnd: 999,
  })

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  const confirmDeleteUserRole = ({ user, role }) =>
    confirm({
      title: t`Confirm Remove User From Account`,
      content: t`Are you sure you want to remove the user ${user.firstName} ${user.lastName} ${user.id} from account ${selectedAccount.name} ${selectedAccount.id}?`,
      color: "danger",
      variant: "confirm",
      onConfirm: async () => {
        try {
          await api.deleteRole({
            accountId: selectedAccount.id,
            roleId: role.id,
          })

          reloadUserList()

          toast.success(t`Done`)
        } catch (err) {
          toastError(err)
          throw err
        }
      },
    })

  function textFilter(user) {
    return (
      user.firstName.toLowerCase().includes(filterText) ||
      user.lastName.toLowerCase().includes(filterText) ||
      user.email.toLowerCase().includes(filterText)
    )
  }

  const requestNewOwnerAlertContent = (
    <>
      <p>
        <Trans>
          Are you sure you want to make another user a new owner if this
          account? If confirmed, changes will apply instantly.
        </Trans>
      </p>
      <Form inline>
        <FormGroup>
          <Label for="selectUser">
            <Trans>Select teammate</Trans>
          </Label>
          <Input
            id="selectUser"
            type="select"
            name="select"
            onChange={(e) => {
              newAccountOwnerUserRef.current = parseInt(e.target.value, 10)
            }}
          >
            {usersData.users
              .filter((u) => u.id !== currentUser.id)
              .map((u) => (
                <option key={u.id} value={u.id}>
                  {getUserFullName(u)}
                </option>
              ))}
          </Input>
        </FormGroup>
      </Form>
    </>
  )

  const requestChangeOwner = () => {
    // set the default selected option as first user among other users on the same account
    newAccountOwnerUserRef.current =
      usersData.users.length > 1 &&
      usersData.users.filter((u) => u.id !== currentUser.id)[0].id

    return confirm({
      title: t`Confirm Changing Account Owner`,
      content: requestNewOwnerAlertContent,
      color: "danger",
      variant: "confirm",
      onConfirm: async () => {
        try {
          const user = usersData.users.find(
            (u) => u.id === newAccountOwnerUserRef.current,
          )
          const role = user.roles.find(
            (r) => r.accountId === selectedAccount.id,
          )

          await api.updateRole({
            userId: user.id,
            roleId: role.id,
            roleDto: { name: OWNER },
          })

          toast.success(t`Request is sent`)
          reloadUserList()
        } catch (err) {
          toastError(err)
          throw err
        } finally {
          newAccountOwnerUserRef.current = null
        }
      },
      onCancel: () => {
        newAccountOwnerUserRef.current = null
      },
    })
  }

  const isCurrentUserOwner = isAccountOwner(
    usersData.users.find((u) => u.id === currentUser.id),
    selectedAccount.id,
  )

  return (
    <ListGroup>
      {usersData.users.filter(textFilter).map((user) => {
        const isCurrentUser = user.id === currentUser.id
        return (
          <ListGroupItem className="mt-2" key={user.id}>
            <Row>
              <Col xs="12" md="6" className="font-xl">
                {user?.img && (
                  <img
                    src={user.img}
                    alt="user img"
                    width="50px"
                    className="mr-2 rounded-circle"
                  />
                )}
                <Link
                  to={reverse(urls.team.details, {
                    userId: user.id,
                  })}
                  data-private
                >
                  {getUserFullName(user)}
                </Link>
                {isCurrentUser && (
                  <>
                    {" "}
                    (<Trans>you</Trans>)
                  </>
                )}
              </Col>

              <Col xs="12" md="4">
                <Trans>Role</Trans>: {getUserRolename(user, selectedAccount.id)}
                <br />
                {user.email} <ClipboardIconButton clipboardText={user.email} />
                <br />
                <Trans>Last activity</Trans>:{" "}
                <FormatRelativeDate date={new Date(user.lastActivityAt)} />
              </Col>

              <Col xs="12" md="2">
                {!isCurrentUser && isCurrentUserOwner ? (
                  <Button
                    block
                    color="danger"
                    onClick={() =>
                      confirmDeleteUserRole({
                        user,
                        role: user.roles.find(
                          (r) => r.accountId === selectedAccount.id,
                        ),
                      })
                    }
                  >
                    <Trans>Remove</Trans>
                  </Button>
                ) : null}
                {isCurrentUser &&
                isCurrentUserOwner &&
                usersData.users.length > 1 ? (
                  <Button block color="primary" onClick={requestChangeOwner}>
                    <Trans>Change Owner</Trans>
                  </Button>
                ) : null}
              </Col>
            </Row>

            <Row>
              <Col>
                <div className="text-muted">
                  <Trans>Created</Trans>{" "}
                  <FormatRelativeDate date={new Date(user.createdAt)} />
                </div>
              </Col>
            </Row>
          </ListGroupItem>
        )
      })}
    </ListGroup>
  )
}

UserList.propTypes = {
  filterText: PropTypes.string,
}
UserList.defaultProps = {}
