import React, { useState } from "react"
import PropTypes from "prop-types"
import { ListGroupItem, Row, Col, Badge, UncontrolledTooltip } from "reactstrap"
import { Button } from "../../../components/button"
import { ClipboardIconButton } from "../../../components/clipboard-button"
import { FormatRelativeDate } from "../../../components/formatters/index"
import { Trans } from "@lingui/macro"

export const DocumentListItem = ({
  document,
  actionsDisabled,
  editActionDisabled,
  isEditing,
  onEdit,
  onSave,
  onCancel,
}) => {
  const [newTitle, setNewTitle] = useState(document.title)
  const [newDescription, setNewDescription] = useState(document.description)

  if (!document) return

  function renderActionButtonsGroup() {
    if (document.isArchived) return null

    const editButton = isEditing ? (
      <Button
        id={"tooltip-edit-" + document.id}
        color="primary"
        className="align-self-center ml-3"
        onClick={() =>
          onSave({
            title: newTitle,
            description: newDescription,
          })
        }
      >
        <i className="fas fa-check" />
      </Button>
    ) : (
      <Button
        id={"tooltip-edit-" + document.id}
        color="primary"
        className="align-self-center ml-3"
        onClick={() => onEdit(document)}
        disabled={actionsDisabled || editActionDisabled}
      >
        <i className="fas fa-edit" />
      </Button>
    )

    return (
      <>
        {isEditing && (
          <>
            <Button
              id={"tooltip-cancel-" + document.id}
              color="error"
              className="align-self-center ml-3"
              onClick={onCancel}
              disabled={actionsDisabled}
            >
              <i className="fas fa-times" />
            </Button>
            <UncontrolledTooltip target={"tooltip-cancel-" + document.id}>
              <Trans>Cancel</Trans>
            </UncontrolledTooltip>
          </>
        )}

        {editButton}
        <UncontrolledTooltip target={"tooltip-edit-" + document.id}>
          {isEditing ? <Trans>Save changes</Trans> : <Trans>Edit title</Trans>}
        </UncontrolledTooltip>
      </>
    )
  }

  return (
    <ListGroupItem className="mt-2" key={document.id}>
      <Row>
        <Col xs="12" md="10" className="text-nowrap">
          {isEditing ? (
            <input
              type="text"
              value={newTitle}
              onChange={(e) => setNewTitle(e.target.value)}
            />
          ) : (
            document.title
          )}
          {document.isArchived && (
            <Badge pill color="warning" className="ml-2">
              <Trans>Archived</Trans>
            </Badge>
          )}
        </Col>
        <Col xs="12" md="2" className="d-flex d-inline justify-content-end">
          <Button
            outline
            color="primary"
            className="align-self-center"
            id={"tooltip-open-" + document.id}
          >
            <a href={document.url} target="_blank" rel="noopener noreferrer">
              <i className="fas fa-external-link-alt" />
            </a>
          </Button>
          <UncontrolledTooltip target={"tooltip-open-" + document.id}>
            <Trans>Open in tab</Trans>
          </UncontrolledTooltip>

          <Button
            outline
            color="primary"
            className="align-self-center ml-3"
            id={"tooltip-copy-" + document.id}
          >
            <ClipboardIconButton clipboardText={document.url} />
          </Button>
          <UncontrolledTooltip target={"tooltip-copy-" + document.id}>
            <Trans>Copy title</Trans>
          </UncontrolledTooltip>

          {renderActionButtonsGroup()}
        </Col>
      </Row>
      <Row>
        <Col>
          {isEditing ? (
            <input
              type="text"
              value={newDescription}
              onChange={(e) => setNewDescription(e.target.value)}
            />
          ) : (
            document.description
          )}
        </Col>
      </Row>
      <Row className="mt-3">
        <Col xs="12">
          <p className="m-0 text-muted text-right">
            <Trans>Last edit</Trans>:{" "}
            <FormatRelativeDate date={new Date(document.updatedAt)} />{" "}
            <Trans>Created</Trans>:{" "}
            <FormatRelativeDate date={new Date(document.updatedAt)} />
          </p>
        </Col>
      </Row>
    </ListGroupItem>
  )
}

DocumentListItem.defaultProps = {
  actionsDisabled: false,
  editActionDisabled: false,
  isEditing: false,
  onEdit: () => {},
  onSave: () => {},
  onCancel: () => {},
}
DocumentListItem.propTypes = {
  document: PropTypes.object.isRequired,
  actionsDisabled: PropTypes.bool,
  editActionDisabled: PropTypes.bool,
  isEditing: PropTypes.bool,
  onEdit: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
}
