import React from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { Button } from "../button"

export function BackButton({ className, disabled, color, id, style }) {
  const history = useHistory()
  return (
    <Button
      className={className}
      disabled={disabled}
      color={color}
      id={id}
      style={style}
      onClick={() => history.goBack()}
    >
      <i className="fas fa-angle-left fa-2x" />
    </Button>
  )
}

BackButton.defaultProps = {
  className: "",
  disabled: false,
  color: "link",
}

BackButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  color: PropTypes.string,
  id: PropTypes.string,
  style: PropTypes.string,
}
