import React, { useContext, useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useAsync } from "react-async"
import { Link } from "react-router-dom"
import { ApiContext } from "../../providers/api-provider"
import { Spinner } from "../../components/spinner"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import { getInviteStatusText, getInviteStatusColor } from "./helpers"
import { FormatRelativeDate } from "../../components/formatters/index"
import {
  Card,
  CardBody,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  Badge,
  Row,
  Col,
} from "reactstrap"
import { ClipboardIconButton } from "../../components/clipboard-button"
import { PENDING, EXPIRED } from "../../constants"
import { useConfirmation } from "../../components/confirmation-dialog"
import { toastError } from "../../components/error"
import { toast } from "react-toastify"
import { Button } from "../../components/button"
import { t, Trans } from "@lingui/macro"

export function InviteList({ filterText }) {
  const api = useContext(ApiContext)
  const confirm = useConfirmation()
  const [invitesDisabled, setInvitesDisabled] = useState(false)

  const { data, error, isPending, reload } = useAsync({
    promiseFn: api.getInviteList,
  })

  useEffect(() => {
    reload()
  }, [reload])

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  const { count, invites } = data

  function textFilter(invite) {
    return (
      invite.firstName.toLowerCase().includes(filterText) ||
      invite.lastName.toLowerCase().includes(filterText) ||
      invite.email.toLowerCase().includes(filterText)
    )
  }

  if (count === 0) {
    return (
      <Card>
        <CardBody>
          <p className="lead">
            <Trans>You don't have teammates yet.</Trans>
          </p>
          <p className="lead">
            <Link to={reverse(urls.team.createInvite)}>
              <Trans>Start inviting teammates.</Trans>
            </Link>
          </p>
        </CardBody>
      </Card>
    )
  }

  const confirmResendInvite = (invite) =>
    confirm({
      title: t`Confirm Re-send Invite`,
      content: t`Do you want to re-send invitation Email to ${invite.firstName} ${invite.lastName} on ${invite.email}? Please wait for at least 10 minutes before re-sending a new Email.`,
      color: "info",
      variant: "confirm",
      onConfirm: async () => {
        try {
          setInvitesDisabled(true)

          await api.createInvite({
            email: invite.email,
            firstName: invite.firstName,
            lastName: invite.lastName,
          })

          toast.success(t`Done!`)
          setInvitesDisabled(false)
        } catch (err) {
          toastError(err)
          throw err
        }
      },
    })

  return (
    <>
      <ListGroup>
        {invites.filter(textFilter).map((i) => {
          return (
            <ListGroupItem
              key={i.id}
              className="mt-2 d-flex justify-content-between"
            >
              <Col>
                <ListGroupItemHeading data-private>
                  {i.firstName} {i.lastName} {i.email}{" "}
                  <ClipboardIconButton clipboardText={i.email} />
                </ListGroupItemHeading>
              </Col>

              <Col xs="4">
                <Row className="d-flex justify-content-end">
                  {[PENDING, EXPIRED].includes(i.status) && (
                    <Button
                      className="mr-2"
                      color="primary"
                      onClick={() => confirmResendInvite(i)}
                      disabled={invitesDisabled}
                    >
                      {invitesDisabled ? (
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        >
                          <span className="sr-only">
                            <Trans>Loading</Trans>...
                          </span>
                        </div>
                      ) : (
                        <i className="fas fa-redo-alt" />
                      )}
                    </Button>
                  )}

                  <Badge
                    className="font-lg p-2"
                    color={getInviteStatusColor(i)}
                  >
                    {getInviteStatusText(i)}
                  </Badge>
                </Row>

                <Row className="d-flex justify-content-end">
                  <span className="text-muted">
                    <Trans>Created</Trans>{" "}
                    <FormatRelativeDate date={new Date(i.createdAt)} />
                  </span>
                </Row>
              </Col>
            </ListGroupItem>
          )
        })}
      </ListGroup>
    </>
  )
}

InviteList.propTypes = {
  filterText: PropTypes.string,
}
InviteList.defaultProps = {}
