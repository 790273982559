import { toast } from "react-toastify"
import { path } from "ramda"
import { t } from "@lingui/macro"
import {
  CLIENT_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  CANCEL_ERROR,
  UNKNOWN_ERROR,
} from "../../constants"

export function toastError(err, options) {
  let content

  switch (err.problem) {
    case SERVER_ERROR:
      content = t`Action is temporarily unavailable.`
      break

    case NETWORK_ERROR:
      content = t`Please check your internet connection.`
      break

    case TIMEOUT_ERROR:
    case CONNECTION_ERROR:
    case CANCEL_ERROR:
    case UNKNOWN_ERROR:
      content = t`Action is temporarily unavailable.`
      break

    case CLIENT_ERROR:
      switch (path(["response", "status"], err)) {
        case 400:
          content = t`Invalid data. Please check your input.`
          break

        case 401:
          content = t`You are not authenticated to perform this action.`
          break

        case 404:
          content = t`You are not authorized to perform this action or resource was not found.`
          break

        default:
          content = t`Oops! Something went wrong.`
      }

      break

    default:
      content = t`Oops! Something went wrong.`
  }

  return toast.error(content, {
    autoClose: 60000,
    ...options,
  })
}
