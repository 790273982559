import { Trans } from "@lingui/macro"
import { addMilliseconds, differenceInHours } from "date-fns"
import { reverse } from "named-urls"
import PropTypes from "prop-types"
import { path } from "ramda"
import React, { useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { Badge, Col, ListGroupItem, Row } from "reactstrap"
import { FormatDate } from "../../../components/formatters/index"
import { getUserFullName } from "../../../lib/helpers"
import { urls } from "../../../urls"
import { UserContext } from "../../account"
import { useMembership } from "../../membership"
import { getBookingBadgeTextAndStatusColor } from "../helpers"

export const BookingListItem = ({ booking }) => {
  const history = useHistory()
  const { selectedMembership } = useMembership()
  const { user } = useContext(UserContext)

  const { color, status } = getBookingBadgeTextAndStatusColor(booking)

  const companyName = path(["account", "company", "name"], booking.membership)

  const { privacyMode } = selectedMembership.location

  let isPrivate = false

  if (privacyMode && booking.membershipId !== selectedMembership.id) {
    isPrivate = true
  }

  if (
    booking.isPrivate &&
    booking.userId !== user.id &&
    booking.recurringBooking?.userId !== user.id
  ) {
    isPrivate = true
  }

  const membershipName = isPrivate
    ? ""
    : booking.membership.name ||
      companyName ||
      getUserFullName(booking.membership.account.users[0])

  const endAt = addMilliseconds(new Date(booking.endAt), 1)
  const timeText =
    differenceInHours(endAt, new Date(booking.startAt)) === 24 ? (
      <div>
        <Trans>Full-day</Trans>
      </div>
    ) : (
      <div>
        <FormatDate date={new Date(booking.startAt)} formatStr="HH:mm" />{" "}
        {" - "}
        <FormatDate date={endAt} formatStr="HH:mm" />
      </div>
    )
  return (
    <ListGroupItem
      key={booking.id}
      className="p-2 cursor-pointer"
      onClick={(e) => {
        if (e.target.tagName === "A") {
          return
        }

        history.push(
          reverse(urls.booking.details, {
            bookingId: booking.id,
          }),
        )
      }}
    >
      <Row>
        {Boolean(booking.user?.img || booking.recurringBooking?.user?.img) && (
          <Col sm="3" className="d-flex align-items-center">
            <img
              src={booking.user?.img || booking.recurringBooking?.user?.img}
              alt="user img"
              width="50px"
              className="rounded-circle"
            />
          </Col>
        )}

        <Col
          sm={
            booking.user?.img || booking.recurringBooking?.user?.img
              ? "9"
              : "12"
          }
        >
          <Row>
            <Col className="d-flex flex-row justify-content-between">
              <div>{timeText}</div>
              <Badge className="p-1" color={color}>
                {status}
              </Badge>
            </Col>
          </Row>
          <Row>
            <Col>
              <Link
                to={reverse(urls.booking.details, {
                  bookingId: booking.id,
                })}
              >
                {isPrivate ? (
                  <Trans>Busy</Trans>
                ) : (
                  booking.title || booking.description || ""
                )}
              </Link>
            </Col>
          </Row>
          <Row>
            <Col>
              {Boolean(membershipName) && <div>{membershipName}</div>}
              {isPrivate ? (
                <div>
                  <Trans>Private</Trans>
                </div>
              ) : (
                <div>
                  {getUserFullName(
                    booking.user ?? booking.recurringBooking?.user,
                  )}
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>

      {booking.recurringBooking && (
        <Row>
          <Col>
            <FormatDate
              date={new Date(booking.recurringBooking.startAt)}
              formatStr="PP"
            />
            -{" "}
            <FormatDate
              date={new Date(booking.recurringBooking.endRecurringAtDay)}
              formatStr="PP"
            />
          </Col>
        </Row>
      )}
    </ListGroupItem>
  )
}

BookingListItem.defaultProps = {}
BookingListItem.propTypes = {
  booking: PropTypes.object.isRequired,
}
