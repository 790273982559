import React, { useContext } from "react"
import { useParsedParams } from "../../hooks"
import { addMonths, fromUnixTime } from "date-fns"
import { useAsync } from "react-async"
import { ApiContext } from "../../providers/api-provider"
import { Spinner } from "../../components/spinner"
import { Button } from "../../components/button"
import { BackButton } from "../../components/backbutton"
import { getBookingDurationUnitName } from "../resource"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  CardFooter,
  Badge,
  ListGroup,
  ListGroupItem,
  ListGroupItemHeading,
  ListGroupItemText,
} from "reactstrap"
import { getMembershipPlanStatusColor } from "./helpers"
import {
  assert,
  isNonZeroPrice,
  priceFromCents,
  renderPrice,
} from "../../lib/helpers"
import {
  FormatRelativeDate,
  FormatDistanceStrict,
  FormatDate,
} from "../../components/formatters/index"
import { useMembership } from "../membership"
import { Trans } from "@lingui/macro"

export function MembershipPlanDetails() {
  const api = useContext(ApiContext)

  const { membershipPlanId } = useParsedParams({ membershipPlanId: Number })
  const { selectedMembership } = useMembership()

  const {
    data: membershipPlan,
    error,
    isPending,
  } = useAsync({
    promiseFn: api.getMembershipPlan,
    locationId: selectedMembership.locationId,
    membershipPlanId,
  })

  assert(!error, error)

  if (isPending) {
    return <Spinner />
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl">
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>
                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText className="text-truncate">
                      {membershipPlan.name}
                    </CardText>
                  </Col>
                  <Col
                    xs="6"
                    md="3"
                    className="d-flex d-inline justify-content-end"
                  >
                    <Button
                      className="align-self-center mr-3"
                      color="primary"
                      disabled
                    >
                      <i className="fas fa-edit" />
                    </Button>

                    <Button
                      className="align-self-center"
                      color="danger"
                      disabled
                    >
                      <i className="fas fa-trash-alt" />
                    </Button>
                  </Col>
                </Row>
                <Row className="d-md-none d-sm-inline ml-0 mr-0">
                  <Col>{membershipPlan.name}</Col>
                </Row>
              </CardTitle>
            </CardHeader>
            <CardBody>
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>Status</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  <Badge
                    className="p-2"
                    color={getMembershipPlanStatusColor(membershipPlan)}
                  >
                    {membershipPlan.status}
                  </Badge>
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>Start</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  <FormatDate
                    date={new Date(membershipPlan.startAt)}
                    formatStr="dd/MM/yyyy"
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>End</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  {membershipPlan.endAt !== null ? (
                    <FormatDate
                      date={new Date(membershipPlan.startAt)}
                      formatStr="dd/MM/yyyy"
                    />
                  ) : (
                    "-"
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>Duration</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  {membershipPlan.endAt !== null ? (
                    <FormatDistanceStrict
                      date1={new Date(membershipPlan.endAt)}
                      date2={new Date(membershipPlan.startAt)}
                      options={{ unit: "day" }}
                    />
                  ) : (
                    "Rolling plan"
                  )}
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>Cancelation</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  <FormatDistanceStrict
                    date1={new Date()}
                    date2={addMonths(
                      new Date(),
                      membershipPlan.cancelationPeriod,
                    )}
                    options={{
                      unit: "month",
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>Description</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  {membershipPlan.description.length > 0
                    ? membershipPlan.description
                    : "-"}
                </Col>
              </Row>
              {isNonZeroPrice(membershipPlan.price) && (
                <Row>
                  <Col xs="3" className="mb-4 text-right">
                    <Trans>Price</Trans>
                  </Col>
                  <Col xs="9" className="mb-4">
                    {renderPrice(priceFromCents(membershipPlan.price))}
                  </Col>
                </Row>
              )}
              <Row>
                <Col xs="3" className="mb-4 text-right">
                  <Trans>Account</Trans>
                </Col>
                <Col xs="9" className="mb-4">
                  <Trans>test</Trans>
                  {/* <Link
                    to={reverse(urls.membership.details.root, {
                      membershipId: membershipPlan.membership.id,
                    })}
                    data-private
                  >
                    {(membershipPlan.membership.account.company &&
                      membershipPlan.membership.account.company.name) ||
                      getUserFullName(
                        membershipPlan.membership.account.users[0],
                      )}
                  </Link> */}
                </Col>
              </Row>
              <Row>
                <Col className="mb-4">
                  <ListGroup>
                    <ListGroupItem>
                      <ListGroupItemHeading>
                        {membershipPlan.resources.length > 0
                          ? "Resources attached to this plan"
                          : "No resources attached"}
                      </ListGroupItemHeading>
                    </ListGroupItem>
                    {membershipPlan.resources.map((r) => (
                      <ListGroupItem key={r.id} className="p-2">
                        <ListGroupItemText className="m-0">
                          {/* <Link
                            to={reverse(urls.resource.details, {
                              resourceId: r.id,
                            })}
                          > */}
                          {r.name}
                          {/* </Link> */}
                          {` ( ${r.resourceType.bookingDurationUnits.map((u) =>
                            getBookingDurationUnitName(u),
                          )} )`}
                        </ListGroupItemText>
                      </ListGroupItem>
                    ))}
                  </ListGroup>
                </Col>
              </Row>
              {Boolean(membershipPlan.stripeInvoices?.length) && (
                <Row>
                  <Col className="mb-4">
                    <ListGroup>
                      <ListGroupItem>
                        <ListGroupItemHeading>
                          <Trans>Latest Invoices</Trans>
                        </ListGroupItemHeading>
                      </ListGroupItem>
                      {membershipPlan.stripeInvoices
                        .filter((s) => s.status !== "draft")
                        .map((i) => (
                          <ListGroupItem key={i.id} className="p-2">
                            <ListGroupItemText className="m-0">
                              <span>
                                <FormatRelativeDate
                                  date={new Date(fromUnixTime(i.created))}
                                />
                              </span>
                              {" | "}
                              <a
                                href={i.hostedInvoiceUrl}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-private
                              >
                                <Trans>Preview Invoice</Trans>
                              </a>
                              {" | "}
                              <a
                                href={i.invoicePdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                data-private
                              >
                                <Trans>Download Invoice</Trans>
                              </a>
                            </ListGroupItemText>
                          </ListGroupItem>
                        ))}
                    </ListGroup>
                  </Col>
                </Row>
              )}
            </CardBody>
            <CardFooter>
              <CardText className="text-muted float-right">
                <Trans>Created</Trans>:{" "}
                <FormatRelativeDate date={new Date(membershipPlan.createdAt)} />
              </CardText>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

MembershipPlanDetails.propTypes = {}
MembershipPlanDetails.defaultProps = {}
