import React, { useContext } from "react"
import { Formik, Form, Field } from "formik"
import { ApiContext } from "../../providers/api-provider"
import { CLIENT_ERROR, countries } from "../../constants"
import { Input } from "../../components/input"
import { Button } from "../../components/button"
import { companyValidator } from "./company-validator"
import { CompanyContext } from "./company-provider"
import { useAsync } from "react-async"
import { toast } from "react-toastify"
import { Spinner } from "../../components/spinner"
import { path } from "ramda"
import { toastError } from "../../components/error"
import {
  Col,
  Row,
  InputGroupText,
  Container,
  Card,
  CardGroup,
  CardBody,
  FormGroup,
} from "reactstrap"
import { t, Trans } from "@lingui/macro"

export function CompanyForm() {
  const api = useContext(ApiContext)
  const { setState: setCompanyState } = useContext(CompanyContext)

  const {
    data: company,
    error,
    isPending,
  } = useAsync({
    promiseFn: api.getCompany,
  })

  if (error && path(["response", "status"], error) !== 404) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="8">
          <CardGroup>
            <Card className="p-4">
              <CardBody>
                <Formik
                  initialValues={{
                    name: "",
                    phone: "",
                    about: "",
                    street: "",
                    houseno: "",
                    city: "",
                    zip: "",
                    country: "",
                    ...company,
                  }}
                  validate={companyValidator}
                  onSubmit={async (values, actions) => {
                    try {
                      let newCompany = null

                      if (company) {
                        newCompany = await api.updateCompany(values)
                        actions.resetForm({ values: newCompany })
                      } else {
                        newCompany = await api.createCompany(values)
                      }

                      setCompanyState((state) => ({
                        ...state,
                        company: newCompany,
                      }))
                      toast.success(t`Saved`)
                    } catch (err) {
                      if (err.problem === CLIENT_ERROR) {
                        actions.setErrors({})
                      }
                      toastError(err)

                      if (!err.isAxiosError) {
                        throw err
                      }
                    } finally {
                      actions.setSubmitting(false)
                    }
                  }}
                >
                  {({ isSubmitting, isValid, dirty }) => (
                    <Form>
                      <h1>
                        <Trans>Company</Trans>
                      </h1>
                      <p className="text-muted">
                        <Trans>
                          Please fill out the following fields for your profile.
                        </Trans>
                      </p>

                      <Field
                        id="name"
                        type="text"
                        name="name"
                        component={Input}
                        label={t`Name`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-arrow-right" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Field
                        id="phone"
                        type="tel"
                        name="phone"
                        component={Input}
                        label={t`Phone`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-phone" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      />

                      <Row>
                        <Col md="8">
                          <Field
                            id="street"
                            type="text"
                            name="street"
                            component={Input}
                            label={t`Street`}
                            addon={
                              <InputGroupText>
                                <i className="fas fa-map-marker-alt" />
                              </InputGroupText>
                            }
                            addonType="prepend"
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            id="houseno"
                            type="text"
                            name="houseno"
                            component={Input}
                            label={t`House`}
                            addon={
                              <InputGroupText>
                                <i className="fas fa-map-marker-alt" />
                              </InputGroupText>
                            }
                            addonType="prepend"
                          />
                        </Col>
                      </Row>

                      <Row>
                        <Col md="8">
                          <Field
                            id="city"
                            type="text"
                            name="city"
                            component={Input}
                            label={t`City`}
                            addon={
                              <InputGroupText>
                                <i className="fas fa-map-marker-alt" />
                              </InputGroupText>
                            }
                            addonType="prepend"
                          />
                        </Col>
                        <Col md="4">
                          <Field
                            id="zip"
                            type="text"
                            name="zip"
                            component={Input}
                            label={t`Zip`}
                            addon={
                              <InputGroupText>
                                <i className="fas fa-map-marker-alt" />
                              </InputGroupText>
                            }
                            addonType="prepend"
                          />
                        </Col>
                      </Row>

                      <Field
                        id="country"
                        type="select"
                        name="country"
                        component={Input}
                        label={t`Country`}
                        addon={
                          <InputGroupText>
                            <i className="fas fa-map-marker-alt" />
                          </InputGroupText>
                        }
                        addonType="prepend"
                      >
                        {countries.map((cc) => (
                          <option key={cc.code} value={cc.code}>
                            {cc.name}
                          </option>
                        ))}
                      </Field>

                      <Field
                        id="about"
                        type="textarea"
                        name="about"
                        component={Input}
                        label={t`About`}
                        rows="9"
                      />

                      <FormGroup className="form-actions">
                        <Button
                          type="submit"
                          loading={isSubmitting}
                          disabled={!dirty || !isValid || isSubmitting}
                          color="primary"
                          block
                        >
                          <Trans>Submit</Trans>
                        </Button>
                      </FormGroup>
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </CardGroup>
        </Col>
      </Row>
    </Container>
  )
}

CompanyForm.propTypes = {}

CompanyForm.defaultProps = {}
