import { Trans, t } from "@lingui/macro"
import { endOfDay, isToday, startOfDay, startOfToday } from "date-fns"
import { intersection, sortBy } from "lodash"
import PropTypes from "prop-types"
import React, { useEffect, useMemo, useState } from "react"
import { useAsync } from "react-async"
import Select from "react-select"
import {
  ButtonGroup,
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardTitle,
  Col,
  Container,
  ListGroup,
  Row,
} from "reactstrap"
import { DelimitedNumericArrayParam, useQueryParam } from "use-query-params"
import { Button } from "../../components/button"
import { DatePickerFormless } from "../../components/datepicker"
import { Spinner } from "../../components/spinner"
import { CLIENT_ERROR } from "../../constants"
import { useApi } from "../../providers"
import { isUserTimezoneDifferentFromLocationTimezone } from "../booking/helpers"
import { useMembership } from "../membership"
import { selectOptionsToTags, tagsToSelectOptions } from "../resource/helpers"
import { FloorSelectorDropdown, ResourceDayOccupancyChart } from "./components"
import FloorplanEditor from "./floorplan-editor"
import {
  getOccupiedResourcesByBookings,
  getOccupiedResourcesByMembershipPlans,
} from "./helpers/index"

function FloorplanEditorMapBackground({ children, imgUrl }) {
  if (!imgUrl) {
    return (
      <div>
        <Trans>There is no floorplan uploaded!</Trans>
      </div>
    )
  }

  return (
    <Container
      fluid
      id="floorplan-container"
      className="min-vh-100 p-0"
      style={{
        backgroundRepeat: "no-repeat",
        backgroundImage: `url(${imgUrl})`,
      }}
    >
      {children}
    </Container>
  )
}

FloorplanEditorMapBackground.propTypes = {
  children: PropTypes.node.isRequired,
  imgUrl: PropTypes.string.isRequired,
}

export const DatePickerCustomInput = React.forwardRef(
  ({ value, onClick }, ref) => (
    <Button
      color="primary"
      className="example-custom-input"
      onClick={onClick}
      ref={ref}
    >
      <i className="far fa-calendar" /> {value}
    </Button>
  ),
)
DatePickerCustomInput.propTypes = {
  value: PropTypes.string,
  onClick: PropTypes.func,
}

export default function Floorplan() {
  const api = useApi()
  const { selectedMembership } = useMembership()
  const initialFloorId = useMemo(() => {
    const val = parseInt(localStorage.getItem("selectedFloorId"), 10)
    return Number.isFinite(val) ? val : null
  }, [])
  const [selectedFloorId, setSelectedFloorId] = useState(initialFloorId)

  const [selectedResource, setSelectedResource] = useState()
  const [selectedDateTime, setSelectedDateTime] = useState(new Date())
  const [occupiedResources, setOccupiedResources] = useState([])
  let [filterTagIds, setFilterTagIds] = useQueryParam(
    "tags",
    DelimitedNumericArrayParam,
  )
  filterTagIds = filterTagIds ?? []

  useEffect(() => {
    localStorage.setItem("selectedFloorId", selectedFloorId)
  }, [selectedFloorId])

  const {
    data: floorsData,
    error: floorsError,
    isPending: floorsIsPending,
  } = useAsync({
    promiseFn: api.getFloorList,
    locationId: selectedMembership.locationId,
    membershipId: selectedMembership.id,
    onResolve: async (data) => {
      if (data.count === 0) return

      await runFetchPoints({
        locationId: selectedMembership.locationId,
        membershipId: selectedMembership.id,
        floorId: selectedFloorId ?? data.floors[0].id,
        rangeEnd: 999,
      })

      await runGetMembershipPlanList({
        locationId: selectedMembership.locationId,
        membershipId: selectedMembership.id,
        startAtGte: selectedDateTime,
        endAtLte: endOfDay(selectedDateTime),
      })

      await runGetBookingList({
        locationId: selectedMembership.locationId,
        membershipId: selectedMembership.id,
        startAtGte: startOfDay(selectedDateTime),
        endAtLte: endOfDay(selectedDateTime),
        rangeStart: 0,
        rangeEnd: 999,
        eager: "[membership.[account.[company,users]],resource,user]",
      })

      if (!selectedFloorId) {
        setSelectedFloorId(data.floors[0]?.id)
      }
    },
  })

  const {
    data: pointsData,
    error: pointsError,
    isPending: pointsIsPending,
    run: runFetchPoints,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getPointList(args)
      return data
    },
  })

  const {
    data: resourcesData,
    error: resourcesError,
    isPending: resourcesIsPending,
  } = useAsync({
    promiseFn: api.getResourceList,
    membershipId: selectedMembership.id,
    eager: "[resourceType,resourceTags]",
  })

  const {
    data: membershipPlansData,
    error: membershipPlansError,
    isPending: membershipPlansIsPending,
    run: runGetMembershipPlanList,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getMembershipPlanList(args)
      return data
    },
  })

  const {
    data: bookingsData,
    error: bookingsError,
    isPending: bookingsIsPending,
    run: runGetBookingList,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getBookingListByLocation(args)
      return data
    },
  })

  const {
    data: tagsData,
    error: tagsError,
    isPending: tagsIsPending,
  } = useAsync({
    promiseFn: api.getResourceTagsList,
    membershipId: selectedMembership.id,
  })

  const {
    data: occupancyData,
    error: occupancyError,
    isPending: occupancyIsPending,
    run: runGetResourceOccupancy,
  } = useAsync({
    deferFn: async ([args]) => {
      const data = await api.getResourceOccupancy(args)
      return data
    },
  })

  async function onDateChange(date) {
    await runGetMembershipPlanList({
      locationId: selectedMembership.locationId,
      membershipId: selectedMembership.id,
      startAtGte: startOfDay(date),
      endAtLte: endOfDay(date),
    })

    await runGetBookingList({
      locationId: selectedMembership.locationId,
      membershipId: selectedMembership.id,
      startAtGte: startOfDay(date),
      endAtLte: endOfDay(date),
      rangeStart: 0,
      rangeEnd: 999,
      eager: "[membership.[account.[company,users]],resource,user]",
    })

    setOccupiedResources([
      ...getOccupiedResourcesByBookings(bookingsData.bookings, date),
      ...getOccupiedResourcesByMembershipPlans(
        membershipPlansData.membershipPlans,
        date,
      ),
    ])

    setSelectedDateTime(date)

    await runGetResourceOccupancy({
      locationId: selectedMembership.locationId,
      membershipId: selectedMembership.id,
      resourceId: selectedResource.id,
      startAt: startOfDay(date),
      endAt: endOfDay(date),
      timezone: "Europe/Berlin", // TODO: more dragons... selectedLocation.timezone
    })
  }

  function clickResourceOnMap(resourceId) {
    const newSelectedResource = resources.find((r) => r.id === resourceId)

    setSelectedResource(newSelectedResource)

    runGetResourceOccupancy({
      locationId: selectedMembership.locationId,
      membershipId: selectedMembership.id,
      resourceId: newSelectedResource.id,
      startAt: startOfDay(selectedDateTime),
      endAt: endOfDay(selectedDateTime),
      timezone: "Europe/Berlin", // TODO: Dragon #3 is released...
    })
  }

  const userImgArr = [
    ...new Set(
      bookingsData?.bookings
        .map((b) => b.user?.img || b.recurringBooking?.user?.img)
        .filter(Boolean) ?? [],
    ),
  ]

  useEffect(() => {
    if (
      floorsData &&
      floorsData.count > 0 &&
      pointsData &&
      bookingsData &&
      membershipPlansData &&
      (bookingsData.count > 0 || membershipPlansData.count > 0)
    ) {
      setOccupiedResources([
        ...getOccupiedResourcesByBookings(
          bookingsData.bookings,
          selectedDateTime,
        ),
        ...getOccupiedResourcesByMembershipPlans(
          membershipPlansData.membershipPlans,
          selectedDateTime,
        ),
      ])
    }
  }, [
    floorsData,
    pointsData,
    bookingsData,
    membershipPlansData,
    selectedDateTime,
    setOccupiedResources,
  ])

  useEffect(() => {
    if (!selectedResource && resourcesData && resourcesData.count > 0) {
      setSelectedResource(resourcesData.resources[0])

      runGetResourceOccupancy({
        locationId: selectedMembership.locationId,
        membershipId: selectedMembership.id,
        resourceId: resourcesData.resources[0].id,
        startAt: startOfDay(selectedDateTime),
        endAt: endOfDay(selectedDateTime),
        timezone: "Europe/Berlin", // TODO: Dragon #4 is released... how many more?..
      })
    }
  }, [
    selectedResource,
    selectedMembership,
    selectedDateTime,
    resourcesData,
    setSelectedResource,
    runGetResourceOccupancy,
  ])

  function filterResourcesByTags(resource) {
    const filterTagIds = filterTags.map((t) => t.id)
    return filterTagIds.length > 0
      ? intersection(
          resource.resourceTags.map((rt) => rt.id),
          filterTagIds,
        ).length === filterTagIds.length
      : true
  }

  if (pointsError?.problem === CLIENT_ERROR && !pointsIsPending) {
    runFetchPoints({
      locationId: selectedMembership.locationId,
      membershipId: selectedMembership.id,
      floorId: floorsData.floors[0]?.id,
      rangeEnd: 999,
    })

    setSelectedFloorId(floorsData.floors[0]?.id)
  }

  if (floorsError) throw floorsError

  if (resourcesError) throw resourcesError
  if (membershipPlansError) throw membershipPlansError
  if (bookingsError) throw bookingsError
  if (occupancyError) throw occupancyError

  if (
    floorsIsPending ||
    resourcesIsPending ||
    membershipPlansIsPending ||
    bookingsIsPending ||
    pointsIsPending
  ) {
    return <Spinner />
  }

  if (pointsError) throw pointsError

  const { floors, count: floorsCount } = floorsData
  const points = sortBy(pointsData?.points ?? [], "id")
  const { resources } = resourcesData
  const occupancy = occupancyData?.occupancy ?? []

  const selectedFloor = floors.find((f) => f.id === selectedFloorId)
  const filterTags = (tagsData?.resourceTags ?? []).filter((t) =>
    filterTagIds.includes(t.id),
  )

  if (floorsCount === 0) {
    return (
      <Container fluid className="py-4">
        <Row>
          <Col>
            <Trans>
              There are no Floors (and Floorplans) assigned to this location.
              Ask a staff member about adding a floorplan for booking.
            </Trans>
          </Col>
        </Row>
      </Container>
    )
  }

  return (
    <Container fluid className="py-4">
      <Row>
        <Col xs="12" lg="9">
          <ListGroup>
            <Card>
              <CardHeader>
                <CardTitle className="mb-0">
                  <Row>
                    <Col>
                      <CardText className="h3">
                        {selectedFloor && selectedFloor.name}
                      </CardText>
                    </Col>
                    <Col className="text-right">
                      <ButtonGroup>
                        <DatePickerFormless
                          id="floorplan-datetime-picker"
                          selected={selectedDateTime}
                          customInput={<DatePickerCustomInput />}
                          onChange={onDateChange}
                        />

                        <Button
                          color="primary"
                          onClick={() => onDateChange(startOfToday())}
                          className="ml-2"
                          disabled={isToday(selectedDateTime)}
                        >
                          <i className="far fa-calendar" /> Today
                        </Button>

                        {selectedFloor && selectedMembership && (
                          <FloorSelectorDropdown
                            className="ml-2"
                            style={{ maxWidth: "120px" }}
                            selectedFloorName={selectedFloor.name}
                            selectedFloorId={selectedFloor.id}
                            floors={floors}
                            disabled={pointsIsPending}
                            loading={pointsIsPending}
                            onSelect={async (newFloor) => {
                              setSelectedFloorId(newFloor.id)

                              await runFetchPoints({
                                locationId: selectedMembership.locationId,
                                membershipId: selectedMembership.id,
                                floorId: newFloor.id,
                                rangeEnd: 999,
                              })
                            }}
                          />
                        )}
                      </ButtonGroup>
                      {selectedFloor && selectedMembership && (
                        <Select
                          id="search-filter-tags"
                          className="multi-select-input mt-2"
                          onChange={(options) =>
                            setFilterTagIds(
                              options
                                ?.map(selectOptionsToTags)
                                .map((t) => t.id) || [],
                            )
                          }
                          isClearable
                          isMulti
                          isLoading={tagsIsPending}
                          disabled={tagsError}
                          options={
                            tagsData?.resourceTags?.map(tagsToSelectOptions) ||
                            []
                          }
                          value={filterTags.map(tagsToSelectOptions) || []}
                          placeholder={t`Filter`}
                        />
                      )}
                    </Col>
                  </Row>
                  {userImgArr.map((img) => {
                    return (
                      <img
                        className="rounded-circle mb-2 mr-1"
                        src={img}
                        alt="img"
                        key={img}
                        width="50px"
                        height="50px"
                      />
                    )
                  })}
                  <CardText className="text-muted">
                    <Trans>Resource occupancy overview</Trans>
                  </CardText>
                </CardTitle>
              </CardHeader>

              <CardBody className="custom-timeline">
                {selectedFloor && points && resources && occupiedResources && (
                  <FloorplanEditorMapBackground
                    imgUrl={selectedFloor.floorplanImgUrl}
                  >
                    <FloorplanEditor
                      points={points}
                      resources={resources.filter(filterResourcesByTags)}
                      occupiedResources={occupiedResources}
                      loading={pointsIsPending}
                      selectedResource={selectedResource}
                      onResourceMouseClick={clickResourceOnMap}
                    />
                  </FloorplanEditorMapBackground>
                )}
              </CardBody>
            </Card>
          </ListGroup>
        </Col>

        <Col xs="12" lg="3">
          <Row>
            <Col xs="6" lg="12">
              {isUserTimezoneDifferentFromLocationTimezone(
                selectedMembership.location.timezone,
              ) && (
                <Card body inverse color="danger">
                  <CardText className="text-white font-weight-bold">
                    <i className="fas fa-exclamation-triangle" />{" "}
                    <Trans>
                      Your timezone differs from the location timezone
                    </Trans>
                  </CardText>
                </Card>
              )}
            </Col>
            <Col xs="6" lg="12">
              {selectedMembership && selectedResource && selectedDateTime && (
                <ResourceDayOccupancyChart
                  occupancy={occupancy}
                  loading={occupancyIsPending}
                  error={occupancyError}
                  selectedMembership={selectedMembership}
                  selectedResource={selectedResource}
                  selectedDate={selectedDateTime}
                />
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Container>
  )
}
