import React from "react"
import PropTypes from "prop-types"
import RSelect from "react-select"
import { ErrorMessage, getIn } from "formik"
import { FormFeedback } from "./form-feedback"
import {
  FormGroup,
  Label,
  InputGroupAddon,
  InputGroup,
  Spinner,
} from "reactstrap"

export const Select = ({
  id,
  field,
  form,
  className,
  label,
  disabled,
  hint,
  addon,
  addonType,
  options,
  value,
  valid,
  invalid,
  onChange,
  isLoading,
  isClearable,
  modifySetFieldValue,
}) => {
  const formFeedback = (
    <ErrorMessage name={field.name} component={FormFeedback} />
  )

  const input = (
    <RSelect
      {...field}
      id={id}
      onChange={(res) => {
        onChange && onChange(res)
        return form.setFieldValue(field.name, modifySetFieldValue(res))
      }}
      valid={
        valid === null
          ? null
          : getIn(form.touched, field.name) && !getIn(form.errors, field.name)
      }
      invalid={
        invalid === null
          ? null
          : getIn(form.touched, field.name) && !!getIn(form.errors, field.name)
      }
      isDisabled={disabled}
      options={options}
      value={value}
      hideSelectedOptions
      isClearable={isClearable}
    />
  )

  if (isLoading) return <Spinner />

  return (
    <FormGroup className={`${className}`}>
      {label && <Label for={id}>{label}</Label>}

      {hint}

      {addon ? (
        <InputGroup>
          {addonType === "prepend" && (
            <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>
          )}
          {input}
          {addonType === "append" && (
            <InputGroupAddon addonType={addonType}>{addon}</InputGroupAddon>
          )}
          {formFeedback}
        </InputGroup>
      ) : (
        input
      )}

      {formFeedback}
    </FormGroup>
  )
}

Select.defaultProps = {
  disabled: false,
  options: [],
  value: null,
  onChange: () => {},
  modifySetFieldValue: (vars) => vars,
  isLoading: false,
  isClearable: true,
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  options: PropTypes.array.isRequired,
  value: PropTypes.object,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  valid: PropTypes.bool,
  invalid: PropTypes.bool,
  label: PropTypes.node,
  hint: PropTypes.node,
  addon: PropTypes.node,
  addonType: PropTypes.node,
  field: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onChange: PropTypes.func,
  modifySetFieldValue: PropTypes.func,
  isLoading: PropTypes.bool,
  isClearable: PropTypes.bool,
}
