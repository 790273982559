import React, {
  createContext,
  useContext,
  useCallback,
  useState,
  useRef,
} from "react"
import PropTypes from "prop-types"
import { ConfirmationDialog } from "./confirmation-dialog"

const ConfirmationServiceContext = createContext(Promise.reject)

export const useConfirmation = () => useContext(ConfirmationServiceContext)

export const ConfirmationDialogProvider = ({ children }) => {
  const [confirmationState, setConfirmationState] = useState(null)
  const [isConfirming, setIsConfirming] = useState(false)
  const [isClosing, setIsClosing] = useState(false)

  const awaitingPromiseRef = useRef()

  const openConfirmation = useCallback(
    (options) => {
      setConfirmationState(options)
      return new Promise((resolve, reject) => {
        awaitingPromiseRef.current = { resolve, reject }
      })
    },
    [setConfirmationState, awaitingPromiseRef],
  )

  const handleClose = async () => {
    if (confirmationState.onCancel) {
      try {
        setIsClosing(true)
        await confirmationState.onCancel()
      } finally {
        setIsClosing(false)
      }
    }

    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject()
    }

    setConfirmationState(null)
  }

  const handleSubmit = async () => {
    if (confirmationState.onConfirm) {
      try {
        setIsConfirming(true)
        await confirmationState.onConfirm()
      } finally {
        setIsConfirming(false)
      }
    }

    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve()
    }

    setConfirmationState(null)
  }

  return (
    <>
      <ConfirmationServiceContext.Provider value={openConfirmation}>
        {children}
      </ConfirmationServiceContext.Provider>

      <ConfirmationDialog
        isOpen={Boolean(confirmationState)}
        isConfirming={isConfirming}
        isClosing={isClosing}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  )
}

ConfirmationDialogProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

ConfirmationDialogProvider.defaultProps = {}
