import { orderBy } from "lodash"
import React, { useContext } from "react"
import { useAsync } from "react-async"
import { Link } from "react-router-dom"
import { urls } from "../../urls"
import { reverse } from "named-urls"
import { ApiContext } from "../../providers/api-provider"
import { UserContext } from "../account/user-provider"
import { Spinner } from "../../components/spinner"
import { BackButton } from "../../components/backbutton"
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardTitle,
  CardBody,
  CardText,
  Button,
  ListGroup,
  ListGroupItem,
  Badge,
} from "reactstrap"
import { USER } from "../../constants"
import { isPast } from "date-fns"
import { TEST, VACC, RCVD } from "./constants"
import { t, Trans } from "@lingui/macro"

export function CovidCertificates() {
  const api = useContext(ApiContext)
  const { user: currentUser } = useContext(UserContext)

  const {
    data: documentsData,
    error,
    isPending,
  } = useAsync({
    promiseFn: api.getMyCovidCertificatesList,
    srcType: USER,
    srcId: currentUser.id,
  })

  if (error) {
    throw error
  }

  if (isPending) {
    return <Spinner />
  }

  function renderBadge(covdoc) {
    if (isPast(new Date(covdoc.validUntil))) {
      return (
        <Badge color="danger" className="ml-2">
          <Trans>outdated</Trans>
        </Badge>
      )
    }
    if (covdoc.type === TEST) {
      return (
        <Badge color="warning" className="ml-2">
          <Trans>valid 1-day</Trans>
        </Badge>
      )
    }
    if ([VACC, RCVD].includes(covdoc.type)) {
      return (
        <Badge color="success" className="ml-2">
          <Trans>valid</Trans>
        </Badge>
      )
    }
    return null
  }

  return (
    <Container className="py-4">
      <Row className="justify-content-center">
        <Col md="10">
          <Card>
            <CardHeader>
              <CardTitle className="font-3xl">
                <Row>
                  <Col xs="6" md="3">
                    <BackButton />
                  </Col>
                  <Col
                    md="6"
                    className="d-none d-md-flex justify-content-center"
                  >
                    <CardText>
                      <Trans>COVID-19 Certificates & Tests</Trans>
                    </CardText>
                  </Col>
                  <Col md="3" className="d-flex justify-content-end">
                    <Link to={reverse(urls.covid.create)}>
                      <Button color="primary">
                        <Trans>Upload</Trans>
                      </Button>
                    </Link>
                  </Col>
                </Row>
              </CardTitle>
            </CardHeader>

            <CardBody>
              <ListGroup>
                {documentsData.count === 0 && t`No documents`}

                {documentsData.count > 0 &&
                  orderBy(
                    documentsData.documents,
                    ["createdAt", "updatedAt"],
                    ["desc", "desc"],
                  ).map((covdoc) => {
                    if (isPast(new Date(covdoc.validUntil))) {
                      return (
                        <ListGroupItem key={covdoc.id}>
                          {covdoc.document.title}
                          {renderBadge(covdoc)}
                        </ListGroupItem>
                      )
                    }
                    return (
                      <Link
                        key={covdoc.id}
                        to={reverse(urls.covid.edit, {
                          documentId: covdoc.document.id,
                        })}
                      >
                        <ListGroupItem action>
                          {covdoc.document.title}
                          {renderBadge(covdoc)}
                        </ListGroupItem>
                      </Link>
                    )
                  })}
              </ListGroup>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

CovidCertificates.propTypes = {}
CovidCertificates.defaultProps = {}
