import cookies from "js-cookie"
import LogRocket from "logrocket"
import PropTypes from "prop-types"
import { path } from "ramda"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { useQueryParam } from "use-query-params"
import { Spinner } from "../../components/spinner"
import { config } from "../../config"
import { Base64JsonParam, isIntertempiUser } from "../../lib/helpers"
import {
  requestSanitizer,
  responseSanitizer,
} from "../../lib/logrocket-sanitizers"
import { Sentry } from "../../lib/sentry"
import { ApiContext } from "../../providers"
import { useMembership } from "../membership"
import { AccountContext } from "./account-provider"
import { AuthForm } from "./auth-form-alt"

export const UserContext = React.createContext()

const defaultState = {
  user: null,
  error: null,
  isPending: true,
  isLoggingIn: false,
}

export const UserProvider = ({ children }) => {
  const api = useContext(ApiContext)

  const [state, setState] = useState(defaultState)

  const getUser = useCallback(async () => {
    try {
      const user = await api.getMyUser()

      setState((state) => ({
        ...state,
        user,
        isPending: false,
        error: null,
      }))

      Sentry.configureScope((scope) => {
        scope.setUser({ id: user.id })
      })
      const isStatisticsCookies = cookies.get("rcl_preferences_consent")

      if (
        process.env.NODE_ENV === "production" &&
        isStatisticsCookies &&
        !isIntertempiUser(user)
      ) {
        LogRocket.init(config.logrocket.url, {
          ...config.logrocket.options,
          network: {
            requestSanitizer: requestSanitizer,
            responseSanitizer: responseSanitizer,
          },
        })

        LogRocket.getSessionURL((sessionURL) => {
          Sentry.configureScope((scope) => {
            scope.setExtra("sessionURL", sessionURL)
          })
        })

        LogRocket.identify(String(user.id))
      }
    } catch (error) {
      setState((state) => ({ ...state, user: null, isPending: false, error }))

      if (!error.isAxiosError) {
        throw error
      }
    }
  }, [api])

  function setLoggingIn(isLoggingIn) {
    return setState((state) => ({ ...state, isLoggingIn }))
  }

  function reset() {
    return setState({ ...defaultState, isPending: false })
  }

  useEffect(() => {
    getUser()
  }, [getUser])

  const { user, error, isPending, isLoggingIn } = state

  if (error && path(["response", "status"], error) !== 401) {
    throw error
  }

  if (isPending) return <Spinner />

  return (
    <UserContext.Provider
      value={{
        isPending,
        isLoggingIn,
        setLoggingIn,
        error,
        user,
        setState,
        getUser,
        reset,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}

UserProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

UserProvider.defaultProps = {}

export const WithUserRequired = ({ children }) => {
  const { user, isLoggingIn } = useContext(UserContext)
  const { setSelectedAccount } = useContext(AccountContext)
  const { reset: resetMembership } = useMembership()
  const [invite, setInvite] = useQueryParam("invite", Base64JsonParam)

  if (isLoggingIn || !user) {
    return <AuthForm />
  }

  if (user && invite) {
    setSelectedAccount(null)
    resetMembership()
    setInvite(null)
  }

  return children
}

WithUserRequired.propTypes = {
  children: PropTypes.node.isRequired,
}

WithUserRequired.defaultProps = {}
