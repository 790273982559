/*eslint-disable*/ module.exports = {
  messages: {
    "<0>Welcome back to <1>Member Portal</1></0>":
      "<0>Welcome back to <1>Member Portal</1></0>",
    About: "About",
    Accept: "Accept",
    Account: "Account",
    "Accounts / Invites": "Accounts / Invites",
    "Action is temporarily unavailable.": "Action is temporarily unavailable.",
    "Active Teammates": "Active Teammates",
    "Add SEPA Direct Debit to pay automatically":
      "Add SEPA Direct Debit to pay automatically",
    "Add to Google calendar": "Add to Google calendar",
    "Add to Outlook calendar": "Add to Outlook calendar",
    "All recurring booking instances": "All recurring booking instances",
    "All upcoming": "All upcoming",
    Archived: "Archived",
    "Are you sure you want to delete the booking":
      "Are you sure you want to delete the booking",
    "Are you sure you want to delete this Account? The account will be deleted if only there are no other users, no resources and no bookings registered on it.":
      "Are you sure you want to delete this Account? The account will be deleted if only there are no other users, no resources and no bookings registered on it.",
    "Are you sure you want to leave this account?":
      "Are you sure you want to leave this account?",
    "Are you sure you want to make another user a new owner if this account? If confirmed, changes will apply instantly.":
      "Are you sure you want to make another user a new owner if this account? If confirmed, changes will apply instantly.",
    "Are you sure you want to remove the user {0} {1} {2} from account {3} {4}?":
      [
        "Are you sure you want to remove the user ",
        ["0"],
        " ",
        ["1"],
        " ",
        ["2"],
        " from account ",
        ["3"],
        " ",
        ["4"],
        "?",
      ],
    "Ask your Operator to check the Location settings to be able to checkin and checkout.":
      "Ask your Operator to check the Location settings to be able to checkin and checkout.",
    Attendees: "Attendees",
    Billing: "Billing",
    "Billing Information": "Billing Information",
    Book: "Book",
    "Book Now": "Book Now",
    "Book now": "Book now",
    "Book now and Check-in": "Book now and Check-in",
    "Bookable Resource": "Bookable Resource",
    "Bookable by": "Bookable by",
    "Bookable resources with occupancy state":
      "Bookable resources with occupancy state",
    "Booked Resource": "Booked Resource",
    Booking: "Booking",
    "Booking made for": "Booking made for",
    "Booking summary": "Booking summary",
    Bookings: "Bookings",
    Busy: "Busy",
    "COVID Certificate": "COVID Certificate",
    "COVID-19 Certificates & Tests": "COVID-19 Certificates & Tests",
    "COVID-19 certificate required": "COVID-19 certificate required",
    Calendar: "Calendar",
    "Can't edit booking with finalized invoice.":
      "Can't edit booking with finalized invoice.",
    "Can't edit started booking.": "Can't edit started booking.",
    Cancel: "Cancel",
    Cancelation: "Cancelation",
    "Cannot calculate the distance to the location!":
      "Cannot calculate the distance to the location!",
    "Cannot set this starting time": "Cannot set this starting time",
    "Change Owner": "Change Owner",
    "Check your email Inbox": "Check your email Inbox",
    "Check-in": "Check-in",
    "Checked-in": "Checked-in",
    Checkins: "Checkins",
    City: "City",
    Company: "Company",
    "Company Business Name": "Company Business Name",
    Confirm: "Confirm",
    "Confirm Changing Account Owner": "Confirm Changing Account Owner",
    "Confirm Delete Booking": "Confirm Delete Booking",
    "Confirm Leaving This Account": "Confirm Leaving This Account",
    "Confirm Re-send Invite": "Confirm Re-send Invite",
    "Confirm Remove User From Account": "Confirm Remove User From Account",
    "Confirm personal liability": "Confirm personal liability",
    "Consider using another web browser for reliability of the app features":
      "Consider using another web browser for reliability of the app features",
    "Contact person name, info": "Contact person name, info",
    "Copied to clipboard": "Copied to clipboard",
    "Copy title": "Copy title",
    Cost: "Cost",
    Country: "Country",
    "Country should have a valid country code":
      "Country should have a valid country code",
    "Create New": "Create New",
    "Create New Organization-Account": "Create New Organization-Account",
    Created: "Created",
    "Created At": "Created At",
    "Current location": "Current location",
    "Danger Zone": "Danger Zone",
    "Date:": "Date:",
    Day: "Day",
    Decline: "Decline",
    "Delete Account": "Delete Account",
    "Delete Booking": "Delete Booking",
    Description: "Description",
    "Description should be at most {0} characters long": [
      "Description should be at most ",
      ["0"],
      " characters long",
    ],
    "Do you want to re-send invitation Email to {0} {1} on {2}? Please wait for at least 10 minutes before re-sending a new Email.":
      [
        "Do you want to re-send invitation Email to ",
        ["0"],
        " ",
        ["1"],
        " on ",
        ["2"],
        "? Please wait for at least 10 minutes before re-sending a new Email.",
      ],
    "Document title": "Document title",
    Documents: "Documents",
    Done: "Done",
    "Done!": "Done!",
    "Download ICalendar (ics) file": "Download ICalendar (ics) file",
    "Download Invoice": "Download Invoice",
    Duration: "Duration",
    "Duration Unit": "Duration Unit",
    "Edit Booking": "Edit Booking",
    "Edit Recurring Booking": "Edit Recurring Booking",
    "Edit title": "Edit title",
    Email: "Email",
    "Email is required": "Email is required",
    End: "End",
    "End at:": "End at:",
    "End date": "End date",
    "Ending at": "Ending at",
    English: "English",
    "Enter teammate details": "Enter teammate details",
    "Enter your One Time Password": "Enter your One Time Password",
    "Enter your details to register.": "Enter your details to register.",
    "Enter your email to get your login code.":
      "Enter your email to get your login code.",
    "Error loading bookings. Try to use <0><1>booking search</1></0> instead.":
      "Error loading bookings. Try to use <0><1>booking search</1></0> instead.",
    "File (scan) of the certificate or test as PDF or an image":
      "File (scan) of the certificate or test as PDF or an image",
    'File format "{0}" not supported': [
      'File format "',
      ["0"],
      '" not supported',
    ],
    "File reading has failed": "File reading has failed",
    "File reading was aborted": "File reading was aborted",
    Filter: "Filter",
    "Filter by resource": "Filter by resource",
    "Filter by status": "Filter by status",
    "Filter by tags": "Filter by tags",
    Finished: "Finished",
    "First Name": "First Name",
    Floorplans: "Floorplans",
    From: "From",
    "Full name": "Full name",
    "Full-day": "Full-day",
    "German (Deutsch)": "German (Deutsch)",
    "Getting your current location": "Getting your current location",
    "Go Back": "Go Back",
    "Half Hour": "Half Hour",
    "Here to booking": "Here to booking",
    House: "House",
    "Houston, we have a problem!": "Houston, we have a problem!",
    "I agree with the <0>Terms & conditions</0> and <1>Privacy policy.</1>":
      "I agree with the <0>Terms & conditions</0> and <1>Privacy policy.</1>",
    "I agree with the <0>Terms & conditions</0> and <1>Privacy policy</1>.":
      "I agree with the <0>Terms & conditions</0> and <1>Privacy policy</1>.",
    "I agree with the <0>terms and conditions.</0>":
      "I agree with the <0>terms and conditions.</0>",
    "I confirm personal responsibility for the information provided above and I will bear any legal consequences in case of providing false information in this form.":
      "I confirm personal responsibility for the information provided above and I will bear any legal consequences in case of providing false information in this form.",
    "If you do not see the resource in the list, then maybe you do not have permissions to book it. Ask your manager to add you permissions.":
      "If you do not see the resource in the list, then maybe you do not have permissions to book it. Ask your manager to add you permissions.",
    "If you have no invites, double-check your email inbox and SPAM folder for the invitation. If you did not receive an invite email, then contact your Location Operator.":
      "If you have no invites, double-check your email inbox and SPAM folder for the invitation. If you did not receive an invite email, then contact your Location Operator.",
    "If you need more accounts, please contact support.":
      "If you need more accounts, please contact support.",
    "If you want to delete the account, make sure there are no other Users using it, no Resources, no active Memberships atc.":
      "If you want to delete the account, make sure there are no other Users using it, no Resources, no active Memberships atc.",
    "If you wanted to use Operators Portal instead, please log-out and login into the Operators Portal application.":
      "If you wanted to use Operators Portal instead, please log-out and login into the Operators Portal application.",
    "Invalid data. Please check your input.":
      "Invalid data. Please check your input.",
    Invite: "Invite",
    "Invite accepted": "Invite accepted",
    "Invite declined": "Invite declined",
    "Invite new teammate": "Invite new teammate",
    "Invite sent!": "Invite sent!",
    "Invite teammate": "Invite teammate",
    "Invited by": "Invited by",
    Invites: "Invites",
    Invoice: "Invoice",
    "It will be shown on your booking invoice.":
      "It will be shown on your booking invoice.",
    "It will be shown on your booking invoice. ex":
      "It will be shown on your booking invoice. ex",
    Language: "Language",
    "Last Name": "Last Name",
    "Last activity": "Last activity",
    "Last activity at": "Last activity at",
    "Last edit": "Last edit",
    "Last occurence": "Last occurence",
    "Latest Invoices": "Latest Invoices",
    "Leave Account": "Leave Account",
    "Leave account": "Leave account",
    Loading: "Loading",
    "Location privacy mode is turned ON": "Location privacy mode is turned ON",
    "Location time": "Location time",
    Login: "Login",
    "Login to <0>Operator Portal</0>": "Login to <0>Operator Portal</0>",
    "Logo image in SVG format": "Logo image in SVG format",
    Logout: "Logout",
    "Make private": "Make private",
    Membership: "Membership",
    "Membership Plan": "Membership Plan",
    Month: "Month",
    "Must be valid URL format": "Must be valid URL format",
    "My Bookings": "My Bookings",
    "My Company": "My Company",
    "My Documents": "My Documents",
    "My Profile": "My Profile",
    "My Team": "My Team",
    Name: "Name",
    "Network error!": "Network error!",
    "No data!": "No data!",
    "No description": "No description",
    "No documents": "No documents",
    "No files attached": "No files attached",
    "No organizations. Create a new one to get started.":
      "No organizations. Create a new one to get started.",
    "No resources to show": "No resources to show",
    "No resources to show. Please create your first resource to see the calendar view.":
      "No resources to show. Please create your first resource to see the calendar view.",
    Notes: "Notes",
    "Nothing yet": "Nothing yet",
    Ok: "Ok",
    "One Time Password": "One Time Password",
    "One time password sent! Please check your inbox.":
      "One time password sent! Please check your inbox.",
    "Only this one": "Only this one",
    "Oops! Something went wrong.": "Oops! Something went wrong.",
    "Oops! You're lost.": "Oops! You're lost.",
    "Open in tab": "Open in tab",
    Organizations: "Organizations",
    "Our crew will be notified about this.":
      "Our crew will be notified about this.",
    "Overview of your resources occupation.":
      "Overview of your resources occupation.",
    Pattern: "Pattern",
    "Personal details": "Personal details",
    Phone: "Phone",
    Plans: "Plans",
    "Please check your internet connection.":
      "Please check your internet connection.",
    "Please create a name for your company or personal account.":
      "Please create a name for your company or personal account.",
    "Please describe the issue here": "Please describe the issue here",
    "Please fill out the following fields for your profile.":
      "Please fill out the following fields for your profile.",
    "Please pay attention that your timezone differs from the location timezone.":
      "Please pay attention that your timezone differs from the location timezone.",
    "Preview Invoice": "Preview Invoice",
    Price: "Price",
    "Privacy settings do not allow you to see these details.":
      "Privacy settings do not allow you to see these details.",
    Private: "Private",
    "Private booking": "Private booking",
    Recurring: "Recurring",
    "Recurring Booking Details": "Recurring Booking Details",
    Register: "Register",
    Remove: "Remove",
    Repeat: "Repeat",
    Repeats: "Repeats",
    "Request is sent": "Request is sent",
    Resource: "Resource",
    "Resource Name": "Resource Name",
    "Resource is free": "Resource is free",
    "Resource occupancy overview": "Resource occupancy overview",
    Resources: "Resources",
    Role: "Role",
    Running: "Running",
    Save: "Save",
    "Save changes": "Save changes",
    Saved: "Saved",
    "Search by text": "Search by text",
    "Select a Day": "Select a Day",
    "Select a Resource from the list to see its availability / occupancy on the calendar.":
      "Select a Resource from the list to see its availability / occupancy on the calendar.",
    "Select an organization to start.": "Select an organization to start.",
    "Select language": "Select language",
    "Select location": "Select location",
    "Select teammate": "Select teammate",
    Service: "Service",
    "Service Ticket": "Service Ticket",
    Settings: "Settings",
    "Show all bookings": "Show all bookings",
    "Something went wrong": "Something went wrong",
    "Sorry, you must agree to our privacy policy before you can login.":
      "Sorry, you must agree to our privacy policy before you can login.",
    "Sorry, you must agree to our privacy policy before you can register.":
      "Sorry, you must agree to our privacy policy before you can register.",
    Start: "Start",
    "Start at:": "Start at:",
    "Start date": "Start date",
    "Start inviting teammates.": "Start inviting teammates.",
    "Starting at": "Starting at",
    Status: "Status",
    Street: "Street",
    Submit: "Submit",
    Success: "Success",
    "Summarize recurring bookings": "Summarize recurring bookings",
    "Switch account": "Switch account",
    System: "System",
    "Team Bookings": "Team Bookings",
    "Team Profile": "Team Profile",
    "Teammate Invites": "Teammate Invites",
    Teammates: "Teammates",
    "Terms & Conditions": "Terms & Conditions",
    "Thank you for submitting a request!":
      "Thank you for submitting a request!",
    "The page you are looking for is temporarily unavailable.":
      "The page you are looking for is temporarily unavailable.",
    "The page you are looking for was not found.":
      "The page you are looking for was not found.",
    "There are no Floors (and Floorplans) assigned to this location. Ask a staff member about adding a floorplan for booking.":
      "There are no Floors (and Floorplans) assigned to this location. Ask a staff member about adding a floorplan for booking.",
    "There are no Resources to book": "There are no Resources to book",
    "There are no active bookings to show":
      "There are no active bookings to show",
    "There is no floorplan uploaded!": "There is no floorplan uploaded!",
    "There's no available activity information yet.":
      "There's no available activity information yet.",
    "This time is reserved for maintenance.":
      "This time is reserved for maintenance.",
    "This title is private to your account/company.":
      "This title is private to your account/company.",
    Time: "Time",
    "Time buffer": "Time buffer",
    Timeline: "Timeline",
    Title: "Title",
    "Title should be at most {0} characters long": [
      "Title should be at most ",
      ["0"],
      " characters long",
    ],
    "To provide the checkin we need to know your current location":
      "To provide the checkin we need to know your current location",
    Today: "Today",
    Type: "Type",
    "Type of document": "Type of document",
    Until: "Until",
    Upcoming: "Upcoming",
    "Upcoming Bookings": "Upcoming Bookings",
    'Updated "{0}"': ['Updated "', ["0"], '"'],
    Upload: "Upload",
    "Upload a Test result or Vaccine Certificate":
      "Upload a Test result or Vaccine Certificate",
    "Upload a file": "Upload a file",
    "Upload a new file": "Upload a new file",
    "Use the fields below to update Billing.":
      "Use the fields below to update Billing.",
    "User Activity": "User Activity",
    "VAT Number": "VAT Number",
    "Valid until date": "Valid until date",
    "Value is required": "Value is required",
    "Value should be a number": "Value should be a number",
    "Value should be at most {0} characters long": [
      "Value should be at most ",
      ["0"],
      " characters long",
    ],
    "We use cookies and similar technologies to recognize your repeat visits and preferences, as well as to measure the effectiveness of our user experience and analyze user behaviour. To learn more about cookies, including how to disable them, view our cookie policy. By clicking accept you consent to the use of cookies. Without accepting these cookies you may not be able to utilize our platform properly.":
      "We use cookies and similar technologies to recognize your repeat visits and preferences, as well as to measure the effectiveness of our user experience and analyze user behaviour. To learn more about cookies, including how to disable them, view our cookie policy. By clicking accept you consent to the use of cookies. Without accepting these cookies you may not be able to utilize our platform properly.",
    "Will repeat": "Will repeat",
    "Workshop, Digital Team Meeting": "Workshop, Digital Team Meeting",
    "Wrong code": "Wrong code",
    "You are not authenticated to perform this action.":
      "You are not authenticated to perform this action.",
    "You are not authorized to perform this action or resource was not found.":
      "You are not authorized to perform this action or resource was not found.",
    "You are too far from the location!": "You are too far from the location!",
    "You do not have permissions to book on a given resources. Please contact your Administrator.":
      "You do not have permissions to book on a given resources. Please contact your Administrator.",
    "You don't have teammates yet.": "You don't have teammates yet.",
    "You have logged-in as a User but do not have registered account for this Portal. To continue using this Portal you have to ask an Operator to invite you.":
      "You have logged-in as a User but do not have registered account for this Portal. To continue using this Portal you have to ask an Operator to invite you.",
    "You have opened a Booking Form page without required information (see details below). Please contact your Administrator to report an issue.":
      "You have opened a Booking Form page without required information (see details below). Please contact your Administrator to report an issue.",
    "You have to upload a COVID-19 Test or Certificate to be able to book. Open 'Covid-19' page in the Profile Menu.":
      "You have to upload a COVID-19 Test or Certificate to be able to book. Open 'Covid-19' page in the Profile Menu.",
    "You have to upload a COVID-19 Test or Certificate to be able to check-in. Open 'Covid-19' page in the Profile Menu.":
      "You have to upload a COVID-19 Test or Certificate to be able to check-in. Open 'Covid-19' page in the Profile Menu.",
    "You must agree to our terms and conditions.":
      "You must agree to our terms and conditions.",
    "You should ask your Landlord to create a bookable resource":
      "You should ask your Landlord to create a bookable resource",
    "You were invited": "You were invited",
    'You were invited to "{0}". Do you want to accept or decline?': [
      'You were invited to "',
      ["0"],
      '". Do you want to accept or decline?',
    ],
    'You were invited to "{0}". Invite will be accepted with your currently selected account: {1}. Do you want to accept or decline?':
      [
        'You were invited to "',
        ["0"],
        '". Invite will be accepted with your currently selected account: ',
        ["1"],
        ". Do you want to accept or decline?",
      ],
    "You will book": "You will book",
    "You've successfully left the account.":
      "You've successfully left the account.",
    "Your selected membership is not active. To continue using the Members Portal you have to select an active membership.":
      "Your selected membership is not active. To continue using the Members Portal you have to select an active membership.",
    "Your timezone differs from the location timezone":
      "Your timezone differs from the location timezone",
    Zip: "Zip",
    all: "all",
    "and will repeat {0}": ["and will repeat ", ["0"]],
    "another user": "another user",
    "checked-in": "checked-in",
    days: "days",
    "did not start": "did not start",
    distance: "distance",
    edit: "edit",
    ended: "ended",
    for: "for",
    "full day": "full day",
    hours: "hours",
    "is running now": "is running now",
    loading: "loading",
    minutes: "minutes",
    "n/a": "n/a",
    never: "never",
    optional: "optional",
    "optional settings": "optional settings",
    outdated: "outdated",
    "per booking": "per booking",
    test: "test",
    valid: "valid",
    "valid 1-day": "valid 1-day",
    you: "you",
    "{0}": [["0"]],
    "{0} is checked-in here": [["0"], " is checked-in here"],
  },
}
