import React, { useContext, useState, useCallback, useEffect } from "react"
import PropTypes from "prop-types"
import { useHistory } from "react-router-dom"
import { reverse } from "named-urls"
import { urls } from "../../urls"
import { ApiContext } from "../../providers"
import { path } from "ramda"
import { CompanyForm } from "./company-form"

export const CompanyContext = React.createContext()

export function useCompany() {
  return useContext(CompanyContext)
}

const defaultState = {
  company: null,
  error: null,
  isPending: true,
}

export const CompanyProvider = ({ children }) => {
  const api = useContext(ApiContext)

  const [state, setState] = useState(defaultState)

  const { company, error, isPending } = state

  const getCompany = useCallback(async () => {
    try {
      const company = await api.getCompany()

      setState((state) => ({
        ...state,
        company,
        isPending: false,
        error: null,
      }))
    } catch (error) {
      if (path(["response", "status"], error) !== 404) {
        setState((state) => ({
          ...state,
          company: null,
          isPending: false,
          error,
        }))
      } else {
        setState((state) => ({
          ...state,
          company: null,
          isPending: false,
          error: null,
        }))
      }

      if (!error.isAxiosError) {
        throw error
      }
    }
  }, [api])

  function reset() {
    return setState({ ...defaultState, isPending: false })
  }

  useEffect(() => {
    getCompany()
  }, [getCompany])

  if (error && path(["response", "status"], error) !== 401) {
    throw error
  }

  return (
    <CompanyContext.Provider
      value={{ isPending, error, company, setState, getCompany, reset }}
    >
      {children}
    </CompanyContext.Provider>
  )
}

CompanyProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

CompanyProvider.defaultProps = {}

export const WithCompanyRequired = ({ children }) => {
  const { company } = useContext(CompanyContext)
  const history = useHistory()

  if (company) return children

  return (
    <CompanyForm
      onSubmitSuccess={() => history.push(reverse(urls.booking.calendar))}
    />
  )
}

WithCompanyRequired.propTypes = {
  children: PropTypes.node.isRequired,
}

WithCompanyRequired.defaultProps = {}
