import React, { useState } from "react"
import PropTypes from "prop-types"
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Spinner,
} from "reactstrap"

export default function FloorSelectorDropdown({
  className,
  style,
  size,
  selectedFloorName,
  selectedFloorId,
  floors,
  onSelect,
  disabled,
  loading,
}) {
  const [floorSelectorOpen, setFloorSelectorOpen] = useState(false)

  return (
    <Dropdown
      className={className}
      style={style}
      size={size}
      isOpen={floorSelectorOpen}
      toggle={() => setFloorSelectorOpen(!floorSelectorOpen)}
      disabled={disabled}
    >
      <DropdownToggle
        caret={!loading}
        className="btn-block text-truncate"
        color="primary"
      >
        {loading ? <Spinner size="sm" color="secondary" /> : selectedFloorName}
      </DropdownToggle>

      <DropdownMenu>
        {floors.map((floor) => (
          <DropdownItem
            key={floor.id}
            disabled={floor.id === selectedFloorId}
            value={floor.id}
            onClick={(e) => {
              e.preventDefault()
              const newFloorId = parseInt(e.target.value)
              if (newFloorId !== selectedFloorId) {
                setFloorSelectorOpen(!floorSelectorOpen)
                onSelect(floor)
              }
            }}
          >
            {floor.name}
          </DropdownItem>
        ))}
      </DropdownMenu>
    </Dropdown>
  )
}

FloorSelectorDropdown.defaultProps = {
  selectedFloorName: "",
  disabled: true,
  loading: false,
  className: "",
  size: "",
}

FloorSelectorDropdown.propTypes = {
  selectedFloorName: PropTypes.string,
  selectedFloorId: PropTypes.number,
  floors: PropTypes.array,
  onSelect: PropTypes.func,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  className: PropTypes.string,
  size: PropTypes.string,
  style: PropTypes.object,
}
