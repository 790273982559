import { t } from "@lingui/macro"
import { isLength } from "validator"
import { countries } from "../../constants"

export const companyValidator = async (values) => {
  const errors = {}

  const maxValueOptions = { max: 50 }
  const maxAboutOptions = { max: 5000 }
  const allowedCountryCodes = countries.map((cc) => cc.code)

  if (!values.name) {
    errors.name = t`Value is required`
  } else if (!isLength(values.name, maxValueOptions)) {
    errors.name = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.phone) {
    errors.phone = t`Value is required`
  } else if (!isLength(values.phone, maxValueOptions)) {
    errors.phone = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.street) {
    errors.street = t`Value is required`
  } else if (!isLength(values.street, maxValueOptions)) {
    errors.street = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.houseno) {
    errors.houseno = t`Value is required`
  } else if (!isLength(values.houseno, maxValueOptions)) {
    errors.houseno = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.city) {
    errors.city = t`Value is required`
  } else if (!isLength(values.city, maxValueOptions)) {
    errors.city = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.zip) {
    errors.zip = t`Value is required`
  } else if (!isLength(values.zip, maxValueOptions)) {
    errors.zip = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.country) {
    errors.country = t`Value is required`
  } else if (!allowedCountryCodes.includes(values.country)) {
    errors.country = t`Country should have a valid country code`
  }

  if (!values.about) {
    errors.about = t`Value is required`
  } else if (!isLength(values.about, maxAboutOptions)) {
    errors.about = t`Value should be at most ${maxAboutOptions.max} characters long`
  }

  return errors
}
