import { t } from "@lingui/macro"
import { isLength, isURL } from "validator"

export const covidCertificateFormValidator = async (values) => {
  const errors = {}

  const maxValueOptions = { max: 150 }

  if (!values.title) {
    errors.title = t`Value is required`
  } else if (!isLength(values.title, maxValueOptions)) {
    errors.title = t`Title should be at most ${maxValueOptions.max} characters long`
  }

  if (values.description && !isLength(values.description, maxValueOptions)) {
    errors.description = t`Description should be at most ${maxValueOptions.max} characters long`
  }

  if (!isURL(values.documentUrl)) {
    errors.documentUrl = t`Must be valid URL format`
  }

  return errors
}
