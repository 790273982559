import React from "react"
import PropTypes from "prop-types"
import { path } from "ramda"
import { t } from "@lingui/macro"
import { Container, Row, Col } from "reactstrap"
import {
  CLIENT_ERROR,
  SERVER_ERROR,
  TIMEOUT_ERROR,
  CONNECTION_ERROR,
  NETWORK_ERROR,
  CANCEL_ERROR,
  UNKNOWN_ERROR,
} from "../../constants"

export function ErrorPage(props) {
  const { err } = props
  let content

  switch (err.problem) {
    case SERVER_ERROR:
      content = (
        <ErrorBody
          code={500}
          title={t`Houston, we have a problem!`}
          subTitle={t`The page you are looking for is temporarily unavailable.`}
        />
      )
      break

    case NETWORK_ERROR:
      content = (
        <ErrorBody
          title={t`Network error!`}
          subTitle={t`Please check your internet connection.`}
        />
      )
      break

    case TIMEOUT_ERROR:
    case CONNECTION_ERROR:
    case CANCEL_ERROR:
    case UNKNOWN_ERROR:
      content = (
        <ErrorBody
          title={t`Houston, we have a problem!`}
          subTitle={t`The page you are looking for is temporarily unavailable.`}
        />
      )
      break

    case CLIENT_ERROR:
      switch (path(["response", "status"], err)) {
        case 404:
          content = (
            <ErrorBody
              code={404}
              title={t`Oops! You're lost.`}
              subTitle={t`The page you are looking for was not found.`}
            />
          )
          break

        default:
          content = (
            <ErrorBody
              code={path(["response", "status"], err)}
              title={t`Houston, we have a problem!`}
              subTitle={t`The page you are looking for is temporarily unavailable.`}
            />
          )
      }

      break

    default:
      content = (
        <ErrorBody
          title={t`Houston, we have a problem!`}
          subTitle={t`The page you are looking for is temporarily unavailable.`}
        />
      )
  }

  return content
}

ErrorPage.propTypes = {
  err: PropTypes.object.isRequired,
}

ErrorPage.defaultProps = {}

function ErrorBody({ code, title, subTitle, content }) {
  return (
    <div className="app flex-row align-items-center">
      <Container>
        <Row className="justify-content-center">
          <Col md="6">
            <div className="clearfix">
              {code && <h1 className="float-left display-3 mr-4">{code}</h1>}
              <h4 className="pt-3">{title}</h4>
              <p className="text-muted float-left">{subTitle}</p>
            </div>
            {content}
          </Col>
        </Row>
      </Container>
    </div>
  )
}

ErrorBody.propTypes = {
  code: PropTypes.number,
  title: PropTypes.node.isRequired,
  subTitle: PropTypes.node.isRequired,
  content: PropTypes.node,
}

ErrorBody.defaultProps = {
  content: null,
}
