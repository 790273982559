import { t } from "@lingui/macro"
import { CookieBanner } from "@palmabit/react-cookie-law"
import { isEmpty } from "lodash"
import React, { useContext, useEffect } from "react"
import { isIE } from "react-device-detect"
import { Route, Switch } from "react-router-dom"
import { toast } from "react-toastify"
import { config } from "../../config"
import { changeFavicon, setThemeVariable } from "../../lib/helpers"
import { Accounts, WhiteLabelContext } from "../../modules/account"
import { Activity } from "../../modules/activity"
import { Billing } from "../../modules/billing/billing"
import { Booking } from "../../modules/booking"
import { Covid } from "../../modules/covid"
import { Floorplan } from "../../modules/floorplan"
import { Maintenance } from "../../modules/maintenance"
import { NotFoundPage } from "../../modules/not-found"
import { MembershipPlan } from "../../modules/plan"
import { Resources } from "../../modules/resource"
import { urls } from "../../urls"
import { Header } from "../header"

export function AppLayout() {
  useEffect(() => {
    if (isIE) {
      toast.info(
        t`Consider using another web browser for reliability of the app features`,
        { autoClose: 10000 },
      )
    }
  })

  const { whiteLabelSettings, isPending: isWhiteLabelPending } =
    useContext(WhiteLabelContext)

  if (
    !isWhiteLabelPending &&
    whiteLabelSettings &&
    !isEmpty(whiteLabelSettings.values)
  ) {
    // eslint-disable-next-line
    Object.keys(whiteLabelSettings.values).map((key) => {
      if (key.startsWith("color")) {
        setThemeVariable(key, whiteLabelSettings.values[key])
      }
    })

    changeFavicon(whiteLabelSettings.values)
  }

  return (
    <div className="app">
      <Header />
      <CookieBanner
        message={t`We use cookies and similar technologies to recognize your repeat visits and preferences, as well as to measure the effectiveness of our user experience and analyze user behaviour. To learn more about cookies, including how to disable them, view our cookie policy. By clicking accept you consent to the use of cookies. Without accepting these cookies you may not be able to utilize our platform properly.`}
        showMarketingOption={false}
        showPreferencesOption={false}
        policyLink={`${config.intertempi.homePage.url}/privacy.html`}
        styles={{
          dialog: {
            bottom: "0px",
          },
          container: {
            bottom: "0px",
            right: "0px",
            width: "100%",
            position: "fixed",
            textAlign: "center",
            backgroundColor: "rgb(184, 107, 107)",
            zIndex: "999999",
          },
          buttonWrapper: {
            paddingTop: "10px",
            paddingBottom: "10px",
          },
          button: {
            backgroundColor: "#20a8d8",
            borderColor: "#20a8d8",
            fontFamily: "Calibri",
          },
          message: {
            fontSize: "15px",
            fontWeight: "bold",
            margin: "1.5em",
            zIndex: "999999",
          },
          policy: {
            display: "block",
            fontSize: "10pt",
            marginLeft: "10px",
            color: "rgb(0,0,0)",
            textDecoration: "underline",
            fontFamily: "Calibri",
          },
          selectPane: {
            display: "inline-block",
          },
        }}
      />

      <div className="app-body mt-0">
        <main className="main pb-5">
          <Switch>
            <Route exact path={[urls.floorplan.view]} component={Floorplan} />

            <Route
              path={[
                urls.booking.teammates,
                urls.booking.calendar,
                urls.booking.timeline,
                urls.booking.create,
                urls.booking.details,
                urls.booking.update,
                urls.recurringBooking.update,
              ]}
              component={Booking}
            />

            <Route
              path={[
                urls.company,
                urls.profile.membership.details,
                urls.profile.membership.documents,
                urls.profile.update,
                urls.profile.user.documents,
                urls.team.list,
                urls.account.create,
              ]}
              component={Accounts}
            />

            <Route
              path={[urls.membershipPlan.details]}
              component={MembershipPlan}
            />

            <Route
              path={[urls.resource.details, urls.resource.list]}
              component={Resources}
            />

            <Route path={[urls.billing]} component={Billing} />

            <Route path={[urls.activity.checkins.list]} component={Activity} />

            <Route
              path={[
                urls.maintenance.serviceTickets.create,
                urls.maintenance.serviceTickets.success,
              ]}
              component={Maintenance}
            />

            <Route
              exact
              path={[urls.covid.list, urls.covid.create, urls.covid.edit]}
              component={Covid}
            />

            <Route component={NotFoundPage} />
          </Switch>
        </main>
      </div>
    </div>
  )
}
