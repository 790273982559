import React from "react"
import { useHistory, useLocation } from "react-router-dom"
import qs from "qs"
import { ServiceTicketForm } from "./service-ticket-form"
import { reverse } from "named-urls"
import { urls } from "../../urls"

export function ServiceTicketCreate(props) {
  const history = useHistory()
  const location = useLocation()

  const { sid: supplyId, rid: resourceId } = qs.parse(location.search, {
    ignoreQueryPrefix: true,
  })

  return (
    <ServiceTicketForm
      supplyId={supplyId}
      resourceId={resourceId}
      onSubmitSuccess={() =>
        history.length > 2
          ? history.goBack()
          : history.push(reverse(urls.maintenance.serviceTickets.success))
      }
    />
  )
}

ServiceTicketCreate.propTypes = {}
ServiceTicketCreate.defaultProps = {}
