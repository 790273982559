import { isLength, isInt, isAfter } from "validator"
import { subMinutes, isToday } from "date-fns"
import { HALF_HOUR } from "../resource"
import { t } from "@lingui/macro"

export const bookingValidator = async (values) => {
  const errors = {}

  const maxValueOptions = { max: 50 }

  if (!isLength(values.title, maxValueOptions)) {
    errors.title = t`Value should be at most ${maxValueOptions.max} characters long`
  }

  if (!values.resourceId) {
    errors.resourceId = t`Value is required`
  } else if (!isInt(String(values.resourceId))) {
    errors.resourceId = t`Value should be a number`
  }

  if (
    !values.isRecurringCheckbox &&
    values.durationUnit === HALF_HOUR &&
    isToday(values.startAtDay) &&
    !isAfter(
      values.startAt.toISOString(),
      subMinutes(new Date(), 25).toISOString(),
    )
  ) {
    errors.startAt = t`Cannot set this starting time`
  }

  return errors
}
