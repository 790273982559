import { i18n } from "@lingui/core"
import { Trans } from "@lingui/macro"
import { reverse } from "named-urls"
import qs from "qs"
import { path, pathOr } from "ramda"
import React, { useContext, useEffect, useState } from "react"
import { Initial } from "react-initial"
import { useMediaQuery } from "react-responsive"
import { Link, NavLink, useHistory, useLocation } from "react-router-dom"
import {
  Collapse,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  UncontrolledDropdown,
} from "reactstrap"
import defaultLogo from "../../assets/img/logo.png"
import { config } from "../../config"
import { LANGUAGE_OPTIONS } from "../../constants"
import {
  capitalize,
  getMembershipLocationAddress,
  sortByCaseInsensitive,
} from "../../lib/helpers"
import {
  AccountContext,
  CompanyContext,
  UserContext,
  WhiteLabelContext,
  useAccount,
} from "../../modules/account"
import { useBilling } from "../../modules/billing"
import { isMembershipActive, useMembership } from "../../modules/membership"
import { ApiContext, useStore } from "../../providers"
import { urls } from "../../urls"
import { CheckinHeaderTimer } from "./checkin-header-timer"

export function Header() {
  const [headerCollapsed, setHeaderCollapsed] = useState(true)
  const api = useContext(ApiContext)
  const { language, updateStore } = useStore()
  const history = useHistory()
  const location = useLocation()
  const { selectedAccount, setSelectedAccount } = useAccount()
  const { user, reset: resetUser } = useContext(UserContext)
  const { reset: resetAccount } = useContext(AccountContext)
  const { reset: resetCompany } = useContext(CompanyContext)
  const { reset: resetBilling } = useBilling()
  const { whiteLabelSettings } = useContext(WhiteLabelContext)

  const {
    reset: resetMembership,
    selectedMembership,
    selectMembership,
    memberships,
  } = useMembership()

  const isMobile = useMediaQuery({ maxWidth: 767 })

  useEffect(() => {
    if (
      isMobile &&
      user &&
      location.pathname === reverse(urls.floorplan.view)
    ) {
      history.push(reverse(urls.resource.list))
    }
  }, [isMobile, user, location, history])

  async function onLogoutClick() {
    await api.deleteToken()
    api.deleteSession()
    resetUser()
    resetAccount()
    resetCompany()
    resetBilling()
    resetMembership()
  }

  const { logoUrl, baseUrl, logoHeight, logoWidth } = {
    ...whiteLabelSettings,
    logoUrl: whiteLabelSettings?.logoUrl ?? defaultLogo,
    logoHeight: whiteLabelSettings?.values?.logoHeight ?? "40",
    baseUrl:
      whiteLabelSettings?.values?.baseUrl ??
      config.intertempi.memberPortalPage.url,
  }

  return (
    <>
      {/* -- DESKTOP VERSION -- */}
      <Navbar
        color="white"
        light
        expand="md"
        className="d-none d-md-flex list-unstyled i-bottom-border-color"
      >
        <NavbarBrand
          tag={Link}
          to={reverse(urls.floorplan.view)}
          className="d-flex justify-content-end"
        >
          <img
            src={logoUrl}
            alt={baseUrl}
            height={logoHeight}
            width={logoWidth}
          />
        </NavbarBrand>

        <NavbarToggler onClick={() => setHeaderCollapsed(!headerCollapsed)} />

        <Collapse isOpen={!headerCollapsed} navbar>
          <Nav className="mr-auto" navbar>
            {user &&
              selectedAccount &&
              selectedMembership &&
              isMembershipActive(selectedMembership) && (
                <>
                  <NavItem
                    active={location.pathname === reverse(urls.floorplan.view)}
                    className="px-3 px-lg-3 px-md-2"
                  >
                    <NavLink
                      className="nav-link"
                      to={reverse(urls.floorplan.view)}
                    >
                      <i className="fas fa-building d-md-none d-lg-inline" />{" "}
                      <Trans>Floorplans</Trans>
                    </NavLink>
                  </NavItem>
                  <NavItem
                    active={location.pathname === reverse(urls.floorplan.view)}
                    className="px-3 px-lg-3 px-md-2"
                  >
                    <NavLink
                      className="nav-link"
                      to={reverse(urls.resource.list)}
                    >
                      <i className="fas fa-bars d-md-none d-lg-inline" />{" "}
                      <Trans>Resources</Trans>
                    </NavLink>
                  </NavItem>
                  <UncontrolledDropdown nav id="booking-nav">
                    <DropdownToggle nav caret>
                      <i className="far fa-calendar-check d-md-none d-lg-inline" />{" "}
                      <Trans>Bookings</Trans>
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem>
                        <NavItem
                          active={
                            location.pathname === reverse(urls.booking.calendar)
                          }
                          className="px-3"
                        >
                          <NavLink
                            className="nav-link"
                            to={reverse(urls.booking.calendar)}
                          >
                            <i className="far fa-calendar-check d-md-none d-lg-inline" />{" "}
                            <Trans>Calendar</Trans>
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem
                          active={
                            location.pathname ===
                            reverse(urls.booking.teammates)
                          }
                          className="px-3 px-lg-3 px-md-2"
                        >
                          <NavLink
                            className="nav-link"
                            to={reverse(urls.booking.teammates)}
                          >
                            <i className="fas fa-people-arrows d-md-none d-lg-inline" />{" "}
                            <Trans>Team Bookings</Trans>
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem
                          active={
                            location.pathname ===
                            reverse(urls.team.details, {
                              userId: user.id,
                            })
                          }
                          className="px-3 px-lg-3 px-md-2"
                        >
                          <NavLink
                            className="nav-link"
                            to={reverse(urls.team.details, {
                              userId: user.id,
                            })}
                          >
                            <i className="fas fa-people-arrows d-md-none d-lg-inline" />{" "}
                            <Trans>My Bookings</Trans>
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                      <DropdownItem>
                        <NavItem
                          active={
                            location.pathname === reverse(urls.booking.create)
                          }
                          className="px-3 px-lg-3 px-md-2"
                        >
                          <NavLink
                            className="nav-link"
                            to={reverse(urls.booking.create)}
                          >
                            <i className="fas fa-calendar d-md-none d-lg-inline" />{" "}
                            <Trans>Book Now</Trans>
                          </NavLink>
                        </NavItem>
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>

                  <NavItem
                    active={
                      location.pathname === reverse(urls.booking.timeline)
                    }
                    className="px-3"
                  >
                    <NavLink
                      className="nav-link"
                      to={reverse(urls.booking.timeline)}
                    >
                      <i className="far fa-calendar-alt" />{" "}
                      <Trans>Timeline</Trans>
                    </NavLink>
                  </NavItem>

                  {selectedMembership.location.checkinAndServiceEnabled && (
                    <NavItem
                      active={
                        location.pathname ===
                        reverse(urls.activity.checkins.list)
                      }
                      className="px-3 px-lg-3 px-md-2"
                    >
                      <NavLink
                        className="nav-link"
                        to={reverse(urls.activity.checkins.list)}
                      >
                        <i className="fas fa-star d-md-none d-lg-inline" />{" "}
                        <Trans>Checkins</Trans>
                      </NavLink>
                    </NavItem>
                  )}

                  {selectedMembership.location.checkinAndServiceEnabled && (
                    <NavItem
                      active={
                        location.pathname ===
                        reverse(urls.maintenance.serviceTickets.create)
                      }
                      className="px-3 px-lg-3 px-md-2"
                    >
                      <NavLink
                        className="nav-link"
                        to={reverse(urls.maintenance.serviceTickets.create)}
                      >
                        <i className="fas fa-concierge-bell d-md-none d-lg-inline" />{" "}
                        <Trans>Service</Trans>
                      </NavLink>
                    </NavItem>
                  )}
                </>
              )}
          </Nav>
        </Collapse>

        {user && selectedAccount && memberships.length > 0 && (
          <UncontrolledDropdown id="nav-location" nav inNavbar>
            <DropdownToggle nav caret>
              {pathOr(
                "Select location",
                ["location", "name"],
                selectedMembership,
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <Trans>Current location</Trans>
                </strong>
              </DropdownItem>

              <DropdownItem tag="div">
                <a
                  href={
                    "https://www.google.com/maps/dir/" +
                    qs.stringify(
                      {
                        api: "1",
                        destination:
                          getMembershipLocationAddress(selectedMembership),
                        travelmode: "transit",
                      },
                      { addQueryPrefix: true },
                    )
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {getMembershipLocationAddress(selectedMembership)}
                </a>
              </DropdownItem>

              <DropdownItem
                href={pathOr("#", ["location", "tcUrl"], selectedMembership)}
                tag="a"
                target="_blank"
              >
                <strong>
                  <Trans>Terms &amp; Conditions</Trans>
                </strong>
              </DropdownItem>

              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <Trans>Select location</Trans>
                </strong>
              </DropdownItem>

              {sortByCaseInsensitive(["location", "name"], memberships)
                .filter(isMembershipActive)
                .map((m) => (
                  <DropdownItem
                    key={m.id}
                    disabled={m.id === path(["id"], selectedMembership)}
                    onClick={() => selectMembership(m.id)}
                  >
                    {m.location.name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}

        {!user && (
          <UncontrolledDropdown id="nav-language" nav inNavbar>
            <DropdownToggle nav caret>
              <Trans>Language</Trans>
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <Trans>Select language</Trans>
                </strong>
              </DropdownItem>

              {LANGUAGE_OPTIONS.map((o) => (
                <DropdownItem
                  key={o.value}
                  disabled={o.value === language}
                  onClick={() => updateStore({ language: o.value })}
                >
                  {i18n._(o.label)}
                </DropdownItem>
              ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}

        {user && (
          <UncontrolledDropdown id="nav-profile" nav inNavbar>
            <DropdownToggle nav>
              <Initial
                name={user.firstName}
                fontSize={24}
                height={40}
                width={40}
              />
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <Trans>Settings</Trans>
                </strong>
              </DropdownItem>
              <DropdownItem
                disabled={location.pathname === reverse(urls.covid.list)}
                to={reverse(urls.covid.list)}
                tag={Link}
              >
                <i className="fas fa-viruses" /> COVID-19
              </DropdownItem>

              <DropdownItem
                disabled={location.pathname === reverse(urls.profile.update)}
                to={reverse(urls.profile.update)}
                tag={Link}
              >
                <i className="fas fa-user" /> <Trans>My Profile</Trans>
              </DropdownItem>

              {selectedAccount && (
                <DropdownItem
                  disabled={location.pathname === reverse(urls.team.list)}
                  to={reverse(urls.team.list)}
                  tag={Link}
                >
                  <i className="fas fa-users" /> <Trans>My Team</Trans>
                </DropdownItem>
              )}

              {selectedAccount && (
                <DropdownItem
                  disabled={location.pathname === reverse(urls.company)}
                  to={reverse(urls.company)}
                  tag={Link}
                >
                  <i className="fas fa-briefcase" /> <Trans>My Company</Trans>
                </DropdownItem>
              )}

              <UncontrolledDropdown id="nav-language" nav inNavbar>
                <DropdownItem>
                  <i className="fas fa-globe" /> <Trans>Language</Trans>
                </DropdownItem>

                <DropdownMenu right>
                  <DropdownItem header tag="div" className="text-center">
                    <strong>
                      <Trans>Select language</Trans>
                    </strong>
                  </DropdownItem>

                  {LANGUAGE_OPTIONS.map((o) => (
                    <DropdownItem
                      key={o.value}
                      disabled={o.value === language}
                      onClick={() => updateStore({ language: o.value })}
                    >
                      {i18n._(o.label)}
                    </DropdownItem>
                  ))}
                </DropdownMenu>
              </UncontrolledDropdown>

              {selectedAccount &&
                selectedMembership &&
                selectedMembership.location.billingEnabled && (
                  <DropdownItem
                    disabled={location.pathname === reverse(urls.billing)}
                    to={reverse(urls.billing)}
                    tag={Link}
                  >
                    <i className="fas fa-credit-card" /> <Trans>Billing</Trans>
                  </DropdownItem>
                )}

              <DropdownItem
                onClick={() => {
                  setSelectedAccount(null)
                  resetMembership()
                  history.push("/")
                }}
              >
                <i className="fas fa-id-card" />{" "}
                <Trans>Accounts / Invites</Trans>
              </DropdownItem>

              <DropdownItem onClick={onLogoutClick}>
                <i className="fas fa-unlock-alt" /> <Trans>Logout</Trans>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Navbar>
      {/* -- END DESKTOP VERSION -- */}

      {/* -- MOBILE VERSION -- */}
      <Navbar
        color="white"
        light
        className="d-md-none d-lg-none d-xl-none list-unstyled"
      >
        <NavbarBrand tag={Link} to={reverse(urls.resource.list)}>
          <img
            src={whiteLabelSettings?.logoUrl ?? defaultLogo}
            alt={whiteLabelSettings?.baseUrl ?? "intertempi"}
            width="50"
            height="50"
          />
        </NavbarBrand>

        {user && selectedAccount && memberships.length > 0 && (
          <UncontrolledDropdown id="nav-location" nav inNavbar>
            <DropdownToggle nav caret>
              {pathOr(
                "Select location",
                ["location", "name"],
                selectedMembership,
              )}
            </DropdownToggle>
            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <Trans>Select location</Trans>
                </strong>
              </DropdownItem>

              {sortByCaseInsensitive(["location", "name"], memberships).map(
                (m) => (
                  <DropdownItem
                    key={m.id}
                    disabled={
                      m.id === path(["id"], selectedMembership) ||
                      !isMembershipActive(m)
                    }
                    onClick={() => selectMembership(m.id)}
                  >
                    {m.location.name}{" "}
                    {!isMembershipActive(m) && `(${capitalize(m.status)})`}
                  </DropdownItem>
                ),
              )}
            </DropdownMenu>
          </UncontrolledDropdown>
        )}

        {user && (
          <UncontrolledDropdown id="nav-profile" nav inNavbar>
            <DropdownToggle nav>
              <Initial
                name={user.firstName}
                fontSize={24}
                height={40}
                width={40}
              />
            </DropdownToggle>

            <DropdownMenu right>
              <DropdownItem header tag="div" className="text-center">
                <strong>
                  <Trans>Settings</Trans>
                </strong>
              </DropdownItem>
              <DropdownItem
                disabled={location.pathname === reverse(urls.profile.update)}
                to={reverse(urls.profile.update)}
                tag={Link}
              >
                <i className="fas fa-user" /> <Trans>My Profile</Trans>
              </DropdownItem>
              {selectedAccount && (
                <DropdownItem
                  disabled={location.pathname === reverse(urls.team.list)}
                  to={reverse(urls.team.list)}
                  tag={Link}
                >
                  <i className="fas fa-users" /> <Trans>My Team</Trans>
                </DropdownItem>
              )}
              {selectedAccount &&
                selectedMembership &&
                selectedMembership.location.billingEnabled && (
                  <DropdownItem
                    disabled={location.pathname === reverse(urls.billing)}
                    to={reverse(urls.billing)}
                    tag={Link}
                  >
                    <i className="fas fa-credit-card" /> <Trans>Billing</Trans>
                  </DropdownItem>
                )}
              {selectedAccount && (
                <DropdownItem
                  onClick={() => {
                    setSelectedAccount(null)
                    resetMembership()
                    history.push("/")
                  }}
                >
                  <i className="fas fa-id-card" />{" "}
                  <Trans>Accounts / Invites</Trans>
                </DropdownItem>
              )}
              <DropdownItem onClick={onLogoutClick}>
                <i className="fas fa-unlock-alt" /> <Trans>Logout</Trans>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        )}
      </Navbar>

      {user &&
        selectedAccount &&
        selectedMembership &&
        isMembershipActive(selectedMembership) && (
          <Navbar
            color="white"
            light
            fixed="bottom"
            className="d-md-none d-lg-none d-xl-none font-weight-bolder"
          >
            <Nav
              navbar
              className="w-100 d-flex flex-row flex-wrap justify-content-between"
            >
              <NavItem
                active={location.pathname === reverse(urls.booking.teammates)}
                className="p-0"
              >
                <NavLink
                  exact
                  className="nav-link"
                  to={reverse(urls.booking.teammates)}
                >
                  <Trans>Bookings</Trans>
                </NavLink>
              </NavItem>

              <NavItem
                active={location.pathname === reverse(urls.booking.create)}
                className="p-0"
              >
                <NavLink
                  exact
                  className="nav-link"
                  to={reverse(urls.resource.list)}
                >
                  <Trans>Resources</Trans>
                </NavLink>
              </NavItem>

              <NavItem
                active={location.pathname === reverse(urls.booking.create)}
                className="p-0"
              >
                <NavLink
                  exact
                  className="nav-link"
                  to={reverse(urls.booking.create)}
                >
                  <Trans>Book now</Trans>
                </NavLink>
              </NavItem>

              <NavItem
                active={location.pathname === reverse(urls.booking.calendar)}
                className="p-0"
              >
                <NavLink
                  exact
                  className="nav-link"
                  to={reverse(urls.booking.calendar)}
                >
                  <Trans>Calendar</Trans>
                </NavLink>
              </NavItem>
              <NavItem
                active={
                  location.pathname === reverse(urls.profile.membership.details)
                }
                className="p-0 mr-5"
              >
                <NavLink
                  exact
                  className="nav-link"
                  to={reverse(urls.profile.membership.details)}
                >
                  <Trans>Membership</Trans>
                </NavLink>
              </NavItem>
            </Nav>
          </Navbar>
        )}
      {/* -- END MOBILE VERSION -- */}

      {selectedMembership ? <CheckinHeaderTimer /> : null}
    </>
  )
}

Header.propTypes = {}
Header.defaultProps = {}
